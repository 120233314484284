import { styled } from "@mui/styles"
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    '&:before': {
        display: 'none'
    }
}))

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary expandIcon={<ExpandMoreIcon sx={{fontSize: '0.9rem'}} />} {...props} />
))(({ theme }) => ({
    flexDirection: 'row',
    display: 'inline-flex',
    gap: 32,
    alignItems: 'center',
    paddingRight: '8px',
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1)
    }
}))