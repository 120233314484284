import { useCallback, useEffect, useState } from 'react'
import { getBreadcrumbs } from 'config/breadcrumbs'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { AppState } from 'store/configureStore'
import { Breadcrumbs, Link, Typography } from '@mui/material'

type BreadcrumbElement = {
    label: string,
    path: string,
}

const Breadcrumb = () => {
    const appData = useSelector((state: AppState) => state.app)
    const location = useLocation()
    const history = useHistory()
    const [breadcrumb, setBreadcrumb] = useState<BreadcrumbElement[]>([])
    const [resourceName, setResourceName] = useState<string>('Loading...')

    useEffect(() => {
        if (appData.loadedResource) {
            setResourceName(appData.loadedResource)
        } else {
            setResourceName('Loading...')
        }
    }, [appData.loadedResource])

    const initiateBreadcrumb = useCallback(async () => {
        const getResourceName = async (arrayLabels: string[], idResource: number) => {
            let newArrayTreated = [...arrayLabels]
            newArrayTreated[1] = resourceName
            return newArrayTreated
        }

        const pathname: string = location.pathname === '/' ? '/datasets' : location.pathname
        let idResource: null | number = null
        let splittedPathname: string[] = pathname.split('/')
        if (splittedPathname[splittedPathname.length - 1] === '') splittedPathname.pop()
        if (splittedPathname[0] === '') splittedPathname.shift()
        splittedPathname = splittedPathname.map((el: string) => {
            if (parseInt(el)) {
                idResource = parseInt(el)
                el = '*'
            }
            return el
        })

        let breadcrumbLabels = getBreadcrumbs(splittedPathname.join('/')) || ['error']
        if (idResource != null)
            breadcrumbLabels = await getResourceName(breadcrumbLabels, idResource)
        
        let result: BreadcrumbElement[] = []
        let tempPath: string = '/'
        splittedPathname.forEach((str: string, i: number) => {
            tempPath += str === '*' ? `${idResource}/` : `${str}/`
            result.push({ label: breadcrumbLabels[i], path: tempPath })
        })

        setBreadcrumb(result)
    }, [location.pathname, resourceName])

    useEffect(() => {
        initiateBreadcrumb()
    }, [location.pathname, resourceName, initiateBreadcrumb])

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                {breadcrumb.map((el, i) => {
                    let isActive = i === (breadcrumb.length - 1)
                    const cleanPath = el.path.substring(el.path.length - 1) === '/' ? el.path.slice(0, -1) : el.path
                    return (
                        <Link color={isActive ? "textPrimary" : "inherit"} href="" onClick={(e) => {
                            e.preventDefault()
                            history.push(cleanPath)
                        }} key={i}>
                            {el.label}
                        </Link>
                    )
                })}
            </Breadcrumbs>
            <Typography variant="body1">/</Typography>
        </div>
    )
}

export default Breadcrumb