import apiHandler from 'api/apiHandler'
import { getListConnectors } from 'assets/data-connections/connectorsData'
import React, { useEffect, useState, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom';
import { AppSetResource, AppUnsetResource } from 'store/actions/AppActions'
import { Body } from 'styles/layout'
import { UserContext } from 'context/authContext'
import { RouterContext } from "components/Router";
import ConnectorForm from 'assets/data-connections/ConnectorForm'
import TitlePage from 'components/layout/TitlePage'

export interface ConnectorFieldDependency {
    field: string,
    condition: boolean,
}

export type ConnectorValueType = "text" | "secret" | "check" | "toggle" | "file"

export interface ConnectorFieldType {
    fieldType: ConnectorValueType,
    fieldLabel: string,
    fieldName: string,
    disabled?: boolean,
    optional?: boolean,
    helperText: string,
    defaultValue: string | boolean,
    dependency?: ConnectorFieldDependency
}

export interface RecordFormat {
    credentials: ConnectorFieldType[],
    params: ConnectorFieldType[],
}

export type DataConnectionElement = { [key: string]: string | boolean };

export interface DataConnection {
    id: number,
    name: string,
    connector_type: string,
    created: string,
    last_modified: string,
    credentials: string | DataConnectionElement[],
    params: DataConnectionElement[],
    owner_id: number,
    organization_id: number,
    is_public: boolean,
    allow_copy: boolean
}

export interface DataConnectionRecord {
    credentials: string | DataConnectionElement[],
    params: DataConnectionElement[],
    is_public: boolean,
    allow_copy: boolean,
}

export const showConnectorData = (connector: string, out: "name" | "logo"): string | undefined => {
    const connectorType = connector.toLowerCase();
    const connectorsData = getListConnectors();
    const connectorData = connectorsData.find((c) => c.name === connectorType);
    if (!connectorData) {
        return undefined
    } else if (out === "name") {
        return connectorData.labelLong?.toString() || connectorData.label.toString()
    } else if (out === "logo") {
        return connectorData.logo
    }
}

const DataConnectionShow = () => {

    const { id } = useParams<{id: string}>();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [record, setRecord] = useState<any>();
    const [recordFormat, setRecordFormat] = useState<RecordFormat>();
    const { hasPermissions } = useContext(UserContext);
    const [justCreated, setJustCreated] = useState<boolean>(false)
    const dispatch = useDispatch();
    const route = useContext(RouterContext)

    useEffect(() => {
        if (!hasPermissions(['connection.viewAll', 'connection.viewGlobal', 'connection.editAll'])) {
            history.push('/data-connections')
        }
    }, [hasPermissions, history])

    useEffect(() => {
        if (route?.to === '/data-connections/create' && !justCreated) {
            setJustCreated(true)
        }
    }, [route, justCreated])

    useEffect(() => {
        if (!id) return;
        apiHandler.getDataConnection(parseInt(id)).then(async (dataConnection) => {
            let fields: any = [];
            const dataConnectorFormat = getListConnectors().find((e) => e.name === dataConnection.connector_type)!;
            const formatDC: RecordFormat = await apiHandler.getDataConnectionFormat(dataConnectorFormat.nameFormat);
            formatDC.credentials.forEach((field: ConnectorFieldType) => {
                const value = (field.fieldType === 'check' || field.fieldType === 'toggle') ? false : field.fieldType === 'file' ? undefined : '';
                fields.push({ name: `credentials.${field.fieldName}`, value });
            })
            formatDC.params.forEach((field: ConnectorFieldType) => {
                const value = dataConnection.params[field.fieldName] ? dataConnection.params[field.fieldName] : (field.fieldType === 'toggle' || field.fieldType === 'check') ? false : '';
                fields.push({name: `params.${field.fieldName}`, value: typeof value === 'object' ? JSON.stringify(value) : value})
            })
            
            const dataForRecord = {
                name: dataConnection.name,
                connector_type: dataConnection.connector_type,
                fields,
                allow_copy: dataConnection.allow_copy,
                is_public: dataConnection.is_public,
            }
            setRecordFormat(formatDC)
            setRecord(dataForRecord)
            dispatch(AppSetResource(dataConnection.name))
        }).then(() => setIsLoading(false));

        return () => {
            dispatch(AppUnsetResource())
        }
    }, [id, dispatch])

    if (!recordFormat || !record || isLoading) {
        return null;
    } else {
        return (
            <Body>
                <TitlePage
                    resource="data-connections"
                    option="show"
                    name={record.name}
                />
                <ConnectorForm dcCreated={justCreated} />
            </Body>
        )
    }
}

export default DataConnectionShow
