import { useEffect, useContext } from 'react'
import LoginForm from 'components/auth/LoginForm'
import { useHistory } from 'react-router-dom';
import { UserContext } from 'context/authContext';
import config from 'config/global';
import { Box, Button, Theme, Typography } from '@mui/material';
import { FcGoogle } from 'react-icons/fc'
import AuthTemplate from 'assets/templates/AuthTemplate';
import SarusLink from 'components/SarusLink';
import { createStyles, makeStyles } from '@mui/styles';
import Divider from 'components/Divider';

const useStyles = makeStyles((theme: Theme) => createStyles({
    typo: { textAlign: 'center' },
}))

const LoginPage = () => {
    const { isLoggedIn, isLoading } = useContext(UserContext);
    const classes = useStyles();
    const history = useHistory();

    useEffect(() => {
        if (isLoggedIn && !isLoading) {
            history.push(config.landingPage);
        }
    }, [isLoggedIn, isLoading, history]);

    const useGoogleLogin = (e: any) => {
        e.preventDefault();
        localStorage.setItem('username', 'oidc')
        const redirect = process.env.PUBLIC_URL + '/v1/oidc_login';
        window.location.replace(redirect)
    }

    const GoogleLogin = () => {
        if (!config.activateGoogleLogin) return null;

        return (
            <>
                <Divider>OR</Divider>
                <Button
                    onClick={useGoogleLogin}
                    size="large"
                    variant="outlined"
                    color="primary"
                    fullWidth
                    startIcon={<FcGoogle />}
                >
                    Continue with Google
                </Button>
            </>
        )
    }

    return (
        <AuthTemplate>
            <Box className="boxContainer">
                <LoginForm />
                <Typography className={classes.typo}>
                    <SarusLink to="/forgotten-password">Forgotten password?</SarusLink>
                </Typography>
            </Box>
            <GoogleLogin />
            <Typography align="center" sx={{fontSize: 12}} color="textSecondary">
                Don't have a Sarus account yet? <SarusLink to="/signup" bold>Sign up</SarusLink>.
            </Typography>
        </AuthTemplate>
    )
}

export default LoginPage
