import React, { FC, useState } from 'react'
import Button from '@mui/material/Button';

import { Theme } from '@mui/material/styles';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';
import PrivacyPolicyForm from './PrivacyPolicyForm';

type DialogProps = {
    open: boolean,
    onClose: (r?: any) => void,
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  return (
      <MuiDialogTitle sx={{m: 0, p: 2}} {...other}>
        <Typography variant="h6" component="span">{children}</Typography>
        {onClose ? (
          <IconButton
              aria-label="close"
                  sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
              }}
              onClick={onClose}
              size="large">
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
  );
};

const DialogCreatePrivacyPolicy: FC<DialogProps> = props => {
    const { open, onClose } = props;
    const [submitFromDial, setSubmitFromDial] = useState<boolean>(false);

    const handleSubmit = (event: React.SyntheticEvent) => {
        event.preventDefault();
        setSubmitFromDial(true);
    }

    return (
        <Dialog scroll={"paper"} open={open} fullWidth maxWidth="md" onClose={onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title" onClose={onClose}>Create New Privacy Policy</DialogTitle>
            <DialogContent style={{paddingBottom: 16 }}>
                <PrivacyPolicyForm isDialog={onClose} submitFromDial={{ val: submitFromDial, set: setSubmitFromDial }} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Create Privacy Policy
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DialogCreatePrivacyPolicy
