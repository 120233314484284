import React, { FC, Fragment } from 'react'
import Button from '@mui/material/Button'
import { ButtonProps } from './ButtonsType';
import { CircularProgress } from '@mui/material';

const CadredButton: FC<ButtonProps> = (props) => {
    const { action, children, disabled, isLoading } = props;
    
    return (
        <Fragment>
            <Button
                color="primary"
                variant="outlined"
                onClick={action}
                disabled={disabled || isLoading}
                endIcon={isLoading && <CircularProgress size={16} />}
            >{children}</Button>
        </Fragment>
    )
}

export default CadredButton
