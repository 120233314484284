import React, { useState, useContext, ChangeEvent, FC, useEffect, Fragment } from 'react'
import { useHistory } from 'react-router-dom';
import { UserContext } from 'context/authContext';
import apiHandler from 'api/apiHandler';
import { LoginData } from 'types/user';
import { ErrorInForm } from 'types/forms';
import { validateEmail } from 'utils/misc';
import PasswordInput from 'components/PasswordInput';
import { FormHelperText, Grid, TextField, Link, Typography, Button, Dialog, DialogTitle, DialogActions, DialogContent } from '@mui/material'
import SimpleCheckbox from 'components/SimpleCheckbox'
import { getText } from 'utils/dictionnary';
import { uniqueId } from 'lodash';

type ResetPasswordData = {
    data: LoginData,
    open: boolean,
    onClose: any
}

type ResetPassContent = {
    email: string,
    initial_password: string,
    new_password: string,
    confirm_new_password: string,
    accept_terms: boolean
}

const initialForm: ResetPassContent = {
    email: '',
    initial_password: '',
    new_password: '',
    confirm_new_password: '',
    accept_terms: false
}

export type UserResetPasswordSend = {
    email: string,
    current_password: string,
    new_password: string,
}

const ResetPasswordForm: FC<ResetPasswordData> = ({data, open, onClose}) => {
    const { login } = useContext(UserContext);
    const history = useHistory();
    const [record, setRecord] = useState<ResetPassContent>(initialForm)
    const [errors, setErrors] = useState<ErrorInForm[]>([])
    const [isFormDisable, setIsFormDisable] = useState<boolean>(false)
    const [isLoging, setIsLoging] = useState<boolean>(false)

    useEffect(() => {
        setRecord((r) => ({...r, email: data.email}))
    }, [data])

    useEffect(() => {
        let allFilled = true
        Object.keys(record).forEach((x) => {
            if (record[x as keyof ResetPassContent] === '') {
                allFilled = false
            }
        })
        if (!record.accept_terms) allFilled = false
        
        setIsFormDisable(!allFilled)
    }, [record])

    const cleanError = (field: string | string[]) => {
        if (Array.isArray(field)) {
            console.log("handle fields");
            return;
        }

        if (errors.find((error: ErrorInForm) => error.name === field)) {
            const filteredErrors = errors.filter((error: ErrorInForm) => error.name !== field);
            setErrors(filteredErrors);
        }
    }

    const isEmpty = (str: string) => {
        return str === '' || !str
    }

    const handleSubmit = (event: React.SyntheticEvent) => {
        event.preventDefault()
        setIsLoging(true)

        const errors: ErrorInForm[] = []

        if (isEmpty(record.email)) {
            errors.push({name: 'email', message: 'Field is required'})
        }
        if (isEmpty(record.initial_password)) {
            errors.push({name: 'initial_password', message: 'Field is required'})
        }
        if (isEmpty(record.new_password)) {
            errors.push({name: 'new_password', message: 'Field is required'})
        }
        if (record.new_password !== record.confirm_new_password) {
            errors.push({name: 'confirm_new_password', message: getText('user.password.notMatch')})
        }

        setErrors(errors)
        if (errors.length === 0) {
            const dataToSend: UserResetPasswordSend = {
                email: record.email,
                current_password: record.initial_password,
                new_password: record.new_password,
            }
            const loginData = { email: record.email, password: record.new_password }
            apiHandler.resetFirstPassword(dataToSend).then(() => {
                apiHandler.login(loginData).then(() => {
                    login();
                })
            }).catch((e) => {
                Object.entries(e.response.data).forEach(([ key, value ]) => {
                    setIsLoging(false)
                    if (key === 'current_password') {
                        setErrors([{name: 'initial_password', message: value as string}])
                    } else if (key === 'email') {
                        setErrors([{name: 'email', message: value as string}])
                    } else {
                        setErrors([{name: 'generic', message: value as string}])
                    }
                })
            })
        } else {
            setIsLoging(false)
        }

    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const value = event.target.type === 'checkbox' ? !record[event.target.name as keyof ResetPassContent] : event.target.value
        setRecord({ ...record, [event.target.name]: value })
        cleanError(event.target.name)
        if(event.target.name === 'confirm_new_password') cleanError('new_password')
    }

    return (
        <Dialog open={isLoging || open} maxWidth={'sm'} onClose={() => onClose(isLoging)}>
            <DialogTitle>Change password</DialogTitle>
                <form onSubmit={handleSubmit} autoComplete="off">
                    <DialogContent>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sx={{mt: 1}}>
                                <Typography>Welcome to Sarus! Please change your password to secure your account.</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Email"
                                            type="text"
                                            name="email"
                                            onChange={handleChange}
                                            value={record.email}
                                            error={!!errors.find((error: ErrorInForm) => error.name === 'email')}
                                            helperText={errors.find((error: ErrorInForm) => error.name === 'email')?.message}
                                            fullWidth
                                            />
                                        </Grid>
                                    <Grid item xs={12}>
                                        <PasswordInput
                                            label="Current password"
                                            name="initial_password"
                                            autoFocus
                                            onChange={handleChange}
                                            value={record.initial_password}
                                            error={errors.find((error: ErrorInForm) => error.name === 'initial_password')?.message}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <PasswordInput
                                            label="New password"
                                            name="new_password"
                                            onChange={handleChange}
                                            value={record.new_password}
                                            error={errors.find((error: ErrorInForm) => error.name === 'new_password')?.message}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <PasswordInput
                                            label="Confirm new password"
                                            name="confirm_new_password"
                                            onChange={handleChange}
                                            value={record.confirm_new_password}
                                            error={errors.find((error: ErrorInForm) => error.name === 'confirm_new_password')?.message}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SimpleCheckbox
                                            label={<FormHelperText>I agree to the <Link href="https://www.sarus.tech/terms-and-conditions">Terms of Service</Link> and <Link href="https://www.sarus.tech/privacy-policy">Privacy Policy</Link>.</FormHelperText>}
                                            name="accept_terms"
                                            onChange={handleChange}
                                            isChecked={record.accept_terms}
                                            error={errors.find((error: ErrorInForm) => error.name === 'accept_terms')?.message}
                                        />
                                    </Grid>
                                    {errors.find((error: ErrorInForm) => error.name === 'generic' && (
                                        <Grid item xs={12}>
                                            <Typography variant="body1" color="error">{error.message}</Typography>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit" disabled={isFormDisable}>Save</Button>
                    </DialogActions>
                </form>
        </Dialog>
    )
}

const LoginForm = () => {
    const { login } = useContext(UserContext);
    const [record, setRecord] = useState<LoginData>({ email: '', password: '' });
    const [openReset, setOpenReset] = useState<boolean>(false);
    const [errors, setErrors] = useState<ErrorInForm[]>([]);
    const history = useHistory();

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setErrors([]);
        setRecord({ ...record, [event.target.name]: event.target.value });
    }

    const handleCloseDialog = (fb?: boolean) => {
        console.log(fb)
        if (fb && typeof fb === 'boolean') {
            if (!fb) {
                setOpenReset(false)
            }
        }
    }

    const doLogin = (data: { email: string, password: string }) => {
        apiHandler.login(data)
            .then(() => {
                login();
                setOpenReset(false)
            })
            .catch(err => {
                if (err.response.status >= 500) {
                    setOpenReset(false)
                    setErrors([
                    ...errors,
                        {
                            name: 'generic',
                            message: `Fatal error: The application has encountered an unexpected error and may need to be restarted. Please contact your Sarus Account Manager.`
                        }
                    ]);
                } else {
                    if (err.response.data.reset_password) {
                        setOpenReset(true)
                    } else {
                        setOpenReset(false)
                        setErrors([{ name: 'login', message: `Wrong email or password` }]);
                    }
                }
            })
    }

    const handleSubmit = (event: React.SyntheticEvent) => {
        event.preventDefault();

        if (!validateEmail(record.email)) {
            setErrors([{ name: 'email', message: `Please enter a valid email address` }])
            return;
        }

        doLogin(record)        
    }

    return (
        <Fragment>
            <ResetPasswordForm data={record} open={openReset} onClose={handleCloseDialog} />
            <form onSubmit={handleSubmit} autoComplete="off" style={openReset ? {display: 'none'} : undefined}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    autoFocus
                                    label="Email"
                                    type="text"
                                    name="email"
                                    onChange={handleChange}
                                    value={record.email}
                                    error={!!errors.find((error: ErrorInForm) => error.name === 'email' || error.name === 'login')}
                                    helperText={errors.find((error: ErrorInForm) => error.name === 'email' || error.name === 'login')?.message}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <PasswordInput
                                    label="Password"
                                    name="password"
                                    onChange={handleChange}
                                    value={record.password}
                                    error={errors.find((error: ErrorInForm) => error.name === 'password')?.message}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    {errors.filter((error) => error.name === 'generic').map(error => (
                        <Grid item xs={12} key={uniqueId()}>
                            <Typography variant="body1" color="error">{error.message}</Typography>
                        </Grid>
                    ))}
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="primary" size="large" fullWidth>log in</Button>
                    </Grid>
                </Grid>
            </form>
        </Fragment>
    )
}

export default LoginForm
