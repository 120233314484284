
// -----------------------------------------
// https://gitlab.com/sarus-tech/sarus-data-spec/-/blob/master/sarus_data_spec/manager/ops/processor/standard/protection_utils/arrow/schema.py?ref_type=heads#L122
/**
def direct_paths_to_pe(
    schema_type: st.Type,
    tables_with_peid: t.List[Path],
    foreign_keys: t.Dict[Path, Path],
) -> Dict[Path, List[List[Path]]]:
    """Returns a dict where each key is a path to a table and each value is
    a list of lists. Each list contains one sequence of paths that
    allows to go to a peid"""
    # in the following, a table is referred by its path

    # creates dict where each table is a key and the value the set
    # of tables pointing to it
    neighbors = defaultdict(set)
    for pointing_key, pointed_key in foreign_keys.items():
        struct_pointing_list = t.cast(
            t.List[st.Path], schema_type.get(pointing_key).structs()
        )
        pointing_table = (
            struct_pointing_list[0]
            if len(struct_pointing_list) == 1
            else path_builder()  # csv case
        )
        struct_pointed_list = t.cast(
            t.List[st.Path], schema_type.get(pointed_key).structs()
        )
        pointed_table = (
            struct_pointed_list[0]
            if len(struct_pointing_list) == 1
            else path_builder()  # csv case
        )
        neighbors[pointed_table].add(pointing_table)

    result = defaultdict(list)
    for entity in tables_with_peid:
        result[entity].append([entity])
    # let's visit all tables with PEID
    tables_to_visit = [entity for entity in tables_with_peid]
    visited_paths: Dict[Path, Set[FrozenSet[st.Path]]] = defaultdict(set)

    # now visit the tables and remove each visited table (aka node)
    while len(tables_to_visit) != 0:
        current_node = tables_to_visit.pop()
        candidates = neighbors[current_node]
        for candidate in candidates:
            existing_paths = result[current_node]  # list of list of Paths
            if any(
                [
                    candidate not in paths_list
                    for paths_list in existing_paths
                ]  # check all existing paths so at it's at least not in one # avoid cycles
            ) and not any(
                [
                    frozenset(path_list)
                    in visited_paths[candidate]  # type:ignore
                    for path_list in existing_paths
                ]
            ):
                for path_list in result[current_node]:
                    # add the candidate to each path_list
                    if candidate not in path_list:
                        curr_list = path_list.copy()
                        curr_list.insert(0, candidate)  # type:ignore
                        result[candidate].append(curr_list)  # type:ignore
                        visited_paths[candidate].add(  # type:ignore
                            frozenset(path_list)
                        )
                tables_to_visit.append(candidate)  # type:ignore

    # convert result to dict and remove tables_with_peid from it
    output = dict(result)
    for entity in tables_with_peid:
        output.pop(entity)
    return output


def shortest_path(paths: List[List[Path]]) -> List[Path]:
    """Selects the shortest list among all path_lists
    in case of tie, selects the first one by alphabetical
    order
    """
    path_lengths = [len(path) for path in paths]
    shortest_paths = [
        path for path in paths if len(path) == np.amin(path_lengths)
    ]
    if len(shortest_paths) == 1:
        return shortest_paths[0]
    else:
        # sort table paths by alphabetical order
        names = [path[1].to_strings_list()[0][-1] for path in paths]
        index = np.argmin(names)
        return shortest_paths[index]

*/

// duplicate from store/actions/DatasetActions.ts for dev
const removeColsFromTables = (array: string[]): string[] => {
    const mapped = array.map(item => {
        const segmented = item.split('.')
        segmented.pop()
        return segmented.join(".")
    })
    return Array.from(new Set(mapped))
}

type Path = string;

interface IDirectPathsToPE {
    [key: string]: Path[][]
}

export const shortestPath = (pathLists: Path[][]) : Path[] => {
    console.log("TODO shortest path");
    return pathLists[0]; // TODO implement
}

export const directPathsToPe = (
    tablesWithPeId: Path[],
    foreignKeys: Record<Path, Path>
): IDirectPathsToPE => {
    // Replace the defaultdict with a standard object and initialization

    // creates dict where each table is a key and the value the set
    // of tables pointing to it
    const neighbors: Record<Path, Set<Path>> = {};

    Object.entries(foreignKeys).forEach(([pointingKey, pointedKey]) => {
        const [pointedTable] = removeColsFromTables([pointedKey])

        const pointingTableWithCol = pointingKey
        if (!neighbors[pointedTable]) {
            neighbors[pointedTable] = new Set()
        }
        neighbors[pointedTable].add(pointingTableWithCol)
    })
    console.log("logging all neighbors")
    for (const [a,b] of Object.entries(neighbors)) {console.log(a, b);}

    // it works until here as of Jan 18th
    const result: IDirectPathsToPE = {};
    tablesWithPeId.forEach(entity => {
        result[entity] = [[entity]]
    })

    const tablesToVisit = [...tablesWithPeId];
    const visitedPaths: Record<Path, Set<Set<Path>>> = {}
    console.log("here")
    let i: number =0
    while (tablesToVisit.length > 0) {
        i++;
        console.log(i);
        console.log('results: ')
        console.log(result)
        console.log('tables to visit: ' + tablesToVisit)
        const currentNode = tablesToVisit.pop()
        if (!currentNode) continue;

        // let's consider all tables pointing to this table
        const candidatesWithCol = neighbors[currentNode] || new Set()

        candidatesWithCol.forEach(candidateWithCol => {
            const [candidate] = removeColsFromTables([candidateWithCol])
            console.log('candidateWithCol : ' + candidateWithCol)
            const existingPaths = result[currentNode]
            if (
                existingPaths.some(pathsList => !pathsList.includes(candidateWithCol)) &&
                !existingPaths.some(pathList =>
                    (visitedPaths[candidateWithCol] || new Set()).has(new Set(pathList))
                )
            ) {
                existingPaths.forEach(pathList => {
                    if(!pathList.includes(candidateWithCol)) {
                        const currList = [candidateWithCol, ...pathList];
                        result[candidate] = result[candidate] || [];
                        result[candidate].push(currList);
                        visitedPaths[candidate] = visitedPaths[candidate] || new Set();
                        visitedPaths[candidate].add(new Set(pathList))
                    }
                });
                tablesToVisit.push(candidate)
            }
        })
    }

    // Remove tablesWithPeId from the result
    tablesWithPeId.forEach(entity => {
        delete result[entity]
    })

    return result
}
