import { FC, useEffect, useState, Fragment } from 'react';
import { DataGrid, GridRowsProp, GridColDef, GridCellParams, GridCellValue } from '@mui/x-data-grid';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Button } from '@mui/material';
import { datagridStyles } from 'styles/datagrid';
import { useSelector } from 'react-redux';

// Icons
import PersonIcon from '@mui/icons-material/Person';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

// Types
import { AccessRulePopulated, AllAccessRule } from 'types/datasets';
import { Group } from 'types/groups';
import { IPrivacyPolicy } from 'types/privacy-policy';
import { PrivacyLog } from 'types/logs';


import { AppState } from 'store/configureStore';
import { DisplayUser } from 'utils/misc';
import { Skeleton } from '@mui/material';
import OverflowTooltip from 'hooks/OverflowTooltip';

const useStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            border: 0,
            '& div[data-rowIndex][role="row"]:first-child': {},
            '& .revoked': {
                textDecoration: 'line-through',
            },
        }
    }
})

const queriesCount = (logs: PrivacyLog[], access: AccessRulePopulated) => {
    return logs.filter(log => log.access_id === access.id).length;
}

const displayUsage = (policies: IPrivacyPolicy[], logs: PrivacyLog[], accessPopulated: AccessRulePopulated, accessRaw: any, isOwner: boolean): string => {
    const count = queriesCount(logs, accessPopulated);
    return `${count} ${count > 1 ? 'queries' : 'query'}`;
}

interface AccessRulesProps {
    showDetails: (id: number) => void,
    deleteRule: (id: number) => void,
}

const DGAccessRules: FC<AccessRulesProps> = props => {
    const { showDetails, deleteRule } = props;
    const [rows, setRows] = useState<GridRowsProp>([]);
    const [filteredRows, setFilteredRows] = useState<GridRowsProp>([]);
    const classes = useStyles();
    const gridStyle = datagridStyles({});
    // To do : rework these states
    // const [filtered, setFiltered] = useState<boolean>(false);
    // const [minimumTimeElapsed, setMinimumTimeElapsed] = useState(true);
    const filtered = false;
    const minimumTimeElapsed = true;

    const { dataset, accessRules, canEdit } = useSelector((state: AppState) => ({
        dataset: state.dataset,
        accessRules: state.dataset.accessRules,
        canEdit: state.dataset.canEdit
    }));

    useEffect(() => {
        if (accessRules) {
            const editor: any = [];
            const rest: any = []
            dataset.accessRules.forEach((rule: AllAccessRule) => {
                const element: any = { ...rule.populated };
                
                element.usage = displayUsage(dataset.privacyPolicies, dataset.privacyLogs, rule.populated, rule.raw, element.pp === "Full remote access");
                const owner = dataset.owner?.email === rule.populated.group
                if (owner) {
                    editor.push(element)
                } else {
                    rest.push(element)
                }
            });
            const list = [...editor, ...rest]
            setRows(list.map((ar) => ({...ar, action: ''})));
            setFilteredRows(list);
        }
    }, [accessRules, dataset])

    const getName = (groupName: GridCellValue): string => {
        const isSingleton = dataset.groups.find((group: Group) => group.name === groupName)?.singleton;
        const owner = dataset.owner?.email === groupName ? '(Owner)' : '';
        const name = isSingleton ? DisplayUser(dataset.users.find(user => user.email === groupName || user.username === groupName)!) : groupName;
        return `${name} ${owner}`
    }

    const isSingleton = (groupName: GridCellValue) => {
        return dataset.groups.find((group: Group) => group.name === groupName)?.singleton;
    }

    const columns: GridColDef[] = [
        {
            field: 'group',
            headerName: 'User / Group',
            type: 'string',
            width: 320,
            disableReorder: true,
            cellClassName: (params: GridCellParams) => params.row.revoked ? `revoked` : ``,
            renderCell: (params: GridCellParams) => {
                if (params.row.id === 'skeleton') return <Skeleton variant="text" width={250} />;
                return <>{OverflowTooltip({ tooltip: getName(params.value), icon: isSingleton(params.value) ? <PersonIcon /> : <PeopleAltIcon />})}</>
            }
        },
        {
            field: 'pp',
            headerName: 'Privacy Policy',
            type: 'string',
            width: 320,
            disableReorder: true,
            renderCell: (params: GridCellParams) => {
                if (params.row.id === 'skeleton') return <Skeleton variant="text" width={200} />;
                return OverflowTooltip({tooltip: params.value})
            },
            cellClassName: (params: GridCellParams) => params.row.revoked ? `revoked` : ``
        },
        {
            field: 'usage',
            headerName: 'Usage',
            type: 'string',
            width: 250,
            disableReorder: true,
            renderCell: (params: GridCellParams) => {
                if (params.row.id === 'skeleton') return <Skeleton variant="text" width={125} />;
                return OverflowTooltip({tooltip: params.value})
            },
            cellClassName: (params: GridCellParams) => params.row.revoked ? `revoked` : ``
        },
        {
            field: 'action',
            headerName: 'Action',
            sortable: false,
            width: 150,
            disableReorder: true,
            renderCell: (params: GridCellParams) => {
                if (params.row.id === 'skeleton') return <></>;
                return (
                    <>
                        <Button onClick={() => showDetails(params.row.id)}>Show</Button>
                        {(
                            (canEdit &&
                                (dataset.owner &&
                                    (dataset.owner.email !== params.row.group && dataset.owner.username !== params.row.group)
                                )
                            )
                            && !params.row.revoked
                        ) && <Button onClick={() => deleteRule(params.row.id)}>Revoke</Button>}
                    </>
                );
            }
        }
    ]

    useEffect(() => {

        const skeletonRow = {
            id: 'skeleton'
        }

        if (filtered) {
            setFilteredRows([...rows, skeletonRow]);
        } else {
            minimumTimeElapsed && setFilteredRows(rows);
        }

    }, [filtered, rows, minimumTimeElapsed])

    return (
        <>
            <DataGrid
                rows={filteredRows}
                columns={columns}
                disableColumnMenu={true}
                disableSelectionOnClick={true}
                hideFooter={true}
                autoHeight={true}
                className={`${gridStyle.datagrid} ${classes.root}`}
                density="compact"
                sx={{
                    '& .MuiDataGrid-row:last-child .MuiDataGrid-cell': {borderBottom: '1px solid rgba(224,224,224,1)'}
                }}
            />
        </>
    )
}

export default DGAccessRules
