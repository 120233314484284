import { Autocomplete, Box, Checkbox, Chip, styled, TextField } from '@mui/material'
import React, { FC, SyntheticEvent, useState } from 'react'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip' 

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

interface Props {
    multiple?: boolean,
    loading?: boolean,
    options: any[],
    value: string[],
    onChange: any,
    noOptionsText?: string,
    inputLabel: string,
    tagLabel?: string,
}

type CustomTooltipProps = TooltipProps & {
    hasIcon?: boolean
}

export const CustomWidthTooltip = styled(({ className, hasIcon, ...props }: CustomTooltipProps) => (
    <Tooltip {...props} classes={{popper: `${className} ${hasIcon && 'customized'}`}} />
))({
    [`&.customized .${tooltipClasses.tooltip}`]: {
        marginLeft: 32
    }
})

const SarusAutoComplete: FC<Props> = (props) => {
    const {
        multiple, loading, options,
        value, onChange, noOptionsText,
        inputLabel
    } = props

    const [inputValue, setInputValue] = useState("")
    
    return (
        <Autocomplete
            multiple={multiple ? true : false}
            size="small"
            disableCloseOnSelect
            limitTags={3}
            loading={loading}
            options={options}
            value={value}
            onChange={onChange}
            noOptionsText={noOptionsText}
            inputValue={inputValue}
            onInputChange={(event, value, reason) => {
                if (reason !== "reset") {
                    setInputValue(value)
                }
            }}
            renderInput={params => <TextField {...params} label={inputLabel} />}
            renderOption={(props, option, { selected }) => {
                return (
                    <li {...props} key={option} style={{ paddingLeft: 8 }}>
                        <CustomWidthTooltip title={option} disableInteractive arrow placement="bottom-start">
                            <Box component="div" sx={{
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden'
                            }}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8, margin: 0 }}
                                    checked={selected}
                                    size="small"
                                /> {option}
                            </Box>
                        </CustomWidthTooltip>
                    </li>
                )
            }}
            renderTags={(tagValue, getTagProps) => {
                return tagValue.map((option, index) => {
                    return (
                    <CustomWidthTooltip key={option} title={option} arrow disableInteractive placement="bottom-start">
                        <Chip {...getTagProps({index})} label={option} />
                    </CustomWidthTooltip>
                    )
                })
            }}
        />
    )
}

export default SarusAutoComplete