import { useEffect, useState } from 'react'
import apiHandler from 'api/apiHandler'
import { GridColDef } from '@mui/x-data-grid';
import { Body } from 'styles/layout';
import { Group } from 'types/groups'
import Typography from '@mui/material/Typography';
import { Title } from 'components/stylized/titles';
import { Search } from 'types/datagrid';
import { DescList } from 'components/stylized/listItems';
import { useContext } from 'react';
import { UserContext } from 'context/authContext';
import ListResource from 'assets/datagrids/ListResource';
import OverflowTooltip from 'hooks/OverflowTooltip';

const GroupList = () => {
    const { hasPermissions } = useContext(UserContext);
    const [groups, setGroups] = useState<Group[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [errorOnLoading, setErrorOnLoading] = useState<boolean>(false);
    const [loadDatas, setLoadDatas] = useState<boolean>(false);

    useEffect(() => {
        let isSubscribed = true;
        if (loadDatas) {
            apiHandler.getAllGroups().then(result => {
                if (isSubscribed) {
                    setGroups(result.filter((group: Group) => !group.singleton));
                    setIsLoading(false)
                    setLoadDatas(false)
                }
            }).catch(error => setErrorOnLoading(true));
        }

        return () => {
            isSubscribed = false;
        }
    }, [loadDatas])

    useEffect(() => {
        setLoadDatas(true);
    }, [])

    const columns: GridColDef[] = [
        { field: 'name', headerName: 'Name', type: 'string', flex: 0.5, renderCell: (params) => OverflowTooltip({tooltip: params.value}) },
        { field: 'description', headerName: 'Description', type: 'string', flex: 1, renderCell: (params) => OverflowTooltip({tooltip: params.value}) },
        { field: 'user_count', headerName: 'Nb of users', type: 'number', flex: 0.3 },
    ]

    const deleteGroups = async (ids: number[]) => {
        for (const id of ids) {
            try {
                await apiHandler.deleteGroup(id)
            } catch (error) {
                console.error(`Failed to delete group with ID ${id} : ${error}`)
            }
        }
        const updatedGroups = groups.filter(group => typeof group.id !== 'undefined' && !ids.includes(group.id))
        setGroups(updatedGroups)
    }

    const searchData: Search = {
        label: 'Search a group name, description',
        type: 'group'
    }

    return (
        <Body>
            <Title variant="h2" comp="h1">Groups</Title>
            <DescList>
                <Typography variant="body2" color="textSecondary">
                    Groups define collusion perimeters for users, meaning the users of a group share the total privacy budget granted to the group.<br />
                    A user can belong to several groups.
                </Typography>
            </DescList>
            <ListResource
                rows={groups}
                columns={columns}
                loading={isLoading}
                doDelete={deleteGroups}
                canSearch={searchData}
                errorOnLoading={errorOnLoading}
                resource="groups"
                hasPerm={hasPermissions(['group.listAll', 'group.listOwn']) ? true : `It looks like there is no group or you don't have sufficient permission to see the list.`}
            />
        </Body>
    )
}

export default GroupList
