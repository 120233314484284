import React, { FC } from 'react'
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';

type SimpleDialogProps = {
    open: boolean,
    buttonLabel?: string,
    message: any;
    handleClose: () => void,
}

const SimpleDialog: FC<SimpleDialogProps> = props => {
    const {
        open,
        buttonLabel,
        message,
        handleClose,
    } = props;

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll={"paper"}
            maxWidth="sm"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">

            </DialogTitle>

            <DialogContent dividers={true}>
                <List>
                    {Array.isArray(message) ? message.map((item: any) => {
                        return <ListItemText primary={item} />
                    }) : <ListItemText primary={message} />
                    }
                </List>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} color="primary" autoFocus>
                    {buttonLabel ? buttonLabel : 'OK'}
                </Button>
            </DialogActions>

        </Dialog>
    )
}

export default SimpleDialog
