import { Dialog, DialogActions, DialogContent, IconButton, Typography } from "@mui/material";
import ConnectorForm from "assets/data-connections/ConnectorForm";
import { ConnectorWithLogo } from "assets/data-connections/connectorsData";
import ConnectorSelection from "assets/data-connections/ConnectorSelection";
import CancelButton from "components/Buttons/CancelButton";
import PrimaryButton from "components/Buttons/PrimaryButton";
import { CreationStep } from "pages/DataConnectionCreate";
import { FC, useState, useCallback, memo } from "react";
import { DialogTitleProps } from "./CreateDatasetTypes";
import { DialogCreateDCProps } from "./CreateDatasetTypes";
import CloseIcon from '@mui/icons-material/Close';
import MuiDialogTitle from '@mui/material/DialogTitle';

const NewDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <MuiDialogTitle sx={{m: 0, p: 2}} {...other}>
            <Typography variant="inherit">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                    onClick={onClose}
                    size="large"
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
};

const DialogCreateDataConnection: FC<DialogCreateDCProps> = memo((props) => {
    const { open, doClose, endDialog } = props;
    const [creationStep, setCreationStep] = useState<CreationStep>('select-connector');
    const [connectorSelected, setConnectorSelected] = useState<ConnectorWithLogo>();
    const [submitForm, setSubmitForm] = useState<boolean>(false)
    const [inTreatment, setInTreatment] = useState<boolean>(false)

    const onClose = useCallback(() => {
        if(inTreatment) return null
        doClose(false)
        setCreationStep('select-connector');
        setConnectorSelected(undefined)
    }, [doClose, inTreatment])

    const cbSubmit = async () => {
        await setSubmitForm(true)
        setTimeout(() => setSubmitForm(false), 100)
    }

    const ShowActions = useCallback(() => {
        if (creationStep === 'data-informations') {
            return (
                <DialogActions>
                    <CancelButton action={onClose}>Cancel</CancelButton>
                    <PrimaryButton action={() => {
                        setInTreatment(true)
                        cbSubmit()
                    }} isLoading={inTreatment} disabled={inTreatment}>Create Connection</PrimaryButton>
                </DialogActions>
            )
        } else {
            return (
                <DialogActions>
                    <CancelButton action={onClose}>Cancel</CancelButton>
                </DialogActions>
            )
        }
    }, [inTreatment, creationStep, onClose])

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth="md"
            onClose={onClose}
            aria-labelledby="form-create-dataconnection-title"
        >
            <NewDialogTitle id="form-create-dataconnection-title" onClose={onClose}>
                Create new data connection
            </NewDialogTitle>
            <DialogContent style={{height: '90vh'}}>
                {creationStep === 'select-connector' ? (
                    <ConnectorSelection
                        onSelect={(connector: ConnectorWithLogo) => { 
                            setConnectorSelected(connector)
                            setCreationStep('data-informations')
                        }}
                        isModal
                    />
                ) : (
                    <ConnectorForm
                        connector={connectorSelected}
                        goBack={() => {
                            setCreationStep('select-connector')
                        }}
                        cb={(data: any) => {
                            if (data === 'error') {
                                setInTreatment(false)
                            } else {
                                setInTreatment(false)
                                endDialog(data)
                            }
                        }}
                        submitForm={submitForm}
                    />
                )}
            </DialogContent>
            <ShowActions />
        </Dialog>
    )
})

export default (DialogCreateDataConnection)