import { ReactNode, useState, useEffect } from 'react'
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Body } from 'styles/layout';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Title } from 'components/stylized/titles';
import SimpleDialog from 'components/dialogs/SimpleDialog';
import { useContext } from 'react';
import { UserContext } from 'context/authContext';
import { useHistory } from 'react-router-dom';
import { Grid } from '@mui/material';
import TopCloseButton from 'components/TopCloseButton';
import CreateDatasetForm from 'assets/datasets/creation/CreateDatasetForm';

type BasicDialog = {
    open: boolean,
    message?: string | ReactNode
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            background: theme.palette.action.selected,
            marginBottom: theme.spacing(1)
        }
    })
);

const DatasetCreate = () => {
    const classes = useStyles();
    const [dialog, setDialog] = useState<BasicDialog>({ open: false });
    const history = useHistory();
    const { hasPermissions } = useContext(UserContext);

    useEffect(() => {
        if (!hasPermissions('dataset.create')) history.push('/datasets');
    }, [hasPermissions, history])

    const handleCloseDialog = () => {
        setDialog({ open: false });
    }

    const StepWithTabs = () => {
        return (
            <Paper square elevation={0} className={classes.paper}>
                <Tabs
                    value={0}
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="Dataset menu"
                    variant="fullWidth"
                >
                    <Tab label="Info &amp; source" disabled={false} />
                    <Tab label="Schema" disabled={true} />
                    <Tab label="Access rules" disabled={true} />
                    <Tab label="Synthetic data" disabled={true} />
                </Tabs>
            </Paper>
        )
    }

    return (
        <Body>
            <SimpleDialog open={dialog.open} message={dialog.message} handleClose={handleCloseDialog} />
            <Grid container>
                <Grid item xs={11}>
                    <Title variant="h2" comp="h1">Add new Dataset</Title>
                </Grid>
                <Grid item xs={1} style={{ textAlign: 'right', display: 'flex', alignItems: 'center', marginTop: 8, marginBottom: 21, justifyContent: 'flex-end' }}>
                    <div style={{marginRight: -5}}>
                        <TopCloseButton action={() => history.push('/datasets')} />
                    </div>
                </Grid>
            </Grid>
            <StepWithTabs />
            <CreateDatasetForm />
        </Body>
    )
}

export default DatasetCreate
