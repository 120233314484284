import { Box } from '@mui/material'
import React, { FC, ReactNode, useRef, useState, useEffect } from 'react'
import { CustomWidthTooltip } from '../SarusAutoComplete'

interface Props {
    children: string | ReactNode,
    str: string,
    icon?: ReactNode,
    passedProps: any,
}

const SarusACElement: FC<Props> = ({ children, icon, str, passedProps }) => {
    const textElementRef = useRef<null | HTMLDivElement>(null)
    const [ready, setReady] = useState(false)
    const [hoverStatus, setHoverStatus] = useState(false)

    const compareSize = () => {
        if (!textElementRef.current) return;
        const compare = textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
        setHoverStatus(compare)
    }

    useEffect(() => {
        compareSize()
        window.addEventListener('resize', compareSize) 
        return () => {
            window.removeEventListener('resize', compareSize)
        }
    }, [])

    useEffect(() => {
        if (ready) return
        document.fonts.ready.then(() => {
            setReady(true)
        })
    }, [])
    
    const DisplayIcon = () => {
        if (!icon) return null
        return (
            React.cloneElement(icon as React.ReactElement<any>, {
                style: {marginRight: 8}
            })
        )
    }

    return (
        <Box component="li" {...passedProps}>
            <CustomWidthTooltip
                disableHoverListener={!hoverStatus}
                title={str}
                disableInteractive
                placement="bottom-start"
                followCursor={false}
                hasIcon={!!icon}
            >
                <Box component="div" sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flex: 1,
                    minWidth: 0,
                }}>
                    <DisplayIcon />
                    <Box
                        component="div"
                        ref={textElementRef}
                        style={{
                            flex: 1, minWidth: 0,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}>{children}</Box>
                </Box>
            </CustomWidthTooltip>
        </Box>
    )
}

export default SarusACElement