import React, { FC, useContext, useEffect, useState } from 'react'
import { ButtonBase, FormControl, FormLabel, Grid, Paper, Theme, Tooltip, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { useHistory } from 'react-router-dom'
import { ConnectorWithLogo, getListConnectors } from './connectorsData'
import CancelButton from 'components/Buttons/CancelButton'
import { UserContext } from 'context/authContext'

const useStyle = makeStyles((theme: Theme) => ({
    selector: {
        width: '102px',
        height: '111px',
        display: 'grid',
        gridTemplateRows: '62px 1fr',
        justifyContent: 'center',
        textAlign: 'center',
        '& > div:first-child': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'center',
            '& > img': {
                height: 45,
                '&.disabled': {
                    filter: 'grayscale(1)',
                    opacity: '0.2',
                }
            }
        },
        '& > div:nth-child(2)': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }
    },
    connectorList: {
        padding: 16,
        display: 'grid',
        gridTemplateColumns: '102px 102px 102px 102px',
        gap: 16,
    },
    title: {
        lineHeight: '16px',
    }
}))

interface ConnectorProps {
    onSelect: (connection: ConnectorWithLogo) => void,
    isModal?: boolean
}

const ConnectorSelection: FC<ConnectorProps> = ({onSelect, isModal}) => {
    const classes = useStyle();
    const history = useHistory();
    const [isPreloading, setIsPreloading] = useState<boolean>(true);
    const { hasPermissions } = useContext(UserContext)
    const isNotAllowed = !hasPermissions('connection.create')

    useEffect(() => {
        let isSubscribed = true;
        setTimeout(() => {
            if (isSubscribed) {
                setIsPreloading(false)
            }
        }, 300);

        return () => {
            isSubscribed = false;
        }
    }, [])

    useEffect(() => {
        if (isNotAllowed) {
            history.push('/data-connections')
        }
    }, [isNotAllowed])

    const ButtonConnector: FC<{ connector: ConnectorWithLogo, disabled?: boolean }> = ({ connector, disabled }) => (
        <ButtonBase
            disabled={!connector.isAvailable || isNotAllowed}
            onClick={() => onSelect(connector)}
        >
            <Paper
                elevation={1}
                className={classes.selector}
            >
                <div>{connector.logo && <img src={connector.logo} className={disabled ? 'disabled' : ''} alt={connector.label.toString()}/>}</div>
                <div>
                    <Typography color="textSecondary" className={classes.title}>
                        {connector.label}
                    </Typography>
                </div>
            </Paper>
        </ButtonBase>
    )

    if (isPreloading) return null;
    return (
        <Grid container spacing={2}>
            <Grid item xs={8}>
                <FormControl variant="standard">
                    <FormLabel>Select a connector</FormLabel>
                </FormControl>
            </Grid>
            <Grid item xs={8}>
                <Grid container spacing={2}>
                    <Grid item xs={12} className={classes.connectorList}>
                        {getListConnectors().map((connector) => {
                            if (connector.isAvailable) {
                                return <ButtonConnector connector={connector} key={connector.name} />
                            } else {
                                return (
                                    <Tooltip title="This connector will be available soon" arrow key={connector.name}>
                                        <span>
                                            <ButtonConnector connector={connector} disabled />
                                        </span>
                                    </Tooltip>
                                )
                            }
                        })}
                    </Grid>
                </Grid>
            </Grid>
            {!isModal && (
                <Grid item xs={8} style={{ marginTop: 16 }}>
                    <CancelButton action={() => { history.push('/data-connections') }}>
                        Cancel
                    </CancelButton>
                </Grid>
            )}
        </Grid>
    )
}

export default ConnectorSelection
