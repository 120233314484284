import React, { FC, ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import { Box, Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import Navigation from 'components/layout/Navigation'
import TopNav from 'components/layout/TopNav'
import { Main } from 'styles/layout'
import { UserContext } from 'context/authContext'

interface ContainerBox {
    children: ReactNode
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    world: {
        margin: 0,
        background: '#ffffff',
        fontFamily: theme.typography.fontFamily,
        fontSize: 14,
        minHeight: '100vh',
        width: '100%',
        display: 'grid',
        gridTemplateAreas: "'sidenav main' 'sidenav main'",
        gridTemplateRows: '70px 1fr',
        gridTemplateColumns: '70px 1fr',
    }
}))


const World: FC<ContainerBox> = ({children}) => {
    const classes = useStyles()

    return (
        <Box className={classes.world}>
            {children}
        </Box>
    )
}

const AppTemplate: FC<ContainerBox> = ({ children }) => {
    const { isLoggedIn } = useContext(UserContext);
    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
    const [isFixed, setIsFixed] = useState<boolean>(false)
    
    const doCollapse = () => {
        const v = !isCollapsed;
        setIsCollapsed(v);
        localStorage.setItem('collapsed', v.toString());
    }

    const updateWindowDimensions = useCallback(() => {
        const newFixed = window.innerWidth < 640
        isFixed !== newFixed && setIsFixed(newFixed)
    }, [isFixed])

    useEffect(() => {
        window.addEventListener("resize", updateWindowDimensions);
        const memoryCollapsed = localStorage.getItem('collapsed');
        setIsCollapsed(memoryCollapsed === 'true')

        return () => window.removeEventListener("resize", updateWindowDimensions)
    }, [updateWindowDimensions])


    return (
        <World>
            <Navigation collapse={isCollapsed} setCollapse={doCollapse} isFixed={isFixed} />
            <Main logged={isLoggedIn} isCollapsed={isCollapsed}>
                <TopNav />
                {children}
            </Main>
        </World>
    )
}

export default AppTemplate