import React, { FC } from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import { WaitingTab } from 'styles/layout';
import { Title } from 'components/stylized/titles';
import ErrorIcon from '@mui/icons-material/WarningOutlined'

type LoadingProps = {
    label: string,
    noProgress?: boolean,
    secondLabel?: string,
    type?: 'error'
}
const Loading: FC<LoadingProps> = ({ label, noProgress, secondLabel, type }) => {

    if (noProgress) {
        return (
            <WaitingTab style={{ textAlign: 'center' }}>
                {(type && type === 'error') && <ErrorIcon style={{fontSize: 50}} />}
                <Title variant="h3">{label}<br />{secondLabel}</Title>
            </WaitingTab>
        )
    }
    return (
        <WaitingTab>
            <Title variant="h3">{label}</Title>
            <CircularProgress size={50} color="inherit" />
            {secondLabel && <Title variant="h3">{secondLabel}</Title>}
        </WaitingTab>
    )
}

export default Loading
