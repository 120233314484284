import React, { FC, ReactNode } from 'react'
import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginTop: theme.spacing(15),
        textAlign: 'center',
        color: theme.palette.grey[400],
    },
}));

export const MainMessage: FC<{ children: ReactNode }> = ({ children }) => {
    const classes = useStyles();

    return <div className={classes.root}>{children}</div>
}