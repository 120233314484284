import { Link } from '@mui/material'
import { FC, ReactNode } from 'react'
import { useHistory } from 'react-router-dom'

interface LinkProp {
    children: ReactNode,
    to: string,
    bold?: boolean,
}

const SarusLink: FC<LinkProp> = ({ to, children, bold }) => {
    const history = useHistory();

    const showContent = () => {
        if (bold) {
            return <span style={{ fontWeight: 'bold' }}>{children}</span>
        } else {
            return children
        }
    }

    return (
        <Link href={to} onClick={(e) => {
            e.preventDefault();
            history.push(to)
        }}>{showContent()}</Link>
    )
}

export default SarusLink