import { Title } from 'components/stylized/titles'
import { Body } from 'styles/layout'
import { DisplayStatus } from 'components/stylized/chips'
import { Typography } from '@mui/material'
import Loading from 'components/utils/Loading'

const Tests = () => {

    return (
        <Body>
            <Title variant="h1">Tests</Title>
            <Typography component="p" variant="body1">Test page to view UI elements before implementation...</Typography>
            <Title variant="h2">Chips</Title>
            <div style={{ display: 'flex', gap: 20, }}>
                <DisplayStatus status="empty" />
                <DisplayStatus status="pending" />
                <DisplayStatus status="ready" />
                <DisplayStatus status="error" />
            </div>
            <Loading label="Loading data..." />
        </Body>
    )
}

export default Tests
