import React, { FC, useState, useEffect, useContext, useCallback } from 'react'
import { useHistory } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import apiHandler from 'api/apiHandler';
import { ErrorInForm } from 'types/forms';
import { Grid, FormHelperText, Link } from '@mui/material';
import { UserContext } from 'context/authContext';
import config from 'config/global';
import PasswordInput from 'components/PasswordInput';
import SimpleCheckbox from 'components/SimpleCheckbox';
import { getText } from 'utils/dictionnary';

type SignProps = {
    token?: string,
}

type UserToValidate = {
    username: string,
    password: string,
    email: string,
    token: string,
    agreeTermsAndPP: boolean,
    personalData?: boolean,
}

interface IUserForm extends UserToValidate {
    confirmPassword: string
}

const initialState: IUserForm = {
    email: '',
    username: '',
    password: '',
    confirmPassword: '',
    token: '',
    agreeTermsAndPP: false,
    personalData: config.isSarusHost ? false : undefined,
}

const SignupForm: FC<SignProps> = ({ token }) => {
    const history = useHistory();
    const [initiated, setInitiated] = useState(false);
    const { isLoggedIn, login } = useContext(UserContext);
    const [record, setRecord] = useState<IUserForm>(initialState)
    const [errorsInForm, setErrorsInForm] = useState<ErrorInForm[]>([]);
    const [isFormDisable, setIsFormDisable] = useState<boolean>(true);

    const loadUser = useCallback((token: string) => {
        apiHandler.getUserFromToken(token).then(user => {
                const updatedData = { token: token, email: user.email };
                setRecord({ ...record, ...updatedData });
            }).catch(e => {
                setErrorsInForm([...errorsInForm, {name: 'token', message: 'Please enter a valid token'}])
            });
    }, [errorsInForm, record])

    useEffect(() => {
        if (!initiated) {
            setInitiated(true)
            if (token && token !== '') {
                loadUser(token)
            }
        }
    }, [token, loadUser, initiated])

    useEffect(() => {
        if (isLoggedIn) history.push(config.landingPage);
    }, [isLoggedIn, history])

    const cleanError = (field: string | string[]) => {
        if (Array.isArray(field)) {
            console.log("handle fields");
            return;
        }

        if (errorsInForm.find((error: ErrorInForm) => error.name === field)) {
            const filteredErrors = errorsInForm.filter((error: ErrorInForm) => error.name !== field);
            setErrorsInForm(filteredErrors);
        }
    }

    useEffect(() => {
        let allFilled = true
        Object.keys(record).forEach((x) => {
            if (record[x as keyof IUserForm] === '') {
                allFilled = false
            }
        })
        if (config.isSarusHost && !record.personalData) {
            allFilled = false
        }
        setIsFormDisable(!(record.agreeTermsAndPP && allFilled))
    }, [record])

    const handleSubmit = (event: React.SyntheticEvent) => {
        event.preventDefault();

        let errors: ErrorInForm[] = [];
        // Check errors
        if (record.username === '') {
            errors.push({name: 'username', message: `Username is empty`})
        }

        if (record.password === '') {
            errors.push({ name: 'password', message: `Password is empty` })
        } else if (record.password !== record.confirmPassword) {
            errors.push({ name: 'confirmPassword', message: getText('user.password.notMatch') })
        }

        if (record.token === '') {
            errors.push({ name: 'token', message: `Your token is empty` })
        }

        setErrorsInForm(errors);
        if (errors.length === 0) {
            const confirmUser = {
                email: record.email,
                username: record.username,
                new_password: record.password,
                token: record.token,
                personal_data: config.isSarusHost ? true : undefined,
            };
            if (!config.isSarusHost) {
                delete confirmUser.personal_data
            }
            apiHandler.confirmUserCreation(confirmUser).then(result => {
                login();
            }).catch(error => {
                if (error.response.status === 400 && error.response.data === 'Invalid token') {
                    setErrorsInForm([{name: 'token', message: 'Please enter a valid token'}])
                } else if (error.response.status === 400 && error.response.data === "Terms Error") {
                    setErrorsInForm([{name: 'any', message: 'Oops! Something went wrong with the T&C approval archiving. Please contact your Sarus AM'}])
                } else {
                    setErrorsInForm([{name: 'any', message: 'Oops, something went wrong. Please check your information and try again'}])
                }
            });
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.type === 'checkbox' ? !record[event.target.name as keyof IUserForm] : event.target.value
        setRecord({ ...record, [event.target.name]: value });
        cleanError(event.target.name);
        if(event.target.name === 'confirmPassword') cleanError('password')
    }

    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label="Email"
                                name="email"
                                value={record.email}
                                onChange={handleChange}
                                error={!!errorsInForm.find((error: ErrorInForm) => error.name === 'email')}
                                helperText={errorsInForm.find((error: ErrorInForm) => error.name === 'email')?.message}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Full name"
                                name="username"
                                type="username"
                                onChange={handleChange}
                                value={record.username}
                                error={!!errorsInForm.find((error: ErrorInForm) => error.name === 'username')}
                                helperText={errorsInForm.find((error: ErrorInForm) => error.name === 'username')?.message}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PasswordInput
                                label="Password"
                                name="password"
                                onChange={handleChange}
                                value={record.password}
                                error={errorsInForm.find((error: ErrorInForm) => error.name === 'password')?.message}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PasswordInput
                                label="Confirm your password"
                                name="confirmPassword"
                                onChange={handleChange}
                                value={record.confirmPassword}
                                error={errorsInForm.find((error: ErrorInForm) => error.name === 'confirmPassword')?.message}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Token"
                                name="token"
                                onChange={handleChange}
                                value={record.token}
                                error={!!errorsInForm.find((error: ErrorInForm) => error.name === 'token')}
                                helperText={errorsInForm.find((error: ErrorInForm) => error.name === 'token')?.message}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <SimpleCheckbox
                                name="agreeTermsAndPP"
                                label={<FormHelperText>I agree to the <Link href="https://www.sarus.tech/terms-and-conditions">Terms of Service</Link> and <Link href="https://www.sarus.tech/privacy-policy">Privacy Policy</Link>.</FormHelperText>}
                                onChange={handleChange}
                                isChecked={record.agreeTermsAndPP}
                                error={errorsInForm.find((error: ErrorInForm) => error.name === 'agreeTermsAndPP')?.message}
                            />
                        </Grid>
                        {
                            config.isSarusHost &&
                            (
                                <Grid item xs={12}>
                                    <SimpleCheckbox
                                        name="personalData"
                                        label={<FormHelperText>I understand that I am not allowed to load any personal data onto the Sarus instance.</FormHelperText>}
                                        onChange={handleChange}
                                        isChecked={record.personalData!}
                                        error={errorsInForm.find((error: ErrorInForm) => error.name === 'personalData')?.message}
                                    />
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Button type="submit" variant="contained" color="primary" size="large" fullWidth disabled={isFormDisable}>
                        Sign up with email
                    </Button>
                    <FormHelperText error style={{marginTop: 8}}>{errorsInForm.find((e) => e.name === 'any')?.message}</FormHelperText>
                </Grid>
            </Grid>
        </form>
    )
}

export default SignupForm
