import React, { FC } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Body } from 'styles/layout'
import UserForm from 'components/forms/UserForm'

const UserProfile: FC<RouteComponentProps<any>> = props => {
    return (
        <Body>
            <UserForm userId="profile" />
        </Body>
    )
}

export default UserProfile
