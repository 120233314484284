import { MouseEvent, useContext, useState, FC, Fragment, ReactNode } from 'react'
import { useHistory } from 'react-router-dom'
import { UserContext } from 'context/authContext';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import HelpIcon from '@mui/icons-material/Help';
import LogoutIcon from '@mui/icons-material/LogoutOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Box, IconButton, Menu, MenuItem, Theme } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { createStyles, makeStyles } from '@mui/styles';
import Breadcrumb from 'components/Breadcrumb';

const useStyles = makeStyles(({spacing, palette}: Theme) => createStyles({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 70,
        padding: '0 30px',
        zIndex: '10 !important',
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            gap: spacing(2),
        }
    },
    action: {
        disply: 'flex',
        alignItems: 'center',
        marginLeft: 16,

        '& > svg': { cursor: 'pointer' },
        '& .badge > svg': { cursor: 'pointer' },

        '& .menu': {
            position: 'absolute',
            top: 45,
            overflow: 'hidden',
            whiteSpace: 'no-wrap',
            height: 0,
            transition: '0 200ms ease-in',
            width: 200,
            zIndex: 10
        },
        
        '&:hover': {
            '& > svg': { color: palette.primary.main },
            '& .badge > svg' : { color: palette.primary.main },
            '& .menu': { height: 'inherit' }
        },

        '&.first .menu': { right: 125 },
        '&.second .menu': { right: 75 },
        '&.third .menu': { right: 25 }
    },
}))

const TopNav = () => {
    const { logout } = useContext(UserContext);
    const classes = useStyles();
    const history = useHistory();
    const [anchorEl, setAnchorEl] = useState<null | {el: HTMLElement, btn: string}>(null)
    const open = Boolean(anchorEl)

    const handleClick = (event: MouseEvent<HTMLElement>, btn: string) => setAnchorEl({el: event.currentTarget, btn})
    const handleClose = () => setAnchorEl(null)

    const ExternalLink: FC<{ to: string, label: string, desactivated?: boolean }> = ({ to, label, desactivated }) => {
        if(desactivated) return null
        return (
            <MenuItem onClick={() => window.open(to)}>
                <ListItemText primary={label} />
                <OpenInNewIcon fontSize="small" color="action" />
            </MenuItem>
        )
    }

    const InternalLink: FC<{ to: string | (() => void), label: string, icon: ReactNode, desactivated?: boolean }> = ({ to, label, icon, desactivated }) => {
        if(desactivated) return null
        
        return (
            <MenuItem onClick={typeof to === 'string' ? () => history.push(to) : to}>
                <ListItemIcon>
                {icon}
                </ListItemIcon>
                <ListItemText primary={label} />
            </MenuItem>
        )
    }

    const ShowMenuList = () => {
        if(!anchorEl) return null
        if (anchorEl.btn === "help") {
            return (
                <Fragment>
                    <ExternalLink to="https://docs.sarus.tech/" label="Documentation" />
                    <ExternalLink desactivated to="https://colab.research.google.com/drive/1QjlabxpnK7EFS5J27dmj0hqZp0a09085#scrollTo=40IfhHB-cbSO" label="Demo notebook" />
                </Fragment>
            )
        } else if (anchorEl.btn === "profile") {
            return (
                <Fragment>
                    <InternalLink to="/profile" label="My profile" icon={<AccountCircleIcon fontSize="small" />} />
                    <InternalLink to={logout} label="Log out" icon={<LogoutIcon fontSize="small" />} />
                </Fragment>
            )
        } else {
            return null
        }
    }

    return (
        <Box className={classes.root} component="header">
            <Breadcrumb />
            <Box>
                <IconButton
                    onClick={(e) => handleClick(e, 'help')}
                    size="small"
                    sx={{color: 'black'}} // MenuText
                    aria-controls={open ? 'help-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <HelpIcon />
                </IconButton>
                <IconButton
                    onClick={(e) => handleClick(e, 'profile')}
                    size="small"
                    sx={{color: 'black'}}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <AccountCircleIcon fontSize="large" />
                </IconButton>
            </Box>
            <Menu
                anchorEl={anchorEl?.el}
                id={anchorEl?.btn === 'profile' ? "account-menu" : anchorEl?.btn === 'help' ? 'help-menu' : 'menu'}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 4px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    minWidth: 200,
                    '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                    },
                },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <ShowMenuList />
            </Menu>
        </Box>
    )
}

export default TopNav
