import { useState, FC, ChangeEvent } from 'react'
import { useDropzone } from 'react-dropzone'
import ClearIcon from '@mui/icons-material/Clear'
import Typography from '@mui/material/Typography';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { DropzoneBox, UploadedFile } from 'styles/form';
import { convertFileToBase64, getExtension } from 'utils/misc';
import { FormHelperText, IconButton, InputLabel } from '@mui/material';

export type InputProps = {
    label: string | JSX.Element,
    name: string,
    error?: string,
    onChange: (event: ChangeEvent<HTMLInputElement>) => void,
    cbFile?: (name: string, file?: any) => any,
    value?: string,
    disabled?: boolean,
    helper?: string,
    startAdornment?: string | JSX.Element,
    shrink?: boolean,
    placeholder?: string,
}

const SingleDrop: FC<InputProps> = (props) => {
    const {
        label,
        name,
        error,
        cbFile,
        disabled
    } = props;

    const [file, setFile] = useState<any>(undefined)
    const [errors, setErrors] = useState<any>()
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const { getRootProps, getInputProps } = useDropzone({
        multiple: false,
        disabled,
        onDrop: acceptedFile => {
            setIsLoading(true)
            const treatedFile = acceptedFile[0]
            if (getExtension(treatedFile.name) !== 'json') {
                setErrors('Only JSON files are supported')
            } else {
                convertFileToBase64(treatedFile).then((file: any) => {
                    setFile(treatedFile)
                    cbFile && cbFile(name, treatedFile)
                    setErrors(undefined)
                }).then(() => setIsLoading(false)).catch(() => setErrors(true))
            }
        }
    });

    const removeFile = () => {
        setFile(undefined)
        cbFile && cbFile(name, '')
    }

    const ShowFile = () => (
        <UploadedFile error={false} small>
            <div></div>
            <span>{file?.name}</span>
            <div className="delete">
                <IconButton size="small" onClick={() => removeFile()} style={{position: "absolute"}}>
                    <ClearIcon  fontSize="small" style={{display: 'flex', justifyContent: 'center'}}/>
                </IconButton>
            </div>
        </UploadedFile>
    )

    const ErrorHandler = () => {
        if (!error && !errors) return null;
        return (
            <FormHelperText error>
                {error || errors}
            </FormHelperText>
        );
    }

    return (
        <div>
            <InputLabel shrink style={{marginBottom: 8}}>{label}</InputLabel>
            {file ? <ShowFile /> : (
                <DropzoneBox error={errors || error} small>
                    <section {...getRootProps()}>
                        <input {...getInputProps()} disabled={isLoading} name={name} />
                        <Typography variant="caption" color="textSecondary" style={{ textAlign: 'center', lineHeight: 1 }}>Drag and drop JSON file<br />or click to open the upload dialog</Typography>
                        <CloudUploadIcon style={{ marginTop: 16 }}/>
                    </section>
                </DropzoneBox>
            )}
            <ErrorHandler />
        </div>
    )
}

export default SingleDrop
