import axios from 'axios';
import { UserResetPasswordSend } from 'components/auth/LoginForm';
import config from 'config/global';
import { BaseDataset, CompleteDataset } from 'types/datasets';
import { Group } from 'types/groups';
import { IPrivacyPolicy } from 'types/privacy-policy';
import { ISetting } from 'types/settings';
import { LoginData, User, CreateUser } from 'types/user';

const service = axios.create({
    baseURL: config.apiURL,
    withCredentials: true
});

const errorHandler = (error: any) => {
    if (error.response.data && config.development) {
        console.log(error.response && error.response.data);
        throw error;
    }
    throw error
}

const apiHandler = {
    service,
    login(data: LoginData) { return service.post(`/login`, data).then(res => res).catch(errorHandler) },
    logout() { return service.get(`/logout`).then(res => res.data).catch(errorHandler) },
    getMe() { return service.get(`/users/me`).then(res => res.data).catch(errorHandler) },

    // Datasets
    getDataset(id: number) { return service.get(`/datasets/${id}`).then(res => res.data).catch(errorHandler) },
    getAllDatasets() { return service.get(`/datasets`).then(res => res.data).catch(errorHandler) },
    createDataset(data: BaseDataset) { return service.post(`/datasets`, data).then(res => res.data).catch(errorHandler) },
    checkSchema(id: number) { return service.get(`/datasets/${id}/infer_schema`).then(res => res.data).catch(errorHandler) },
    inferSchema(id: number) { return service.post(`/datasets/${id}/infer_schema`).then(res => { return { data: res.data, status: res.status } }).catch(errorHandler) },
    prepareDataset(id: number) { return service.post(`/datasets/${id}/prepare`).then(res => { return { data: res.data, status: res.status, all: res } }).catch(errorHandler) },
    checkDatasetPreparation(id: number) { return service.get(`/datasets/${id}/prepare`).then(res => res.data).catch(errorHandler) },
    editDataset(id: number, data: Partial<CompleteDataset>) { return service.put(`/datasets/${id}`, data).then(res => { return { data: res.data, status: res.status } }).catch(errorHandler) },
    editDatasetRelationship(id: number, data: Partial<CompleteDataset>) { return service.put(`/datasets/${id}`, data).then(res => res).catch(errorHandler) },
    deleteDataset(id: number) { return service.delete(`/datasets/${id}`).then(res => res.data).catch(errorHandler) },
    getSyntheticData(id: number, options: { schema?: string, limit: number }) { return service.get(`/datasets/${id}/synthetic_data${options.schema ? `/${options.schema}` : ''}?rows_number=${options.limit}`).then(res => res.data).catch(errorHandler) },

    // Datasources
    // getAllDatasources() { return service.get(`/datasources`).then(res => res.data).catch(errorHandler) },
    getDatasource(id: number) { return service.get(`/datasources/${id}`).then(res => res.data).catch(errorHandler) },

    // Data Connections
    getAllDataConnections() { return service.get(`/dataconnections`).then(res => res.data).catch(errorHandler) },
    getDataConnection(id: number) { return service.get(`/dataconnections/${id}`).then(res => res.data).catch(errorHandler) },
    getDataConnectionFormat(connector: string) { return service.get(`dataconnection_format/${connector}`).then(res => res.data).catch(errorHandler) },
    createDataConnection(data: any) { return service.post(`/dataconnections`, data, { headers: { 'Content-Type': 'application/json' } }).then(res => res.data).catch(errorHandler) },
    deleteDataConnection(id: number) { return service.delete(`/dataconnections/${id}`).then(res => res.data).catch(errorHandler) },
    editDataConnection(id: number, data: any) { return service.put(`/dataconnections/${id}`, data, { headers: { 'Content-Type': 'application/json' } }).then(res => res.data).catch(errorHandler) },
    checkDataConnection(id: number) { return service.get(`/dataconnections/${id}/check`).then(res => res).catch(errorHandler) },

    // Users
    getUser(id: number) { return service.get(`/users/${id}`).then(res => res.data).catch(errorHandler) },
    getAllUsers() { return service.get(`/users`).then(res => res.data).catch(errorHandler) },
    createUser(data: CreateUser) { return service.post(`/users`, data).then(res => res.data).catch(errorHandler) },
    editUser(id: number, data: Partial<User>) { return service.put(`/users/${id}`, data).then(res => res.data).catch(errorHandler) },
    deleteUser(id: number) { return service.delete(`/users/${id}`).then(res => res.data).catch(errorHandler) },
    getUserFromToken(token: string) { return service.get(`/users/confirm?token=${token}`).then(res => res.data).catch(errorHandler) },
    confirmUserCreation(data: any) { return service.put(`/users/confirm`, data).then(res => res.data).catch(errorHandler) },
    resetFirstPassword(data: UserResetPasswordSend) { return service.put(`/users/initial_password_reset`, data).then(res => res).catch(errorHandler) },

    // Groups
    getGroup(id: number) { return service.get(`/groups/${id}`).then(res => res.data).catch(errorHandler) },
    getAllGroups() { return service.get(`/groups`).then(res => res.data).catch(errorHandler) },
    createGroup(data: Group) { return service.post(`/groups`, data).then(res => res.data).catch(errorHandler) },
    editGroup(id: number, data: Group) { return service.put(`/groups/${id}`, data).then(res => res.data).catch(errorHandler) },
    deleteGroup(id: number) { return service.delete(`/groups/${id}`).then(res => res.data).catch(errorHandler) },

    // Privacy policies
    getPrivacyPolicy(id: number) { return service.get(`/privacy_policy/${id}`).then(res => res.data).catch(errorHandler) },
    getAllPrivacyPolicies() { return service.get(`/privacy_policy`).then(res => res.data).catch(errorHandler) },
    createPrivacyPolicy(data: IPrivacyPolicy) { return service.post(`/privacy_policy`, data).then(res => res.data).catch(errorHandler) },
    editPrivacyPolicy(id: number, data: IPrivacyPolicy) { return service.put(`/privacy_policy/${id}`, data).then(res => res.data).catch(errorHandler) },
    deletePrivacyPolicy(id: number) { return service.delete(`/privacy_policy/${id}`).then(res => res.data).catch(errorHandler) },
    getDefaultWhitelist() { return service.get(`/privacy_policy/default_whitelist`).then(res => res.data).catch(errorHandler) },

    // Settings
    getSetting(id: number) { return service.get(`/settings/${id}`).then(res => res.data).catch(errorHandler) },
    getAllSettings() { return service.get(`/settings`).then(res => res.data).catch(errorHandler) },
    createSetting(data: ISetting) { return service.post(`/settings`, data).then(res => res.data).catch(errorHandler) },
    editSetting(id: number, data: ISetting) { return service.put(`/settings/${id}`, data).then(res => res.data).catch(errorHandler) },

    // Logs
    getAllAccessLogs() { return service.get(`/access_logs`).then(res => res.data).catch(errorHandler) },
    getAllPrivacyLogs() { return service.get(`/privacy_logs`).then(res => res.data).catch(errorHandler) },

    // Health
    getServicesHealth() { return service.get(`/healthcheck`).then(res => res.data).catch(errorHandler) },
}

export default apiHandler;
