import { useEffect, useState } from 'react'
import apiHandler from 'api/apiHandler'
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { User } from 'types/user';
import { Body } from 'styles/layout';
import { Title } from 'components/stylized/titles';
import { Typography } from '@mui/material';
import { Search } from 'types/datagrid';
import { DescList } from 'components/stylized/listItems';
import { listRoles, Role } from 'config/roles';
import { useContext } from 'react';
import { UserContext } from 'context/authContext';
import ListResource from 'assets/datagrids/ListResource';
import { getText } from 'utils/dictionnary';
import OverflowTooltip from 'hooks/OverflowTooltip';

const UserList = () => {
    const { hasPermissions } = useContext(UserContext);
    const [users, setUsers] = useState<User[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [errorOnLoading, setErrorOnLoading] = useState<boolean>(false);

    useEffect(() => {
        let isSubscribed = true;
        setIsLoading(true)
        apiHandler.getAllUsers()
            .then(result => {
                if (isSubscribed) {
                    setUsers(result.map((user: User) => user.username === '' ? {...user, username: getText('user.invitedUser')} : user));
                    setIsLoading(false);
                }
            }).catch(err => setErrorOnLoading(true));
        return () => {
            isSubscribed = false;
        }
    }, [])

    const columns: GridColDef[] = [
        { field: 'email', headerName: 'Email', type: 'string', flex: 0.5, renderCell: (params) => OverflowTooltip({tooltip: params.value}) },
        { field: 'username', headerName: 'Full name', type: 'string', flex: 0.5, renderCell: (params: GridCellParams) => OverflowTooltip({tooltip: params.value, text: params.value === 'Invited User' ? <em>{params.value}</em> : params.value}) },
        {
            field: 'roles', headerName: 'Role(s)', type: 'string', flex: 0.5, renderCell: (params: GridCellParams) => {
                const list: any[] = params.value as any[];
                const rolesNames: string = list.reduce((acc, current) => {
                    const role: Role = listRoles.find((role: Role) => role.name === current)!;
                    return `${acc === '' ? '' : `${acc}, `}${role.label}`
                }, ``);
                return OverflowTooltip({tooltip: rolesNames, text: <div>{rolesNames}</div>})
            }
        },
        {
            field: 'nb_groups', headerName: 'Nb of group(s)', type: 'number', flex: 0.4, renderCell: (params: GridCellParams) => {
                return <>{params.row.groupId.length - 1}</>
        } }
    ];

    const deleteUsers = async (ids: number[]) => {
        for (const id of ids) {
            try {
                await apiHandler.deleteUser(id)
            } catch (error) {
                console.error(`Failed to remove user with ID ${id}: ${error}`)
            }
        }
        const updatedUsers = users.filter(user => typeof user.id !== 'undefined' && !ids.includes(user.id))
        setUsers(updatedUsers)
    }

    const searchData: Search = {
        label: 'Search username, email, role',
        type: 'user'
    }

    return (
        <Body>
            <Title variant="h2" comp="h1">Users</Title>
            <DescList>
                <Typography variant="body2" color="textSecondary">
                    Below is the list of users you can manage.<br />
                    They have one or more role(s) (set of permissions) and can be granted access
                    to a dataset from Datasets section, individually or through a group.
                </Typography>
            </DescList>
            <ListResource
                rows={users}
                columns={columns}
                loading={isLoading}
                doDelete={deleteUsers}
                canSearch={searchData}
                errorOnLoading={errorOnLoading}
                labelCreate="Add"
                labelDelete="Remove"
                resource="users"
                hasPerm={hasPermissions(['user.listAll', 'user.listOwn']) ? true : getText('user.needPerm')}
            />
        </Body>
    )
}

export default UserList
