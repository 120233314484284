import React, { FC } from 'react'
import Button from '@mui/material/Button'
import { ButtonProps } from './ButtonsType';
import { CircularProgress } from '@mui/material';
import { Add } from '@mui/icons-material';

const SecondaryButton: FC<ButtonProps> = (props) => {
    const { action, children, disabled, isLoading, fullWidth, large, add } = props;
    
    return (
        <Button
            color="secondary"
            variant="contained"
            onClick={action}
            disabled={disabled || isLoading}
            endIcon={isLoading && <CircularProgress size={16} />}
            fullWidth={!!fullWidth}
            size={large ? "large" : "medium"}
            startIcon={add ? <Add /> : undefined}
        >
            {children}
        </Button>
    )
}

export default SecondaryButton
