import {
    CircularProgress,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemSecondaryActionProps,
    ListItemText,
    Theme,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { FC } from 'react'
import { useSelector, shallowEqual } from 'react-redux';
import { AppState } from 'store/configureStore';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { CompleteDataset } from 'types/datasets';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        paddingTop: 0,
        paddingBottom: 0,
        "& .MuiListItem-gutters": {
            paddingLeft: 0,
            paddingRight: 0,
        },
        '& .MuiListItemSecondaryAction-root': {
            right: 6,
        },
        '& .MuiTypography-colorTextSecondary.error': {
            fontSize: '0.8em',
        }
    },
    error: {
        color: theme.palette.error.main,
        '& *': {
            color: theme.palette.error.main,
        }
    },
    errorText: {
        fontSize: '0.8em',
        fontStyle: 'italic',
        paddingTop: 8,
        paddingLeft: 8,
    }
}));

const ShowIconStatus = (props: ListItemSecondaryActionProps) => {
    return <ListItemSecondaryAction {...props} sx={{top: '22px'}} />
}

const RenderIcon: FC<{ status: string, previous?: string, schema?: boolean }> = React.memo(({ status, previous, schema }) => {
    const { dataset } = useSelector((state: AppState) => ({
        dataset: state.dataset.dataset,
    }), shallowEqual);
    if (!schema && (dataset?.status === 'empty' || (status === 'PENDING' && previous === 'FAILURE'))) return <MoreHorizIcon style={{opacity: '0.1'}}/>;

    if (status === 'PROGRESS' || (status === 'PENDING' && (!previous || (previous !== 'PENDING' && previous !== 'PROGRESS')))) {
        return <CircularProgress size={18} color="inherit" style={{marginRight: 2}} />
    } else if (status === 'SUCCESS') {
        return <CheckIcon />
    } else if (status === 'FAILURE') {
        return <ErrorIcon color='error'/>
    } else {
        return <><MoreHorizIcon style={{opacity: '0.1'}}/></>
    }
});

const DetailViewer: FC<{dataset?: CompleteDataset}> = ({ dataset }) => {
    const classes = useStyles()
    const { monitorSelected, schemaState } = useSelector((state: AppState) => ({
        monitorSelected: state.monitor[dataset!.id],
        schemaState: state.schema,
    }), shallowEqual);
    const monitor = monitorSelected.details
    if (!monitor) return null;

    const secondary = (bloc: 'data' | 'marginal' | 'synthetic' | 'sql' | 'schema') => {
        switch (bloc) {
            case 'data':
                return monitor.prepare_parquet.error ? { secondary: <Typography className={classes.errorText}>{monitor.prepare_parquet.error}</Typography>, className: monitor.prepare_parquet.status === 'FAILURE' ? classes.error : '' } : {};
            case 'marginal':
                return monitor.marginals.error ? { secondary: <Typography className={classes.errorText}>{monitor.marginals.error}</Typography>, className: monitor.marginals.status === 'FAILURE' ? classes.error : '' } : {};
            case 'synthetic':
                return monitor.synthetic_data.error ? { secondary: <Typography className={classes.errorText}>{monitor.synthetic_data.error}</Typography>, className: monitor.synthetic_data.status === 'FAILURE' ? classes.error : '' } : {};
            case 'sql':
                return monitor.generate_sql?.error ? { secondary: <Typography className={classes.errorText}>{monitor.generate_sql.error}</Typography>, className: monitor.generate_sql.status === 'FAILURE' ? classes.error : '' } : {};
            case 'schema':
                return schemaState.errorInSchema ? { secondary: <Typography className={classes.errorText}>{schemaState.errorInSchema}</Typography>, className: schemaState.checkingState === 'FAILURE' ? classes.error : '' } : {};
            default:
                return {};
        }
    }

    const getSchemaStatus = () => {
        if(schemaState.currentState === 'FINISHED') return 'SUCCESS'
        switch (schemaState.checkingState) {
            case 'WAITING':
            default:
                return 'PENDING'
            case 'ERROR':
            case 'FAILURE':
                return 'FAILURE'
            case 'SUCCESS':
                return 'SUCCESS'
        }
    }

    return (
        <div>
            <List dense className={classes.root}>
                <ListItem>
                    <ListItemText primary="Detecting schema" {...secondary('schema')} />
                    <ShowIconStatus>
                        <RenderIcon status={getSchemaStatus()} schema />
                    </ShowIconStatus>
                </ListItem>
                <ListItem>
                    <ListItemText primary="Preparing parquet" {...secondary('data')} />
                    <ShowIconStatus>
                        <RenderIcon status={monitor.prepare_parquet.status} />         
                    </ShowIconStatus>
                </ListItem>
                <ListItem>
                    <ListItemText primary="Generating marginals" {...secondary('marginal')} />
                    <ShowIconStatus>
                        <RenderIcon status={monitor.marginals.status} previous={monitor.prepare_parquet.status} />
                    </ShowIconStatus>
                </ListItem>
                <ListItem>
                    <ListItemText primary="Generating synthetic data" {...secondary('synthetic')} />
                    <ShowIconStatus>
                        <RenderIcon status={monitor.synthetic_data.status} previous={monitor.marginals.status} /> 
                    </ShowIconStatus>
                </ListItem>
                <ListItem>
                    <ListItemText primary="Generating SQL data" {...secondary('sql')} />
                    <ShowIconStatus>
                        {monitor.generate_sql && <RenderIcon status={monitor.generate_sql.status} previous={monitor.synthetic_data.status} />}
                    </ShowIconStatus>
                </ListItem>
            </List>
        </div>
    )
}

export default React.memo(DetailViewer)
