import { FC } from 'react';
import Chip from '@mui/material/Chip';
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import { Theme } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { wordingStatus } from 'utils/misc';

const customStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        background: theme.palette.grey[200],
        display: 'inline-grid',
        gridTemplateColumns: '14px 1fr',
        gap: 4,
        fontSize: '0.8125rem',
        outline: 0,
        borderRadius: 16,
        height: 24,
        whiteSpace: 'nowrap',
        verticalAlign: 'middle',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
        padding: '0 4px',
        '& .label': {
            padding: '0 4px'
        }
    }
}));

interface Props {
    status?: string;
}

type CustomChip = {
    label: string,
    className?: string
}

const ChipCustom: FC<CustomChip> = ({ label }) => {
    const classes = customStyles();
    return (
        <div className={classes.root}>
            <CircularProgress size={14} color="inherit" />
            <span className="label">{label}</span>
        </div>
    );
}

const IconSelector: FC<{ status: string }> = props => {
    const { status } = props;
    // "pending" status is handled by another component
    switch (status) {
        case 'ready':
            return <DoneIcon fontSize="small" />
        case 'error':
            return <ErrorIcon fontSize="small" />
        case 'empty':
            return <ErrorIcon fontSize="small" />
        default:
            return null;
    }
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        backgroundColor: (props: Props) =>
            props.status === 'ready' ? theme.palette.grey[200]
                : props.status === 'empty' ? theme.palette.warning.light
                    : props.status === 'pending' ? theme.palette.grey[200]
                        : theme.palette.error.light,
        color: (props: Props) =>
            props.status === 'error' ? theme.palette.error.main
                : props.status === 'pending' ? 'rgba(0, 0, 0, 0.54)'
                    : props.status === 'empty' ? theme.palette.warning.dark
                        : 'inherit',
        padding: '0 4px',
        '& .MuiChip-labelSmall': {
            padding: '0 4px',
        }
    }
}))

export const DisplayStatus: FC<{ status?: string }> = props => {
    const { status } = props;
    const classes = useStyles({ status });

    const styleProps = {
        status
    }

    if (!status) return null;
    if (status === 'pending') {
        return <ChipCustom
            label={wordingStatus(status)}
        />
    } else {
        return <Chip
            label={wordingStatus(status)}
            size="small"
            icon={<IconSelector status={status} />}
            className={classes.root}
            {...styleProps}
        />
    }
}