import { SchemaCheckingState, SchemaState } from "store/reducers/SchemaReducer";
import { DataSpecSchema } from "types/datasets";

export const SCHEMA_INITIALIZE = "SCHEMA_INITIALIZE";
export const SCHEMA_CHANGE = "SCHEMA_CHANGE";
export const SCHEMA_CHECK = "SCHEMA_CHECK";
export const SCHEMA_UNLOAD = "SCHEMA_UNLOAD";

export interface SchemaInitialize {
    type: typeof SCHEMA_INITIALIZE
}

export interface SchemaChange {
    type: typeof SCHEMA_CHANGE,
    payload: {
        state: SchemaState,
        param?: string | DataSpecSchema
    }
}

export interface SchemaCheck {
    type: typeof SCHEMA_CHECK,
    payload: {
        status: SchemaCheckingState
        error?: string
    }
}

export interface SchemaUnload {
    type: typeof SCHEMA_UNLOAD
}

export type SchemaDispatchTypes =
    SchemaInitialize
    | SchemaChange
    | SchemaCheck
    | SchemaUnload