import { Clear, Search } from '@mui/icons-material'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import React, { ChangeEvent, FC } from 'react'

interface SearchboxProps {
    onChange: (event: ChangeEvent<HTMLInputElement>) => void,
    placeholder?: string,
    value: string,
    disabled?: boolean,
    fullWidth?: boolean
    cleanSearch: () => void
}

const Searchbox: FC<SearchboxProps> = (props) => {
    const {
        onChange,
        placeholder,
        value,
        disabled,
        fullWidth,
        cleanSearch
    } = props

    const SearchIcon = () => (
        <InputAdornment position="start">
            <Search />
        </InputAdornment>
    )

    const ResetIcon = () => (
        <InputAdornment position="end">
            <IconButton
                size="small"
                onClick={cleanSearch}
            >
                <Clear />
            </IconButton>
        </InputAdornment>
    )


    return (
        <TextField
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            fullWidth={fullWidth}
            InputProps={{
                startAdornment: <SearchIcon />,
                endAdornment: value !== '' && <ResetIcon />
            }}
        />
    )
}

export default Searchbox