import { applyMiddleware, createStore, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension'

// import logger from 'middlewares/logger';
// import monitorReducerEnhancer from 'middlewares/monitorReducer';
import datasetReducer from './reducers/DatasetReducer';
import monitorReducer from './reducers/MonitorReducer';
import schemaReducer from './reducers/SchemaReducer';
import appReducer from './reducers/AppReducer';

const rootReducer = combineReducers({
    dataset: datasetReducer,
    monitor: monitorReducer,
    schema: schemaReducer,
    app: appReducer,
});

const middlewares = [thunkMiddleware];

const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(...middlewares))
)

export type AppState = ReturnType<typeof rootReducer>
export default store;