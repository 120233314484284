export const APP_SET_RESOURCE = "APP_SET_RESOURCE"
export const APP_UNSET_RESOURCE = "APP_UNSET_RESOURCE"

export interface AppSetResource {
    type: typeof APP_SET_RESOURCE,
    payload: string
}

export interface AppUnsetResource {
    type: typeof APP_UNSET_RESOURCE
}

export type AppActionDispatchTypes = AppSetResource
    | AppUnsetResource