import { useEffect, useState } from 'react'
import { GridCellParams, GridColDef, GridSortDirection } from '@mui/x-data-grid';
import { Body } from 'styles/layout';
import { Title } from 'components/stylized/titles';
import { DescList } from 'components/stylized/listItems';
import { capitalize, Typography } from '@mui/material';
import { Search } from 'types/datagrid';
import apiHandler from 'api/apiHandler';
import { User } from 'types/user';
import { getListConnectors } from 'assets/data-connections/connectorsData';
import { showConnectorData } from './DataConnectionShow';
import ListResource from 'assets/datagrids/ListResource';
import OverflowTooltip from 'hooks/OverflowTooltip';

const DataConnectionList = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [errorOnLoading, setErrorOnLoading] = useState<boolean>(false);
    const [listOfDataConnections, setListOfDataConnections] = useState<any>([]);

    useEffect(() => {
        Promise.all([
            apiHandler.getAllDataConnections(),
            apiHandler.getAllUsers()
        ]).then((result) => {
            const rDataConnections = result[0];
            const rUsers = result[1];
            let dataConnectionsList = rDataConnections.map((e: any) => {
                let result = e;
                result.connector_type = capitalize(e.connector_type)
                result.owner_id = rUsers.find((u: User) => u.id === e.owner_id)?.email
                return result
            })
            setListOfDataConnections(dataConnectionsList);
            setIsLoading(false);
        }).catch((e) => {
            setErrorOnLoading(true)
        });
    }, [])

    const showDate = (date: unknown) => {
        type FormatDate = {
            day?: '2-digit' | 'numeric',
            weekday?: 'narrow' | 'short' | 'long',
            year?: '2-digit' | 'numeric',
            month?: '2-digit' | 'short' | 'long' | 'numeric' | 'narrow',
            hour?: '2-digit' | 'numeric',
            minute?: '2-digit' | 'numeric',
            second?: 'numeric' | '2-digit'
        }
        const options: FormatDate =
            { year: 'numeric', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit' };
        const newDate = new Date(date as number);
        const formated = newDate.toLocaleDateString('en-GB', options);
        return formated;
    }

    const columns: GridColDef[] = [
        { field: 'name', headerName: 'Name', type: 'string', flex: 0.5, renderCell: (params) => OverflowTooltip({tooltip: params.value}) },
        {
            field: 'connector_type', headerName: 'Connection type', type: 'string', flex: 0.5, renderCell: (params: GridCellParams) => {
                const data = getListConnectors().find((d) => d.name === (params.value as string).toLowerCase());
                const result = <><img src={data!.logo} style={{ width: 20, marginRight: 10 }} alt={showConnectorData(data!.name, 'name')} /> {data?.labelLong || data?.label}</>
                return OverflowTooltip({tooltip: (data?.labelLong || data?.label) as string, text: result})
        } },
        { field: 'is_public', headerName: 'Public', type: 'boolean' },
        { field: 'owner_id', headerName: 'Owner', type: 'string', width: 200, renderCell: (params) => OverflowTooltip({tooltip: params.value}) },
        { field: 'created', headerName: 'Created', type: 'dateTime', width: 150, renderCell: (params: GridCellParams) => OverflowTooltip({ tooltip: showDate(params.value) }) }
    ]

    const sortModel = [{
        field: 'created',
        sort: 'desc' as GridSortDirection,
    }];

    const deleteConnection = async (ids: number[]) => {
        // await id.forEach(async (el) => {
        //     await apiHandler.deleteDataConnection(el).catch(error => console.log(error));
        // })
        // setListOfDataConnections(listOfDataConnections.filter((e: any) => !id.includes(e.id)));
        for (const id of ids) {
            try {
                await apiHandler.deleteDataConnection(id)
            } catch (error) {
                console.error(`Failed to delete Data Connection with ID ${id} : ${error}`)
            }
        }
        const updatedDC = listOfDataConnections.filter((dc: any) => typeof dc.id !== 'undefined' && !ids.includes(dc.id))
        setListOfDataConnections(updatedDC)
    }

    const searchData: Search = {
        label: 'Search a data connection',
        type: 'data-connections'
    }

    return (
        <Body>
            <Title variant="h2" comp="h1">Data Connections</Title>
            <DescList>
                <Typography variant="body2" color="textSecondary">
                    A data connection is a link to a remote repository or database where source data is located, and optionally a destination location for the created Sarus objects.<br />
                    Data connections make it easy to manage the source data of your Sarus datasets.
                </Typography>
            </DescList>
            <ListResource
                rows={listOfDataConnections}
                columns={columns}
                loading={isLoading}
                sortModel={sortModel}
                canSearch={searchData}
                doDelete={deleteConnection}
                errorOnLoading={errorOnLoading}
                resource="data-connections"
            />
        </Body>
    )
}

export default DataConnectionList
