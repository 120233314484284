import React, { FC } from 'react'
import { Delete } from '@mui/icons-material'
import { Button } from '@mui/material'
import { ButtonProps } from './ButtonsType'

const DeleteButton: FC<ButtonProps> = (props) => {
    const {
        action,
        children,
        disabled,
        large,
    } = props

    return (
        <Button
            color="primary"
            onClick={action}
            size={large ? 'large' : 'medium'}
            startIcon={<Delete />}
            disabled={disabled}
        >
            {children}
        </Button>
    )
}

export default DeleteButton