import { useEffect, useContext, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { UserContext } from 'context/authContext';
import globalConfig from 'config/global';
import { Box, Button, Typography } from '@mui/material';
import SignupForm from 'components/forms/SignupForm';
import { FcGoogle } from 'react-icons/fc'
import AuthTemplate from 'assets/templates/AuthTemplate';
import SarusLink from 'components/SarusLink';
import Divider from 'components/Divider';
import config from 'config/global'

const SignupPage = () => {
    const { isLoggedIn, isLoading } = useContext(UserContext);
    const location = useLocation()
    const history = useHistory();
    const [token, setToken] = useState<string | undefined>(undefined);
    const [isLoadingToken, setIsLoadingToken] = useState<boolean>(true)

    useEffect(() => {
        if (isLoggedIn && !isLoading) {
            history.push(globalConfig.landingPage);
        }
    }, [isLoggedIn, isLoading, history]);

    useEffect(() => {
        const searchedToken = new URLSearchParams(location.search).get("token")
        setToken(searchedToken ?? undefined)
        setIsLoadingToken(false)
    }, [location.search])

    const submitOIDC = () => {
        localStorage.setItem('username', 'oidc')
        const redirect = process.env.PUBLIC_URL + '/v1/oidc_login?token=123';
        window.location.replace(redirect)
    }

    const SignUpGoogle = () => {
        if (token || !config.activateGoogleLogin) return null;
        return (
            <>
                <Button
                    onClick={submitOIDC}
                    size="large"
                    variant="outlined"
                    color="primary"
                    fullWidth
                    startIcon={<FcGoogle />}
                >
                    Sign Up with Google
                </Button>
                <Divider>OR</Divider>
            </>
        )
    }

    if (isLoadingToken) return null;
    return (
        <AuthTemplate>
            <SignUpGoogle />
            <Box className="boxContainer">
                <SignupForm token={token} />
            </Box>
            <Typography align="center" style={{ fontSize: '12px' }} color="textSecondary">
                Already have a Sarus account? <SarusLink to="/login" bold>Log in</SarusLink>.
            </Typography>
        </AuthTemplate>
    )
}

export default SignupPage
