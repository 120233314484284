import { FC } from 'react'
import { TextField } from '@mui/material';
import NumberFormat, { NumberFormatValues } from 'react-number-format';

export type InputProps = {
    label?: string | JSX.Element,
    name: string,
    error?: string | boolean,
    onChange: (e: NumberFormatValues) => void
    value?: string,
    disabled?: boolean,
    helper?: string,
    startAdornment?: string | JSX.Element,
    shrink?: boolean,
    placeholder?: string,
    max?: number,
    min?: number,
    raw?: boolean,
}

const NumberInput: FC<InputProps> = (props) => {
    const {
        error,
        onChange,
        value,
        disabled,
        raw,
    } = props;

    const calculateWidth = (): number => {
        const baseByChar = 10;
        const minTreshold = 5;

        if (value) {
            if (value.length < minTreshold) {
                return minTreshold * baseByChar;
            } else {
                return value.length * baseByChar;
            }
        } else {
            return minTreshold * baseByChar;
        }
    }

    if (raw) {
        const NumberFormatAny = NumberFormat as any
        return (
            <NumberFormatAny
                onValueChange={onChange}
                thousandSeparator
                value={value}
                customInput={TextField}
                decimalScale={2}
                error={!!error}
                style={{width: calculateWidth(), maxWidth: 150 }}
                disabled={disabled}
            />
        )
    } else {
        return (
            <div>
                
            </div>
        )
    }
}

export default NumberInput
