import { Checkbox, FormControl, FormControlLabel, FormHelperText, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { ChangeEvent, FC } from 'react'

const useStyles = makeStyles((theme: Theme) => ({
    checkbox: {
        padding: `4px`,
        marginRight: 5,
        marginLeft: 5,
    },
    helper: {
        marginTop: 0
    }
}));

interface InputProps {
    name: string,
    label: string | JSX.Element,
    isChecked: boolean,
    onChange: (event: ChangeEvent<HTMLInputElement>) => void,
    error?: string,
    disabled?: boolean,
    helperText?: string | JSX.Element
}

const SimpleCheckbox: FC<InputProps> = (props) => {
    const { name, label, isChecked, onChange, error, disabled, helperText } = props
    const classes = useStyles();

    return (
        <FormControl variant="standard">
            <FormControlLabel
                control={
                    <Checkbox
                        checked={isChecked}
                        onChange={onChange}
                        name={name}
                        disabled={disabled}
                        value={isChecked}
                        size="small"
                        color="primary"
                        className={classes.checkbox}
                    />
                }
                label={label}
            />
            <FormHelperText className={classes.helper}>{error || helperText}</FormHelperText>
        </FormControl>
    )
}

export default SimpleCheckbox
