import { FormHelperText, IconButton, Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'
import TextLabel from 'components/TextLabel'
import React, { ChangeEvent, FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import FileCopyIcon from '@mui/icons-material/FileCopy';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { load, dump } from 'js-yaml'
import copy from 'copy-to-clipboard'
import apiHandler from 'api/apiHandler';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { EditRelationships } from 'store/actions/DatasetActions';

const spaces = 2

type ConsoleType = {
    name: string
    label?: string | ReactNode,
    update?: (data: string) => void,
    disable: boolean,
    error: string,
    baseValue?: string,
    placeholder?: string,
    dlFilename?: string,
    small?: boolean,
    withIdleCheck?: number,
    idleCallBack?: (act: boolean) => void,
    onFocus?: () => void,
    passRef?: any
}

const ActionIcons = styled("div")(({ theme }) => ({
    position: 'absolute',
    right: '16px',
    top: '8px',
    zIndex: 2,
    textAlign: 'right',
}))

interface ConsoleContainerProps { size?: number }

const ConsoleContainer = styled('div', { shouldForwardProp: (prop) => prop !== "size"})<ConsoleContainerProps>(({ theme, size }) => ({
    height: size ? `${size + 18}px` : '58px',
    background: '#EEE',
    border: `1px solid ${theme.palette.grey[300]}`,
    boxSizing: 'border-box',

    '& .editor, .highligh': {
        height: size ? `${size + 8}px` : '40px',
        width: `100%`,
        lineHeight: '16pt',
        display: 'block',
        boxSizing: 'border-box',
        border: 0,
        margin: 0,
        position: 'absolute',
        top: '8px',
        bottom: '8px',
        left: 0,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        fontFamily: "'Roboto Mono', monospace",
        fontSize: '11pt',
        letterSpacing: '0',
        overflow: 'hidden',
        whiteSpace: 'pre-wrap !important',
        tabSize: 2,
    },
    '& .editor': {
        zIndex: 1,
        color: 'transparent',
        background: 'transparent',
        border: 'none',
        caretColor: 'black',
        resize: 'none',
        outline: 'none',
        overflow: 'auto',
        WebkitBoxShadow: 'none',
        MozBoxShadow: 'none',
        boxShadow: 'none',
        WebkitTextFillColor: 'transparent',
        appearance: 'none',
        '&.disable': {
            whiteSpace: 'pre-wrap',
            overflow: 'auto',
            outline: 'none',
            userSelect: 'none',
            msUserSelect: 'none',
            MozUserSelect: 'none',
            WebkitUserSelect: 'none'
        }
    },
    '& .highligh': {
        zIndex: 0,
    },
}))

const Console = styled("code")(({ theme }) => ({
    '& .comment': {
        color: theme.palette.primary.main
    },
    '& .disabled': {
        fontStyle: 'italic',
        color: theme.palette.grey[500]
    },
    outline: 'none !important',
    '& :focus': {
        outline: 'none !important'
    },
    '&.error': {
        borderColor: theme.palette.error.main,
    }
}))

export interface ConsoleBoxHandles {
    blurTextarea: () => void
}
// TextareaHandle
const ConsoleBox: FC<ConsoleType> = (props) => {
    const { name, label, update, disable, error, baseValue, dlFilename, small, withIdleCheck, idleCallBack, onFocus, passRef } = props
    const {datasetId} = useParams<{datasetId: string}>()
    const dispatch = useDispatch()
    const [text, setText] = useState<any>({value: '', caret: -1, target: null})
    const [html, setHtml] = useState('')
    const consoleRef = useRef<HTMLDivElement>(null)
    const textareaRef = useRef<HTMLTextAreaElement>(null)
    const [copied, setCopied] = useState<boolean>(false)
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null)
    const [YAMLError, setYAMLError] = useState(null)

    useEffect(() => {
        if(passRef)
        passRef(textareaRef)
    }, [passRef])

    const getHeight = useCallback(() => {
        if (small && baseValue && baseValue !== 'None' && textareaRef.current) {
            const lineHeight = parseInt(getComputedStyle(textareaRef.current).lineHeight)
            const extraLineHeight = lineHeight
            return baseValue?.split('\n').length * extraLineHeight
        } else {
            return 200
        }
    }, [small, baseValue])

    useEffect(() => {
        setText((txt: any) => ({...txt, value: baseValue}))
    }, [disable, baseValue])

    useEffect(() => {
        update && update(text.value)
    }, [text, update])

    const copyToClipboard = async (text: string) => {
        setCopied(true)
        copy(text)
    }

    useEffect(() => {
        const timer = copied ? setTimeout(() => setCopied(false), 2000) : null
        return () => {
            timer && clearTimeout(timer)
        }
    }, [copied, setCopied])

    const downloadYaml = (text: string) => {
        try {
            const string = JSON.stringify(text)
            const wStringify = load(string, { onWarning: (e) => console.log(e) })
            const wDump = dump(string, {
                indent: 2,
            })

            const blob = new Blob([wStringify as string], { type: "text/plain" })
            const url = URL.createObjectURL(blob)
            const link = document.createElement("a")
            link.download = `${dlFilename ?? `file`}.yaml`
            link.href = url
            link.click()
        } catch (e) {
            console.log(e)
        }
    }

    const checkText = () => {
        let result = ''
        if(text.value) {
            if (text.value === 'None') {
                result = '<div class="disabled">None</div>'
            } else {
                const array = text.value.split(`\n`).map((line: string) => {
                    let treatedLine = line.replaceAll(' ', '&nbsp;')
                    treatedLine = treatedLine.replaceAll('<', '&lt;')
                    treatedLine = treatedLine.replaceAll('<', '&gt;')
                    if (treatedLine.replaceAll('&nbsp;', ' ').trim().startsWith('#')) return `<span class="comment">${treatedLine}</span>`
                    else return treatedLine
                })
                if (array[array.length - 1] === "") array.pop()
                result = array.join('<br>') + '<br><br>'
            }
        }

        setHtml(result)
    }

    useEffect(() => syncScroll(), [html])

    const syncScroll = () => {
        if (consoleRef.current && textareaRef.current) {
            consoleRef.current.scrollTop = textareaRef.current.scrollTop
            consoleRef.current.scrollLeft = textareaRef.current.scrollLeft
        }
    }

    useEffect(() => {
        textareaRef.current && textareaRef.current.addEventListener('scroll', syncScroll)
        return () => {
            textareaRef.current && textareaRef.current.removeEventListener('scroll', syncScroll)
        }
    }, [textareaRef])

    useEffect(() => {
        checkText()
        if (text.caret >= 0) {
            text.target.setSelectionRange(text.caret + spaces, text.caret + spaces)
        }
    }, [text])

    const handleTab = (e: any) => {
        if (disable) return;

        let content = e.target.value
        let caret = e.target.selectionStart

        if (e.key === 'Tab') {
            e.preventDefault()
            let newText = content.substring(0, caret) + ' '.repeat(spaces) + content.substring(caret)
            setText({value: newText, caret, target: e.target})
        }
    }

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        if (disable) return;
        let value = e.target.value
        setText((v: any) => ({...v, value, caret: -1, target: e.target}))
        if(withIdleCheck) {
            idleCallBack && idleCallBack(true)
            if(timer) clearTimeout(timer)
            const newTimer = setTimeout(() => {
                apiHandler.editDatasetRelationship(parseInt(datasetId), {relationship_spec: value}).then(() => {
                    dispatch(EditRelationships(value))
                    setYAMLError(null)
                }).catch(error => {
                    setYAMLError(error.response.data)
                }).finally(() =>
                idleCallBack && idleCallBack(false))
            }, withIdleCheck)
            setTimer(newTimer)
        }
    }

    return (
        <div>
            {(label && !disable) && <TextLabel htmlFor={name} msg={label} isTextHelper/>}
            <div style={{position: 'relative'}}>
                <ConsoleContainer size={getHeight()}>
                    {text.value !== 'None' && (
                        <ActionIcons>
                            <Tooltip title={copied ? "Copied!" : "Copy code"} arrow>
                                <IconButton onClick={() => copyToClipboard(text.value)}>
                                    <FileCopyIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Download as YAML file" arrow>
                                <IconButton onClick={() => downloadYaml(text.value)}>
                                    <SaveAltIcon />
                                </IconButton>
                            </Tooltip>
                        </ActionIcons>
                    )}
                    <textarea
                        ref={textareaRef}
                        id={`editor-${name}`}
                        className={`editor ${disable && 'disable'}`}
                        spellCheck={false}
                        onScroll={syncScroll}
                        onKeyDown={handleTab}
                        onInput={handleChange}
                        readOnly={disable}
                        value={text.value}
                        onClick={() => {
                            onFocus && onFocus()
                        }}
                    ></textarea>
                    <Console
                        id={`console-${name}`}
                        className={`highligh ${error !== '' ? 'error' : ''} ${disable && 'disable'}`}
                        ref={consoleRef}
                        dangerouslySetInnerHTML={{ __html: html }}
                        onChange={checkText}
                    />
                </ConsoleContainer>
                {(YAMLError || (error !== '')) && <FormHelperText error style={{whiteSpace: 'pre-wrap'}}>{YAMLError ? YAMLError : error}</FormHelperText>}
            </div>
        </div>
    )
}

export default ConsoleBox
