import { MonitorDispatchTypes, MONITOR_ADD_TO_MANAGER, MONITOR_UPDATE, MONITOR_FINISHED, MONITOR_SET_STATE, MonitorUpdate } from "store/actions/MonitorActionsType";
import { MonitorProps } from "types/datasets";

export type MonitorStatusType = 'WAITING' | 'WORKING' | 'FINISHED'

const initialMonitor: MonitorProps = {
    prepare_parquet: {
        progress: 0,
        status: 'PENDING',
        error: "",
    },
    marginals: {
        progress: 0,
        status: 'PENDING',
        error: ""
    },
    synthetic_data: {
        progress: 0,
        status: 'PENDING',
        error: ""
    },
    generate_sql: {
        progress: 0,
        status: 'PENDING',
        error: ""
    }
};

const finishedPreparation: MonitorProps = {
    prepare_parquet: {
        progress: 1,
        status: 'SUCCESS',
        error: ''
    },
    marginals: {
        progress: 1,
        status: 'SUCCESS',
        error: ''
    },
    synthetic_data: {
        progress: 1,
        status: 'SUCCESS',
        error: ''
    },
    generate_sql: {
        progress: 1,
        status: 'SUCCESS',
        error: ''
    }
}

export type monitorStatus = 'none' | 'progress' | 'finished';

export interface monitoredDatasets {
    [key: number]: {
        status: monitorStatus,
        details: MonitorProps
    }
}

export interface DefaultMonitorState {
    listMonitored: monitoredDatasets
}

const defaultState: monitoredDatasets = {}

const test: DefaultMonitorState = {
    listMonitored: {
        1: {
            status: "none",
            details: {
                prepare_parquet: {
                    progress: 1,
                    status: 'SUCCESS',
                    error: ''
                },
                marginals: {
                    progress: 0,
                    status: 'PROGRESS',
                    error: ''
                },
                synthetic_data: {
                    progress: 0,
                    status: 'PENDING',
                    error: ''
                },
                generate_sql: {
                    progress: 0,
                    status: 'PENDING',
                    error: ''
                }
            }
        },
        2: {
            status: "none",
            details: {
                prepare_parquet: {
                    progress: 1,
                    status: 'SUCCESS',
                    error: ''
                },
                marginals: {
                    progress: 0,
                    status: 'PROGRESS',
                    error: ''
                },
                synthetic_data: {
                    progress: 0,
                    status: 'PENDING',
                    error: ''
                },
                generate_sql: {
                    progress: 0,
                    status: 'PENDING',
                    error: ''
                }
            }
        }
    }
}


const updateMonitor = (state: monitoredDatasets, action: MonitorDispatchTypes): monitoredDatasets => {
    const { payload } = action as MonitorUpdate
    const { id, details } = payload
    const updated = { status: state[id].status, details }
    return {
        ...state,
        [id]: updated
    }
}
const finishMonitoring = (state: monitoredDatasets, action: MonitorDispatchTypes): monitoredDatasets => {
    const { payload } = action as MonitorUpdate
    const { id, details } = payload
    const updated = { status: state[id].status, details }
    return {
        ...state,
        [id]: updated
    }
}

const monitorReducer = (state: monitoredDatasets = defaultState, action: MonitorDispatchTypes): monitoredDatasets => {
    switch (action.type) {
        case MONITOR_ADD_TO_MANAGER:
            if (action.payload.confirmAddition) {
                return {
                    ...state,
                    [action.payload.id]: {
                        status: action.payload.status === 'ready' ? 'finished' : 'none',
                        details: action.payload.status === 'ready' ? finishedPreparation : initialMonitor
                    }
                }
            } else {
                return state
            }
        case MONITOR_UPDATE:
            return updateMonitor(state, action)
        case MONITOR_FINISHED:
            return {
                ...state,
                    [action.payload.id]: {
                        ...state[action.payload.id],
                        status: 'finished'
                    }
            }
        case MONITOR_SET_STATE:
            return {
                ...state,
                    [action.payload.id]: {
                        ...state[action.payload.id],
                        status: action.payload.state
                    }
            }
        default:
            return state
    }
}

export default monitorReducer