import { Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        paddingTop: theme.spacing(1),
    },
    marginBottom: {
        marginBottom: theme.spacing(1),
    },
    selector: {
        color: theme.palette.text.secondary,
        width: '133px',
        height: '123px',
        display: 'grid',
        gridTemplateRows: '62px 1fr',
        justifyContent: 'center',
        textAlign: 'center',
        '& > div:first-child': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'center',

            '& > img': {
                height: 45,
            }
        },
        '& > div:nth-child(2)': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        '&.active': {
            color: theme.palette.primary.main,
        },
        '&.disabled': {

        }
    },
    title: {
        lineHeight: '16px',
    },
    dialogPaper: {
        minHeight: '90vh',
        maxHeight: '90vh',
    },
    disabled: {
        opacity: 0.5,
    }
}));

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    }
);