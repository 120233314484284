import { useState, useEffect } from 'react';
import apiHandler from 'api/apiHandler';
import { CompleteDataset } from 'types/datasets';
import { GridCellParams, GridColDef, GridSortDirection } from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import { Body } from 'styles/layout';
import { Title } from 'components/stylized/titles';
import { formatDatasetDate } from 'utils/misc';
import { DisplayStatus } from 'components/stylized/chips';
import { User } from 'types/user';
import { Search } from 'types/datagrid';
import { DescList } from 'components/stylized/listItems';
import { useContext } from 'react';
import { UserContext } from 'context/authContext';
import Link from '@mui/material/Link';
import ListResource from 'assets/datagrids/ListResource';
import OverflowTooltip from 'hooks/OverflowTooltip';

const DatasetList = () => {
    const [listOfDatasets, setListOfDataset] = useState<CompleteDataset[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [errorOnLoading, setErrorOnLoading] = useState<boolean>(false);
    const { hasPermissions } = useContext(UserContext);
    const [isDeleting, setIsDeleting] = useState<boolean>(false)
    const pollInterval = 5000;

    useEffect(() => {
        const fetchResources = async () => {
            if(!isDeleting) {
                try {
                    const [datasets, users] = await Promise.all([apiHandler.getAllDatasets(), apiHandler.getAllUsers()])
                    let datasetList = formatDatasetDate(datasets as CompleteDataset[])
                    datasetList.map((dataset) => {
                        let result = dataset
                        result.owner_id = users.find((u: User) => u.id === dataset.owner_id)?.email
                        return result
                    })
                    setListOfDataset(datasetList)
                    setErrorOnLoading(false)
                    setIsLoading(false)
                } catch(error) {
                    setErrorOnLoading(true)
                    console.error("Error fetching resources:", error)
                }
            }
        }

        fetchResources()
        const interval = setInterval(fetchResources, pollInterval)

        return () => clearInterval(interval)
    }, [isDeleting])

    const columns: GridColDef[] = [
        { field: 'display_name', headerName: 'Name', type: 'string', flex: 0.5, renderCell: (params) => OverflowTooltip({tooltip: params.value}) },
        { field: 'name', headerName: 'Slug', type: 'string', flex: 0.5, renderCell: (params) => OverflowTooltip({tooltip: params.value}) },
        { field: 'human_description', headerName: 'Description', type: 'string', flex: 0.9, renderCell: (params) => OverflowTooltip({tooltip: params.value}) },
        { field: 'status', headerName: 'Status', flex: 0.5, renderCell: (params: GridCellParams) => (<DisplayStatus status={params.value as string} />) },
        { field: 'owner_id', headerName: 'Owner', type: 'string', flex: 0.5, renderCell: (params) => OverflowTooltip({tooltip: params.value}) },
        {
            field: 'last_modified',
            headerName: 'Last modified',
            type: 'dateTime',
            flex: 0.5,
            renderCell: (params: GridCellParams) => {
                type FormatDate = {
                    year: '2-digit',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                }
                const options: FormatDate = { year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
                const date = new Date(params.value as number);
                const formated = date.toLocaleDateString('en-GB', options);
                return OverflowTooltip({tooltip: formated})
            }
        },
    ];

    const searchData: Search = {
        label: 'Search dataset name, slug, owner, description',
        type: 'dataset'
    }

    const sortModel = [{
        field: 'last_modified',
        sort: 'desc' as GridSortDirection,
    }];

    const deleteDatasets = async (idsToDelete: number[]) => {
        setIsDeleting(true)
        const remainingDatasets = listOfDatasets.filter(row => !idsToDelete.includes(row.id));

        setListOfDataset(remainingDatasets)

        for (const id of idsToDelete) {
            try {
                await apiHandler.deleteDataset(id)
            } catch (error) {
                const failedDataset = listOfDatasets.find(ds => ds.id === id)
                if(failedDataset) {
                    setListOfDataset(prevDatasets => [...prevDatasets, failedDataset])
                }
                break
            }
        }

        setIsDeleting(false)
    }

    return (
        <Body>
            <Title variant="h2" comp="h1">Datasets</Title>
            <DescList>
                <Typography variant="body2" color="textSecondary">
                    A Sarus dataset is a set of data that is made available to data practitioners without exposing sensitive information.<br />
                    Below is the list of datasets you have access to.
                    {hasPermissions('dataset.create') && (<>
                        &nbsp;You can add new datasets, edit access rules, or audit their usage. <Link href={`/docs/index.html`} target="_blank">Learn more</Link>
                    </>)}
                </Typography>
            </DescList>
            <ListResource
                rows={listOfDatasets}
                columns={columns}
                loading={isLoading}
                sortModel={sortModel}
                canSearch={searchData}
                doDelete={deleteDatasets}
                errorOnLoading={errorOnLoading}
                labelCreate="Add"
                labelDelete="Remove"
                resource="datasets"
                hasPerm={hasPermissions(['dataset.listAll', 'dataset.listOwn']) ? true : `It looks like there is no dataset or you don't have sufficient permission to see the list.`}
            />
        </Body>
    )
}

export default DatasetList
