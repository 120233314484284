import { getText } from "utils/dictionnary";

const breadcrumbList = [
    { pathname: 'login', breadcrumb: [''] },
    // Dataset
    { pathname: 'datasets', breadcrumb: ['Datasets'] },
    { pathname: 'datasets/create', breadcrumb: ['Datasets', 'Add new Dataset'] },
    { pathname: 'datasets/*', breadcrumb: ['Datasets', 'Edit Dataset'] },
    { pathname: 'datasets/*/schema', breadcrumb: ['Datasets', 'Edit Dataset', 'Schema'] },
    { pathname: 'datasets/*/access-rules', breadcrumb: ['Datasets', 'Edit Dataset', 'Access Rules'] },
    { pathname: 'datasets/*/synthetic-data', breadcrumb: ['Datasets', 'Edit Dataset', 'Synthetic Data'] },
    // Data Connections
    { pathname: 'data-connections', breadcrumb: ['Data Connections'] },
    { pathname: 'data-connections/create', breadcrumb: ['Data Connections', 'Create new Data Connection'] },
    { pathname: 'data-connections/*', breadcrumb: ['Data Connections', 'Edit Data Connection']},
    // Users
    { pathname: 'users', breadcrumb: ['Users'] },
    { pathname: 'users/create', breadcrumb: ['Users', 'Add new User'] },
    { pathname: 'users/*', breadcrumb: ['Users', 'Edit User'] },
    { pathname: 'profile', breadcrumb: ['My profile'] },
    // Groups
    { pathname: 'groups', breadcrumb: ['Groups'] },
    { pathname: 'groups/create', breadcrumb: ['Groups', 'Create new Group'] },
    { pathname: 'groups/*', breadcrumb: ['Groups', 'Edit Group'] },
    // Privacy Policies
    { pathname: 'privacy-policies', breadcrumb: ['Privacy Policies'] },
    { pathname: 'privacy-policies/create', breadcrumb: ['Privacy Polices', 'Create new Privacy Policy'] },
    { pathname: 'privacy-policies/*', breadcrumb: ['Privacy Policies', 'Privacy Policy detail'] },
    { pathname: 'privacy-policies/*/edit', breadcrumb: ['Privacy Policies', '*', 'Edit Privacy Policy'] },
    // Data Security Logs
    { pathname: 'audit', breadcrumb: ['Audit Trail'] },
    // Settings
    { pathname: 'settings', breadcrumb: ['Settings'] },
    { pathname: 'settings/create', breadcrumb: ['Settings', 'Add new setting'] },
    { pathname: 'settings/*', breadcrumb: ['Settings', 'Edit setting'] },
    // Healthcheck
    { pathname: 'health', breadcrumb: [getText('title.serviceHealth')] },

    // Misc
    { pathname: 'testing', breadcrumb: ['Tests'] }
]

export const getBreadcrumbs = (pathname: string) => {
    return breadcrumbList.find(x => x.pathname === pathname)?.breadcrumb;
}