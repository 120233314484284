import React, { FC, Fragment } from 'react'
import Button from '@mui/material/Button'
import { ButtonProps } from './ButtonsType';
import { CircularProgress } from '@mui/material';

const PrimaryButton: FC<ButtonProps> = (props) => {
    const { action, children, disabled, isLoading, fullWidth, large, form, type } = props;
    
    return (
        <Fragment>
            <Button
                color="primary"
                variant="contained"
                onClick={action}
                disabled={disabled || isLoading}
                endIcon={isLoading && <CircularProgress size={16} />}
                fullWidth={!!fullWidth}
                size={large ? "large" : "medium"}
                form={form}
                type={type}
            >{children}</Button>
        </Fragment>
    )
}

export default PrimaryButton
