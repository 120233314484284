import { ChangeEvent, FC } from 'react'
import { FormControl, FormHelperText, Input, InputLabel } from '@mui/material'

export type InputProps = {
    label: string | JSX.Element,
    name: string,
    error?: string,
    onChange: (event: ChangeEvent<HTMLInputElement>) => void
    value?: string
    disabled?: boolean,
    helper?: string,
    startAdornment?: string | JSX.Element,
    shrink?: boolean,
    placeholder?: string,
    cbFile?: (name: string, el: any) => any,
}

const TextInput: FC<InputProps> = (props) => {
    const {label, name, error, value, onChange, disabled, helper, startAdornment, shrink, placeholder} = props

    return (
        <FormControl style={{ width: 280 }} error={!!error} variant="standard">
            <InputLabel htmlFor={`${name}-field`} shrink={shrink}>{label}</InputLabel>
            <Input
                id={`${name}-field`}
                name={name}
                onChange={onChange}
                value={value || ''}
                disabled={disabled}
                placeholder={placeholder}
                startAdornment={startAdornment && <div style={{marginRight: 8}}>{startAdornment}</div>}
            />
            <FormHelperText error={!!error}>{error || helper}</FormHelperText>
        </FormControl>
    )
}

export default TextInput
