import React, { useState, useEffect, FC } from 'react'
import { Grid, Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { Title } from 'components/stylized/titles'
import TopCloseButton from 'components/TopCloseButton'
import { getText } from 'utils/dictionnary'
import { useHistory } from 'react-router-dom'

type Resource = 'datasets' | 'data-connections' | 'privacy-policies' | 'users' | 'groups' | 'logs'

interface TitlePageProps {
    resource: Resource,
    option: 'create' | 'show',
    name?: string
}

interface ResourceData {
    title: string,
    url: string
}

const initialData: ResourceData = { title: '', url: '' };

const useStyles = makeStyles(({spacing}: Theme) => createStyles({
    closeButton: {
        textAlign: 'right',
        display: 'flex',
        alignItems: 'center',
        marginTop: spacing(1),
        marginBottom: spacing(3),
        justifyContent: 'flex-end',
        '& > div': {
            marginRight: '-1px'
        }
    }
}))

const TitlePage: FC<TitlePageProps> = ({resource, option, name}) => {
    const classes = useStyles()
    const history = useHistory()
    const [resourceData, setResourceData] = useState<ResourceData>(initialData)

    useEffect(() => {
        let data: ResourceData = {...initialData}
        if (option === 'create') {
            switch (resource) {
                case 'datasets':
                    data.title = getText('title.create.dataset')
                    break;
                case 'data-connections':
                    data.title = getText('title.create.dc')
                    break;
                case 'privacy-policies':
                    data.title = getText('title.create.pp')
                    break;
                case 'users':
                    data.title = getText('title.create.user')
                    break;
                case 'groups':
                    data.title = getText('title.create.group')
                    break;
                default:
            }
        } else {
            if (name) {
                data.title = name
            }
        }

        data.url = `/${resource}`
        setResourceData(data)

    }, [name, resource, option, setResourceData])

    return (
        <Grid container>
            <Grid item xs={11}>
                <Title variant="h2" comp="h1">
                    {resourceData.title}
                </Title>
            </Grid>
            <Grid item xs={1} className={classes.closeButton}>
                <div>
                    <TopCloseButton action={() => {
                        history.push(resourceData.url)
                    }} />
                </div>
            </Grid>
        </Grid>
  )
}

export default TitlePage