import React, { FC, useEffect, useState } from 'react';
import { IPrivacyPolicy } from 'types/privacy-policy';
import DGAccessRules from 'components/datagrids/DGAccessRules'
import { AllAccessRule } from 'types/datasets';
import AddRuleForm from './AddRuleForm';
import ShowRuleDetails from './ShowRuleDetails';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/configureStore';
import { EditDataset, ResetClick, RevokeAccessRule } from 'store/actions/DatasetActions';
import { useHistory, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { createSelector } from '@reduxjs/toolkit';

type AccessRulesProps = {
}

const monitorList = (state: AppState) => state.monitor
const selectorMonitor = (id: number) => createSelector(
    monitorList,
    (list) => list[id]?.status
)

const datasetData = (state: AppState) => state.dataset
const selectorDataset = () => createSelector(
    datasetData,
    (ds) => ({accessRules: ds.accessRules, privacyPolicies: ds.privacyPolicies, privacyLogs: ds.privacyLogs})
)

const selectorDSStatus = () => createSelector(
    datasetData,
    (ds) => ds.dataset?.status
)

const AccessRules: FC<AccessRulesProps> = React.memo(() => {
    const { datasetId } = useParams<{ datasetId: string }>();
    const dispatch = useDispatch();
    const [accessDetails, setAccessDetails] = useState<number | undefined>();
    const { accessRules, privacyPolicies, privacyLogs } = useSelector(selectorDataset())
    const status = useSelector(selectorDSStatus())

    const showDetails = (id: number) => setAccessDetails(id);
    const closeDetails = () => setAccessDetails(undefined);

    const revokeRule = (id: number) => {
        let editedAccesses = accessRules.map(x => x.raw);
        editedAccesses.find(x => x.id === id)!.revoked = true;
        dispatch(EditDataset(parseInt(datasetId), { accesses: editedAccesses }))
        dispatch(RevokeAccessRule(id, accessRules));
    }

    if (accessDetails) {
        const rule = accessRules.find((rule: AllAccessRule) => rule.raw.id === accessDetails)!;
        return (
            <ShowRuleDetails
                rule={rule}
                privacyPolicy={privacyPolicies.find((pp: IPrivacyPolicy) => pp.id === rule.raw.pp_id)!}
                privacyLogs={privacyLogs}
                closeDetails={closeDetails}
            />
        )
    } else {
        return (
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <AddRuleForm />
                </Grid>
                <Grid item xs={12}>
                    <DGAccessRules
                        showDetails={showDetails}
                        deleteRule={revokeRule}
                    />
                </Grid>
            </Grid>
        )
    }
});

export default AccessRules;