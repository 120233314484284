import { Box, Chip  } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useRef, useState, useEffect, FC } from 'react'
import { CustomWidthTooltip } from '../SarusAutoComplete'

interface Props {
    name: string,
    passedProps: any,
}

const useStyles = makeStyles(() => ({
    chip: {
        maxWidth: 200
    }
}))

const SarusACChip: FC<Props> = (props) => {
    const {
        name,
        passedProps
    } = props
    const textElementRef = useRef<null | HTMLDivElement>(null)
    const [ready, setReady] = useState(false)
    const [hoverStatus, setHoverStatus] = useState(false)
    const classes = useStyles()

    const compareSize = () => {
        if (!textElementRef.current) return;
        const compare = textElementRef.current.scrollWidth > textElementRef.current.clientWidth
        setHoverStatus(compare)
    }

    useEffect(() => {
        compareSize()
        window.addEventListener('resize', compareSize)
        return () => {
            window.removeEventListener('resize', compareSize)
        }
    }, [])

    useEffect(() => {
        if (ready) return
        document.fonts.ready.then(() => {
            setReady(true)
        })
    }, [])

    return (
        <CustomWidthTooltip
            disableHoverListener={!hoverStatus}
            title={name}
            disableInteractive
            placement="bottom-start"
        >
            <span style={{marginBottom: 4}}>
                <Chip {...passedProps}
                    className={classes.chip}
                    label={
                        <Box
                            ref={textElementRef}
                            sx={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',

                            }}
                        >
                            {name}
                        </Box>
                    }
                />
            </span>
        </CustomWidthTooltip>
    )
}

export default SarusACChip