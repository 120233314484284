import { useEffect, useState } from 'react'
import apiHandler from 'api/apiHandler';
import { ISetting } from 'types/settings';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Body } from 'styles/layout';
import { Title } from 'components/stylized/titles';
import { DescList } from 'components/stylized/listItems';
import ListResource from 'assets/datagrids/ListResource';
import { formatNumber } from 'utils/misc';

const SettingList = () => {
    const [settings, setSettings] = useState<ISetting[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [errorOnLoading, setErrorOnLoading] = useState<boolean>(false);


    useEffect(() => {
        let isSubscribe = true;
        apiHandler.getAllSettings()
            .then(result => {
                if (isSubscribe) {
                    setSettings(result);
                    setIsLoading(false);
                }
            })
            .catch(error => setErrorOnLoading(true));

        return () => {
            isSubscribe = false;
        }
    }, [])

    const formatCellFromValue = (params: GridRenderCellParams) => {
        if(typeof params.value === "number")
            return formatNumber(params.value)
        else if (params.value == null)
            return null
        else
            return params.value.toString()
    }

    const columns: GridColDef[] = [
        { field: 'name', headerName: 'Name', flex: 1, renderCell: (params: GridRenderCellParams) => <strong>{params.value}</strong> },
        { field: 'value', headerName: 'Value', flex: 1, renderCell: formatCellFromValue  }
    ]

    return (
        <Body>
            <Title variant="h2" comp="h1">Settings</Title>
            <DescList></DescList>
            <ListResource
                rows={settings}
                columns={columns}
                loading={isLoading}
                disableCreate
                errorOnLoading={errorOnLoading}
                resource="settings"
            />
        </Body>
    )
}

export default SettingList
