import React, { FC, ReactNode } from 'react'
import MuiDivider from '@mui/material/Divider'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => createStyles({
    root: {
        color: 'rgba(0, 0, 0, 0.54)'
    }
}))

interface DividerProps {
    children?: ReactNode
}

const Divider: FC<DividerProps> = ({children}) => {
    const classes = useStyles();
    return (
        <MuiDivider className={classes.root}>
            {children}
        </MuiDivider>
    )
}

export default Divider