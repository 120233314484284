import { useEffect, useState } from 'react'
import { Body } from 'styles/layout';
import { Title } from 'components/stylized/titles';
import apiHandler from 'api/apiHandler';
import { GridCellParams, GridColDef, GridSortDirection } from '@mui/x-data-grid';
import { AccessLog } from 'types/logs';
import { formatLogsDate } from 'utils/misc';
import { Search } from 'types/datagrid';
import { DescList } from 'components/stylized/listItems';
import ListResource from 'assets/datagrids/ListResource';
import { Typography } from '@mui/material';
import OverflowTooltip from 'hooks/OverflowTooltip';

const LogList = () => {
    const [logs, setLogs] = useState<AccessLog[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        let isSubscribed = true;

        apiHandler.getAllAccessLogs()
            .then(result => {
                if (isSubscribed) {
                    const treatedLogs = formatLogsDate(result);
                    setLogs(treatedLogs);
                }
            }).then(() => {
                if (isSubscribed) {
                    setIsLoading(false);
                }
            }).catch(error => {
                console.log(error);
            });

        return () => {
            isSubscribed = false;
        }
    }, [])

    const columns: GridColDef[] = [
        { field: 'id', hide: true },
        { field: 'username', headerName: 'Username', flex: 0.5, renderCell: (params) => OverflowTooltip({tooltip: params.value}) },
        { field: 'path', headerName: 'Path', flex: 1, renderCell: (params) => OverflowTooltip({tooltip: params.value}) },
        { field: 'status_code', headerName: 'Status code', flex: 0.5 },
        {
            field: 'time',
            headerName: 'Time',
            flex: 0.5,
            renderCell: (params: GridCellParams) => {
                type FormatDate = {
                    year: '2-digit',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                }
                const options: FormatDate = { year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
                const date = new Date(params.value as number);
                const formated = date.toLocaleDateString('en-GB', options);
                return OverflowTooltip({tooltip: formated});
            },
        }
    ]

    const searchData: Search = {
        label: 'Search username, path, status code',
        type: 'log'
    }

    const sortModel = [{
        field: 'time',
        sort: 'desc' as GridSortDirection,
    }];

    return (
        <Body>
            <Title variant="h2" comp="h1">Audit Trail</Title>
            <DescList>
                <Typography variant="body2" color="textSecondary"></Typography>
            </DescList>
            <ListResource
                columns={columns}
                rows={logs}
                loading={isLoading}
                disableCreate
                canSearch={searchData}
                resource="logs"
                sortModel={sortModel}
            />
        </Body>
    )
}

export default LogList
