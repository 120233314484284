import { Autocomplete, Box, TableCell, TextField, Tooltip, Typography } from '@mui/material'
import OverflowTooltip from 'hooks/OverflowTooltip'
import React, { FC, ReactNode } from 'react'

interface ACellProps {
    datasetStatus: string,
    tableStatus: string,
    options: any,
    onChange: (event: any, newValue: string | null) => void
    size: number,
    protectedChip: ReactNode,
    colindex: number,
    isDisable?: boolean,
    label?: string
}

const AdaptativeCell: FC<ACellProps> = (props) => {
    const {
        datasetStatus,
        tableStatus,
        options,
        onChange,
        size,
        protectedChip,
        colindex,
        isDisable,
        label
    } = props

        const italicStyle = {fontStyle: 'italic', color: 'GrayText'}
    
        if (datasetStatus !== 'empty') {
            if (tableStatus === 'public') {
                return <TableCell><Typography sx={italicStyle}>No protection</Typography></TableCell>
            } else {
                return <TableCell>{label}</TableCell>
            }
        } else {
            return (
                <TableCell sx={{minWidth: 150, width: `${size * 9}px`, maxWidth: 300}} aria-colindex={colindex}>
                    {
                        tableStatus === 'public' ?
                            <Autocomplete
                                sx={{width: '100%'}}     
                                options={['rowId', ...options.map((row: any) => row.name)]}
                                onChange={onChange}
                                disabled={isDisable}
                                renderOption={(props, option) => {
                                    const titleRowId = `A fictitious rowId column was added so that it can be selected as the protection key of the table. Such a protection key protects all rows of the table.`
                                    let result = <></>
                                    if(option === 'rowId') {
                                        result = (
                                            <Tooltip arrow title={titleRowId} disableInteractive key={option}>
                                                <div>
                                                    <Box component="li" {...props} sx={italicStyle}>
                                                        <Box component="div">
                                                            {option}
                                                        </Box>
                                                    </Box>
                                                </div>
                                            </Tooltip>
                                        )
                                    } else {
                                        result = (
                                            <div key={option}>
                                                <Box component="li" {...props} sx={{whiteSpace: "nowrap",...(option.startsWith("Inherited") && {...italicStyle})}}>
                                                    <OverflowTooltip tooltip={option} />
                                                </Box>
                                            </div>
                                        )
                                    }
                                    return result
                                }}
                                renderInput={(params) => <TextField {...params} placeholder="No protection" sx={{ '& input::placeholder': { fontStyle: 'italic' } }} />}
                            />
                        :
                        protectedChip
                    }
                </TableCell>
            )
    }
}

export default AdaptativeCell