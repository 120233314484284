import { Paper, Tab, Tabs, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'
import { FC } from 'react';

type Step = {
    label: string,
    disabled?: boolean
}

interface StepperProps {
    steps: Step[],
    actualStep: number,
    onChange: (event: React.ChangeEvent<{}>, value: any) => void,
}

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        background: theme.palette.action.selected,
        marginBottom: theme.spacing(4)
    }
}))

const Stepper: FC<StepperProps> = (props) => {
    const { steps, actualStep, onChange } = props;
    const classes = useStyles();

    return (
        <Paper square elevation={0} className={classes.paper}>
            <Tabs onChange={onChange} value={actualStep} indicatorColor="primary" textColor="primary" variant="fullWidth">
                {steps.map((step: Step, index: number) => {
                    return <Tab key={index} label={step.label} disabled={step.disabled} />
                })}
            </Tabs>
        </Paper>
    )
}

export default Stepper
