import React, { ChangeEvent, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { DatagridProps } from 'types/datagrid'
import { GridRowParams, GridRowsProp, GridSelectionModel } from '@mui/x-data-grid'
import { createStyles, makeStyles } from '@mui/styles'
import { Box, Collapse, Theme, Tooltip } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { Title } from 'components/stylized/titles'
import SecondaryButton from 'components/Buttons/SecondaryButton'
import Searchbox from 'components/SearchBox'
import { getListConnectors } from 'assets/data-connections/connectorsData'
import { UserContext } from 'context/authContext'
import { Permission } from 'config/roles'
import DeleteButton from 'components/Buttons/DeleteButton'
import { pluralizeResource } from 'utils/misc';
import { Group } from 'types/groups'
import { CompleteDataset } from 'types/datasets'
import { User } from 'types/user'
import { IPrivacyPolicy } from 'types/privacy-policy'
import { ISetting } from 'types/settings'
import { MainMessage } from 'components/stylized/errorMessages';
import DialogBox from 'assets/modal/DialogBox'
import ShowDatagrid from './ShowDatagrid'

type Resource = Group | CompleteDataset | User | IPrivacyPolicy | ISetting;

const useStyles = makeStyles(({ palette, spacing }: Theme) => createStyles({
    errorBox: {
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: 32,
        padding: 32,
        color: palette.grey[400],
    },
    noMaxWidth: {
        maxWidth: 'none'
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 16,
        '& > div': {
            display: 'flex'
        },
        '& > div:first-child': {
            alignItems: 'flex-start',
            width: 400
        },
        '& > div:last-child': {
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: spacing(4)
        }
    }
}))

const ListResource: FC<DatagridProps> = (props) => {
    const {
        rows,
        columns,
        loading,
        doDelete,
        disableCreate,
        sortModel: baseSortModel,
        canSearch,
        labelCreate,
        labelDelete,
        resource,
        hasPerm,
    } = props

    const classes = useStyles()
    const history = useHistory()
    const { hasPermissions, user } = useContext(UserContext)
    const [filteredList, setFilteredList] = useState(rows)
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel[]>([])
    const [otherParams, setOtherParams] = useState<any>({})
    const [searching, setSearching] = useState<string>('')
    const [selectionToDelete, setSelectionToDelete] = useState<Resource[]>([])
    const [dialogDelete, setDialogDelete] = useState<boolean>(false);

    const showResource = (rsc: string, multiple: boolean): string => {
        switch (rsc) {
            case 'datasets':
                return multiple ? 'datasets' : 'dataset';
            case 'groups':
                return multiple ? 'groups' : 'group';
            case 'users':
                return multiple ? 'users' : 'user';
            case 'privacy-policies':
                return multiple ? 'privacy policies' : 'privacy policy';
            case 'logs':
                return multiple ? 'logs' : 'log';
            case 'settings':
                return multiple ? 'settings' : 'setting';
            case 'data-connections':
                return multiple ? 'data connections' : 'data connection';
            default:
                return '';
        }
    }

    const canIDeleteIt = useCallback((): boolean => {
        let permission: Permission[] | undefined = undefined
        if (resource === 'datasets') permission = ['dataset.editAny', 'dataset.editOwn'];
        else if (resource === 'users') permission = ['user.editDeleteAny', 'user.editDeleteOwn'];
        else if (resource === 'groups') permission = ['group.editAny', 'group.editOwn'];
        else if (resource === 'privacy-policies') permission = ['privacyPolicy.editGlobal', 'privacyPolicy.editOwn'];
        else if (resource === 'data-connections') permission = ['connection.editAll'];
        return Boolean(permission && hasPermissions(permission))
    }, [resource, hasPermissions])

    useEffect(() => { setFilteredList(rows) }, [loading, rows])
    useEffect(() => {
        switch (resource) {
            case 'datasets':
                setOtherParams((params: any) => ({
                    ...params,
                    isRowSelectable: (rowParams: GridRowParams) => hasPermissions('dataset.editAny') || rowParams.row.owner_id === user?.email
                }))
                break;
            case 'data-connections':
                setOtherParams((params: any) => ({
                    ...params,
                    isRowSelectable: (rowParams: GridRowParams) => canIDeleteIt() || rowParams.row.owner_id === user?.email
                }))
                break;
            case 'privacy-policies':
                setOtherParams((params: any) => ({
                    ...params,
                    isRowSelectable: (rowParams: GridRowParams) => canIDeleteIt() && parseInt(rowParams.row.id) !== 1 // TODO: Find more secure way to detect "full remote access"
                }))
                break;
            case 'users':
                setOtherParams((params: any) => ({
                    ...params,
                    isRowSelectable: (rowParams: GridRowParams) => {
                        const canDelete = rowParams.row.roles.includes('admin') ? hasPermissions('adminUser.createEditDelete') : hasPermissions(['user.editDeleteAny', 'user.editDeleteOwn']);
                        return canDelete && canIDeleteIt()
                    }
                }))
                break;
            case 'health':
                setOtherParams((params: any) => ({
                    ...params,
                    isRowSelectable: (rowParams: GridRowParams) => false
                }))
                break;
        }
    }, [resource, hasPermissions, canIDeleteIt, user])

    const rowContainsSearch = (dataToSearch: string, searchParam: string) => {
        if(typeof dataToSearch !== 'string') return false
        return dataToSearch.toLowerCase().search(searchParam.toLowerCase()) !== -1
    }

    const doFiltering = useCallback(() => {
        if (!canSearch?.type) return null

        const filtering = (array: any) => {
            switch (canSearch.type) {
                case 'dataset':
                    return array.filter((row: any) => {
                        return (
                        rowContainsSearch(row.name, searching)
                        || rowContainsSearch(row.display_name, searching)
                        || rowContainsSearch(row.owner_id, searching)
                        || rowContainsSearch(row.human_description, searching)
                    )})
                case 'data-connections':
                    return array.filter((row: any) => {
                        const connector = getListConnectors().find((dc) => dc.name === row.connector_type.toLowerCase())
                        const label = (connector?.labelLong || connector?.label) as string
                        return (
                            rowContainsSearch(row.name, searching)
                            || rowContainsSearch(label, searching)
                            || rowContainsSearch(row.owner_id, searching)
                        )
                    })
                case 'privacypolicy':
                    return array.filter((row: any) => (
                        rowContainsSearch(row.name, searching)
                    ))
                case 'user':
                    return array.filter((row: any) => (
                        rowContainsSearch(row.username, searching)
                        || rowContainsSearch(row.email, searching)
                    ))
                case 'group':
                    return array.filter((row: any) => (
                        rowContainsSearch(row.name, searching)
                        || rowContainsSearch(row.description, searching)
                    ))
                case 'log':
                    return array.filter((row: any) => (
                        rowContainsSearch(row.username, searching)
                        || rowContainsSearch(row.path, searching)
                        || rowContainsSearch((row.status_code + ''), searching)
                    ))
                default:
                    return [...rows]
            }
        }
        const newFilteredRows = filtering(rows)
        setFilteredList(newFilteredRows)
    }, [canSearch, searching, rows])

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearching(event.target.value)
    }

    const handleMultipleDelete = (array: GridSelectionModel[]) => {
        const arrayDelete: GridRowsProp = rows.filter((row: any) => array.includes(row.id!));
        setSelectionToDelete(arrayDelete as Resource[])
        setDialogDelete(true)
    }

    useEffect(() => {
        doFiltering()
    }, [doFiltering, searching])

    const showNoRow = (
        <MainMessage>
            <Title variant="h3">
                No resource to display.<br />
                It looks like there is no {showResource(resource, false)} or you don't have sufficient permission to see the list
            </Title>
        </MainMessage>
    )
    
    const handleContentDialog = useMemo(() => {
        const keyword = (resource === 'datasets' || resource === 'users') ? 'remove' : 'delete'
        const multiple = selectionToDelete.length > 1
        const displayResource = `${multiple ? 'these' : 'this'} ${showResource(resource, multiple)}`
        return <>Are you sure you want to {keyword} {displayResource}?</>
    }, [selectionToDelete, resource]);

     const confirmDelete = (rsc: Partial<Resource>[]) => {
        const idToDelete: number[] = rsc.map(item => item.id!);
        doDelete && doDelete(idToDelete);
        setSelectionToDelete([]);
        setSelectionModel([]);
        setDialogDelete(false);
    }

    const cancelDelete = () => {
        setSelectionToDelete([]);
        setDialogDelete(false);
    }

    const CreateButton = () => {
        if (disableCreate) return null

        let perm: Permission[] = [];
        let tooltipMessage = '';
        if (resource === 'datasets') {
            perm = ['dataset.create'];
            tooltipMessage = `You don’t have sufficient permissions to add datasets`;
        } else if (resource === 'users') {
            perm = ['user.create'];
            tooltipMessage = `You don’t have sufficient permissions to add new users`;
        } else if (resource === 'groups') {
            perm = ['group.create'];
            tooltipMessage = `You don’t have sufficient permissions to create new groups`;
        } else if (resource === 'privacy-policies') {
            perm = ['privacyPolicy.createGlobal', 'privacyPolicy.createLocal'];
            tooltipMessage = `You don’t have sufficient permissions to create new privacy policies`;
        } else if (resource === 'data-connections') {
            perm = ['connection.create']
            tooltipMessage = `This feature is not available in demo version`;
            // tooltipMessage = `You don't have sufficient permissions to create new data connection`;
        }

        const CButton = () => (
             <SecondaryButton
                action={() => history.push(`${resource}/create`)}
                disabled={!hasPermissions(perm)}
                add
            >
                {labelCreate ?? 'Create'}
            </SecondaryButton>
        )

        if (hasPermissions(perm)) {
            return <CButton />
        } else {
            return (
                <Tooltip title={tooltipMessage} arrow classes={{ tooltip: classes.noMaxWidth }}>
                    <span><CButton /></span>
                </Tooltip>
            )
        }
    }

    return (
        <>
            <DialogBox
                title={(resource === 'datasets' || resource === 'users') ? 'Remove?' : 'Delete?'}
                content={handleContentDialog}
                open={dialogDelete}
                confirm={() => selectionToDelete && confirmDelete(selectionToDelete)}
                close={cancelDelete}
            />
            <Box className={classes.actions} mt={(!canSearch && disableCreate) ? 2 : 4} mb={(!canSearch && disableCreate) ? 2 : 4}>
                <Box>
                    {canSearch && (<Searchbox
                        value={searching}
                        onChange={handleSearch}
                        placeholder={canSearch?.label}
                        cleanSearch={() => setSearching('')}
                        disabled={typeof hasPerm === 'string'}
                        fullWidth
                    />)}
                </Box>
                <Box>
                    <Collapse in={selectionModel.length > 0}>
                        <DeleteButton
                            action={() => handleMultipleDelete(selectionModel)}
                        >
                            <span>
                                {labelDelete ?? 'Delete'} {selectionModel.length} {pluralizeResource(selectionModel.length, resource)}
                            </span>
                        </DeleteButton>
                    </Collapse>
                    <CreateButton />
                </Box>
            </Box>
            <ShowDatagrid
                rows={filteredList}
                cols={columns}
                noRowScreen={showNoRow}
                loading={loading}
                hasPerm={hasPerm}
                canDelete={Boolean(doDelete)}
                resource={resource}
                sortModel={baseSortModel}
                otherParams={otherParams}
                handleSelection={(s: any) => setSelectionModel(s)}
            />
        </>
    )
}

export default ListResource