import { Dispatch } from "redux";
import { SchemaCheckingState, SchemaState } from "store/reducers/SchemaReducer";
import { SchemaDispatchTypes, SCHEMA_CHANGE, SCHEMA_CHECK, SCHEMA_INITIALIZE, SCHEMA_UNLOAD } from "./SchemaActionsType";

export const InitSchema = () => async (dispatch: Dispatch<SchemaDispatchTypes>) => {
    dispatch({ type: SCHEMA_INITIALIZE });
}

export const SchemaUnload = () => async (dispatch: Dispatch<SchemaDispatchTypes>) => {
    dispatch({ type: SCHEMA_UNLOAD });
}

export const SchemaChange = (state: SchemaState, param?: string | any) => async (dispatch: Dispatch<SchemaDispatchTypes>) => {
    const payload: {state: SchemaState, error?: string, schema?: any} = {
        state: state,
        error: undefined,
        schema: undefined,
    };
    if (param) {
        if(typeof param === 'string') payload.error = param;
        else {
            payload.schema = param;
        }
    }
    dispatch({ type: SCHEMA_CHANGE, payload });
}

export const SchemaCheck = (state: SchemaCheckingState, error?: string) => async (dispatch: Dispatch<SchemaDispatchTypes>) => {
    const payload: { status: SchemaCheckingState, error?: string } = {
        status: state,
        error: error
    }
    dispatch({ type: SCHEMA_CHECK, payload });
}