import { FC } from "react";
import { FormHelperText, IconButton, MenuProps } from "@mui/material";
import config from 'config/global'
import { UploadedFile } from "styles/form";
import ClearIcon from '@mui/icons-material/Clear'

export const SelectMenuProps: Partial<MenuProps> = {
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "left"
    }
};

export const ShowCSVGuidelines = () => (
    <FormHelperText
        style={{ marginBottom: '16px', marginTop: '16px', lineHeight: '16px' }}
    >
        Tabular data must be encoded as UTF-8 with 500MB max file size,<br />
        and contain more than {config.dataset.minRow} rows.<br />
        Supported delimiters for CSV files are: ‘,’ or ‘;’
    </FormHelperText>
)

export const ShowFiles: FC<{ list: string[], remove: any }> = (props) => {
    const { list, remove } = props;
    return (
        <div>
            {
                list.map((table: string) => {
                    return (
                        <UploadedFile key={table} error={false} small>
                            <div></div>
                            <span>{table}</span>
                            <div className="delete">
                                <IconButton size="small" onClick={() => {
                                    remove(list.filter(((e: string) => e !== table)))
                                }} style={{position: 'absolute'}}>
                                    <ClearIcon fontSize="small" style={{display: 'flex', justifyContent: 'center'}}/>
                                </IconButton>
                            </div>
                        </UploadedFile>
                    )
                })
            }
        </div>
    )
}

export const getUploadFileLabel = (connector?: string): string => {
    if (connector === 'gcs') {
        return 'gcsutil URI of file or repo'
    } else if (connector === 's3') {
        return 'Object URL (file or repo)'
    }
    return 'URI of file or repo'
}