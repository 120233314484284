import { FC, ReactNode, useContext, useEffect } from 'react';
import { UserContext } from 'context/authContext';
import { Link, NavLink } from 'react-router-dom'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ViewListIcon from '@mui/icons-material/ViewList';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import StorageIcon from '@mui/icons-material/Storage';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import SettingsIcon from '@mui/icons-material/Settings';
import Fade from '@mui/material/Fade';
import Logo from 'logo_bird.svg';
import { Box, Theme, Typography } from '@mui/material';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import globalConfig from 'config/global';
import { createStyles, makeStyles } from '@mui/styles';
import useFontFaceObserver from 'use-font-face-observer';

type StyleProps = {
    isCollapsed: boolean,
    isFontLoaded: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => createStyles({
    sideNav: {
        background: theme.palette.primary.dark,
        gridArea: 'sidenav',
        width: ({isCollapsed}) => isCollapsed ? '70px' : '256px',
        position: 'fixed',
        height: '100%',
        overflow: 'hidden',
        '& ul': {
            margin: 0,
            padding: 0,
            listStyle: 'none',
            position: 'absolute',
            top: 230,
            width: ({ isCollapsed }: StyleProps) => isCollapsed ? '70px' : '256px',
            '& li': {
                '&.gutter': {
                    marginTop: theme.spacing(4)
                },
                '& a': {
                    color: theme.palette.primary.contrastText,
                    textDecoration: 'none',
                    minHeight: 48,
                    display: 'grid',
                    gridTemplateColumns: ({ isCollapsed }: StyleProps) => isCollapsed ? '1fr' : '50px 1fr',
                    alignItems: 'center',
                    position: 'relative',
                    paddingLeft: ({ isCollapsed }: StyleProps) => isCollapsed ? '0' : '20px',
                    justifyContent: ({ isCollapsed }: StyleProps) => isCollapsed ? 'center' : 'left',
                    textAlign: ({ isCollapsed }: StyleProps) => isCollapsed ? 'center' : 'left',
                    '&:hover': {
                        background: theme.palette.primary.main
                    },
                    '& svg': {
                        margin: ({ isCollapsed }: StyleProps) => isCollapsed ? '0 auto' : '0',
                    },
                    '&.active': {
                        background: theme.palette.primary.main,
                        '&:after': {
                            fontFamily: '"Material Icons"',
                            content: ({ isCollapsed, isFontLoaded }: StyleProps) => (isCollapsed || !isFontLoaded) ? '' : '"keyboard_arrow_right"',
                            fontSize: '1.2rem',
                            right: 10,
                            position: 'absolute'
                        }
                    }
                }
            }
        },
        '& .collapseIcon': {
            position: 'absolute',
            bottom: 20,
            transform: ({ isCollapsed }: StyleProps) => isCollapsed ? 'rotate(0)' : 'rotate(180deg)',
            cursor: 'pointer',
            left: ({ isCollapsed }: StyleProps) => isCollapsed ? '20px' : '210px',
            color: theme.palette.primary.main,
            '&:hover': {
                color: theme.palette.primary.contrastText
            }
        },
        '& div.brandLogo': {
            textAlign: 'center',
            padding: ({ isCollapsed }: StyleProps) => isCollapsed ? '16px 0' : '50px 0',
            color: theme.palette.primary.contrastText,
            fontSize: '1rem',
            transition: 'all 200ms ease-in',
            '& .logo': {
                width: ({ isCollapsed }: StyleProps) => isCollapsed ? '40px' : '100px',
            }
        }
    }
}))

interface LinkProps {
    icon: ReactNode,
    link: string,
    label: string,
    gutter?: boolean,
    collapsed: boolean,
}

const MenuLink: FC<LinkProps> = ({ icon, link, label, collapsed, gutter }) => {
    const isFontLoaded = useFontFaceObserver([
        {family: 'Material Icons'}
    ])

    return (
    <li className={gutter ? 'gutter' : ''}>
        <NavLink to={link}>
            <>
                {icon}
                <Typography variant={collapsed ? "caption" : "body1"}>{label}</Typography>
            </>
        </NavLink>
    </li>
    )
}

interface INavigationProps {
    collapse: boolean,
    setCollapse: () => void,
    isFixed: boolean,
}

const Navigation: FC<INavigationProps> = props => {
    const { collapse, setCollapse, isFixed } = props;
    const isCollapsed = () => isFixed ? true : collapse;
    const isFontLoaded = useFontFaceObserver([
        {family: 'Material Icons'}
    ])
    const classes = useStyles({isCollapsed: isCollapsed(), isFontLoaded});
    const { isLoggedIn, isLoading } = useContext(UserContext);

    if (isLoading || !isLoggedIn) {
        return null;
    }
    return (
        <Box className={classes.sideNav}>
            <div className="brandLogo">
                <Link to={globalConfig.landingPage}><img src={Logo} className="logo" alt="Sarus Logo" /></Link>
                <Fade in={!collapse}>
                    <div><strong>Sarus</strong> Technologies</div>
                </Fade>
            </div>
            <ul>
                <MenuLink icon={<ViewListIcon />} link="/datasets" label="Datasets" collapsed={isCollapsed()} />
                <MenuLink icon={<SyncAltIcon />} link="/data-connections" label="Data Connections" collapsed={isCollapsed()} />
                <MenuLink icon={<VerifiedUserIcon />} link="/privacy-policies" label={isCollapsed() ? 'P.Policies' : 'Privacy Policies'} collapsed={isCollapsed()} />
                <MenuLink icon={<PersonIcon />} link="/users" label="Users" collapsed={isCollapsed()} />
                <MenuLink icon={<GroupIcon />} link="/groups" label="Groups" collapsed={isCollapsed()} />
                <MenuLink icon={<FindInPageIcon />} link="/audit" label={isCollapsed() ? 'Audit' : 'Audit Trail'} collapsed={isCollapsed()} gutter />
                <MenuLink icon={<StorageIcon />} link="/health" label={isCollapsed() ? 'Health' : 'Service Health & Logs'} collapsed={isCollapsed()} />
                <MenuLink icon={<SettingsIcon />} link="/settings" label={isCollapsed() ? 'Settings' : 'Settings'} collapsed={isCollapsed()} />
            </ul>
            <div className="collapseIcon">
                <ArrowForwardIosIcon onClick={setCollapse} />
            </div>
        </Box>
    )
}

export default Navigation
