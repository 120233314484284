import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FC } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
    },
    title: {
        margin: 0,
        padding: 0,
    },
    body: {
        marginTop: 12,
        marginBottom: 0
    }
}));

type Props = {
    message?: string,
    cancel?: string,
    confirm?: string,
    cleanUp: any,
}

const CustomPrompt: FC<Props> = ({ message, cancel, confirm, cleanUp }) => {
    const classes = useStyles();
    const doCancel = () => cleanUp(false);
    const doValid = () => cleanUp(true);

    return (
        <Dialog open onClose={doCancel} className={classes.root}>
            <DialogContent>
                <DialogTitle id="alert-dialog-title" className={classes.title}>
                    Discard unsaved changes?
                </DialogTitle>
                {
                    message && (
                        <DialogContentText id="alert-dialog-text" className={classes.body}>
                            {message}
                        </DialogContentText>
                    )
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={doCancel}>{cancel || `Stay`}</Button>
                <Button onClick={doValid} color="secondary" autoFocus>{confirm || `Discard`}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default CustomPrompt;

//The dataset has not been added to Sarus Vault yet. Are you sure you want to leave?