import React, { FC, useState, useEffect, useCallback, ChangeEvent, SyntheticEvent, useContext, useRef } from 'react'
import { Prompt, useHistory, useLocation, useParams } from 'react-router-dom'
import apiHandler from 'api/apiHandler'
import { Grid, Theme, Tooltip, Typography } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import GoBackButton from 'components/Buttons/GoBackButton'
import SimpleCheckbox from 'components/SimpleCheckbox'
import { Title } from 'components/stylized/titles'
import TextInput, { InputProps } from 'components/TextInput'
import { ConnectorFieldType, ConnectorValueType, RecordFormat, showConnectorData } from 'pages/DataConnectionShow'
import { ConnectorWithLogo, getListConnectors } from './connectorsData'
import { ErrorInForm } from 'types/forms'
import { cleanError } from 'helpers/forms'
import PasswordInput from 'components/PasswordInput'
import ToggleInput from 'components/Toggle'
import SingleDrop from 'components/FileInput/SingleDrop'
import PrimaryButton from 'components/Buttons/PrimaryButton'
import { getText } from 'utils/dictionnary'
import CancelButton from 'components/Buttons/CancelButton'
import CadredButton from 'components/Buttons/CadredButton'
import usePreventReload from 'hooks/usePreventReload'
import { FieldValue, DCFields, RecordDC, DataConnection, DataToSend, TestCheckStatus } from 'types/data-connections'
import { UserContext } from 'context/authContext'

interface ConnectorFormProps {
    connector?: ConnectorWithLogo
    goBack?: () => void
    cb?: (data: any) => void
    dcCreated?: boolean
    submitForm?: boolean,
    set?: (d: boolean) => void
}

const useStyles = makeStyles(({spacing}: Theme) => createStyles({
    logo: {
        height: '1.2em',
        marginRight: spacing(2),
        display: 'flex',
        alignItems: 'center'
    },
    buttonsGroup: {
        display: 'flex',
        gap: 32
    }
}))

const initialRecord: RecordDC = {
    name: '',
    fields: {},
    is_public: true,
    allow_copy: true,
}

const baseDataToSendCreate: DataToSend = {
    name: '',
    connector_type: '',
    credentials: {},
    params: {},
    allow_copy: true,
    is_public: true
}

const baseDataToSendEdit: DataToSend = {
    name: '',
    credentials: {},
    params: {},
    allow_copy: true,
    is_public: true
}

const getBaseValue = (type: ConnectorValueType) => {
    switch (type) {
        case 'text':
        case 'secret':
        default:
            return ''
        case 'check':
        case 'toggle':
            return false
        case 'file':
            return ''
    }
}

const ConnectorForm: FC<ConnectorFormProps> = (props) => {
    const { id } = useParams<{ id: string }>()
    const {
        connector,
        goBack,
        cb: callBack,
        dcCreated: isDCJustCreated,
        submitForm,
        set
    } = props
    const {
        getDataConnectionFormat,
        getDataConnection,
        checkDataConnection,
        editDataConnection,
        createDataConnection,
    } = apiHandler
    const location = useLocation()
    const history = useHistory()
    const classes = useStyles()
    const { hasPermissions } = useContext(UserContext)
    const [isEditMode, setIsEditMode] = useState<boolean>(Boolean(location.pathname.split('/')[1] === 'data-connections' && id))
    const [handleInEdit, setHandleInEdit] = useState<boolean>(false)
    const [initialized, setInitialized] = useState<boolean>(false)
    const [DCFormat, setDCFormat] = useState<RecordFormat>()
    const [record, setRecord] = useState<RecordDC>(initialRecord)
    const [inTreatment, setInTreatment] = useState<boolean>(false)
    const [hasSave, setHasSave] = useState<any | undefined>(undefined)
    const [isDirty, setIsDirty] = useState<boolean>(false)
    const [errorsInFields, setErrorsInFields] = useState<ErrorInForm[]>([])
    const [checkStatus, setCheckStatus] = useState<TestCheckStatus>({ initiated: false, error: false })
    const prevHasSaveRef = useRef()
    const canEdit = hasPermissions('connection.editAll')

    useEffect(() => {
        const inEdit = Boolean(location.pathname.split('/')[1] === 'data-connections' && id);
        setIsEditMode(inEdit)
        setHandleInEdit(inEdit)
    }, [location, setIsEditMode])

    useEffect(() => {
        if (isDCJustCreated) {
            setErrorsInFields([{ name: 'info', message: getText('dc.created') }])
            window.scrollTo({
                top: document.documentElement.scrollHeight,
                behavior: 'smooth'
            })

            setTimeout(() => {
                const scrollToBottom = () => {
                    window.scrollTo({
                        top: document.body.scrollHeight,
                        behavior: 'smooth'
                    })
                }
                scrollToBottom()
            }, 300)
        }
    }, [isDCJustCreated])

    const getFormatFields = useCallback(async (connector: ConnectorWithLogo, data?: DataConnection) => {
        const dataConnectionFormat: RecordFormat = await getDataConnectionFormat(connector.nameFormat)
        let fields: DCFields = {}
        
        dataConnectionFormat.credentials.forEach(field => {
            const key = `credentials.${field.fieldName}`;
            const value = field.defaultValue ? field.defaultValue : getBaseValue(field.fieldType)
            fields[key] = value
        })

        dataConnectionFormat.params.forEach(field => {
            const key = `params.${field.fieldName}`
            let value = field.defaultValue ? field.defaultValue : getBaseValue(field.fieldType)
            if (data && data.params[field.fieldName as keyof typeof data.params] && (
                data.params[field.fieldName as keyof typeof data.params] !== undefined &&
                data.params[field.fieldName as keyof typeof data.params] !== getBaseValue(field.fieldType)
            )) {
                value = data.params[field.fieldName as keyof typeof data.params]!
            }
            fields[key] = value
        })

        return {
            fields,
            format: dataConnectionFormat
        }
    }, [getDataConnectionFormat])

    const loadDataConnection = useCallback(async (id: string) => {
        try {
            const dataConnection: DataConnection = await getDataConnection(parseInt(id))
            const dataConnector = await getListConnectors().find((connector) => connector.name === dataConnection.connector_type)!

            const fields = await getFormatFields(dataConnector, dataConnection)

            const DCToRecord: RecordDC = {
                name: dataConnection.name,
                connector_type: dataConnection.connector_type,
                fields: fields.fields,
                allow_copy: dataConnection.allow_copy,
                is_public: dataConnection.is_public
            }

            setDCFormat(fields.format)
            setRecord(DCToRecord)
        } catch (error) {
            console.group('Error loading data')
            console.log(error)
            console.groupEnd()
        } finally {
            setInitialized(true)
        }
    }, [getDataConnection, getFormatFields])

    const initiateNewDataConnection = useCallback(async () => {
        try {
            if(!connector) throw new Error ('Missing connector')
            const fields = await getFormatFields(connector)
            const DCToRecord: RecordDC = {
                name: '',
                fields: fields.fields,
                allow_copy: initialRecord.allow_copy,
                is_public: initialRecord.is_public
            }

            setDCFormat(fields.format)
            setRecord(DCToRecord)
        } catch (error: any) {
            console.group('Error loading data')
            console.log(error.message)
            console.groupEnd()
        } finally {
            setInitialized(true)
        }
    }, [connector, getFormatFields])

    useEffect(() => {
        if (isEditMode) {
            loadDataConnection(id)
        } else {
            initiateNewDataConnection()
        }
    }, [isEditMode, id, initiateNewDataConnection, loadDataConnection])

    const getFullFieldName = useCallback((name?: string) => {
        if(!name) return false
        let fieldName = name;
        if (DCFormat?.credentials.find((key) => key.fieldName === name)) {
            fieldName = 'credentials.' + name;
        } else if (DCFormat?.params.find((key) => key.fieldName === name)) {
            fieldName = 'params.' + name;
        }
        return fieldName
    }, [DCFormat])

    const initErrorsAndStatus = () => {
        cleanError('info', errorsInFields, setErrorsInFields)
        cleanError('any', errorsInFields, setErrorsInFields)
        setCheckStatus({ initiated: false, error: false })
    }

    const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        initErrorsAndStatus()

        let other: DCFields = {}
        const splittedName = event.target.name.split('.')
        if (splittedName.length > 1) {
            const dependencies: { name: string, field: ConnectorFieldType }[] = []
            DCFormat?.credentials.forEach(field => {
                if (field.dependency?.field === splittedName[1]) {
                    dependencies.push({name: `credentials.${field.fieldName}`, field})
                }
            })
            DCFormat?.params.forEach(field => {
                if (field.dependency?.field === splittedName[1]) {
                    dependencies.push({name: `params.${field.fieldName}`, field})
                }
            })

            if (dependencies.length) {
                dependencies.forEach(field => {
                    const resettedValue = field.field.defaultValue ? field.field.defaultValue : (field.field.fieldType === "check" || field.field.fieldType === "toggle") ? false : field.field.fieldType === 'file' ? '' : ''
                    other[field.name] = resettedValue
                })
            }
        }

        if (event.target.name === 'name') {
            setRecord(record => ({ ...record, name: event.target.value }))
        } else if (event.target.name === 'allow_copy' || event.target.name === 'is_public') {
            setRecord(record => ({...record, [event.target.name]: !record[event.target.name as keyof typeof record]}))
        } else if (event.target.type === 'checkbox') {
            let fields = { ...record.fields }
            const filteredField = fields[event.target.name]
            if (filteredField !== undefined) {
                const newVal = !filteredField
                fields[event.target.name] = newVal
                setRecord(record => {
                    fields = {...fields, ...other}    
                    return {...record, fields}
                })
            }
        } else {
            let fields = { ...record.fields }
            fields[event.target.name] = event.target.value
            setRecord(record => ({...record, fields}))
        }

        setIsDirty(true)
        setHasSave(undefined)
    }, [DCFormat, errorsInFields, record])

    const handleUpload = useCallback((name: string, file: any) => {
        const fields = { ...record.fields }
        if(!(name in fields)) return
        cleanError(name, errorsInFields, setErrorsInFields)
        initErrorsAndStatus()

        if (!file || file === '') {
            fields[name] = ''
            setRecord(record => ({...record, fields}))
        } else {
            const reader = new FileReader()
            reader.onload = async (event: any) => {
                const text = event.target.result
                fields[name] = text
                setRecord(record => ({...record, fields}))
            }
            reader.readAsText(file)
        }
    }, [record.fields, errorsInFields])

    const handleSave = useCallback((e?: SyntheticEvent) => {
        e && e.preventDefault();

        if (isEditMode && isDCJustCreated) {
            setHandleInEdit(true)
        }

        setInTreatment(true)
        let dataToSend = isEditMode ? baseDataToSendEdit : baseDataToSendCreate
        const errors: ErrorInForm[] = []

        dataToSend.name = record.name
        dataToSend.is_public = record.is_public
        dataToSend.allow_copy = record.allow_copy

        if (!isEditMode) {
            dataToSend.connector_type = connector?.name
            if (record.name === '') {
                errors.push({name: 'name', message: getText('dc.missingField')})
            }
        }

        type CheckField = {name: string, value: FieldValue}
        const checkField = (field: CheckField) => {
            const name = field.name.split('.')
            if (name[0] === undefined) {
                return
            }
            const format = name[0] === 'credentials' ? DCFormat?.credentials.filter(fieldFormat => fieldFormat.fieldName === name[1])[0] : DCFormat?.params.filter(fieldFormat => fieldFormat.fieldName === name[1])[0]

            const isDependencyIsOk = () => {
                const data = Object.keys(record.fields).find(key => key === getFullFieldName(format?.dependency?.field))
                return (record.fields[data as keyof typeof record.fields] !== format?.dependency?.condition)
            }
            
            if (!format?.optional) {
                if (isDependencyIsOk()) {
                    // ??
                } else if (record.fields[field.name] === '' || record.fields[field.name] === undefined) {
                    errors.push({name: field.name, message: getText('dc.missingField')})
                }
            }
            if (name[0]) {
                dataToSend[name[0] as 'credentials' | 'params'][name[1]] = field.value
            }
        }

        Object.entries(record.fields).forEach(([key, value]) => {
            checkField({name: key, value: value})
        })

        setErrorsInFields(errors)
        const handleError = (response: any) => {
            setIsDirty(true)
            setInTreatment(false)
            if (response.status >= 400 && response.status < 500) {
                setErrorsInFields([{name: 'any', message: response.data.message}])
            } else {
                setErrorsInFields([{ name: 'any', message: getText('api.dc.error500') }]);
            }
        }

        if (errors.length) {
            setInTreatment(false)
            if(callBack) callBack('error')
        } else {
            if (isEditMode) {
                editDataConnection(parseInt(id), JSON.stringify(dataToSend)).then(() => {
                    setCheckStatus({initiated: false, error: false, status: true, message: getText('dc.edited')})
                    setIsDirty(false)
                    setInTreatment(false)
                }).catch(error => {
                    handleError(error.response)
                })
            } else {
                createDataConnection(JSON.stringify(dataToSend)).then(result => {
                    setIsDirty(false)
                    setInTreatment(false)
                    return result
                }).then(result => {
                    setHasSave(result)
                }).catch(error => {
                    if (callBack) {
                        callBack('error')   
                    }
                    handleError(error.response)
                })
            }
        }
    }, [DCFormat, connector, createDataConnection, editDataConnection, getFullFieldName, id, isEditMode, record])

    useEffect(() => {
        if (hasSave && !prevHasSaveRef.current) {
            if (callBack) {
                callBack(hasSave)
            } else {
                history.push(`/data-connections/${hasSave.id}`)
            }
        }
        prevHasSaveRef.current = hasSave
    }, [hasSave, callBack, history])

    const showFields = useCallback(() => {
        const showField = (rawName: string) => {
            const n = rawName.split('.');
            const formatList: any = DCFormat![n[0] as keyof typeof DCFormat];
            const format = formatList.find((k: ConnectorFieldType) => k.fieldName === n[1]);
            
            if (format.dependency) {
                let varName = format.dependency.field;
                if (DCFormat?.credentials.find((k: ConnectorFieldType) => k.fieldName === format.dependency.field)) {
                    varName = 'credentials.' + varName;
                } else if (DCFormat?.params.find((k: ConnectorFieldType) => k.fieldName === format.dependency.field)) { 
                    varName = 'params.' + varName;
                }
                if (record.fields[varName] !== format.dependency.condition) {
                    return;
                }
            }

            const props: InputProps = {
                name: rawName,
                label: `${format.fieldLabel} ${format.optional ? '(optional)' : ''}`,
                error: errorsInFields.find((e) => e.name === rawName)?.message,
                helper: format.helperText,
                disabled: inTreatment || format.disabled || !canEdit,
                onChange: handleChange,
            }
            
            switch (format.fieldType) {
                case 'text':
                default:
                    props.value = record.fields[rawName] as string;
                    props.shrink = true;
                    props.placeholder = format.defaultValue
                    return <TextInput {...props} />;
                case 'secret':
                    props.value = record.fields[rawName] as string;
                    props.shrink = true;
                    return <PasswordInput {...props} />;
                case 'check':
                    const isChecked = record.fields[rawName] as boolean
                    return <SimpleCheckbox isChecked={isChecked} {...props} />
                case 'toggle':
                    const isToggled = record.fields[rawName] as boolean
                    return <ToggleInput status={isToggled} {...props} />
                case 'file':
                    props.cbFile = handleUpload
                    return <SingleDrop {...props} />
            }
        }

        const result = Object.keys(record.fields).map((key) => {
            const fieldToRender = showField(key)
            if(!fieldToRender) return null
            else {
                return (
                    <Grid item xs={12} key={key}>
                        {fieldToRender}
                    </Grid>
                )
            }
        })

        const nameField = (
            <Grid item xs={12} key={`name-field`}>
                <TextInput
                    name="name"
                    label="Name"
                    error={errorsInFields.find((e) => e.name === 'name')?.message}
                    onChange={handleChange}
                    value={record.name}
                    disabled={inTreatment || !canEdit}
                    shrink
                />
            </Grid>
        )

        const connectorField = record.connector_type && (
            <Grid item xs={12} key={`connector-field`}>
                <TextInput
                    label="Connection type"
                    name="connector"
                    onChange={() => {}}
                    startAdornment={<img src={showConnectorData(record.connector_type, 'logo')} style={{ height: 17 }} alt={showConnectorData(record.connector_type, 'name')} />}
                    value={showConnectorData(record.connector_type, 'name') || ''}
                    disabled
                />
            </Grid>
        )

        if (!isEditMode) {
            return [nameField, ...result]
        } else {
            return [connectorField, ...result];
        }
    }, [errorsInFields, DCFormat, handleChange, inTreatment, handleUpload, record.fields])

    const SubmitButton = () => {
        if (canEdit) {
            return (
                <PrimaryButton action={handleSave} disabled={!canEdit || isEditMode ? !isDirty : false} isLoading={inTreatment}>
                    {isEditMode ? 'Save Changes' : 'Create Connection'}
                </PrimaryButton>
            )
        } else {
            return (
                <Tooltip arrow title={`You don't have sufficient permissions to edit data connections`}>
                    <span>
                        <PrimaryButton action={handleSave} disabled={!canEdit || isEditMode ? !isDirty : false} isLoading={inTreatment}>
                            {isEditMode ? 'Save Changes' : 'Create Connection'}
                        </PrimaryButton>
                    </span>
                </Tooltip>
            )
        }
    }

    const ActionBar = () => {

        const doTestConnection = () => {
            setCheckStatus({ initiated: true, error: false })
            setHasSave(undefined)
            cleanError('any', errorsInFields, setErrorsInFields)
            checkDataConnection(parseInt(id)).then(result => {
                if (result.status < 400) {
                    setCheckStatus({ initiated: false, error: false, status: true, message: getText('dc.testSuccess') })
                } else {
                    const errorMessage = result.status === 500 ? getText('api.dc.error500') : result.data
                    setCheckStatus({ initiated: false, error: true, status: true, message: errorMessage })
                }
            }).catch(error => {
                const errorMessage = error.response.status === 500 ? getText('api.dc.error500') : error.response.data
                setCheckStatus({ initiated: false, error: true, status: true, message: errorMessage })
            })
        }

        const ShowFeedBacks = () => {
            if (inTreatment) {
                return (
                    <Typography variant="body1">
                        {getText(handleInEdit ? 'dc.edited' : 'dc.creating')}
                    </Typography>
                )
            } else  if (checkStatus.initiated) {
                return (
                    <Typography variant="body1">
                        {getText('dc.testing')}
                    </Typography>
                )
            } else if (checkStatus.status) {
                return (
                    <Typography variant="body1" color={checkStatus.error ? 'error' : 'inherit'}>
                        {checkStatus.message}
                    </Typography>
                )
            } else if (errorsInFields.find(e => e.name === 'info')) {
                return (
                    <Typography variant="body1">
                        {errorsInFields.find(e => e.name === 'info')?.message}
                    </Typography>
                )
            } else {
                return (
                    <Typography variant="body1" color="error">
                        {errorsInFields.find(e => e.name === 'any')?.message}
                    </Typography>
                )
            }
        }

        if (callBack) {
            return (
                <Grid item xs={12}>
                    <ShowFeedBacks />
                </Grid>
            )
        } else if (isEditMode) {
            return (
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} className={classes.buttonsGroup}>
                            <CadredButton action={doTestConnection} isLoading={checkStatus.initiated} disabled={inTreatment || isDirty}>
                                Test
                            </CadredButton>
                            <SubmitButton />
                        </Grid>
                        <Grid item xs={12}>
                            <ShowFeedBacks />
                        </Grid>
                    </Grid>
                </Grid>
            )
        } else {
            return (
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} className={classes.buttonsGroup}>
                            <SubmitButton />
                            <CancelButton action={() => history.push('/data-connections')}>
                                Cancel
                            </CancelButton>
                        </Grid>
                        <Grid item xs={12}>
                            <ShowFeedBacks />
                        </Grid>
                    </Grid>
                </Grid>
            )
        }
    }

    useEffect(() => { 
        if (submitForm) {
            handleSave()
            // set(false)
        }
    }, [submitForm, set])

    const TitleCreate = () => {
        if(connector) {
            return (
                <Grid item xs={12}>
                    <Title variant="h3" comp={!!callBack ? 'div' : 'h2'} className={classes.logo}>
                        <GoBackButton action={goBack} tooltip="Back to connector types" />
                        <img src={connector.logo} className={classes.logo} alt={connector.label.toString()} />New {connector.labelLong || connector.label} Connection
                    </Title>
                </Grid>
            )
        } else {
            return null
        }
    }

    usePreventReload(isDirty)

    if(!initialized) return null
    return (
        <form action="/" method="POST" onSubmit={handleSave} id="create-dc">
            <Prompt when={isDirty} message={JSON.stringify(isEditMode ? {confirm: `Discard`, cancel: `Keep editing`} : {message: getText('dc.notCreated')})} />
            <Grid container spacing={4}>
                {!isEditMode && <TitleCreate />}
                <Grid item xs={12} style={isEditMode ? {marginTop: 16} : {}}>
                    <Grid container spacing={2}>
                        {showFields()}
                        <Grid item xs={12}>
                            <SimpleCheckbox
                                name="allow_copy"
                                label="Allow copy onto Sarus instance (Recommended)"
                                helperText="Some processing jobs may be considerably slower or not be possible when the instance-level copy is not available"
                                onChange={handleChange}
                                isChecked={record.allow_copy}
                                disabled={inTreatment || !canEdit}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SimpleCheckbox
                                name="is_public"
                                label="Make data connection public"
                                helperText="All Sarus users with dataset.create permission will be able to use this connection as a data source (non public connections will only be available to you)"
                                onChange={handleChange}
                                isChecked={record.is_public}
                                disabled={inTreatment || !canEdit}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <ActionBar />
            </Grid>
        </form>
    )
}

export default ConnectorForm