import React, { useCallback, useState, useEffect, useContext } from 'react'
import { Body } from 'styles/layout'
import ConnectorSelection from 'assets/data-connections/ConnectorSelection'
import { useHistory } from 'react-router-dom'
import { ConnectorWithLogo } from 'assets/data-connections/connectorsData'
import { UserContext } from 'context/authContext'
import TitlePage from 'components/layout/TitlePage'
import ConnectorForm from 'assets/data-connections/ConnectorForm'

export type CreationStep = 'select-connector' | 'data-informations';

const DataConnectionCreate = () => {
    const [creationStep, setCreationStep] = useState<CreationStep>('select-connector');
    const [connectorSelected, setConnectorSelected] = useState<ConnectorWithLogo>();
    const { hasPermissions } = useContext(UserContext);
    const history = useHistory();

    useEffect(() => {
        if(!hasPermissions('connection.create')) history.push('/data-connections')
    }, [hasPermissions, history])

    const handleConnectorSelection = (connector: ConnectorWithLogo) => {
        setConnectorSelected(connector)
        setCreationStep('data-informations')
    }

    const ShowForm = useCallback(() => {
        switch (creationStep) {
            case 'data-informations':
                return connectorSelected ? <ConnectorForm connector={connectorSelected} goBack={() => {
                    setCreationStep('select-connector')
                }} /> : <>There is an error</>
            case 'select-connector':
            default:
                return <ConnectorSelection onSelect={handleConnectorSelection} />
        }
    }, [creationStep, connectorSelected])

    return (
        <Body>
            <TitlePage resource="data-connections" option="create" />
            <ShowForm />
        </Body>
    )
}

export default DataConnectionCreate
