import React from 'react'
import PrivacyPolicyForm from 'components/forms/PrivacyPolicyForm'
import { Body } from 'styles/layout'
import { Grid } from '@mui/material'
import { Title } from 'components/stylized/titles'
import TopCloseButton from 'components/TopCloseButton'
import { useHistory } from 'react-router-dom'

const PrivacyPoliciesCreate = () => {
    const history = useHistory()

    return (
        <Body>
            <Grid container>
                <Grid item xs={11}>
                    <Title variant="h2" comp="h1">Create new Privacy Policy</Title>
                </Grid>
                <Grid item xs={1} style={{ textAlign: 'right', display: 'flex', alignItems: 'center', marginTop: 8, marginBottom: 21, justifyContent: 'flex-end' }}>
                    <div style={{marginRight: -5}}>
                        <TopCloseButton action={() => history.push('/privacy-policies')} />
                    </div>
                </Grid>
            </Grid>
            <PrivacyPolicyForm />

        </Body>
    )
}

export default PrivacyPoliciesCreate
