import { useContext, useEffect, useState } from 'react'
import { Body } from 'styles/layout';
import { Title } from 'components/stylized/titles';
import apiHandler from 'api/apiHandler';
import { GridColDef, GridRenderCellParams, GridSortDirection } from '@mui/x-data-grid';
import { DescList } from 'components/stylized/listItems';
import ListResource from 'assets/datagrids/ListResource';
import { CircularProgress, Grid, Typography, Box } from '@mui/material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { UserContext } from 'context/authContext';
import { getText } from 'utils/dictionnary';
import { formatNumber } from 'utils/misc';

type ServiceStatus = boolean | "pending"

interface ServiceHealth {
    id: number,
    name: string,
    status: ServiceStatus,
}

const initServices: ServiceHealth[] = [
    {
        id: 0,
        name: "dataowner",
        status: "pending"
    }, {
        id: 1,
        name: "dataowner_db",
        status: "pending"
    }, {
        id: 2,
        name: "dataowner_workers",
        status: "pending"
    }, {
        id: 3,
        name: "diffpriv_db",
        status: "pending"
    }, {
        id: 4,
        name: "private_learning_lab",
        status: "pending"
    }, {
        id: 5,
        name: "private_learning_lab_workers",
        status: "pending"
    }] 


const HealthList = () => {
    const [services, setServices] = useState<ServiceHealth[]>(initServices);
    const [errorInLoading, setErrorInLoading] = useState<boolean>(false)
    const [downloadClicked, setDownloadClicked] = useState<boolean>(false)
    const { hasPermissions } = useContext(UserContext)

    useEffect(() => {
        let isSubscribed = true;

        apiHandler.getServicesHealth()
            .then(result => {
                if (isSubscribed) {
                    const data: ServiceHealth[] = []
                    Object.entries(result).forEach(([label, value], index: number) => {
                        data.push({ id: index, name: label, status: Boolean(value) })
                    })
                    setServices(data)
                }
            }).catch(error => {
                setErrorInLoading(true)
            });

        return () => {
            isSubscribed = false;
        }
    }, [])

    const getStatus = (status: ServiceStatus) => {
        switch (status) {
            case "pending":
            default:
                return <CircularProgress size={18} color="inherit" style={{marginRight: 2}} />
            case true:
                return <CheckCircleIcon color="primary" />
            case false:
                return <ErrorOutlineIcon sx={{color: "error.main"}} />
        }
    }

    const columns: GridColDef[] = [
        { field: 'id', hide: true },
        { field: 'name', headerName: 'Service', flex: 0.7 },
        { field: 'status', headerName: 'Status', renderCell: (params: GridRenderCellParams) => getStatus(params.value), flex: 0.3 },
    ]

    const sortModel = [{
        field: 'time',
        sort: 'desc' as GridSortDirection,
    }];

    return (
        <Body>
            <Title variant="h2" comp="h1">{getText('title.serviceHealth')}</Title>
            <DescList>
                <Grid container>
                    <Grid item xs={8}>
                        <Typography variant="body2" color="textSecondary">
                            This section provides ongoing visibility into the availability and health of your Sarus services.<br />
                            {hasPermissions(['adminUser.createEditDelete']) && `Last service logs can also be downloaded for debugging.`}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} textAlign="right">
                        {
                            hasPermissions(['adminUser.createEditDelete']) && (
                                <Box>
                                    <PrimaryButton
                                        action={(e: any) => {
                                            e.preventDefault()
                                            setDownloadClicked(true)
                                            const url = `/v1/logs?rows_number=10000`
                                            const filename = `logs.txt`
                                            const fakelink = document.createElement('a')
                                            fakelink.style.display = 'none'
                                            document.body.appendChild(fakelink)
                                            fakelink.setAttribute('href', url)
                                            fakelink.setAttribute('download', filename)
                                            fakelink.click()
                                        }}
                                        disabled={downloadClicked}
                                    >
                                        Download logs
                                    </PrimaryButton>
                                    <Typography variant="body2" color="textSecondary" sx={{mt: 1}}>
                                        {
                                            downloadClicked ?
                                                `Download will start in a few seconds...` : `Up to ${formatNumber(10000)} lines`
                                        }
                                    </Typography>
                                </Box>        
                            )
                        }
                    </Grid>
                </Grid>
            </DescList>
            <ListResource
                columns={columns}
                rows={services}
                loading={false}
                disableCreate
                resource="health"
                sortModel={sortModel}
                errorOnLoading={errorInLoading}
                hidePagination
            />
        </Body>
    )
}

export default HealthList
