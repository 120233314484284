import React, { FC } from 'react'
import IconButton from '@mui/material/IconButton'
import Clear from '@mui/icons-material/Clear'

interface ButtonProps {
    action: () => void,
    dialog?: boolean,
}

const TopCloseButton: FC<ButtonProps> = ({ action, dialog = false }) => {
    
    const iconDialog = { zIndex: 0, position: 'absolute', right: 8, top: 8 };
    const iconNoDialog = { zIndex: 0 };

    return (
        <IconButton onClick={action} style={dialog ? iconDialog : iconNoDialog} size="large">
            <Clear fontSize="small" />
        </IconButton>
    );
}

export default TopCloseButton
