import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
import { DisplayStatus } from 'components/stylized/chips';
import { Title } from 'components/stylized/titles';
import { useSelector } from 'react-redux';
import { AppState } from 'store/configureStore';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        gap: 32
    }
});

const TitleDataset = React.memo(() => {
    const classes = useStyles();
    const dataset = useSelector((state: AppState) => state.dataset);

    return (
        <div className={classes.root}>
            <Title variant="h2" comp="h1">{dataset.dataset?.display_name}</Title>
            <DisplayStatus status={dataset.dataset?.status} />
        </div>
    )
})

export default TitleDataset;
