import ConsoleBox from "components/ConsoleBox"
import { FC } from "react"
import { useSelector } from "react-redux"
import { AppState } from "store/configureStore"

interface DisplayRelationshipsProps {
    name: string,
    disable: boolean,
    error: string,
    dlFileName: string,
    idleCallBack: (act: any) => void,
    onFocus: () => void,
    passRef: any,
}

const DisplayRelationships: FC<DisplayRelationshipsProps> = (props) => {
    const {name: consoleName, disable, error, dlFileName, idleCallBack, onFocus, passRef} = props
    const {datasetStatus, relationships} = useSelector((state: AppState) => ({
        datasetStatus: state.dataset.dataset?.status,
        relationships: state.dataset.relationships
    }))

    return (<div>
        <ConsoleBox 
            name={consoleName}
            disable={disable}
            error={error}
            baseValue={datasetStatus === "empty" ? relationships : relationships || "None"}
            dlFilename={dlFileName}
            small
            withIdleCheck={1000}
            idleCallBack={idleCallBack}
            onFocus={onFocus}
            passRef={passRef}
        />
    </div>)
}

export default DisplayRelationships