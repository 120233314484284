import { useContext } from 'react';
import { UserContext } from 'context/authContext';

import Routes from 'components/Routes';
import AppTemplate from 'assets/templates/AppTemplate';

const App = () => {
    const { isLoggedIn } = useContext(UserContext);

    if (isLoggedIn) {
        return (
            <AppTemplate>
                <Routes />
            </AppTemplate>
        )
    } else {
        return (
            <Routes />
        )
    }
}

export default App;
