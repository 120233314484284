import { Box, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { DataGrid, GridColDef, GridRowParams, GridSelectionModel, GridSortDirection, GridSortModel, MuiEvent } from '@mui/x-data-grid'
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { MainMessage } from 'components/stylized/errorMessages';
import { Title } from 'components/stylized/titles';
import React, { FC, Fragment, memo, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { datagridStyles } from 'styles/datagrid';

type sortModel = {
    field: string,
    sort: GridSortDirection,
}

interface Props {
    loading: boolean,
    hasPerm: string | boolean | undefined,
    noRowScreen: JSX.Element,
    rows: any,
    cols: GridColDef[],
    hidePagination?: boolean,
    sortModel?: sortModel[],
    canDelete: boolean,
    resource: string,
    otherParams: any,
    errorOnLoad?: boolean,
    handleSelection: any,
}

const useStyles = makeStyles(({ palette, spacing }: Theme) => createStyles({
    errorBox: {
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: 32,
        padding: 32,
        color: palette.grey[400],
    }
}))

const ShowDatagrid: FC<Props> = (props) => {
    const { 
        loading,
        hasPerm,
        noRowScreen,
        rows,
        cols,
        hidePagination,
        canDelete,
        sortModel: baseSortModel,
        resource,
        otherParams,
        errorOnLoad,
        handleSelection,
    } = props;

    const [itemsPerPage, setItemsPerPage] = useState<number>(resource === "settings"? 25 : 10)
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel[]>([])
    const [sortModel, setSortModel] = useState<GridSortModel | undefined>(baseSortModel)
    
    const history = useHistory()
    const isRowClickable = !(resource === 'logs' || resource === 'health' || resource === 'settings')
    const importedClasses = datagridStyles({ isClickable: isRowClickable })
    const classes = useStyles()
    
    useEffect(() => handleSelection(selectionModel), [selectionModel])
    
    const handleRowClick = (param: GridRowParams, event: MuiEvent<{ target: any }>) => {
        if(resource === "settings") return undefined
        let url = `/${resource}/${param.row.id}`
        if (param.row.display_name && param.row.status === 'empty') {
            url += '/schema'
        }
        if(!isRowClickable) return
        if (event.target.getAttribute('type') !== 'checkbox')
            history.push(url)
    }

    const DisplayErrorOnLoad = () => {
        if (!errorOnLoad) return null
        return (
            <Box className={classes.errorBox}>
                <Title variant="h3">Error while loading the {resource} list</Title>
                <div>
                    <PrimaryButton action={() => history.go(0)}>Refresh</PrimaryButton>
                </div>
            </Box>
        )
    }

    if (loading) {
        return (
            <Fragment>
                <input type="hidden" name="loading" value="true" />
            </Fragment>
        )
    }
    if (rows.length === 0 && !loading) {
        return noRowScreen
    } else if (typeof hasPerm === 'string') {
        return (
            <MainMessage>
                <Title variant="h3">
                    No resource to display.<br />{hasPerm}
                </Title>
            </MainMessage>
        )  
    } else {
        return (
            <DataGrid
                rows={rows}
                columns={cols}
                disableColumnMenu
                pageSize={itemsPerPage}
                onPageSizeChange={(newPageSize) => setItemsPerPage(newPageSize)}
                rowsPerPageOptions={[5, 10, 25]}
                pagination
                hideFooter={hidePagination}
                autoHeight
                disableSelectionOnClick
                hideFooterSelectedRowCount
                checkboxSelection={Boolean(canDelete)}
                onRowClick={handleRowClick}
                loading={loading}
                onSelectionModelChange={(newSelection: GridSelectionModel[]) => setSelectionModel(newSelection)}
                selectionModel={selectionModel}
                sortModel={sortModel}
                onSortModelChange={(newSortedModel) => setSortModel(newSortedModel)}
                components={{
                    ErrorOverlay: DisplayErrorOnLoad
                }}
                className={importedClasses.datagrid}
                density="compact"
                {...otherParams}
            />
        )
    }
}

export default memo(ShowDatagrid)