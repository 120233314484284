import { createTheme } from '@mui/material/styles';
import { ThemeOptions } from '@mui/material';

export const rawTheme: ThemeOptions = {
    palette: {
        primary: {
            light: '#428E92',
            main: '#006064',
            dark: '#00363A',
            contrastText: '#ffffff',
        },
        secondary: {
            light: '#FF616F',
            main: '#FF1744',
            dark: '#C4001D',
            contrastText: '#ffffff',
        },
        error: {
            main: '#C4001D',
            dark: '#4E000C',
            light: '#F9E5E8',
            contrastText: '#ffffff',
        },
        warning: {
            light: '#FFF5E5',
            dark: '#663D00',
            main: '#ff9800',
        },
        text: {
            secondary: 'rgba(0, 0, 0, 0.54)',
            disabled: 'rgba(0, 0, 0, 0.38)',
        },
        grey: {
            100: '#f5f5f5',
            200: '#eeeeee',
            400: '#bdbdbd',
        },
        background: {
            default: '#ffffff',
            paper: '#ffffff',
        },
    },
    typography: {
        htmlFontSize: 16,
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
        h1: {
            fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
            fontWeight: 300,
            fontSize: '4rem',
            lineHeight: 1.167,
            letterSpacing: '-0.01562em',
        },
        h2: {
            fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
            fontWeight: 300,
            fontSize: '3rem',
            lineHeight: 1.2,
            letterSpacing: '-0.00833em',
        },
        h3: {
            fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
            fontWeight: 400,
            fontSize: '1.5rem',
            lineHeight: 1.167,
            letterSpacing: '0em',
        },
        h4: {
            fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
            fontWeight: 400,
            fontSize: '1.125rem',
            lineHeight: 1.334,
            letterSpacing: '-0.00735em',
        },
        body1: {
            fontSize: '0.875rem',
        },
        body2: {
            fontSize: '0.875rem',
        }
    },
    shape: {
        borderRadius: 0,
    },
    components: {
        MuiLink: {
            defaultProps: {
                underline: 'hover'
            }
        },
        MuiTextField: {
            defaultProps: {
                variant: 'standard'
            }
        },
    }
}

const theme = createTheme(rawTheme);

export default theme;