import styled from 'styled-components';
import theme from 'styles/muiTheme';

interface IDropzoneProps {
    error: boolean
    small?: boolean
}

export const DropzoneBox = styled.div<IDropzoneProps>`
    section {
        display: flex;
        height: 120px;
        width: ${({small}) => small ? '280px' : '351px'};
        border: ${({ error }) => error ? `1px solid ${theme.palette.error.main}` : `1px dashed ${theme.palette.grey[400]}`};
        align-items: center;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;

        svg {
            color: ${theme.palette.grey[400]};
            font-size: 3em;
        }
    }
`
interface IUploadedFileProps {
    error: boolean,
    small?: boolean
}

export const UploadedFile = styled.div<IUploadedFileProps>`
    background: ${({ error }) => error ? theme.palette.error.light : theme.palette.grey[200]};
    display: grid;
    grid-template-columns: ${({ error }) => error ? '40px 1fr 40px' : '10px 1fr 40px'};
    align-items: center;
    margin-top: 8px;
    width: ${({ small }) => small ? `280px` : `351px`};

    .before {
        display: flex;
        align-items: center;
        justify-content: center;
        &.error {
            color: ${theme.palette.error.main};
        }
    }

    span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        width: 100%;
        padding: 8px;
        ${({ error }) => error && `color: ${theme.palette.error.dark};`}
    }

    .delete {
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        /* cursor: pointer;
        &:hover {
            background: ${({ error }) => error ? theme.palette.error.main : theme.palette.grey[400]};;
        } */
    }
`