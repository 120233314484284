import { FormControlLabel, Switch } from '@mui/material';
import React, { ChangeEvent, FC } from 'react'

interface InputProps {
    name: string,
    label: string | JSX.Element,
    status: boolean,
    onChange: (event: ChangeEvent<HTMLInputElement>) => void,
    error?: string,
    disabled?: boolean,
    helperText?: string | JSX.Element
}

const ToggleInput: FC<InputProps> = (props) => {
    const { name, label, status, onChange, disabled } = props;

    return (
        <div>
            <FormControlLabel
                control={
                    <Switch
                        checked={status}
                        onChange={onChange}
                        name={name}
                        color="primary"
                        disabled={disabled}
                    />
                }
                label={label}
            />
        </div>
    )
}

export default ToggleInput
