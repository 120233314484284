import { CompleteDataset } from "types/datasets";
import { Group } from "types/groups";
import { AccessLog } from 'types/logs';
import { User } from "types/user";

const resourceLibrary: { [key: string]: string[] } = {
    'datasets': ['dataset', 'datasets'],
    'data-connections': ['data connection', 'data connections'],
    'users': ['user', 'users'],
    'groups': ['group', 'groups'],
    'privacy-policies': ['privacy policy', 'privacy policies'],
    'logs': ['log', 'logs'],
    'settings': ['setting', 'settings'],
    'health': ['health', 'health']
}

// Pluralize resource from URL parsed
export const pluralizeResource = (nb: number, resource: string): string => {
    const plural = nb > 1;
    return resourceLibrary[resource][plural ? 1 : 0];
}

// Convert string to slug
export const convertToSlug = (txt: string): string => {
    const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/-,:;'
    const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz______'
    const p = new RegExp(a.split('').join('|'), 'g')

    return txt.toString().toLowerCase()
        .replace(/\s+/g, '_') // Replace spaces with _
        .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, '_and_') // Replace & with 'and'
        // eslint-disable-next-line
        .replace(/[^\w\-]+/g, '_') // Remove all non-word characters
        // eslint-disable-next-line
        .replace(/\_\_+/g, '_') // Replace multiple _ with single _
        .replace(/^_+/, '') // Trim _ from start of text
        .replace(/_+$/, ''); // Trim _ from end of text
}

// Get extension of file, return extension as string or undefined
export const getExtension = (fileName: string): string | undefined => {
    return fileName.split('.').pop();
}

// Convert a File to object returned by the upload input into a base 64 string
export const convertFileToBase64 = (rawFile: File) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = () => reject;
        reader.readAsDataURL(rawFile);
    })

// Edit datasetList to convert format date to d/m/y, H:i
export const formatDatasetDate = (datasetList: CompleteDataset[]) => {
    return datasetList.map((row: CompleteDataset) => {
        const dateFormatted: number = Date.parse(row.last_modified! as string);
        return { ...row, last_modified: dateFormatted };
    })
}

// export const formatLogsDate = (logsList: AccessLog[])

export const formatLogsDate = (logsList: AccessLog[]) => {
    return logsList.map((row: AccessLog) => {
        const dateFormatted: number = Date.parse(row.time as string);
        return { ...row, time: dateFormatted };
    })
}

export const formatDateString = (str: string): string => {
    type FormatDate = {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
    }
    const date = new Date(str);
    const options: FormatDate = { year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
    return date.toLocaleDateString('en-GB', options);
}

export const getFileFromSource = (source?: string) => {
    return source ? JSON.parse(source?.replaceAll("'", "\"")).join(', ') : ''
    // return source ? source.split('/').pop() : '';
}

export const wordingStatus = (status?: string): string => {
    switch (status) {
        case 'ready':
            return 'Ready';
        case 'pending':
            return 'Processing';
        case 'error':
            return 'Error';
        case 'empty':
            return 'Draft';
        default:
            return '';
    }
}

// Check is valid email
export const validateEmail = (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
    return re.test(email);
}

// Display user by format "Email - Fullname" if there is fullname
export const DisplayUser = (user: User, singleton?: Group): string => {
    if (singleton) {
        
    }

    if (user.username && user.username !== '') {
        return `${user.email} - ${user.username}`
    } else {
        return `${user.email}`
    }
}

/** -------------------------------
 * Display for numbers in app
 -------------------------------- */
export const formatNumber = (number: string | number, opts?: {isSD?: boolean}): string => {
    const treatString = (str: string): number => {
        const result = str.replace(/,/g, "")
        return parseFloat(result)
    }

    let clearNumber: number | string = typeof number === "number" ? number : treatString(number)
    const locale = "en-US"
    let options = {}

    if (!opts?.isSD) {
        if(clearNumber % 1 !== 0) {
            clearNumber = clearNumber.toFixed(2)
            options = { minimumFractionDigits: 2 }
        }
    }

    clearNumber = clearNumber.toLocaleString(locale, options)
    
    return clearNumber;
};

export const strftime = (sFormat: string, date: Date) => {
    if (!(date instanceof Date)) date = new Date();
    let nDay = date.getDay(),
        nDate = date.getDate(),
        nMonth = date.getMonth(),
        nYear = date.getFullYear(),
        nHour = date.getHours(),
        aDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        aMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        aDayCount = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334],
    isLeapYear = function() {
        return (nYear%4===0 && nYear%100!==0) || nYear%400===0;
    },
    getThursday = function() {
        let target = new Date(date);
        target.setDate(nDate - ((nDay+6)%7) + 3);
        return target;
    },
    zeroPad = function(nNum: number, nPad: number) {
        return ((Math.pow(10, nPad) + nNum) + '').slice(1);
    };
    return sFormat.replace(/%[a-z]/gi, function(sMatch) {
        return (({
        '%a': aDays[nDay].slice(0,3),
        '%A': aDays[nDay],
        '%b': aMonths[nMonth].slice(0,3),
        '%B': aMonths[nMonth],
        '%c': date.toUTCString(),
        '%C': Math.floor(nYear/100),
        '%d': zeroPad(nDate, 2),
        '%e': nDate,
        '%F': date.toISOString().slice(0,10),
        '%G': getThursday().getFullYear(),
        '%g': (getThursday().getFullYear() + '').slice(2),
        '%H': zeroPad(nHour, 2),
        '%I': zeroPad((nHour+11)%12 + 1, 2),
        '%j': zeroPad(aDayCount[nMonth] + nDate + ((nMonth>1 && isLeapYear()) ? 1 : 0), 3),
        '%k': nHour,
        '%l': (nHour+11)%12 + 1,
        '%m': zeroPad(nMonth + 1, 2),
        '%n': nMonth + 1,
        '%M': zeroPad(date.getMinutes(), 2),
        '%p': (nHour<12) ? 'AM' : 'PM',
        '%P': (nHour<12) ? 'am' : 'pm',
        '%s': Math.round(date.getTime()/1000),
        '%S': zeroPad(date.getSeconds(), 2),
        '%u': nDay || 7,
        '%V': (function() {
                var target: any = getThursday(),
                    n1stThu = target.valueOf();
                target.setMonth(0, 1);
                var nJan1 = target.getDay();
                if (nJan1!==4) target.setMonth(0, 1 + ((4-nJan1)+7)%7);
                return zeroPad(1 + Math.ceil((n1stThu-target)/604800000), 2);
                })(),
        '%w': nDay,
        '%x': date.toLocaleDateString(),
        '%X': date.toLocaleTimeString(),
        '%y': (nYear + '').slice(2),
        '%Y': nYear,
        '%z': date.toTimeString().replace(/.+GMT([+-]\d+).+/, '$1'),
        '%Z': date.toTimeString().replace(/.+\((.+?)\)$/, '$1')
        }[sMatch] || '') + '') || sMatch;
    });
}