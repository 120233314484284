import { FC, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { AccordionDetails, TableContainer, Button, Chip, FormHelperText, Grid, SelectChangeEvent } from "@mui/material";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from "store/configureStore";
import NavByAutocomplete from "components/NavByAutocomplete";
import { Title } from "components/stylized/titles";
import { styled } from '@mui/styles'
import { ErrorConsole } from "components/datasets/Schema";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SelectRange from "./SelectRange";
import { EditionInSchema, SchemaInitRanges, SchemaEditRange, EditProtections, EditRelationships, InitProtections } from "store/actions/DatasetActions";
import { SchemaRanges } from "types/schema";
import AdaptativeCell from "./AdaptativeCell";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { ParseRanges, getOptionsFrom } from "./RangesHelper";
import { Accordion, AccordionSummary } from "components/Accordion";
import { PublicChip, ProtectedChip, LinkedChip } from "components/Chips"
import { ProtectedKeyData, ProtectionsDataType } from "store/reducers/DatasetReducer";
import DisplayRelationships from "components/datasets/Schema/DisplayRelationships";

export type NavOption = { name: string, status: ReactNode }
export const MAX_POSSIBLE_VALUES: number = 10000
export const MAX_NUM_CATEGORIES: number = 200

type ParsedTable = {
    name: string[],
    status: "public" | "private" | "linked",
    linkedCol?: string[],
    type?: any
}

interface PKProps {
    label: string,
    disabled?: boolean,
    onRemoveKey: (event: any) => void,
}

const ProtectionKey: FC<PKProps> = ({ label, disabled, onRemoveKey }) => {
    const style = label === "rowId" ? { fontStyle: "italic", color: "text.secondary" } : label.startsWith("Inherited from") ? { fontStyle: "italic" } : {}
    const [isTruncated, setIsTruncated] = useState(false)
    const chipRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if(chipRef.current) {
            const chipWidth = chipRef.current.clientWidth
            setIsTruncated(chipWidth >= 300)
        }
    }, [])

    return (
        <Tooltip arrow disableInteractive title={label === "rowId" ? `A fictitious rowId column was added so that it can be selected as the protection key of the table. Such a protection key protects all rows of the table.` : isTruncated ? label : ''}>
            <span>
                <Chip ref={chipRef} label={label} onDelete={disabled ? undefined : onRemoveKey} sx={{maxWidth: 300, ...style}} size="small" />
            </span>
        </Tooltip>
    )
}

type TableProps = {
    handleNext: (data: { txt?: string, protection: string }) => void,
    errorAPI: { get: string | false, set: (str: string | false) => void},
    errorInConsole: ErrorConsole
}

const Actions = styled('div')(({ theme }) => ({
    marginTop: '16px',
    textAlign: 'right'
}))

const ShowSchema: FC<TableProps> = props => {
    const { handleNext, errorInConsole, errorAPI } = props;
    const { datasetName, canEdit, status, schema, schemaRanges } = useSelector((state: AppState) =>
    ({
        datasetName: state.dataset.dataset?.name,
        canEdit: state.dataset.canEdit,
        status: state.dataset.dataset?.status,
        schemaRanges: state.dataset.schemaRanges,
        schema: state.schema.features,
    }));

    const { datasetId } = useParams <{ datasetId: string }>()
    const history = useHistory()
    const dispatch = useDispatch()

    const apiRelationships = useSelector((state: AppState) => state.dataset.dataset?.relationship_spec)
    const reduxRelationships = useSelector((state: AppState) => state.dataset.relationships)
    const protections = useSelector((state: AppState) => state.dataset.dataset?.protection_spec)
    const tempProtections = useSelector((state: AppState) => state.dataset.protections)
    const schemaIsEdited = useSelector((state: AppState) => state.dataset.schemaEdited)
    const relationRef = useRef<any>()

    const [selectedTable, setSelectedTable] = useState<number>(0)
    const [tableList, setTableList] = useState<string[]>([])
    const [txt, setTxt] = useState('')
    const [shouldRedirect, setShouldRedirect] = useState<boolean>(false)
    // DATASPEC
    const [dataSpecTable, setDataSpecTable] = useState<any>({})
    const [schemaData, setSchemaData] = useState<any>({ isMultitable: false })
    const [showError, setShowError] = useState<boolean>(false)
    const [consoleErrorLog, setConsoleErrorLog] = useState<ErrorConsole>({ id: 0, message: '' })
    //
    const [baseTables, setBaseTables] = useState<ParsedTable[]>([])
    const [openConfirm, setOpenConfirm] = useState<boolean>(false)
    const [openRelationshipEditingModal, setOpenRelationshipEditingModal] = useState(false)
    const [prefixProtection, setPrefixProtection] = useState<string>('')
    const [maxLengthCol, setMaxLengthCol] = useState<number>(0)
    const [dataDisplay, setDataDisplay] = useState<{ title: ReactNode, data: string } | undefined>(undefined)
    const [isCSV, setIsCSV] = useState<boolean>(false)
    const [initSchema, setInitSchema] = useState<boolean>(false)
    const [shouldBlockNavigation, setShouldBlockNavigation] = useState<boolean>(true)
    const [disableProtectionKeys, setDisableProtectionKeys] = useState<boolean>(false)
    const [isEditingProtectionKeys, setIsEditingProtectionKeys] = useState(false)
    const [protectionsKeysState, setProtectionsKeysState] = useState<ProtectionsDataType>({})
    const [lockRelationships, setLockRelationships] = useState(false)
    const [initRelationships, setInitRelationships] = useState(false)

    useEffect(() => {
        // TODO : fix that, very dirty!
        if (!window.location.href.includes("synthetic-data")) {   
            if(apiRelationships && apiRelationships !== reduxRelationships && !initRelationships) {
                setInitRelationships(true)
                dispatch(EditRelationships(apiRelationships))
            }
        }
    }, [apiRelationships, reduxRelationships, dispatch, initRelationships])

    useEffect(() => {
        if(tempProtections) {
            setProtectionsKeysState(tempProtections)
        }
    }, [tempProtections])

    const schemaIdentifierToArrayString = (name: string) => {
        if (isCSV) {
            return `[\"${prefixProtection}\"]`
        } else {
            const parts = name.split(".")
            const result = JSON.stringify([prefixProtection, ...parts])
            return result
        }
    }

    const displayTableName = (arr: any) => {
        if (isCSV) return datasetName
        if (arr.length === 1) {
            return arr[0]
        } else {
            return arr.join('.')
        }
    }

    const handleCloseConfirm = () => {
        setOpenConfirm(false)
    }

    const updateError = useCallback(() => {
        setConsoleErrorLog(errorInConsole)
        setShowError(true)
    }, [errorInConsole])

    const updateErrorAPI = useCallback(() => {
        dispatch(EditionInSchema())
        if (errorAPI.set && errorAPI.get !== false) {
            errorAPI.set(false)
        }
    }, [errorAPI, dispatch])

    useEffect(() => {
        if (errorInConsole.id !== consoleErrorLog.id) {
            updateError()
        }
    }, [consoleErrorLog, errorInConsole, setShowError, setConsoleErrorLog, updateError])

    useEffect(() => {
        setShowError(false)
    }, [txt, setShowError])

    useEffect(() => {
        let longest = 0
        for (const table of baseTables) {
            for (const arr of table.type) {
                const { name } = arr
                if (name.length > longest) {
                    longest = name.length
                }
            }
        }
        setMaxLengthCol(longest)
    }, [baseTables])

    const getProtections = (protectionString?: string) => {
        let specs = JSON.parse(protectionString || "{}")
        let prefix: string[] = []
        const result: any[] = []
        Object.entries(specs).forEach(([key, val]) => {
            const intermediary = key.replace(/'/g, '"').replace(/\(/g, '[').replace(/\)/g, ']')
            const jsonified = intermediary.replace(/",\s*\]/g, '"]')
            const r = JSON.parse(jsonified)
            setPrefixProtection(r[0])
            prefix.push(r[0])
            r.shift()
            result.push({[JSON.stringify(r)]: val})
        })
        return {prefix, result}
    }

    const initiateProtectionKeys = (options: any, reset: boolean = false) => {
        if(!reset && tempProtections && Object.keys(tempProtections).length >= 1) return
        let initProtectionKeys: {[k:string]: ProtectedKeyData} = {}
        const {prefix, result} = getProtections(protections)
        result.forEach((table: {[k: string]:[string[], string]}, index: number) => {
            const tableName = JSON.stringify([prefix[index], ...JSON.parse(Object.keys(table)[0])])
            const tableValue = Object.values(table)[0]
            const keyRef = tableValue[1] ? tableValue[1] : null
            const refData = keyRef ? {
                foreign: keyRef.split(":")[0].split(".")[2],
                primary: keyRef.split(":")[1].split(".").slice(1).join("."),
            } : null
            const keyName = keyRef ? `Inherited from ${refData?.primary}: ${refData?.foreign}` : tableValue[0][0]
            initProtectionKeys[tableName] = {
                selectedPK: reset ? null : keyName ? {name: keyName, ref: keyRef} : null,
                status: reset ? "public" : (keyName ? (keyRef ? "linked" : "private") : "public"),
                options: options[index].type?.map((col: any) => ({name: col.name, ref: null}))
            }
        })
        dispatch(InitProtections(initProtectionKeys))
    }

    useEffect(() => {
        if (schema && protections && !initSchema) {
            setInitSchema(true)
            const isStruct = schema.hasOwnProperty('struct')
            const pureSchema = isStruct ? schema.struct : schema.union
            let schemaList: [string, string][] = []
            setIsCSV(isStruct)
            if (!isStruct) {
                pureSchema.fields.forEach((elem: any) => {
                    elem.type.union.fields.forEach((e: any) => {
                        schemaList.push([elem.name, e.name])
                    })
                })
            }
            const isMultitable = isStruct || schemaList.length === 1 ? false : true;

            if (isStruct) {
                setDataSpecTable(pureSchema)
            } else if (!isMultitable && !isStruct) {
                setDataSpecTable(pureSchema.fields[0].type.union.fields[0].type.struct)
            }

            const {prefix} = getProtections(protections)

            let schemaTables: any = []
            if (!isStruct) {
                pureSchema.fields.forEach((sch: any) => {
                    const n = sch.name
                    const tables = [...sch.type.union.fields]
                    const temp: any = []
                    tables.forEach((t) => {
                        temp.push({ name: [n, t.name], type: t.type.struct.fields})
                    })
                    schemaTables.push(...temp)
                })
            } else {
                schemaTables.push({ name: [], type: pureSchema.fields })
            }
            setBaseTables(schemaTables)

            initiateProtectionKeys(schemaTables)

            const SchemaInfos = {
                isMultitable: isMultitable,
                listTable: isStruct ? 0 : schemaList,
                schemaList: pureSchema
            }

            setSchemaData(SchemaInfos)
            const rangesParsed = ParseRanges(schemaTables, prefix, status!, schemaRanges)
            dispatch(SchemaInitRanges(rangesParsed))
            setTableList(schemaList.map((e: any) => e.join('.')))
        }

    }, [schema, protections, initSchema])

    useEffect(() => {
        if (schemaData && schemaData.isMultitable) {
            const table = schemaData.listTable[selectedTable]
            const toRender = schemaData.schemaList.fields.map((d: any) => {
                if (d.name === table[0]) {
                    const simple = d.type.union.fields
                    let finalTable = undefined
                    simple.forEach((e:any, i: number) => {
                        if (e.name === table[1])
                            finalTable = simple[i]
                    })
                    return finalTable
                } else {
                    return null
                }
            }).filter((e: any) => e !== null);
            setDataSpecTable(toRender[0].type.struct)
        }

    }, [tableList, selectedTable, schema, schemaData])

    const sendProtections = useCallback(() => {
        // TODO @GDG is it still used ?
        let protection: {[key: string]: string[]} = {}
        baseTables.forEach((table) => {
            const tableName = table.name.length === 1 ? [] : table.name
            protection[JSON.stringify([prefixProtection, ...tableName])] = table.status === 'private' ? ['rowId'] : []
        })
        return JSON.stringify(protection)
    }, [baseTables, prefixProtection])

    const validateNextButton = () => {
        // TODO @GDG is it still used ?
        setOpenConfirm(false)
        const protection = sendProtections()
        if (txt === '') {
            handleNext({protection: protection})
        } else {
            handleNext({txt: (reduxRelationships === '' || !reduxRelationships) ? txt : undefined, protection})
        }
    }

    useEffect(() => {
        // TODO @GDG
        // I don't understand why we have a useEffect here, and use the shouldBlockNavigation var
        // why don't we just put the 2 lines for history.push directly in the button below ???
        if (!shouldBlockNavigation) {
            const baseUrl = `/datasets/${datasetId}`
            history.push(`${baseUrl}/synthetic-data`)
        }
    }, [shouldBlockNavigation])

    const EditButton = useCallback(() => {
        const permit = canEdit;

        const ButtonFormatted = () => (
            <Button
                variant="contained"
                color="primary"
                onClick={() => {
                    if (Object.values(protectionsKeysState).filter((value) => value.status === "public").length > 0) {
                        // display confirm modal
                        // console.log(Object.values(protectionsKeysState).filter((value) => value.status === "public"))
                        setOpenConfirm(true)
                    } else {
                        setShouldBlockNavigation(false)
                    }
                }}
                disabled={!permit}
            >
                Next
            </Button>
        );

        return !permit ? (
            <Tooltip title="You don't have sufficient permission to edit datasets" arrow>
                <span>
                    <ButtonFormatted />
                </span>
            </Tooltip>
        ) : <ButtonFormatted />
    }, [baseTables, validateNextButton])

    const GetChip = (status: "public" | "linked" | "private") => {
        switch(status) {
            case "linked":
                return <LinkedChip />
            case "private":
                return <ProtectedChip />
            case "public":
            default:
                return <PublicChip />
        }
    }

    const TableSelection = useCallback(() => {
        if (schemaData && !schemaData.isMultitable) return null
        if (!tempProtections) return null

        const options: NavOption[] = Object.entries(tempProtections).map(([tableName, tableData]) => {
            const treatingName = [...JSON.parse(tableName)]
            treatingName.shift()
            const parsedName = treatingName.join(".")
            return {name: parsedName, status: GetChip(tableData.status)}
        })

        const optionNames = options.map((option) => option.name)
        return (
            <NavByAutocomplete
                id="table-selection"
                options={optionNames}
                chipMapping={options}
                onChange={(event, newValue) => {
                    if(newValue) setSelectedTable(tableList.indexOf(newValue))
                }}
                onInputChange={(event, newInput) => {
                    if(tableList.indexOf(newInput) > -1) setSelectedTable(tableList.indexOf(newInput))
                }}
                value={tableList[selectedTable]}
            />
        )
    }, [schemaData, selectedTable, tempProtections, tableList])

    const NextButton = () => {

        const showText = (multi: boolean) => {
            return <>The schema is a central component of the Sarus dataset. It will be made public to all practitioners who were granted query access to the dataset through access rules. It is also used for synthetic data generation and differential privacy computations.</>
        }

        const errorDisplay = errorAPI.get ? errorAPI.get : 'Oops! Something went wrong with this data. Please contact your Sarus Account Manager'

        return (
            <Grid container spacing={4}>
                <Grid item xs={9}>
                    <FormHelperText>
                        {showText(schemaData.isMultitable)}
                    </FormHelperText>
                </Grid>
                <Grid item xs={3} textAlign={"right"}>
                    <EditButton />
                {errorAPI.get !== false ? <FormHelperText error sx={{textAlign: 'right'}}>{errorDisplay}</FormHelperText> : null}
                </Grid>
            </Grid>
        )
    }

    /*
    *
    * DATASPEC UTILITIES
    *
    * */

    const getTypeFromDataSpec = (feature: any) => {
        const isOptional = feature.type.hasOwnProperty('optional')
        let featureType = ''
        const getType = (type: any) => {
            if (type.hasOwnProperty('integer')) return 'integer'
            else if (type.hasOwnProperty('id')) return 'id'
            else if (type.hasOwnProperty('float')) return 'float'
            else if (type.hasOwnProperty('boolean')) return 'boolean'
            else if (type.hasOwnProperty('integer')) return 'integer'
            else if (type.hasOwnProperty('time')) return 'time'
            else if (type.hasOwnProperty('duration')) return 'duration'
            else if (type.hasOwnProperty('date')) return 'date'
            else if (type.hasOwnProperty('datetime')) return 'datetime'
            else if (type.hasOwnProperty('text')) return 'text'
            else if (type.hasOwnProperty('enum')) return 'enum'
            else if (type.hasOwnProperty('unit')) return 'unit'
            else {
                console.log("------ TYPE ERROR ------")
                console.log(type)
                console.log("------------------------")
                return 'error'
            }
        }

        featureType = getType(isOptional ? feature.type.optional.type : feature.type)

        return {type: featureType, optional: isOptional}
    }


    const DialogDisplayData = () => {
        return (
            <Dialog
                open={!!dataDisplay}
                onClose={() => setDataDisplay(undefined)}
            >
                <DialogTitle>{dataDisplay?.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {dataDisplay?.data}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDataDisplay(undefined)}>Close</Button>
                </DialogActions>
            </Dialog>
        )
    }

    const DialogConfirmWithPublicTables = () => {
        const publicTables = Object.entries(protectionsKeysState).filter(([key, value]) => value.status === "public")
        const isSolo = publicTables.length === 1
        const titleSolo  = `Continue with public table?`
        const titleMulti = `Continue with ${publicTables.length} public tables?`
        const getPublicTablesNames = () => {
            const treatedPublicTables = Object.keys(protectionsKeysState)
                .filter(table => protectionsKeysState[table].status === "public")
                .map(table => {
                let key = JSON.parse(table)
                key.shift()
                return JSON.stringify(key)
            })
            if (treatedPublicTables.length === 1) {
                return JSON.parse(treatedPublicTables[0]).join('.')
            } else if (treatedPublicTables.length > 1) {
                return treatedPublicTables.map(x => JSON.parse(x).join('.')).join(', ')
            } else {
                return ''
            }
        }
        const descriptionSolo  = `Table ${getPublicTablesNames()} is not protected. Its rows may be accessible in query results in clear. Add a protection key if you want to have privacy guarantees.`
        const descriptionMulti = `Tables ${getPublicTablesNames()} are not protected. Their rows may be accessible in query results in clear. Add protection keys if you want to have privacy guarantees.`
        const confirmLabelSolo = `Continue with 1 public table`
        const confirmLabelMulti = `Continue with ${publicTables.length} public tables`

        return (
            <Dialog
                open={openConfirm}
                onClose={handleCloseConfirm}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{isSolo ? titleSolo : titleMulti}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {isSolo ? descriptionSolo : descriptionMulti}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirm} color="primary">Edit protection keys</Button>
                    <Button onClick={() => {
                        const baseUrl = `/datasets/${datasetId}`
                        history.push(`${baseUrl}/synthetic-data`)
                    }} color="primary">{isSolo ? confirmLabelSolo : confirmLabelMulti}</Button>
                </DialogActions>
            </Dialog>
        )
    }

    const DialogEditingRelationships = () => {
        const handleClose = () => {
            setOpenRelationshipEditingModal(false)
            setLockRelationships(true)
        }
        const handleConfirm = () => {
            initiateProtectionKeys(baseTables, true)
            setOpenRelationshipEditingModal(false)
            setLockRelationships(false)
        }

        const handleLeave = () => {
            if(relationRef.current) relationRef.current.blur()
            setOpenRelationshipEditingModal(false)
            setIsEditingProtectionKeys(true)
            setLockRelationships(true)
        }

        return (
            <Dialog
                open={openRelationshipEditingModal}
                onClose={handleLeave}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Edit Relationships and discard Protection Keys changes?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Editing Relationships will reset Protection Keys section. Are you sure you want to edit and discard all changes made in Protection Keys?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Cancel editing</Button>
                    <Button onClick={handleConfirm} color="primary">Yes, edit and discard</Button>
                </DialogActions>
            </Dialog>
        )
    }

    const handleFocusConsoleBox = () => {
        if(isEditingProtectionKeys && status === 'empty') {
            setOpenRelationshipEditingModal(true)
        }
        setIsEditingProtectionKeys(false)
    }

    const handleEditProtectionKey = ({table, key}: {table: string[], key: string | null}) => {
        const result = {[JSON.stringify([prefixProtection, ...table])]: key}
        dispatch(EditProtections(result))
    }

    const ProtectionKeyRowDisplay: FC<{name: string, data: ProtectedKeyData}> = ({name, data}) => {
        const parsedName = JSON.parse(name)
        parsedName.shift()
        const displayableName = displayTableName(parsedName)
        const DisplayChip = () => {
            if(data.selectedPK && data.selectedPK.name.startsWith("Inherited from ")) { // TODO : why not check status instead of name ???
                return <LinkedChip />
            } else if (data.selectedPK) {
                return <ProtectedChip />
            } else {
                return <PublicChip />
            }
        }

        const getLabel = () => {
            if(!data.selectedPK) return ""
            if(typeof data.selectedPK === "string") return data.selectedPK
            if(typeof data.selectedPK === "object" && data.selectedPK.name) return data.selectedPK?.name
            return ""
        }

        return (
            <TableRow hover={status === 'empty'}>
                <TableCell aria-colindex={0}>{displayableName}</TableCell>
                <TableCell aria-colindex={1}><DisplayChip /></TableCell>
                <AdaptativeCell
                    datasetStatus={status || ''}
                    tableStatus={data.status}
                    options={data.options}
                    isDisable={disableProtectionKeys}
                    colindex={2}
                    onChange={(event: any, newValue: string | null) => {
                        updateErrorAPI()
                        setIsEditingProtectionKeys(true)
                        setLockRelationships(true)
                        handleEditProtectionKey({table: parsedName, key: newValue})
                    }}
                    size={maxLengthCol}
                    label={getLabel()}
                    protectedChip={<ProtectionKey label={getLabel()} onRemoveKey={() => {
                        updateErrorAPI()
                        handleEditProtectionKey({table: parsedName, key: null})
                    }} />}
                />
            </TableRow>
        )
    }

    return (
        <Grid container spacing={3}>
            <Prompt when={status === 'empty' && schemaIsEdited && !shouldRedirect} message={(location, action) => {
                return location.pathname.startsWith(`/datasets/${datasetId}/`) ? true : JSON.stringify({ message: 'The dataset has not been made available through Sarus yet.' })
                }
            } />
            {
                status === 'empty' && (
                    <Grid item xs={12}>
                        <NextButton />
                    </Grid>
                )
            }
            <DialogConfirmWithPublicTables />
            <DialogDisplayData />
            <DialogEditingRelationships />
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Title variant="h3" comp="h2" noGutter>Relationships</Title>
                        {status === 'empty' && <FormHelperText sx={{mb: 1}}>Add primary and foreign keys or edit the ones initialized from the source (optional).</FormHelperText>}
                    </Grid>
                    <Grid item xs={12}>
                        <DisplayRelationships
                            name="tableRelationships"
                            disable={(!canEdit || status !== 'empty') || lockRelationships}
                            error={showError ? errorInConsole.message : ""}
                            dlFileName={`${datasetName}_pkfk`}
                            idleCallBack={(act) => setDisableProtectionKeys(act)}
                            onFocus={handleFocusConsoleBox}
                            passRef={(ref: any) => (relationRef.current = ref.current)}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Title variant="h3" comp="h2" noGutter>Protection Keys</Title>
                        {status === 'empty' && <FormHelperText sx={{mb: 1}}>Define the protection keys for tables that include private information. Add or remove them from the protection key column, the table protection status will automatically be
updated. Note that protection is propagated along foreign keys. Tables with inherited protection will have the "Linked" status. See documentation to know more.</FormHelperText>}
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer sx={{maxWidth: "80%", overflow: "auto", maxHeight: "600px"}}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" width={300}>Table</TableCell>
                                        <TableCell align="left" width={200}>Protection status</TableCell>
                                        <TableCell align="left">Protection key</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        Object.entries(protectionsKeysState).map(([tableName, data], index) => {
                                            return <ProtectionKeyRowDisplay name={tableName} data={data} key={index} />
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Accordion elevation={0} disableGutters square>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        arial-controls="panel-control"
                        id="panel"
                        sx={{px: 0, ml: 0}}
                    >
                        <Title variant="h3" comp="h2" noGutter>Advanced Columns Settings</Title>
                    </AccordionSummary>
                    <AccordionDetails sx={{p: 0}}>
                        <FormHelperText sx={{mb: 2}}>{status === 'empty' ? 'Select a table to view the per-column settings. They can be edited to customize the synthetic data and privacy computations.' : 'Select a table to view the per-column settings.'}</FormHelperText>
                        <TableSelection />
                        <TableContainer sx={(theme) => ({maxHeight: 400, mt: 2, overflow: 'auto'})}>
                            <Table stickyHeader size="small" style={{tableLayout: 'auto'}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Column</TableCell>
                                        <TableCell>Type</TableCell>
                                        <TableCell>Range</TableCell>
                                        <TableCell>Synthesis method</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataSpecTable.fields && dataSpecTable.fields.map((feature: any, i: number) => {
                                        const featureData = getTypeFromDataSpec(feature)
                                        const type = featureData.optional ? `Optional(${feature.type.optional.type.name})` : feature.type.name

                                        const thisTable: any = baseTables.length > 1 ? baseTables.find(x => x.name.join('.') === tableList[selectedTable]) : baseTables[0]
                                        const thisStatus = thisTable ? thisTable.status : ''

                                        const DisplayDataPopup = ({title, data}: {title: string | ReactNode, data: string}) => {
                                            setDataDisplay({title: title, data: data})
                                        }
                                        const getValue = () => {
                                            if (!schemaRanges) return;
                                            if (isCSV) {
                                                return schemaRanges[Object.keys(schemaRanges)[0]][feature.name]
                                            } else {
                                                const tableName = schemaIdentifierToArrayString(tableList[selectedTable])
                                                return schemaRanges[tableName][feature.name]
                                            }
                                        }
                                        const colValue = getValue()
                                        const options = getOptionsFrom(feature, featureData, thisStatus, status!, DisplayDataPopup, colValue)

                                        const OnChangeRange = (event: SelectChangeEvent) => {
                                            updateErrorAPI()
                                            const editedColumn: SchemaRanges = {
                                                [schemaIdentifierToArrayString(tableList[selectedTable])]:
                                                    {
                                                        [feature.name as string]: event.target.value
                                                    }
                                            };
                                            dispatch(SchemaEditRange(editedColumn))
                                        }

                                        const CheckDisplayType = (type: string) => {
                                            if (type.startsWith("Text")) {
                                                return "Text"
                                            } else {
                                                return type
                                            }
                                        }

                                        const displayLine = () => {

                                            const textDisplay = () => {

                                                if (options.length === 0) {
                                                    console.error('No option for that feature')
                                                    return ''
                                                } else if (options.length === 1) {
                                                    const optUnique = options[0]
                                                    return <>{optUnique.label} {optUnique.display}</>
                                                } else {
                                                    const optSelected = options.find((el) => el.value === colValue)
                                                    return <>{optSelected?.label} {optSelected?.display}</>
                                                }
                                            }
                                            return <>{textDisplay()}</>
                                        }

                                        const getSynthesis = () => {
                                            if (options) {
                                                const result = options.length > 1 ? options.filter(x => x.value === colValue) : options
                                                return result[0]?.synthesis || ''
                                            }
                                        }

                                        return (
                                            <TableRow key={i}>
                                                <TableCell key={feature.name + 'name'} width="300">{feature.name}</TableCell>
                                                <TableCell key={feature.name + 'type'} style={{whiteSpace: 'nowrap'}}>{CheckDisplayType(type)}</TableCell>
                                                <TableCell key={feature.name + 'range'}>{
                                                    (status !== 'empty' || thisStatus === 'public') ?
                                                        displayLine() :
                                                        <SelectRange OnChange={OnChangeRange} defaultOption={colValue} options={options} />
                                                }</TableCell>
                                                <TableCell key={feature.name + "synthesis"} width="200">{getSynthesis()}</TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item xs={12}>
                <Actions>
                    {status === 'empty' && <EditButton />}
                </Actions>
            </Grid>
        </Grid>
    );
};


export default ShowSchema;
