import styled from 'styled-components';
import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

type DGProps = {
    isClickable?: boolean,
}

export const datagridStyles = makeStyles<Theme, DGProps>((theme: Theme) =>
    createStyles({
        datagrid: {
            border: 0,
            '& .MuiDataGrid-cell:focus-within': {
                outline: 'none',
            },
            '& .MuiDataGrid-cell': {
                cursor: ({ isClickable }) => isClickable ? 'pointer' : 'inherit',
            },
            '& :focus': {
                outline: 'none !important',
            },
            '& .MuiDataGrid-iconSeparator': {
                display: 'none',
            },
            '& .MuiDataGrid-row.Mui-selected': {
                backgroundColor: theme.palette.grey[200],
            },
            '& .centeredHead': {
                displey: 'flex',
                justifyContent: 'center'
            },
            '& .MuiDataGrid-columnHeaderTitleContainer': {
                padding: 0
            },
            "& .MuiDataGrid-cell, & .MuiDataGrid-columnHeaderTitle": {
                textOverflow: "clip"
            },
        },
        label: {
            marginTop: theme.spacing(1)
        },
        overlay: {
            marginTop: theme.spacing(1),
            flexDirection: 'column',
            '& .ant-empty-img-1': {
                fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
            },
            '& .ant-empty-img-2': {
                fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
            },
            '& .ant-empty-img-3': {
                fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
            },
            '& .ant-empty-img-4': {
                fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
            },
            '& .ant-empty-img-5': {
                fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
                fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
            },
        }
    })
);

export const DataActions = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 16px;
    margin-bottom: 32px;
    margin-top: 32px;

    > div {
        display: flex;
        align-items: flex-start;
    }

    > div:first-child {
        width: 400px;
    }

    > div:last-child {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 32px;
    }
`;