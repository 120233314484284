import React, { FC, useEffect, useState, useContext, ChangeEvent, useCallback } from 'react'
import { UserContext } from 'context/authContext'
import { Prompt, useHistory } from 'react-router-dom'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Button from '@mui/material/Button'
import { IPrivacyPolicy } from 'types/privacy-policy'
import apiHandler from 'api/apiHandler'
import Grid from '@mui/material/Grid'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { ErrorInForm } from 'types/forms';
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormHelperText, Link, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import ToggleInput from 'components/Toggle'
import SimpleCheckbox from 'components/SimpleCheckbox'
import NumberInput from 'components/NumberInput'
import PrimaryButton from 'components/Buttons/PrimaryButton'
import CancelButton from 'components/Buttons/CancelButton'
import WithInfo from 'components/WithInfo'
import ConsoleBox from 'components/ConsoleBox'

const ShowParameter: FC<{
    param: 'perQuery' | 'perUser' | 'perGroup',
    record: IPrivacyPolicy,
    activeParameters: activeParam,
    errorsInForm: ErrorInForm[],
    cleanError: any,
    setRecord: any,
    classes: any,
    disabled: boolean,
}> = ({ param, record, activeParameters, errorsInForm, cleanError, setRecord, classes, disabled }) => {
    const value = param === 'perQuery' ? record.per_request_epsilon : param === 'perUser' ? record.per_user_epsilon : record.global_epsilon
    const errorName = param === 'perQuery' ? 'params.request' : param === 'perUser' ? 'params.user' : 'params.group'
    if (activeParameters[param]) {
        return <NumberInput
            name={param}
            value={value + ''}
            onChange={(e) => {
                cleanError(errorName)
                setRecord({ ...record, [getFieldName(param as fieldParameter)]: parseFloat(e.value) })
            }}
            error={!!errorsInForm.find((e, d) => e.name === errorName)}
            disabled={disabled}
            raw
        />
    } else {
        return <span className={classes.defaultParam}>Unlimited</span>
    }
}

const defaultValues = {
    perQuery: 2,
    perUser: 20,
    perGroup: 20
}
type fieldParameter = 'perQuery' | 'perUser' | 'perGroup'
const getFieldName = (field: fieldParameter) => {
    const list = {
        'perQuery': 'per_request_epsilon',
        'perUser': 'per_user_epsilon',
        'perGroup': 'global_epsilon',
    }
    return list[field]
}

type activeParam = {
    perQuery: boolean,
    perUser: boolean,
    perGroup: boolean,
}

const initialeState: IPrivacyPolicy = {
    name: '',
    synthetic_data_access: true,
    differential_privacy_access: true,
    per_request_epsilon: defaultValues.perQuery,
    per_user_epsilon: undefined,
    global_epsilon: undefined,
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        defaultParam: {
            fontStyle: 'italic',
            color: theme.palette.grey[500]
        },
        tableRoot: {
            width: '560px',
            '& .MuiTableCell-root': {
                width: '280px'
            },
            '& .MuiTableCell-body': {
                height: '63px'
            }
        }
    }),
);

export type checkTypePPDialog = { modified?: boolean, data?: IPrivacyPolicy };

interface IFormProps {
    data?: IPrivacyPolicy,
    isDialog?: (r: checkTypePPDialog) => void,
    submitFromDial?: {val: boolean, set: any},
}

const PrivacyPolicyForm: FC<IFormProps> = props => {
    const { data, isDialog, submitFromDial } = props
    const { hasPermissions } = useContext(UserContext);
    const [record, setRecord] = useState<IPrivacyPolicy>(initialeState);
    const [isModified, setIsModified] = useState<boolean>(false);
    const [isEditable, setIsEditable] = useState<boolean>(true);
    const [isTreating, setIsTreating] = useState<boolean>(false);
    const [strictlyDisabled, setStrictlyDisabled] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [errorsInForm, setErrorsInForm] = useState<ErrorInForm[]>([]);
    const [cancelState, setCancelState] = useState<boolean>(false);
    const [activeParameters, setActiveParameters] = useState<activeParam>({ perQuery: true, perUser: false, perGroup: false });
    const classes = useStyles();
    const history = useHistory();
    const isCreateForm = !Boolean(data)
    const [defaultWhitelist, setDefaultWhitelist] = useState<string>('')
    const [activeWhitelist, setActiveWhitelist] = useState<boolean>(isCreateForm ? false : true)
    const [whitelistContent, setWhitelistContent] = useState<string>('')

    useEffect(() => {
        if (!data) {
            apiHandler.getDefaultWhitelist().then((r) => {
                setDefaultWhitelist(r)
            }).then(() => setIsLoading(false)).catch(e => console.log('Error loading the default whitelist'))
        } else {
            setIsLoading(false)
        }
    }, [])

    useEffect(() => {
        if (!activeWhitelist) {
            setWhitelistContent(defaultWhitelist)

        }
    }, [activeWhitelist, defaultWhitelist])

    const hasParameterError = useCallback((param: 'query' | 'user' | 'group') => {
        // If true : error

        const forActiveParameters = {
            'query': 'perQuery',
            'user': 'perUser',
            'group': 'perGroup'
        };
        const forRecord = {
            'query': 'per_request_epsilon',
            'user': 'per_user_epsilon',
            'group': 'global_epsilon',
        };
        const recordValue = record[forRecord[param] as keyof typeof record];

        if (record.differential_privacy_access) {
            // Check if param is active
            if (activeParameters[forActiveParameters[param] as keyof typeof activeParameters]) {
                // If activated
                if (recordValue === null || recordValue === undefined || recordValue === '') {
                    return 'Please specify a limit value'
                } else if (typeof recordValue === 'number' && recordValue <= 0) {
                    return 'DP limit value should be strictly positive'
                } else {
                    return false
                }
            } else {
                return !(recordValue === null || recordValue === undefined)
            }
        } else {
            return recordValue !== 0
        }
    }, [activeParameters, record])

    const handleSubmit = useCallback((event?: React.SyntheticEvent) => {
        event && event.preventDefault();
        if (isLoading) return;
        setIsTreating(true);
        const errors: ErrorInForm[] = [];
        if (record.name === '' || !record.name) {
            errors.push({ name: 'name', message: 'Please enter a name for the privacy policy' })
        };

        if (record.differential_privacy_access && (
            record.per_user_epsilon === undefined
            && record.per_request_epsilon === undefined
            && record.global_epsilon === undefined
        )) {
            errors.push({ name: 'params', message: 'Please set at least one limit for Differential Privacy access' })
        }

        if (record.differential_privacy_access && activeParameters.perQuery && (isNaN(record.per_request_epsilon as number) || record.per_request_epsilon === '')) {
            errors.push({ name: 'params.request', message: 'Please specify a limit value' })
        } else if (record.differential_privacy_access && activeParameters.perUser && (isNaN(record.per_user_epsilon as number) || record.per_user_epsilon === '')) {
            errors.push({ name: 'params.user', message: 'Please specify a limit value' })
        } else if (record.differential_privacy_access && activeParameters.perGroup && (isNaN(record.global_epsilon as number) || record.global_epsilon === '')) {
            errors.push({ name: 'params.group', message: 'Please specify a limit value' })
        }

        const errorInQuery = hasParameterError('query');
        if (errorInQuery) {
            errors.push({ name: 'params.request', message: typeof errorInQuery === 'string' ? errorInQuery : 'Please specify a limit value' })
        }

        const errorInUser = hasParameterError('user');
        if (errorInUser) {
            errors.push({ name: 'params.user', message: typeof errorInUser === 'string' ? errorInUser : 'Please specify a limit value' })
        }

        const errorInGroup = hasParameterError('group');
        if (errorInGroup) {
            errors.push({ name: 'params.group', message: typeof errorInGroup === 'string' ? errorInGroup : 'Please specify a limit value' })
        }

        let toSend: IPrivacyPolicy = { ...record }
        delete toSend.differential_privacy_access
        if (record.differential_privacy_access && !toSend.per_request_epsilon && toSend.per_request_epsilon !== 0)
            toSend.per_request_epsilon = null
        if (record.differential_privacy_access && !toSend.per_user_epsilon && toSend.per_user_epsilon !== 0)
            toSend.per_user_epsilon = null
        if (record.differential_privacy_access && !toSend.global_epsilon && toSend.global_epsilon !== 0)
            toSend.global_epsilon = null

        if (activeWhitelist) {
            toSend.whitelisted_transforms = whitelistContent
        }
        delete toSend.default_epsilon;
        setErrorsInForm(errors)

        if (errors.length === 0) {
            if (data && data.id) {
                return
                //* Commented since we can't edit PP now
                // apiHandler.editPrivacyPolicy(data.id, toSend)
                //     .then((result: any) => {
                //         setIsLoading(false);
                //         setIsModified(false)
                //     }).then(() => history.push('/privacy-policies')
                //     ).catch((error: any) => console.log(error));
            } else {
                if (isDialog) {
                    setIsTreating(false);
                    isDialog({ data: toSend })
                } else {
                    apiHandler.createPrivacyPolicy(toSend)
                        .then((result: any) => {
                            setIsTreating(false);
                            setIsModified(false)
                        }).then(() => history.push('/privacy-policies')
                    ).catch((error: any) => {
                        const status = error.response.status
                        const receivedError = error.response.data
                        if (status >= 500) {
                            setErrorsInForm([{name: 'any', message: `Oops, something went wrong. Please check the settings and try again`}])
                        } else {
                            Object.entries(receivedError).forEach(([key, value]) => {
                                if(value === 'Privacy policy name already used') {
                                    setErrorsInForm([{ name: 'name', message: 'This name is already taken' }]);
                                } else if (key === 'whitelisted_transforms' || key === 'whitelist') {
                                    setErrorsInForm([{ name: 'whitelist', message: value as string }]);
                                    scrollToWhitelist()
                                } else {
                                    setErrorsInForm([{ name: 'any', message: value as string}]);
                                }
                            })
                        }
                        setIsTreating(false)
                        console.log(toSend)
                    });
                }
            }
        } else {
            setIsTreating(false);
        }
    }, [activeParameters, data, activeWhitelist, whitelistContent, hasParameterError, history, isDialog, isLoading, record]);

    const scrollToWhitelist = useCallback(() => {
        const element = document.getElementById("editor-whitelistOps")
        if (element) {
            element.scrollIntoView({behavior: 'smooth'})
        }
    }, [])

    useEffect(() => {
        if (!data) {
            if (!hasPermissions(['privacyPolicy.createGlobal', 'privacyPolicy.createLocal']))
                history.push(`/privacy-policies`)

            setIsEditable(true)
        } else {
            if (!hasPermissions(['privacyPolicy.editGlobal', 'privacyPolicy.editOwn']))
                history.push(`/privacy-policies`)

            if (data.id === 1) {
                setStrictlyDisabled(true)
            } else {
                setIsEditable(true)
            }

            if (data?.per_request_epsilon === 0 && data.per_user_epsilon === 0 && data.global_epsilon === 0) {
                setRecord({ ...data, differential_privacy_access: false });
            } else {
                let params: any = { differential_privacy_access: true }
                let paramsBool: activeParam = { perQuery: false, perUser: false, perGroup: false }
                if (data.per_request_epsilon || data.per_request_epsilon === 0) {
                    params.per_request_epsilon = data.per_request_epsilon
                    paramsBool.perQuery = true
                }
                if (data.per_user_epsilon || data.per_user_epsilon === 0) {
                    params.per_user_epsilon = data.per_user_epsilon
                    paramsBool.perUser = true
                }
                if (data.global_epsilon || data.global_epsilon === 0) {
                    params.global_epsilon = data.global_epsilon
                    paramsBool.perGroup = true
                }
                setRecord({ ...data, ...params })
                setActiveParameters(paramsBool)
            }
        }
    }, [data, hasPermissions, history])

    useEffect(() => {
        if (submitFromDial?.val) {
            handleSubmit();
            submitFromDial.set(false)
        }
    }, [submitFromDial, handleSubmit])

    const handleChange = (event: React.ChangeEvent<{ name: string, value: unknown, checked?: boolean, type: string }>) => {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.name === 'name' ? event.target.value : parseFloat(event.target.value as string);
        let editedRecord = { ...record };
        if (event.target.name === 'differential_privacy_access') {
            if (value) {
                editedRecord.per_request_epsilon = defaultValues.perQuery
                editedRecord.per_user_epsilon = undefined
                editedRecord.global_epsilon = undefined
                setActiveParameters({ ...activeParameters, perQuery: true, perGroup: false, perUser: false });
            } else {
                editedRecord.per_request_epsilon = 0
                editedRecord.per_user_epsilon = 0
                editedRecord.global_epsilon = 0
            }
        }
        editedRecord = { ...editedRecord, [event.target.name]: value };
        setRecord(editedRecord);
        setIsModified(true);
    }

    const DialogCancel: FC = () => {
        let message: { [key: string]: string } = { message: '', title: '', confirm: '' };
        if (data && data.id) {
            message.title = `Discard unsaved changes?`
            message.confirm = `Discard`
            message.confirmLink = `/privacy-policies/${data.id}`;
            message.cancel = `Keep editing`
        }
        else {
            message.title = `Discard unsaved changes?`
            message.message = `The privacy policy has not been created yet.`;
            message.confirm = `Discard`
            message.confirmLink = `/privacy-policies`;
            message.cancel = `Stay`
        }

        return (
            <Dialog
                open={cancelState}
                onClose={() => setCancelState(false)}
            >
                <DialogTitle>{message.title}</DialogTitle>
                {message.message && (
                    <DialogContent>
                        <DialogContentText>
                            {message.message}
                        </DialogContentText>
                    </DialogContent>
                )}
                <DialogActions>
                    <Button onClick={() => setCancelState(false)}>
                        {message.cancel}
                    </Button>
                    <Button onClick={() => history.push(message.confirmLink)} color="secondary">
                        {message.confirm}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    const EditButton = () => {
        if (strictlyDisabled || getIsDisabled()) return null;
        let params: any = { action: handleSubmit };
        params.disabled = getIsDisabled();

        if (!data && hasPermissions(['privacyPolicy.createGlobal', 'privacyPolicy.createLocal'])) {
            return (
                <PrimaryButton {...params}>Create Privacy Policy</PrimaryButton>
            )
        } else if (data) {
            return (
                <PrimaryButton {...params}>Save changes</PrimaryButton>
            )
        }
        return null;
    };

    const cleanError = (field: string | string[]) => {
        if (Array.isArray(field)) {
            console.log("handle fields");
            return;
        }

        if (errorsInForm.find((error: ErrorInForm) => error.name === field)) {
            const filteredErrors = errorsInForm.filter((error: ErrorInForm) => error.name !== field);
            setErrorsInForm(filteredErrors);
        }
    }

    const handleParameter = (e: ChangeEvent<{ name: string, checked: boolean }>) => {
        setActiveParameters({ ...activeParameters, [e.target.name]: e.target.checked });
        const errorName = e.target.name === 'perQuery' ? 'params.request' : e.target.name === 'perUser' ? 'params.user' : 'params.group'
        cleanError(errorName);

        if (e.target.checked) {
            setRecord({ ...record, [getFieldName(e.target.name as fieldParameter)]: defaultValues[e.target.name as fieldParameter] });
        } else {
            setRecord({ ...record, [getFieldName(e.target.name as fieldParameter)]: undefined });
        }
    }

    const getIsDisabled = (): boolean => {
        return strictlyDisabled || isLoading || !isEditable || data !== undefined || isTreating
    }

    const ShowAnyErrors = () => {
        const err = errorsInForm.find((er) => er.name === 'any')?.message
        if (err) {
            return (
                <Grid item xs={8}>
                    <FormHelperText error style={{whiteSpace: 'pre-wrap'}}>
                        {err}
                    </FormHelperText>
                </Grid>
            )
        } else {
            return null
        }
    }

    if (!isLoading) {
        return (
            <form onSubmit={handleSubmit}>
                <Prompt when={isModified} message={JSON.stringify({
                    message: data ?  undefined : 'The privacy policy has not been created yet.'
                })} />
                <DialogCancel />
                <Grid container spacing={4}>
                    <Grid item xs={8}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                    <TextField
                                        id="name"
                                        name="name"
                                        label="Name"
                                        onChange={(e) => {
                                            handleChange(e);
                                            cleanError('name');
                                        }}
                                        disabled={getIsDisabled()}
                                        value={record.name}
                                        error={!!errorsInForm.find(x => x.name === 'name')}
                                        helperText={errorsInForm.find(x => x.name === 'name')?.message}
                                        style={{ width: 300 }}
                                    />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={8}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <FormControl component="fieldset" variant="standard">
                                    <WithInfo
                                        content={<FormLabel component="legend">Synthetic data access</FormLabel>}
                                        info={
                                            <>
                                                Sarus by default provides synthetic data to let the data practitioners (data scientists, analysts)
                                                see and manipulate privacy-safe individual rows. Synthetic data are instrumental to get a feel of
                                                the source data, prepare analyses, build preprocessing pipelines, and design or debug ML models. <a href="/docs/sarus_concepts.html#synthetic-data">Learn more</a>.
                                            </>
                                        }
                                    />
                                    <ToggleInput
                                        name="synthetic_data_access"
                                        label={record.synthetic_data_access ? "Yes" : "No"}
                                        onChange={handleChange}
                                        status={record.synthetic_data_access}
                                        disabled={true || getIsDisabled()}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={8}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <FormControl component="fieldset" variant="standard">
                                    <WithInfo
                                        content={<FormLabel component="legend">Differential Privacy access</FormLabel>}
                                        info={
                                            <>
                                                When activated, all the data output accessed by the data practitioners under this privacy policy
                                                are protected by Differential Privacy. Some exceptions can be defined, see Whitelisting section
                                                below. <a href="/docs/sarus_concepts.html#differential-privacy">Learn more</a>.
                                            </>
                                        }
                                    />
                                    <ToggleInput
                                        name="differential_privacy_access"
                                        label={record.differential_privacy_access ? "Yes" : "No"}
                                        onChange={handleChange}
                                        status={record.differential_privacy_access!}
                                        disabled={getIsDisabled()}
                                    />
                                </FormControl>
                            </Grid>
                            {
                                record.differential_privacy_access && (
                                    <>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle2">Differential Privacy parameters</Typography>
                                            </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2" color="textSecondary" style={{ fontSize: '12px' }}>
                                                For any Differential Privacy access, Sarus keeps an account of the privacy loss of all executed queries. For a given dataset and group of users where the current privacy policy applies, if the cumulated privacy loss exceeds the per-query, per-user, or per-group-of-users limits defined below, DP queries will be rejected.
                                                {/* Sarus keeps an account of the privacy loss of all executed queries. For a given dataset and group of users where the current privacy policy applies, if the cumulated privacy loss exceeds the per-query, per-user, or per-group-of-users limits defined below, DP queries will be rejected.  */}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Table className={classes.tableRoot}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>DP limitation level(s)</TableCell>
                                                        <TableCell>Limit value</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>
                                                            <SimpleCheckbox
                                                                name="perQuery"
                                                                label="Per query"
                                                                isChecked={activeParameters.perQuery}
                                                                onChange={handleParameter}
                                                                disabled={getIsDisabled()}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <ShowParameter
                                                                param="perQuery"
                                                                record={record}
                                                                setRecord={setRecord}
                                                                classes={classes}
                                                                cleanError={cleanError}
                                                                errorsInForm={errorsInForm}
                                                                activeParameters={activeParameters}
                                                                disabled={getIsDisabled()}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            <SimpleCheckbox
                                                                name="perUser"
                                                                label="Per user"
                                                                isChecked={activeParameters.perUser}
                                                                onChange={handleParameter}
                                                                disabled={getIsDisabled()}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <ShowParameter
                                                                param="perUser"
                                                                record={record}
                                                                setRecord={setRecord}
                                                                classes={classes}
                                                                cleanError={cleanError}
                                                                errorsInForm={errorsInForm}
                                                                activeParameters={activeParameters}
                                                                disabled={getIsDisabled()}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            <SimpleCheckbox
                                                                name="perGroup"
                                                                label="Per group of users"
                                                                isChecked={activeParameters.perGroup}
                                                                onChange={handleParameter}
                                                                disabled={getIsDisabled()}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <ShowParameter
                                                                param="perGroup"
                                                                record={record}
                                                                setRecord={setRecord}
                                                                classes={classes}
                                                                cleanError={cleanError}
                                                                errorsInForm={errorsInForm}
                                                                activeParameters={activeParameters}
                                                                disabled={getIsDisabled()}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                            <div>
                                                <FormHelperText error>{errorsInForm.find((e) => e.name.startsWith('params'))?.message}</FormHelperText>
                                            </div>
                                        </Grid>
                                    </>
                                )
                            }
                        </Grid>
                    </Grid>

                    <Grid item xs={8}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <FormControl component="fieldset" variant="standard">
                                    <WithInfo
                                        content={<FormLabel component="legend">Whitelisting</FormLabel>}
                                        info={
                                            <>
                                                On an exception basis and because you consider the risk is acceptable,
                                                you can decide to whitelist some operations or graphs for the data practitioners meaning the data practitioners
                                                will be allowed to directly execute those operations and graphs on the real remote data,
                                                without Differential Privacy. <a href="/docs/sarus_concepts.html#privacy-policy">Learn more</a>.
                                            </>
                                        }
                                    />
                                    <ToggleInput
                                        name="whitelisting"
                                        label={activeWhitelist ? "Yes" : "No"}
                                        onChange={() => {
                                            setActiveWhitelist((v) => !v)
                                        }}
                                        status={activeWhitelist}
                                        disabled={getIsDisabled()}
                                    />
                                </FormControl>
                            </Grid>
                            {
                                activeWhitelist && (
                                    <Grid item xs={12}>
                                        <ConsoleBox
                                            name="whitelistOps"
                                            label={<>Specify the operations you want to whitelist. See full operations <Link href='/docs/sdk_documentation/op_list.html#supported-operations' target={"_blank"}>here</Link>.</>}
                                            update={(txt => setWhitelistContent(txt))}
                                            disable={getIsDisabled()}
                                            error={errorsInForm.find(v => v.name === "whitelist")?.message || ''}
                                            baseValue={(record.whitelisted_transforms || whitelistContent) || defaultWhitelist}
                                            dlFilename={'whitelisted_ops'}
                                        />
                                    </Grid>
                                )
                            }
                        </Grid>
                    </Grid>
                    <ShowAnyErrors />
                    {
                        !isDialog && (
                            <Grid item xs={8}>
                                <div style={{ display: 'flex', gap: 32 }}>
                                    <EditButton />
                                    <CancelButton
                                        action={(e: any) => {
                                            e.preventDefault();
                                            history.push(`/privacy-policies`);
                                        }}
                                    >
                                        Close
                                    </CancelButton>
                                </div>
                            </Grid>
                        )
                    }
                </Grid>
            </form>
        )
    } else {
        return null;
    }
}

export default PrivacyPolicyForm
