import { Box, SxProps } from '@mui/material'
import InfoHelper from 'components/InfoHelper'
import React, { FC, ReactNode } from 'react'

const style: SxProps = {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 2
}

type WithInfoType = {
    content: ReactNode,
    info: ReactNode | string,
    disabled?: boolean,
}

const WithInfo: FC<WithInfoType> = (props) => {
    const {content, info, disabled} = props

    return (
        <Box sx={{...style, color: disabled ? 'rgba(0,0,0,0.38)' : 'inherit'}}>
            {content}
            <InfoHelper text={info} />
        </Box>
    )
}

export default WithInfo