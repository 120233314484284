export type Permission =
    'adminUser.createEditDelete'
    | 'user.listAll'
    | 'user.create'
    | 'user.editDeleteAny'
    | 'user.editDeleteOwn'
    | 'user.fullName.edit'
    | 'user.listOwn'
    | 'user.permission.editAny'
    | 'user.permission.editOwn'
    | 'group.listAll'
    | 'group.listOwn'
    | 'group.create'
    | 'group.editAny'
    | 'group.editOwn'
    | 'dataset.listAll'
    | 'dataset.listOwn'
    | 'dataset.create'
    | 'dataset.createFromFile'
    | 'dataset.editAny'
    | 'dataset.editOwn'
    | 'dataset.use'
    | 'privacyPolicy.createGlobal'
    | 'privacyPolicy.editGlobal'
    | 'privacyPolicy.createLocal'
    | 'privacyPolicy.editOwn'
    | 'sdk.access'
    | 'accessRule.viewOwn'
    | 'connection.create'
    | 'connection.viewAll'
    | 'connection.viewGlobal'
    | 'connection.editAll'

export type Role = { name: string, label: string, permissions: Permission[] };
const admin_role: Role = { name: 'admin', label: 'Admin', permissions: ['adminUser.createEditDelete', 'user.create', 'user.editDeleteAny', 'user.editDeleteOwn', 'user.fullName.edit', 'user.listAll', 'user.listOwn', 'user.permission.editAny', 'user.permission.editOwn', 'group.create', 'group.editAny', 'group.editOwn', 'group.listAll', 'group.listOwn', 'privacyPolicy.createGlobal', 'privacyPolicy.editGlobal', 'privacyPolicy.createLocal', 'privacyPolicy.editOwn', 'dataset.create', 'dataset.editAny', 'dataset.editOwn', 'dataset.listAll', 'dataset.listOwn', 'dataset.use', 'accessRule.viewOwn', 'sdk.access', 'connection.viewAll', 'connection.editAll'] }
const dataPreparator_role: Role = { name: 'dataPreparator', label: 'Data Preparator', permissions: ['user.create', 'user.editDeleteOwn', 'user.fullName.edit', 'user.listAll', 'user.listOwn', 'user.permission.editOwn', 'group.create', 'group.editOwn', 'group.listOwn', 'privacyPolicy.createLocal', 'privacyPolicy.editOwn', 'dataset.create', 'dataset.createFromFile', 'dataset.editOwn', 'dataset.listOwn', 'dataset.use', 'accessRule.viewOwn', 'sdk.access', 'connection.viewGlobal'] }

if (!window.location.href.includes('demo.sarus.tech')) {
  admin_role["permissions"].push('dataset.createFromFile');
  admin_role["permissions"].push('connection.create');
  dataPreparator_role["permissions"].push('dataset.createFromFile');
  dataPreparator_role["permissions"].push('connection.create');
}

export const listRoles: Role[] = [
     admin_role,
    { name: 'userManager', label: 'User Manager', permissions: ['user.create', 'user.editDeleteAny', 'user.editDeleteOwn', 'user.fullName.edit', 'user.listAll', 'user.listOwn', 'user.permission.editAny', 'user.permission.editOwn', 'group.create', 'group.editAny', 'group.editOwn', 'group.listOwn',  'accessRule.viewOwn', 'dataset.listOwn'] },
    dataPreparator_role,
    { name: 'dataPractitioner', label: 'Data Practitioner', permissions: ['user.fullName.edit', 'dataset.listOwn', 'dataset.use', 'accessRule.viewOwn', 'sdk.access'] },
    ]

export const getAllPermissions = (roles: Role[]): Permission[] => {
    const rawPerms = roles.reduce((acc: Permission[], role: Role) => [...acc, ...role.permissions], []);
    const cleanPerms = rawPerms.reduce((acc: Permission[], perm: Permission) => !~acc.indexOf(perm) ? [...acc, perm] : [...acc], []);
    return cleanPerms;
}
