export interface AsyncData<T> {
    done: boolean,
    data?: T
}

export interface AsyncFunction<T> extends Function {
    (): PromiseLike<AsyncData<T>>
}

export async function asyncPoll<T>(
    fn: AsyncFunction<T>,
    pollInterval: number = 5 * 1000,
    pollTimeout: number = 30 * 1000
): Promise<T> {
    const endTime = new Date().getTime() + pollTimeout;
    const checkCondition = (resolve: Function, reject: Function): void => {
        Promise.resolve(fn())
            .then((result) => {
                const now = new Date().getTime();
                if (result.done) {
                    resolve(result.data)
                } else if ((now < endTime) || pollTimeout === 0) {
                    setTimeout(checkCondition, pollInterval, resolve, reject)
                } else {
                    reject(new Error('AsyncPoller: reached timeout'));
                }
            }).catch((err) => {
                reject(err)
            })
    }
    return new Promise(checkCondition)
}