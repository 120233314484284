import React, { useEffect, FC, useState, useContext } from 'react'
import { UserContext } from 'context/authContext'
import { Prompt, useHistory } from 'react-router-dom'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { Group } from 'types/groups'
import apiHandler from 'api/apiHandler'
import { Box, Grid, Typography } from '@mui/material'
import { Title } from 'components/stylized/titles'
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import { DisplayUser } from 'utils/misc'
import { User } from 'types/user'
import { getText } from 'utils/dictionnary'
import usePreventReload from 'hooks/usePreventReload';
import { CustomWidthTooltip } from 'components/FormElements/SarusAutoComplete'
import { isEqual } from 'lodash'

interface IFormProps {
    data?: Group,
}

const initialState: Group = {
    name: '',
    description: '',
    singleton: false,
}


type ErrorInForm = {
    name: string,
    error: string,
}

const GroupForm: FC<IFormProps> = props => {
    const { data } = props;
    const { hasPermissions } = useContext(UserContext);
    const [record, setRecord] = useState<Group>(data ? data : initialState);
    const [isFormDisable, setIsFormDisable] = useState<boolean>(true)
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorsInForm, setErrorsInForm] = useState<ErrorInForm[]>([]);
    const [isModified, setIsModified] = useState<boolean>(false);
    const [isUserModified, setIsUserModified] = useState<boolean>(false);
    const [hasCreated, sethasCreated] = useState<boolean>(false);
    const [userList, setUserList] = useState<User[]>([]);
    const [usersInGroup, setUsersInGroup] = useState<User[]>([]);
    const [initialUsersInGroup, setInitialUsersInGroup] = useState<User[]>([]);
    const [confirmMessage, setConfirmMessage] = useState<string | undefined>();
    const history = useHistory();

    useEffect(() => {
        if (hasPermissions(['user.listAll', 'user.listOwn'])) {
            apiHandler.getAllUsers().then(users => {
                setUserList(users)
                const list = users.filter((user: User) => user.groupId.find((idGroup) => idGroup === data?.id));
                setUsersInGroup(list);
                setInitialUsersInGroup(list);
            });
        }
    }, [hasPermissions, data]);

    useEffect(() => {
        // Check group permission
        if (!data) {
            if (!hasPermissions('group.create'))
                history.push(`/groups`)
        }
        if (data) {
            if (!hasPermissions(['group.editAny', 'group.editOwn']))
                history.push(`/groups`)
        }
    }, [data, hasPermissions, history])

    const handleSubmit = (event: React.SyntheticEvent) => {
        event.preventDefault();
        if (isLoading) return;
        setIsLoading(true);
        const errors: ErrorInForm[] = [];
        if (record.name === '' || !record.name) {
            errors.push({ name: 'name', error: getText('group.nameMissing') })
        }

        const newUsers = usersInGroup.filter(x => !initialUsersInGroup.includes(x))
        const leavingUsers = initialUsersInGroup.filter(x => !usersInGroup.includes(x))

        setErrorsInForm(errors)
        if (errors.length === 0) {
            if (data && data.id) {
                apiHandler.editGroup(data.id, record).then(() => {
                    newUsers.forEach((user) => {
                        let groupId = user.groupId;
                        groupId.push(data.id!);
                        apiHandler.editUser(user.id, { groupId });
                    })
                    leavingUsers.forEach((user) => {
                        let groupId = user.groupId;
                        groupId = groupId.filter((groups) => groups !== data.id!);
                        apiHandler.editUser(user.id, { groupId });
                    })
                }).then(result => {
                    setIsLoading(false);
                    setIsModified(false);
                    setConfirmMessage(getText('group.editSuccess'))
                }).catch(error => console.log(error));
            } else {
                apiHandler.createGroup(record).then((group) => {
                    newUsers.forEach((user) => {
                        let groupId = user.groupId;
                        groupId.push(group.id);
                        apiHandler.editUser(user.id, { groupId });
                    })
                }).then(result => {
                    setIsLoading(false);
                    sethasCreated(true)
                }).catch(error => console.log(error));
            }
        } else {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (hasCreated) {
            history.push('/groups');
        }
    }, [hasCreated])

    useEffect(() => {
        if (data && data.id) {
            if (record.name === data.name && record.description === data.description && initialUsersInGroup === usersInGroup) {
                setIsFormDisable(true)
            } else {
                setIsFormDisable(false)
            }
        } else {
            if (record.name === '') {
                setIsFormDisable(true)
            } else {
                setIsFormDisable(false)
            }
        }
    }, [data, record, initialUsersInGroup, usersInGroup])

    const handleChange = (event: React.ChangeEvent<{ name: string, value: unknown, checked?: boolean, type: string }>) => {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        const newRecord = { ...record, [event.target.name]: value }
        setRecord(newRecord);
        setIsModified(true);
    }

    const cleanError = (field: string | string[]) => {
        if (Array.isArray(field)) {
            console.log("handle fields");
            return;
        }

        if (errorsInForm.find((error: ErrorInForm) => error.name === field)) {
            const filteredErrors = errorsInForm.filter((error: ErrorInForm) => error.name !== field);
            setErrorsInForm(filteredErrors);
        }
    }

    const EditButton: FC<{isEdited: boolean}> = ({isEdited}) => {
        let params: any = { disabled: !isEdited };
        if (hasPermissions(['group.editAny', 'group.editOwn']))
            params.type = 'submit';
        else
            params.disabled = true;

        if (!hasPermissions(['group.editAny', 'group.editOwn']) && data) {
            return (
                <Tooltip title={getText('feedback.perm.editGroup')} arrow>
                    <span>
                        <Button
                            variant="contained"
                            color="primary"
                            {...params}
                        >
                            {data ? getText('btn.save') : getText('btn.group.create')}
                        </Button>
                    </span>
                </Tooltip>
            )
        } else {
            return (
                <Button
                    variant="contained"
                    color="primary"
                    {...params}
                >
                    {data ? getText('btn.save') : getText('btn.group.create')}
                </Button>
            )
        }
    };


    const addUsers = (
        <Autocomplete
            multiple
            id="users-list-in-group"
            value={usersInGroup}
            onChange={(event, newValue) => {
                setUsersInGroup(newValue);
                if (isEqual(newValue, initialUsersInGroup)) {
                    setIsUserModified(false)
                }
                else {
                    setIsUserModified(true)
                }
            }}
            options={userList}
            getOptionLabel={(option) => DisplayUser(option)}
            renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => (
                <CustomWidthTooltip key={option.email} title={option.email} arrow disableInteractive placement="bottom-start">
                    <Chip label={option.email} {...getTagProps({ index })} />
                </CustomWidthTooltip>
            ))}
            renderInput={(params) => (
                <TextField {...params} label="Users" variant="standard" />
            )}
            renderOption={(props, option, { selected }) => (
                <li {...props} key={option.id} style={{paddingLeft: 8}}>
                    <CustomWidthTooltip title={DisplayUser(option)} disableInteractive arrow placement='bottom-start'>
                        <Box component="div" sx={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden'
                        }}>
                            {DisplayUser(option)}
                        </Box>
                    </CustomWidthTooltip>
                </li>
            )}
            style={{width: 300}}
        />
    );

    const isFormEdited = isModified || isUserModified
    usePreventReload(!hasCreated && isFormEdited)

    return (
        <form onSubmit={handleSubmit}>
            <Prompt when={!hasCreated && isFormEdited} message={JSON.stringify({cancel: 'Keep Editing'})} /> 
            <Title variant="h2" comp="h1">{data ? getText('title.group.edit') : getText('title.group.create')}</Title>
            <Grid container spacing={4}>
                <Grid item xs={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div style={{ width: 300, height: 50 }}>
                                <TextField
                                    autoFocus
                                    id="name"
                                    name="name"
                                    label="Name"
                                    onChange={(e) => {
                                        handleChange(e);
                                        cleanError('name');
                                    }}
                                    value={record.name}
                                    error={!!errorsInForm.find(x => x.name === 'name')}
                                    helperText={errorsInForm.find(x => x.name === 'name')?.error}
                                    style={{width: 300}}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="description"
                                name="description"
                                label="Description"
                                onChange={handleChange}
                                value={record.description}
                                style={{width: 300}}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {addUsers}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <div style={{ display: 'flex', gap: 32 }}>
                        <EditButton isEdited={!hasCreated && isFormEdited} />
                        <Button
                            onClick={(e) => {
                                history.push(`/groups`)
                            }}
                        >
                            Close
                        </Button>
                    </div>
                    {
                        confirmMessage && <Typography variant="body1" style={{ marginTop: 8 }}>{confirmMessage}</Typography>
                    }
                </Grid>
            </Grid>
        </form>
    )
}

export default GroupForm
