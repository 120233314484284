import { SCHEMA_CHANGE, SCHEMA_CHECK, SCHEMA_INITIALIZE, SCHEMA_UNLOAD } from "store/actions/SchemaActionsType";
import { SchemaDataType } from "types/datasets";

export type SchemaState = "WAITING" | "WORKING" | "FINISHED" | "ERROR"
export type SchemaCheckingState = "WAITING" | "PENDING" | "SUCCESS" | "ERROR" | "FAILURE"

export interface DefaultSchemaState {
    currentState: SchemaState,
    checkingState: SchemaCheckingState,
    errorInSchema?: string,
    features?: SchemaDataType
}

const defaultState: DefaultSchemaState = {
    currentState: "WAITING",
    checkingState: "WAITING",
}

const schemaReducer = (state: DefaultSchemaState = defaultState, action: any): DefaultSchemaState => {
    switch (action.type) {
        case SCHEMA_INITIALIZE:
        case SCHEMA_UNLOAD:
            return {
                currentState: "WAITING",
                checkingState: "WAITING"
            }
        case SCHEMA_CHANGE:
            if (!action.payload.schema) {
                return {
                    ...state,
                    currentState: action.payload.state,
                }
            }
            return {
                ...state,
                currentState: action.payload.state,
                features: action.payload.schema
            }
        case SCHEMA_CHECK:
            return {
                ...state,
                checkingState: action.payload.status,
                errorInSchema: action.payload.error
            }
        default:
            return state
    }
}

export default schemaReducer;