import React, { useRef, useEffect, useState, ReactNode } from 'react'
import { Box, styled, Theme } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'

interface Props {
    tooltip: string,
    text?: string | ReactNode,
    icon?: ReactNode,
    isMultiline?: boolean
}

type CustomTooltipProps = TooltipProps & {
    hasIcon?: boolean
}

const CustomTooltip = styled(({ className, hasIcon, ...props }: CustomTooltipProps) => (
    <Tooltip {...props} classes={{popper: `${className} ${hasIcon && 'customized'}`}} />
))({
    [`&.customized .${tooltipClasses.tooltip}`]: {
        marginLeft: 32
    }
})

const OverflowTooltip = (props: Props) => {
    const { tooltip: tooltipText, text, icon, isMultiline } = props;
    const [hoverStatus, setHoverStatus] = useState(false)
    const textElementRef = useRef<null | HTMLDivElement>(null)
    const [ready, setReady] = useState(false)

    const compareSize = () => {
        if (!textElementRef.current) return;
        const compare = textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
        setHoverStatus(compare)
    }

    useEffect(() => {
        compareSize()
        window.addEventListener('resize', compareSize) 
        return () => {
            window.removeEventListener('resize', compareSize)
        }
    }, [])

    useEffect(() => {
        if (ready) return
        document.fonts.ready.then(() => {
            setReady(true)
        })
    }, [])

    const DisplayIcon = () => {
        if (!icon) return <></>
        return (
            React.cloneElement(icon as React.ReactElement<any>, {
                style: {marginRight: 8}
            })
        )
    }

    return (
        <CustomTooltip
            title={tooltipText}
            disableHoverListener={!hoverStatus}
            disableInteractive
            placement="bottom-start"
            followCursor={false}
            hasIcon={!!icon}
            arrow
        >
            <Box component="div"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flex: 1,
                    minWidth: 0,
                }}
            >
                <DisplayIcon />
                <Box
                    component="div"
                    ref={textElementRef}
                    sx={{
                        flex: 1,
                        minWidth: {
                            Webkit: "-webkit-fill-available",
                            Moz: "-moz-available",
                            default: "fill-available",
                        },
                        width: '10px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {tooltipText}
                </Box>
            </Box>
        </CustomTooltip>
    )
}

export default OverflowTooltip