import { FormControl, FormHelperText, IconButton, Input, InputAdornment, InputLabel } from '@mui/material'
import React, { ChangeEvent, FC, useState } from 'react'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

type InputProps = {
    label: string | JSX.Element,
    name: string,
    error?: string,
    value?: string,
    onChange: (event: ChangeEvent<HTMLInputElement>) => void
    disabled?: boolean,
    shrink?: boolean,
    fullWidth?: boolean,
    autoFocus?: boolean,
}

const PasswordInput: FC<InputProps> = (props) => {
    const { label, name, error, disabled, onChange, value, shrink, fullWidth, autoFocus } = props
    const [show, setShow] = useState<boolean>(false)

    const handleClickShowPassword = () => {
        setShow(!show);
    }

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <FormControl error={!!error} variant="standard" fullWidth={fullWidth}>
            <InputLabel htmlFor={`${name}-field`} shrink={shrink}>{label}</InputLabel>
            <Input
                inputProps={{ type: show ? 'text' : 'password' }}
                id={`${name}-field`}
                name={name}
                onChange={onChange}
                value={value || ''}
                disabled={disabled}
                error={!!error}
                autoFocus={autoFocus}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            tabIndex={-1}
                            aria-label="toggle password"
                            disabled={disabled}
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            size="large">
                            {show ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
                style={fullWidth ? undefined : { width: 280 }}
                fullWidth={fullWidth}
            />
            <FormHelperText error>{error}</FormHelperText>
        </FormControl>
    );
}

export default PasswordInput
