import config from 'config/global'

const dictionnary = {

    // -------------------------------------------
    // ++           Error messages              ++
    // -------------------------------------------
    'login.badCredentials': `Can't connect. Invalid credentials`,

    'dc.invalidPath': `Can't access file. Invalid bucket/path or insufficient permission`,
    'dc.invalidPermission': `Can't access file. Invalid bucket/path or insufficient permission`,
    'dc.missingField': `Field is required`,
    'dc.notCreated': `The data connection has not been created yet.`,

    'dataset.missingName': `Please enter a name for the dataset`,
    'dataset.nameAlreadyTaken': `This name is already taken`,
    'dataset.missingFile': `Please upload a CSV file`,
    'dataset.source.badFormat': `Only CSV files are supported`,
    'dataset.source.badEncoding': `Data should be encoded as UTF-8`,
    'dataset.source.tooMuchColumns': `Data should be less than ${config.dataset.maxColumn} columns`,
    'dataset.source.tooFewRows': `Data should be more than ${config.dataset.minRow} rows`,
    'dataset.source.invalidDelimiters': `Supported delimiters for CSV files are ',' or ';'`,

    'user.missingRole': `Please select at least one role`,
    'user.alreadyExists': `This user already has a Sarus account`,
    'user.email.notValid': `Please enter a valid email`,
    'user.requireOneRole': `Please select at least one role`,
    'user.password.emptyCurrent': `Current password is empty`,
    'user.password.notMatch': `Passwords do not match`,

    'pp.missingName': `Please enter a name for the privacy policy`,
    'pp.nameAlreadyTaken': `This name is already taken`,

    'api.dc.error500': `Oops! Something went wrong with this connection. Please contact your Sarus representative`,


    // -------------------------------------------
    // ++            Titles pages               ++
    // -------------------------------------------
    'title.create.dataset': 'Add new Dataset',
    'title.create.dc': 'Create new Data Connection',
    'title.create.pp': 'Create new Privacy Policy',
    'title.create.user': 'Add new User',
    'title.create.group': 'Create new Group',
    'title.profile': `My profile`,
    'title.user': `User detail`,
    'title.serviceHealth': `Service Health & Logs`,
    'title.group.create': `Create new Group`,
    'title.group.edit': `Group detail`,


    // -------------------------------------------
    // ++         Feedback messages             ++
    // -------------------------------------------
    'dc.testing': 'Testing data connection...',
    'dc.testSuccess': 'Data connection test succeeded!',
    'dc.edited': 'Data connection edited successfully!',
    'dc.creating': "Creating data connection...",
    'dc.created': 'Data connection created successfully!',
    'dc.noTableInSearch': `No tables match your search`,
    'user.action.invited': `User invitation has been created`,
    'user.loadingData': `Loading user data...`,
    'user.notFound': `User not found.`,
    'user.notFoundMore': `It might have been removed or you don't have access.`,

    'user.needPerm': `It looks like there is no user or you don't have sufficient permission to see the list.`,
    'user.invitedUser': `Invited User`,

    'group.editSuccess': `Group edited successfully!`,
    'group.nameMissing': `Please enter a name for the group`,
    'group.leaveMessageCreation': `The group has not been created yet.`,

    'feedback.perm.editUser': `You don't have sufficient permissions to edit users`,
    'feedback.perm.editGroup': `You don't have sufficient permissions to edit groups`,


    'dialog.title.leave': `Discard unsaved changes?`,
    'dialog.btn.confirm': `Discard`,
    'dialog.create.btn.cancel': `Stay`,
    'dialog.create.message': `The user has not been invited to join Sarus yet.`,
    'dialog.edit.btn.cancel': `Keep editing`,


    // -------------------------------------------
    // ++               Buttons                 ++
    // -------------------------------------------
    'btn.save': 'save changes',
    'btn.group.create': 'create group',
}

export const getText = (code: keyof typeof dictionnary): string => {
    return dictionnary[code]
}
