import React, { FC, useContext, useEffect, useState } from 'react'
import { User } from 'types/user'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormHelperText,
    Grid,
    Input,
    Theme,
    Typography,
    Zoom,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import InputPassword from './InputPassword';
import { UserContext } from 'context/authContext'
import apiHandler from 'api/apiHandler';
import FormControl from '@mui/material/FormControl';
import { useHistory } from 'react-router';
import { Title } from 'components/stylized/titles';
import { ErrorInForm } from 'types/forms';
import { Group } from 'types/groups';
import Loading from 'components/utils/Loading';
import { listRoles, Role } from 'config/roles';
import Tooltip from '@mui/material/Tooltip';
import { validateEmail } from 'utils/misc';
import { CreateUser } from 'types/user'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useLocation } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch } from 'react-redux';
import { AppSetResource, AppUnsetResource } from 'store/actions/AppActions';
import { getText } from 'utils/dictionnary';
import copy from 'copy-to-clipboard';

const useStyles = makeStyles((theme: Theme) => ({
    errorMessage: {
        background: theme.palette.error.light,
        color: theme.palette.error.dark,
        width: '80%',
        margin: '0 auto',
        padding: '0.5em',
        textAlign: 'center',
        display: 'flex',
        gap: '0.3em',
        alignItems: 'center',
        justifyContent: 'center',
        '& .icon': {
            color: theme.palette.error.main,
            fontSize: 16,
        }
    },
    buttonsGroup: {
        display: 'flex',
        gap: 32
    }
}));

type EditUser = {
    userId?: number | 'profile' | 'create',
}

interface IUserToEdit {
    id: string | number,
    username?: string,
    email?: string,
    roles: string[],
    groupId: number[],
    groups: string | string[],
    token?: string,
    new_password?: string,
    passwordConfirm?: string,
    current_password?: string,
}

const initialState: IUserToEdit = {
    id: -1,
    current_password: '',
    new_password: '',
    passwordConfirm: '',
    groupId: [],
    roles: [],
    groups: [],
}

const initialCreateState: CreateUser = {
    email: '',
    roles: ['dataPractitioner'],
    groupId: [],
}

type FormState = "initiating" | "edit" | "create" | "created" | "profile";

const UserForm: FC<EditUser> = props => {
    const { userId } = props;
    const classes = useStyles();
    const { user, hasPermissions, refreshPermissions } = useContext(UserContext);
    const location = useLocation();
    const [userState, setUserState] = useState<FormState>("initiating");
    const [token, setToken] = useState<string>();
    const [record, setRecord] = useState<IUserToEdit | CreateUser>(initialState);
    const [errorsInForm, setErrorsInForm] = useState<ErrorInForm[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [initiated, setInitiated] = useState<boolean>(false);
    const [groupList, setGroupList] = useState<Group[]>([]);
    const [confirmMessage, setConfirmMessage] = useState<string | undefined>(undefined);
    const [cancelState, setCancelState] = useState<boolean>(false);
    const [isModified, setIsModified] = useState<boolean>(false);
    const [isDataNotFound, setIsDataNotFound] = useState<boolean>(false);
    const [disableSave, setDisableSave] = useState<boolean>(true);
    const [copied, setCopied] = useState<boolean>(false)
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if (userId == null) return;
        let isSubscribed = true;
        const page = location.pathname.split('/');
        if (page[1] === 'users') {
            if (page[2] === 'create') {
                setUserState('create')
                apiHandler.getAllGroups().then(groups => {
                    if (isSubscribed) {
                        setRecord(initialCreateState)
                        setGroupList(groups)
                    }
                }).catch(() => {
                    if (isSubscribed) setIsDataNotFound(true);
                }).finally(() => {
                    if (isSubscribed) setIsLoading(false);
                })
            } else {
                setUserState('edit')
                Promise.all([
                    userId && apiHandler.getUser(userId as number),
                    apiHandler.getAllGroups()
                ]).then(results => {
                    if (isSubscribed) {
                        const userFetched = results[0];
                        const groupFetched = results[1];
                        dispatch(AppSetResource(userFetched.email))
                        if (userFetched != null) {
                            setRecord(userFetched)
                        }
                        setGroupList(groupFetched)
                    }
                }).catch(() => {
                    if (isSubscribed) setIsDataNotFound(true);
                }).finally(() => {
                    if (isSubscribed) setIsLoading(false);
                })
            }
        } else if(userId === 'profile') {
            setUserState('profile')
            const profile = { ...initialState, ...user };
            setRecord(profile);
            setIsLoading(false);
        }

        return () => {
            isSubscribed = false;
            dispatch(AppUnsetResource())
        }
    }, [location.pathname, user, userId, dispatch])

    useEffect(() => {
        // Check group permission
        if (!isLoading) {
            if (userState !== 'profile') {
                if (!hasPermissions(['user.listAll', 'user.listOwn']))
                    history.push(`/users`)
                if (!userId && !hasPermissions('user.create'))
                    history.push(`/users`)
            }
        }
    }, [userState, userId, isLoading, history, hasPermissions])

    useEffect(() => {
        if (!initiated) {
            setInitiated(true);
        }
    }, [userState, initiated])

    const handleSubmit = (event: React.SyntheticEvent) => {
        event.preventDefault();
        // setIsLoading(true);

        let errors = [];

        if (!record.roles.length) {
            errors.push({ name: 'roles', message: getText('user.requireOneRole') });
        }
        if (userState === "create") {
            if (!validateEmail((record as CreateUser).email)) {
                errors.push({ name: 'email', message: getText('user.email.notValid') })
            }
        } else {
            if ((record as IUserToEdit).current_password === '' && ((record as IUserToEdit).new_password !== '' || (record as IUserToEdit).passwordConfirm !== ''))
                errors.push({ name: 'current_password', message: getText('user.password.emptyCurrent') })
            if ((record as IUserToEdit).new_password !== (record as IUserToEdit).passwordConfirm) {
                errors.push({ name: 'new_password', message: getText('user.password.notMatch') })
                errors.push({ name: 'passwordConfirm', message: getText('user.password.notMatch') })
            }
        }

        setErrorsInForm(errors)
        if (errors.length) {
            // setIsLoading(false);
        } else {
            if (userState === "create") {
                apiHandler.createUser((record as CreateUser)).then((result) => {
                    setUserState("created");
                    setToken(result.token);
                    setIsLoading(false)
                }).catch((error) => {
                    if (error.response.data.message === "Email or username already used.") {
                        setErrorsInForm([{ name: 'email', message: getText('user.alreadyExists') }]);
                    } else {
                        setErrorsInForm([{ name: 'generic', message: error.response.data.message }]);
                    }
                    // setIsLoading(false);
                })
            } else {
                let userParsed: Partial<IUserToEdit> = { roles: record.roles, groupId: record.groupId };
                if ((record as IUserToEdit).new_password !== '' && (record as IUserToEdit).new_password != null) {
                    userParsed = { ...userParsed, new_password: (record as IUserToEdit).new_password, current_password: (record as IUserToEdit).current_password }
                }
                delete userParsed.groups;
                apiHandler.editUser(parseInt((record as IUserToEdit).id as string), userParsed as Partial<User>).then((result) => {
                    setIsLoading(false);
                    if (userState !== 'profile') {
                        // setEditMode(false);
                    }
                    else
                        setDisableSave(true)
                    setRecord({ ...record, current_password: '', new_password: '', passwordConfirm: '' });
                    setIsModified(false)
                    refreshPermissions();
                    setConfirmMessage(`${userState === 'profile' ? 'Profile' : 'User'} edited successfully!`);
                }).catch(error => {
                    if (error.response.status === 403) {
                        if (error.response.data === "Missing permission") {
                            console.log("Error in permissions")
                        }
                        // setErrorsInForm([{name: ''}])
                    } else if(error.response.status === 400) {
                        const errors: ErrorInForm[] = [];
                        for (const [key, value] of Object.entries(error.response.data)) {
                            errors.push({name: key, message: value as string})
                        }
                        setErrorsInForm(errors)
                    }
                });
            }
        }
    }

    const cleanError = (field: string | string[]) => {
        if (Array.isArray(field)) {
            console.log("handle fields");
            return;
        }

        if (errorsInForm.find((error: ErrorInForm) => error.name === field)) {
            const filteredErrors = errorsInForm.filter((error: ErrorInForm) => error.name !== field);
            setErrorsInForm(filteredErrors);
        }
    }

    const handleChange = (event: React.ChangeEvent<{ name: string, value: unknown }>): void => {
        setRecord({ ...record, [event.target.name]: event.target.value });
        if ((record as IUserToEdit).new_password && (record as IUserToEdit).passwordConfirm && (record as IUserToEdit).current_password) {
            setDisableSave(false);
        } else {
            setDisableSave(true);
        }
        cleanError(event.target.name);
        setIsModified(true);
    };

    const editEmail = (
        <Grid item xs={8}>
            <TextField
                name="email"
                id="field-email"
                value={record.email}
                label="Email"
                variant="standard"
                disabled={userState !== 'create'}
                style={{width: 300, height: 50 }}
                error={!!errorsInForm.find((error: ErrorInForm) => error.name === "email")}
                helperText={errorsInForm.find((error: ErrorInForm) => error.name === "email")?.message}
                onChange={handleChange}
            />
        </Grid>
    )

    const editUsername = (
        <Grid item xs={8}>
            <TextField
                name="username"
                id="field-username"
                value={(record as IUserToEdit).username}
                onChange={handleChange}
                variant="standard"
                label="Full name"
                disabled={userState !== 'profile' || true}
                style={{width: 300, height: 50}}
            />
        </Grid>
    )

    const editRoles = (
        <Grid item xs={8}>
            <Autocomplete
                multiple
                id="roles"
                value={listRoles.filter((role: Role) => record.roles.includes(role.name))}
                onChange={(event, newValue) => {                
                    setRecord({...record, roles: newValue.map((r) => r.name) as string[] });
                    setIsModified(true);
                }}
                options={listRoles.filter(r => {
                    if (r.name === 'admin') {
                        return hasPermissions('adminUser.createEditDelete')
                    } else {
                        return r
                    }
                })}
                getOptionLabel={(option) => option.label}
                renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => (
                    <Chip label={option.label} {...getTagProps({index})} />
                ))}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        label="Role(s)"
                        error={!!errorsInForm.find((error: ErrorInForm) => error.name === "roles")}
                        helperText={errorsInForm.find((error: ErrorInForm) => error.name === "roles")?.message}
                    />
                )}
                disabled={(!hasPermissions(['user.editDeleteAny', 'user.editDeleteOwn']) && userState !== 'create') || userState === 'profile'}
                style={{width: 300}}
            />
        </Grid>
    )

    const editGroups = (
        <Grid item xs={8}>
            <Autocomplete
                multiple
                id="groups"
                value={groupList.filter((groups: Group) => record.groupId.includes(groups.id as number) && !groups.singleton)}
                onChange={(event, newValue) => {                
                    setRecord({...record, groupId: newValue.map((g) => g.id) as number[] });
                    setIsModified(true);
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={groupList.filter((g: Group) => !g.singleton)}
                getOptionLabel={(option) => option.name}
                renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => (
                    <Chip label={option.name} {...getTagProps({index})} />
                ))}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        label="Group(s) (optional)"
                        error={!!errorsInForm.find((error: ErrorInForm) => error.name === "groups")}
                        helperText={errorsInForm.find((error: ErrorInForm) => error.name === "groups")?.message}
                    />
                )}
                disabled={(!hasPermissions(['user.editDeleteAny', 'user.editDeleteOwn']) && userState !== 'create') || userState === 'profile'}
                style={{width: 300}}
            />
        </Grid>
    )

    const editPassword = (
        <Grid item xs={8}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <InputPassword
                        label="Current password"
                        name="current_password"
                        id="current-pass"
                        value={(record as IUserToEdit).current_password}
                        handleChange={handleChange}
                        error={errorsInForm.find(x => x.name === 'current_password')?.message}
                        width={300}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputPassword
                        label="New password"
                        name="new_password"
                        id="new-pass"
                        value={(record as IUserToEdit).new_password}
                        handleChange={handleChange}
                        error={errorsInForm.find(x => x.name === 'new_password')?.message}
                        width={300}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputPassword
                        label="Confirm new password"
                        name="passwordConfirm"
                        id="confirm-pass"
                        value={(record as IUserToEdit).passwordConfirm}
                        handleChange={handleChange}
                        error={errorsInForm.find(x => x.name === 'passwordConfirm')?.message}
                        width={300}
                    />
                </Grid>
            </Grid>
        </Grid>
    )

    const DialogCancel: FC = () => {
        let message: { [key: string]: string } = { message: '', title: '', confirm: '' };
        if (userState === 'edit') {
            message.title = getText('dialog.title.leave');
            message.confirm = getText('dialog.btn.confirm')
            message.confirmLink = `/users`;
            message.cancel = getText('dialog.edit.btn.cancel');
        }
        else if (userState === 'create') {
            message.title = getText('dialog.title.leave');
            message.message = getText('dialog.create.message');
            message.confirm = getText('dialog.btn.confirm')
            message.confirmLink = `/users`;
            message.cancel = getText('dialog.create.btn.cancel');
        }
        else {
            message.title = getText('dialog.title.leave');
            message.confirm = `Cancel edition`
            message.confirmLink = `/users`;
            message.cancel = getText('dialog.edit.btn.cancel');
        }

        return (
            <Dialog
                open={cancelState}
                onClose={() => setCancelState(false)}
            >
                <DialogTitle>{message.title}</DialogTitle>
                {message.message && (
                    <DialogContent>
                        <DialogContentText>
                            {message.message}
                        </DialogContentText>
                    </DialogContent>
                )}
                <DialogActions>
                    <Button onClick={() => setCancelState(false)}>
                        {message.cancel}
                    </Button>
                    <Button onClick={() => history.push(message.confirmLink)} color="secondary">
                        {message.confirm}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    const EditButton = () => {
        let params: any = {};
        params = { type: 'submit' };
        if (userState === 'profile') {
            params.disabled = disableSave;
        } else {
            params.disabled = !isModified
        }
        
        if (!hasPermissions(['user.editDeleteAny']) && userState !== 'create' && window.location.pathname !== "/profile") {
            params = { disabled: true };
        }

        const formattedButton = (
            <Button
                variant="contained"
                color="primary"
                {...params}
            >
                {(userState === 'edit' || userState === 'profile') ? 'Save changes' : 'Add User'}
            </Button>
        )

        if (userState === 'edit' || userState === 'profile') {
            if(!hasPermissions(['user.editDeleteAny']) && userState !== 'profile') {
                return (
                    <Tooltip title={getText('feedback.perm.editUser')} arrow>
                        <span>
                            {formattedButton}
                        </span>
                    </Tooltip>
                )
            } else {
                return formattedButton;
            }
        } else {
            return formattedButton
        }
    };

    useEffect(() => {
        const timer = copied ? setTimeout(() => setCopied(false), 2000) : null
        return () => {
            timer && clearTimeout(timer)
        }
    }, [copied, setCopied])

    const copyToClipboard = async (text: string) => {
        setCopied(true)
        copy(text)
    }

    const CopyToken: FC<{ tokenStr: string }> = ({tokenStr}) => {
        const urlWithToken = `${window.location.origin}/signup?token=${tokenStr}`;
        return (
            <FormControl style={{ width: 592 }} variant="standard">
                <Input
                    value={urlWithToken}
                    style={{ width: 592 }}
                    disabled
                    endAdornment={
                        <InputAdornment position="end">
                            <Tooltip
                                title={copied ? "Copied!" : "Copy link"}
                                placement="right"
                                TransitionComponent={Zoom}
                                arrow
                            >
                                <IconButton
                                    onClick={() => {
                                        copyToClipboard(urlWithToken)
                                    }}
                                    size="large">
                                    <LibraryBooksIcon />
                                </IconButton>
                            </Tooltip>
                        </InputAdornment>
                    }
                />
                {
                    userState === 'edit' && (
                        <FormHelperText>Please share this invitation link with the user so that they finalize their account creation.</FormHelperText>
                    )
                }
            </FormControl>
        );
    }

    const CloseButton = () => {
        if (userState === 'profile') return null
        return (
            <Button onClick={e => {
                e.preventDefault()
                history.push('/users')
            }}>
                Close
            </Button>
        )
    }

    const ShowFeedbacks = () => {
        if (!confirmMessage) return null
        return (
            <Grid item xs={12}>
                <Typography variant="body1">{confirmMessage}</Typography>   
            </Grid>
        )
    }

    if (isLoading) {
        return (
            <Loading label={getText('user.loadingData')} />
        )
    } else if (isDataNotFound) {
        return (
            <Loading label={getText('user.notFound')} secondLabel={getText('user.notFoundMore')} noProgress />
        )
    } else if (userState === "created") {
        return (
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Title variant="h2" comp="h1">{getText('title.create.user')}</Title>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h4">{getText('user.action.invited')}</Typography>
                    <Typography variant="caption" component="p" color="textSecondary">Please share the below invitation link with the user so that they finalize their account creation.</Typography>
                </Grid>
                <Grid item xs={6}>
                    <CopyToken tokenStr={token!} />
                </Grid>
                <Grid item xs={12}>
                    <div style={{ display: 'flex', gap: 32 }}>
                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                history.push('/users');
                            }}>
                            Close
                        </Button>
                    </div>
                </Grid>
            </Grid>
        )
    } else {
        return (
            <form onSubmit={handleSubmit}>
                <DialogCancel />
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Title variant="h2" comp="h1">{userState === "create" ? getText('title.create.user') : userState === 'profile' ? getText('title.profile') : getText('title.user')}</Title>
                    </Grid>
                    {errorsInForm.find((error: ErrorInForm) => error.name === 'generic') && (
                            <Grid item xs={12}>
                                <Typography variant="body1" className={classes.errorMessage}><ErrorOutlineIcon className="icon" /> {errorsInForm.find((error: ErrorInForm) => error.name === 'generic')?.message}</Typography>
                            </Grid>
                        )
                    }
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            {editEmail}
                            { (userState === "edit" || userState === 'profile') && editUsername }
                            { userState !== 'profile' && editRoles}
                            { userState !== 'profile' && editGroups}
                            {userState === 'profile' && editPassword}
                        </Grid>
                    </Grid>
                    {
                        (hasPermissions('user.create') && (record as IUserToEdit).token && userState !== 'profile') && (
                            <Grid item xs={8}>
                                <CopyToken tokenStr={(record as IUserToEdit).token!} />
                            </Grid>
                        )
                    }
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} className={classes.buttonsGroup}>
                                <EditButton />
                                <CloseButton />
                            </Grid>
                        </Grid>
                        <ShowFeedbacks />
                    </Grid>
                </Grid>
            </form>
        )
    }
}

export default UserForm
