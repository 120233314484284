import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import React, { FC } from 'react'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

interface ErrorDisplayProps {
    message?: string
}

const useStyles = makeStyles(({palette, spacing}: Theme) => createStyles({
    root: {
        color: palette.error.main,
        marginTop: 150,
        textAlign: 'center',
        fontSize: 18,
    },
    icon: { 
        fontSize: 32,
        marginBottom: spacing(2)
    }, 
    text: {
        '& span': {
            fontSize: 14,
            fontStyle: 'italic'
        }
    }
}))

const ErrorDisplay: FC<ErrorDisplayProps> = (props) => {
    const { message } = props
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <div className={classes.icon}><ErrorOutlineIcon fontSize="inherit" /></div>
            <div className={classes.text}>
                Schema could not be detected.<br />
                <span>{message}</span>
            </div>
        </div>
    )
}

export default ErrorDisplay