import React, { FC, ReactNode } from 'react'
import { Theme } from '@mui/material/styles';


import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 40,
            display: 'block'
        }
    }
    ));

type DescProps = { children?: ReactNode }
export const DescList: FC<DescProps> = ({ children }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            {children}
        </div>
    )
}
