import apiHandler from 'api/apiHandler';
import { Dispatch } from 'redux';
import { AppState } from 'store/configureStore';
import { MonitorProps } from 'types/datasets';
import { AsyncData, asyncPoll } from 'utils/async';
import { MonitorDispatchTypes, MONITOR_ADD_TO_MANAGER, MONITOR_FINISHED, MONITOR_SET_STATE, MONITOR_UPDATE} from './MonitorActionsType'

const formatPrepare = (result: any): MonitorProps => {
    const resultMonitor: MonitorProps = {
        prepare_parquet: {
            progress: result.prepare_parquet.status === 'SUCCESS' ? 1 : 0,
            status: result.prepare_parquet.status,
            error: result.prepare_parquet.info || ''
        },
        marginals: {
            progress: result.marginals.status === 'SUCCESS' ? 1 : 0,
            status: result.marginals.status,
            error: result.marginals.info || ''
        },
        synthetic_data: {
            progress: result.synthetic_data === 'PROGRESS' ? ((result.synthetic_data.info.current - 1) / result.synthetic_data.info.total) : result.synthetic_data.status === 'SUCCESS' ? 1 : 0,
            status: result.synthetic_data.status,
            error: result.synthetic_data.status === 'FAILURE' ? result.synthetic_data.info : ''
        },
        generate_sql: result.generate_sql ? {
            progress: result.generate_sql.status === 'SUCCESS' ? 1 : 0,
            status: result.generate_sql.status,
            error: result.generate_sql.info || ''
        } : null
    }
    return resultMonitor
} 

export const AddMonitorToManager = (id: number, status: string) => async (dispatch: Dispatch<MonitorDispatchTypes>, getState: () => AppState) => {
    const actualList = getState().monitor;
    let doAddToList: boolean = !actualList[id]

    if (status === 'progress') {
        
    }
    return dispatch ({ type: MONITOR_ADD_TO_MANAGER, payload: {id, status, confirmAddition: doAddToList}})
}

export const FinishMonitor = (id: number) => async (dispatch: Dispatch<MonitorDispatchTypes>) => {
    return dispatch({ type: MONITOR_FINISHED, payload: { id } });
}

export const StartMonitoring = (id: number) => async (dispatch: Dispatch<MonitorDispatchTypes>, getState: () => AppState) => {
    const { checkDatasetPreparation } = apiHandler;
    dispatch({ type: MONITOR_SET_STATE, payload: { id, state: 'progress' } });

    asyncPoll(
        async (): Promise<AsyncData<any>> => {
        try {
            const result = await checkDatasetPreparation(id);
            if (result.prepare_parquet) {
                const resultMonitor = formatPrepare(result)
                dispatch({ type: MONITOR_UPDATE, payload: { id, details: resultMonitor } });

                let isWorking = true;
                if (result.prepare_parquet.status === 'FAILURE'
                    || result.marginals.status === 'FAILURE'
                    || result.synthetic_data.status === 'FAILURE'
                    || result.generate_sql?.status === 'FAILURE'
                    || (result.prepare_parquet.status === 'SUCCESS'
                        && result.marginals.status === 'SUCCESS'
                        && result.synthetic_data.status === 'SUCCESS'
                        && (!result.generate_sql || result.generate_sql.status === 'SUCCESS')
                    )) {
                    isWorking = false;
                }

                if (!isWorking) {
                    dispatch({ type: MONITOR_FINISHED, payload: { id } });
                    return Promise.resolve({ done: true, data: resultMonitor })
                } else {
                    return Promise.resolve({ done: false })
                }
            } else {
                return Promise.reject()
            } 
        } catch (err) {
            return Promise.reject(err)
        }
    },
    (5 * 1000), 0)
}