import { FormHelperText, InputLabel } from '@mui/material'
import React, { FC, ReactNode } from 'react'

type TextLabelType = {
    msg: string | ReactNode,
    htmlFor?: string,
    isTextHelper?: boolean,
}

const TextLabel: FC<TextLabelType> = ({ msg, htmlFor, isTextHelper }) => {
    if (isTextHelper) {
        return (
            <FormHelperText sx={{mb: 0.5, fontSize: '10.5px'}}>{msg}</FormHelperText>
        )
    } else {
        return (
            <InputLabel htmlFor={htmlFor} sx={{mb: 1}}>{msg}</InputLabel>
        )
    }
}

export default TextLabel