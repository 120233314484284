import { FC, ReactNode } from 'react'
import { Paper, Theme, Box } from '@mui/material'
import { Title } from 'components/stylized/titles'
import Logo from 'logo_bird.svg';
import { createStyles, makeStyles } from '@mui/styles';

type CustomStyle = { small?: boolean }

const useStyles = makeStyles<Theme, CustomStyle>((theme: Theme) => createStyles({
    body: {
        background: '#EAF3F3',
        width: '100vw',
        minHeight: '100vh',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: props => ({
        margin: '0 auto',
        padding: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(4),
        '& .boxContainer': {
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
            width: '100%'
        },
        '& .logo': {
            width: 100
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
        [theme.breakpoints.up('sm')]: {
            width: props.small ? 444 : 600
        }
    }),
    title: {
        padding: `${theme.spacing(2)} auto`,
        margin: '0 auto',
        textAlign: 'center'
    }
}))

interface TemplateProps {
    children: ReactNode
    hideLogo?: boolean
    small?: boolean
}

const AuthTemplate: FC<TemplateProps> = ({ children, hideLogo, small }) => {
    const classes = useStyles({small: small});
    
    const ShowLogo: FC<{hide?: boolean}> = ({ hide }) => {
        if (hide) return null;
        return (
            <Title variant="h2" comp="h1" className={classes.title}>
                <img src={Logo} className="logo" alt="Sarus Logo" />
            </Title>
        )
    }

    return (
        <Box className={classes.body}>
            <Paper className={classes.paper}>
                <ShowLogo hide={hideLogo} />
                {children}
            </Paper>
        </Box>
  )
}

export default AuthTemplate