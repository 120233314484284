import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import PrivacyPolicyForm from 'components/forms/PrivacyPolicyForm'
import { IPrivacyPolicy } from 'types/privacy-policy';
import apiHandler from 'api/apiHandler';
import Loading from 'components/utils/Loading';
import { Body } from 'styles/layout';
import { useDispatch } from 'react-redux';
import { AppSetResource, AppUnsetResource } from 'store/actions/AppActions';
import { Grid } from '@mui/material';
import TopCloseButton from 'components/TopCloseButton';
import { Title } from 'components/stylized/titles';

const PrivacyPoliciesCreate = () => {
    const { id } = useParams<{ id: string }>();
    const [privacyPolicy, setPrivacyPolicy] = useState<IPrivacyPolicy>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isDataNotFound, setIsDataNotFound] = useState<boolean>(false);
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        let isSubscribed = true;
        apiHandler.getPrivacyPolicy(parseInt(id)).then(result => {
            if (isSubscribed) {
                setPrivacyPolicy(result)
                dispatch(AppSetResource(result.name))
                setIsLoading(false)
            }
        }).catch(error => {
            setIsDataNotFound(true);
            dispatch(AppUnsetResource())
            setIsLoading(false);
        });

        return () => {
            isSubscribed = false;
            dispatch(AppUnsetResource())
        }
    }, [id, dispatch])

    if (isLoading) {
        return <Loading label="Loading Privacy Policy..." />
    } else if (isDataNotFound) {
        return <Loading label="Privacy Policy not found." secondLabel="It might have been removed or you don't have access." noProgress />;
    } else {
        return (
            <Body>
                <Grid container>
                    <Grid item xs={11}>
                        <Title variant="h2" comp="h1">Privacy Policy detail</Title>
                    </Grid>
                    <Grid item xs={1} style={{ textAlign: 'right', display: 'flex', alignItems: 'center', marginTop: 8, marginBottom: 21, justifyContent: 'flex-end' }}>
                        <div style={{marginRight: -5}}>
                            <TopCloseButton action={() => history.push('/privacy-policies')} />
                        </div>
                    </Grid>
                </Grid>
                <PrivacyPolicyForm data={privacyPolicy} />
            </Body>
        )
    }

}

export default PrivacyPoliciesCreate
