import React from 'react'
import GroupForm from 'components/forms/GroupForm'
import { Body } from 'styles/layout'

const GroupCreate = () => {
    return (
        <Body>
            <GroupForm />
        </Body>
    )
}

export default GroupCreate
