import React, { FC, useState } from 'react'
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { FormHelperText } from '@mui/material';

interface IPasswordProps {
    label?: string,
    name: string,
    value?: string,
    handleChange: (e: React.ChangeEvent<{ name: string, value: unknown }>) => void,
    error?: string,
    fullwidth?: boolean,
    disabled?: boolean,
    id: string,
    width: number
}

const InputPassword: FC<IPasswordProps> = props => {
    const { label, name, value, error, handleChange, fullwidth, id, disabled = false, width } = props;
    const [show, setShow] = useState<boolean>(false);

    const handleClickShowPassword = () => {
        setShow(!show);
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <FormControl
            variant="standard"
            fullWidth={fullwidth ? fullwidth : false}
            style={{ width: width, height: 50}}
        >
            <InputLabel htmlFor={id}>{label ? label : 'Password'}</InputLabel>
            <Input
                id={id}
                inputProps={{ type: show ? 'text' : 'password' }}
                value={value}
                key={name}
                name={name}
                error={!!error}
                disabled={disabled}
                onChange={handleChange}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            disabled={disabled}
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            size="large">
                            {show ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
            />
            {error && <FormHelperText error>{error}</FormHelperText>}
        </FormControl>
    );
}

export default InputPassword
