import { monitorStatus } from "store/reducers/MonitorReducer";
import { MonitorProps } from "types/datasets";

export const MONITOR_ADD_TO_MANAGER = "MONITOR_ADD_TO_MANAGER"
export const MONITOR_START = "MONITOR_START"
export const MONITOR_UPDATE = "MONITOR_UPDATE"
export const MONITOR_SET_STATE = "MONITOR_SET_STATE"
export const MONITOR_FINISHED = "MONITOR_FINISHED"

export interface MonitorAddToManager {
    type: typeof MONITOR_ADD_TO_MANAGER,
    payload: {
        id: number,
        status: string,
        confirmAddition?: boolean
    }
}

export interface MonitorStart {
    type: typeof MONITOR_START,
    payload: {
        id: number
    }
}

export interface MonitorUpdate {
    type: typeof MONITOR_UPDATE,
    payload: {
        id: number,
        details: MonitorProps
    }
}

export interface MonitorSetState {
    type: typeof MONITOR_SET_STATE,
    payload: {
        id: number,
        state: monitorStatus
    }
}

export interface MonitorFinished {
    type: typeof MONITOR_FINISHED,
    payload: {
        id: number
    }
}

export type MonitorDispatchTypes =
    MonitorAddToManager
    | MonitorStart
    | MonitorUpdate
    | MonitorFinished
    | MonitorSetState