import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import { AuthProvider } from 'context/authContext';
import { Provider } from 'react-redux';

import "@fontsource/roboto-mono";
import theme from 'styles/muiTheme';
import Router from 'components/Router';
import store from 'store/configureStore';


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


ReactDOM.render(
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <AuthProvider>
                    <Router />
                </AuthProvider>
            </Provider>
        </ThemeProvider>
    </StyledEngineProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
