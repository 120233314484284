import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import React, { FC } from 'react'
import { SchemaRangeOption, SchemaRangeOptionValue } from 'types/schema'

type SelectRangeProps = {
    options: SchemaRangeOption[],
    defaultOption?: SchemaRangeOptionValue,
    OnChange: (event: SelectChangeEvent) => void
}

const SelectRange: FC<SelectRangeProps> = (props) => {
    const { options, defaultOption, OnChange } = props


    if (options.length <= 1) {
        if (options.length === 1) {
            return <>{options[0].label}</>
        } else {
            return <>'There is an error...'</>
        }
    } else {
        return (
            <Select
                value={defaultOption || ''}
                onChange={OnChange}
                variant="standard"
                sx={{width: '100%'}}
            >
                {options.map(opt => {
                    return (
                        <MenuItem key={opt.value} value={opt.value}>{opt.label} {opt.display}</MenuItem>
                    )
                })}
            </Select>
        )   
    }
}

export default SelectRange