import { Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason, Box, TextField } from '@mui/material'
import SarusACElement from 'components/FormElements/SarusACElement'
import { CustomWidthTooltip } from 'components/FormElements/SarusAutoComplete'
import { NavOption } from 'components/tables/ShowSchema'
import React, { FC, ReactElement, ReactNode, SyntheticEvent } from 'react'

type OptionWithStatus = {name: string, status: ReactElement} 

interface NavByAutocompleteProps {
    options: string[],
    chipMapping: NavOption[],
    id?: string,
    onChange: (event: SyntheticEvent<Element, Event>, newValue: string | null, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails) => void,
    onInputChange: (event: any, newInput: string) => void,
    value: string,
    label?: string,
}

const NavByAutocomplete: FC<NavByAutocompleteProps> = (props) => {
    const {
        options,
        chipMapping,
        id,
        onChange,
        onInputChange,
        value,
        label
    } = props

    return (
        <Autocomplete
            autoSelect
            disablePortal
            disableClearable
            id={id || 'nav-autocomplete'}
            options={options}
            onChange={onChange}
            onInputChange={onInputChange}
            sx={{ width: 300 }}
            value={value}
            renderInput={(params) => <TextField {...params} label={label} InputProps={{
                ...params.InputProps,
                startAdornment: (
                    <div style={{marginRight: 5}}>
                        {params.InputProps.startAdornment}
                        {chipMapping.find(x => x.name === value)?.status}
                    </div>
                )
            }}/>}
            renderOption={(props, option) => {
                return (
                    <SarusACElement key={option} passedProps={props} str={option}>
                        <span style={{ marginRight: 5, fontSize: '0.8rem !important'}}>{chipMapping.find((item) => item.name === option)?.status}</span>
                        {option}
                    </SarusACElement>
                )
            }}
        />
  )
}

export default NavByAutocomplete