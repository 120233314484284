import { Typography } from '@mui/material'
import AuthTemplate from 'assets/templates/AuthTemplate'
import PrimaryButton from 'components/Buttons/PrimaryButton'
import { useHistory } from 'react-router-dom'

const LostPassword = () => {
    const history = useHistory()

    return (
        <AuthTemplate hideLogo>
            <Typography align="center">Please contact support@sarus.tech.<br />
                We'll come back to you shortly.</Typography>
            <PrimaryButton action={() => history.push('/login')} fullWidth>Back to login</PrimaryButton>
        </AuthTemplate>
    )
}

export default LostPassword