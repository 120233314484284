import { useState, useEffect, createContext, ReactNode, useCallback } from 'react';
import apiHandler from 'api/apiHandler';
import { User } from 'types/user';
import { Permission, getAllPermissions, listRoles, Role } from 'config/roles';
import { Group } from 'types/groups';

interface IProps {
    children: ReactNode,
}

interface IAuth {
    user?: User,
    myGroups: Group[],
    getMeAsGroup: () => Group | undefined,
    login: () => void,
    logout: () => void,
    hasPermissions: (need: Permission | Permission[]) => boolean,
    refreshPermissions: () => void,
    isLoggedIn: boolean,
    isLoading: boolean,
}

const initialState: IAuth = {
    user: undefined,
    myGroups: [],
    getMeAsGroup: () => undefined,
    login: () => null,
    logout: () => null,
    hasPermissions: () => false,
    refreshPermissions: () => null,
    isLoggedIn: false,
    isLoading: true,
}

export const UserContext = createContext(initialState);

export const AuthProvider = (props: IProps) => {
    const { children } = props;

    const [user, setUser] = useState<User | undefined>(undefined);
    const [myGroups, setMyGroups] = useState<Group[]>([]);
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [myPermissions, setMyPermissions] = useState<Permission[]>([]);

    const refreshPermissions = useCallback(() => {
        if (user) {
            const roles = listRoles.filter((role: Role) => user.roles.find((roleName: string) => roleName === role.name));
            const permissions = getAllPermissions(roles)
            setMyPermissions(permissions);
        }
    }, [user])

    useEffect(() => {
        if (user) {
            refreshPermissions();
        }
    }, [user, refreshPermissions])

    const login = () => {
        Promise.all([
            apiHandler.getMe(),
            apiHandler.getAllGroups()
        ]).then(response => {
            const userFetched = response[0];
            const groupsFetched = response[1];
            setUser(userFetched);
            setMyGroups(groupsFetched);
            setIsLoggedIn(true);
            localStorage.setItem('username', response[0].username);
        })
            .then(() => setIsLoading(false))
            .catch(error => {
                setIsLoggedIn(false);
                setUser(undefined);
                setIsLoading(false);
            });
    }

    useEffect(() => {
        if (localStorage.getItem('username')) {
            login();
        } else {
            setUser(undefined);
            setIsLoggedIn(false);
            setIsLoading(false);
        }
    }, [])

    const logout = (): void => {
        apiHandler.logout().then(() => {
            setUser(undefined);
            setMyPermissions([]);
            setIsLoggedIn(false);
            localStorage.removeItem('username');
        }).catch(err => {
            console.log(err);
        })
    }

    const getMeAsGroup = (): Group | undefined => {
        if (user) {
            return myGroups.find((group: Group) => group.name === user.username || group.name === user.email);
        } else {
            return undefined;
        }
    }

    const hasPermissions = (need: Permission | Permission[]): boolean => {
        // if (globalConfig.development) return true;
        if (!user) return false;
        if (Array.isArray(need)) {
            let result = false;
            need.forEach((n: Permission) => {
                if (myPermissions.includes(n)) result = true
            });
            return result;
        } else
            return myPermissions.includes(need);
    }

    const authValues: IAuth = {
        user: user,                         // Basic user data
        myGroups: myGroups,                 // Get user groups
        getMeAsGroup: getMeAsGroup,         // Get my Singleton (fc)
        login: login,                       // Do Login
        logout: logout,                     // Do Logout
        hasPermissions: hasPermissions,     // Ask if user has permission (permission name)
        refreshPermissions,                 // Refresh permissions rights
        isLoggedIn: isLoggedIn,             // State if is logged in
        isLoading: isLoading,               // State if loaded
    }

    return (
        <UserContext.Provider value={authValues}>
            {children}
        </UserContext.Provider>
    )
}