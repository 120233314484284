import React, { FC } from 'react'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/styles';

type InfoHelperType = {
    text: any
}

const CustomToolType = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{popper: className}} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: '500px',
        '& a': {
            color: 'white'    
        }
    }
})

const InfoHelper: FC<InfoHelperType> = (props) => {
    const { text } = props;

    return (
        <CustomToolType title={text} arrow placement="right">
            <InfoOutlinedIcon sx={{color: 'GrayText'}} />
        </CustomToolType>
    )
}

export default InfoHelper