import { useState, FC, Fragment } from 'react'
import { useDropzone } from 'react-dropzone'
import ClearIcon from '@mui/icons-material/Clear'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Typography from '@mui/material/Typography';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { DropzoneBox, UploadedFile } from 'styles/form';
import { convertFileToBase64, getExtension } from 'utils/misc';
import CircularProgress from '@mui/material/CircularProgress';
import { File } from 'types/datasets';

interface IDropzoneProps {
    uploadFunc: (file?: File) => void,
    onBlur?: () => void,
    label?: string,
    multiple?: boolean,
    name: string,
    error: { name: string, message: string }[],
    isDisabled: boolean,
}

const DropZone: FC<IDropzoneProps> = (props) => {
    const {
        label,
        multiple,
        uploadFunc,
        name,
        error,
        isDisabled,
    } = props;

    const [file, setFile] = useState<File | undefined>(undefined);
    const [errors, setErrors] = useState({ type: false, size: false});
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { getRootProps, getInputProps } = useDropzone({
        multiple: multiple ? multiple : false,
        disabled: isDisabled,
        noDrag: false,
        onDrop: acceptedFiles => {
            setIsLoading(true);
            const treatedFile = acceptedFiles[0];
            convertFileToBase64(treatedFile).then(base64 => {
                if (getExtension(treatedFile.name) === 'csv') {
                    // if (treatedFile.size > 524288000) {
                    //     setErrors({...errors, type: false, size: true})
                    // } else {
                    //     setErrors({ ...errors, type: false, size: false })
                    // }
                    setErrors({ ...errors, type: false });
                    setFile({ title: treatedFile.name, src: base64 });
                    uploadFunc({ title: treatedFile.name, src: base64 });
                } else {
                    setErrors({ ...errors, type: true });
                    setFile({ title: treatedFile.name, src: '' });
                }
            }).then(() => {
                setIsLoading(false);
            }).catch(err => {
                setErrors({ ...errors, type: true });
            });
        }
    })

    const removeFile = (file: any) => {
        setFile(undefined)
        setErrors({ type: false, size: false });
        uploadFunc(undefined);
    }

    const IconItem = () => {
        if (isLoading) {
            return <CircularProgress />
        }
        if (errors.type) {
            return <ErrorOutlineIcon />
        }
        return null;
    }

    const thumbs = (
        <UploadedFile key={file?.title} error={errors.type}>
            <div className={`before ${errors.type && `error`}`}>
                <IconItem />
            </div>
            <span>{file?.title}</span>
            <div className="delete" onClick={() => removeFile(file)}>
                <ClearIcon />
            </div>
        </UploadedFile>
    );

    const ErrorHandler = () => {
        const file = error.find(x => x.name === 'upload');
        if (!file && !errors.type) return null;
        return (
            <Typography color="error" variant="caption">
                {file?.message ? file.message : `Only CSV files are supported`}
                {/* {errors.size && 'Too big'}
                {errors.type && `Only CSV files are supported`} */}
            </Typography>
        );
    }

    return (
        <Fragment>
            <DropzoneBox error={!!error.find(x => x.name === 'upload')}>
                <section {...getRootProps()}>
                    <input {...getInputProps()} name={name} />
                    <Typography variant="body2" color="textSecondary" style={{textAlign: 'center'}}>{label ? label : <>Drag and drop a file<br />or click to open the upload dialog</>}</Typography>
                    <CloudUploadIcon />
                </section>
                {file && thumbs}
            </DropzoneBox>
            <ErrorHandler />
        </Fragment>
    )
}

export default DropZone
