import React, { useContext, useState, useEffect, useCallback, useRef } from 'react'
import makeStyles from '@mui/styles/makeStyles';
import { FormHelperText, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useDispatch, useSelector }  from 'react-redux';
import { AppState } from 'store/configureStore';
import { UserContext } from 'context/authContext';
import Tooltip from '@mui/material/Tooltip';
import apiHandler from 'api/apiHandler';
import Loading from 'components/utils/Loading';
import NavByAutocomplete from "components/NavByAutocomplete";
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { Title } from 'components/stylized/titles';
import WithInfo from 'components/WithInfo';
import axios, { AxiosError } from 'axios';
import { AddClick, ReloadDataset } from 'store/actions/DatasetActions';
import { useHistory, useParams } from 'react-router-dom';
import { AddMonitorToManager } from 'store/actions/MonitorActions';
import { ProtectionsDataType } from 'store/reducers/DatasetReducer';
import { PublicChip, ProtectedChip, LinkedChip } from "components/Chips"

const MAX_COLS = 150

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

const useStyles = makeStyles({
    root: {
        border: 0,
        '& .MuiDataGrid-iconSeparator': {
            display: 'none',
        },
        '& .test': {
            maxWidth: '100% !important',
        }
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    }
})

type HeadCell = {
    disablePadding: boolean,
    label: string,
    numeric: boolean,
}
type Data = {
    [key: string]: string,
}
type Order = 'asc' | 'desc'
type EnhanceTableProps = {
    classes: ReturnType<typeof useStyles>,
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void,
    order: Order,
    orderBy?: string,
    rowCount: number,
    headCells: HeadCell[],
}

const EnhancedTableHead = (props: EnhanceTableProps) => {
    const { classes, onRequestSort, order, orderBy, headCells } = props;
    const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property)
    }

    return (
        <TableHead>
            <TableRow>
                {
                    headCells.map((headCell: HeadCell) => {
                        return (
                            <TableCell
                                key={headCell.label}
                                sortDirection={orderBy === headCell.label ? order : false}
                            >
                                <TableSortLabel
                                    active={orderBy === headCell.label}
                                    direction={orderBy === headCell.label ? order : 'asc'}
                                    onClick={createSortHandler(headCell.label)}
                                >
                                    {headCell.label}
                                    {orderBy === headCell.label ? (
                                        <span className={classes.visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                    ) : null}
                                </TableSortLabel>
                            </TableCell>
                        )
                    })
                }
            </TableRow>
        </TableHead>
    )
}

export type ErrorConsole = { id: number, message: string }
type ColsNumber = { total: number, detail: number[] }

const SyntheticData = () => {
    const { dataset, features } = useSelector((state: AppState) => ({
        dataset: state.dataset.dataset,
        features: state.schema.features
    }));
    const relationships = useSelector((state: AppState) => state.dataset.relationships)
    console.log("relationship_spec: ")
    console.log(relationships)
    const schemaRanges = useSelector((state: AppState) => state.dataset.schemaRanges)
    const protections = useSelector((state: AppState) => state.dataset.protections)

    const dispatch = useDispatch()

    const history = useHistory()
    const { hasPermissions } = useContext(UserContext);
    const { datasetId } = useParams<{datasetId: string}>()
    const classes = useStyles();
    const [headCells, setHeadCells] = useState<HeadCell[]>([]);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [syntheticDatas, setSyntheticDatas] = useState<any>(null);
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<any>();
    const [page, setPage] = useState<number>(0);
    const [isMenuCollapsed, setIsMenuCollapsed] = useState<boolean>(false);
    const [rowsPerPage, setRowsPerPage] = useState<number>(50);
    const [schemaToLoad, setSchemaToLoad] = useState<number>()
    const [downloadClicked, setDownloadClicked] = useState<boolean>(false)
    const [schemaState, setSchemaState] = useState<any>({ isMultitable: false, list: [], schema: {} })
    const [selectedSD, setSelectedSD] = useState<{id: number, schema: any}>({id: 0, schema: undefined})
    const [initiated, setInitiated] = useState<boolean>(false)
    const isInit = useRef(false)
    const isLoadingThis = useRef({ id: 0, loaded: false })
    const [errorAPI, setErrorAPI] = useState<string | false>(false)
    const [stopLoading, setStopLoading] = useState<boolean>(false)
    const [errorInConsole, setErrorInConsole] = useState<ErrorConsole>({ id: 0, message: '' })
    const [nbCols, setNbCols] = useState<ColsNumber>({ total: 0, detail: [] })
    const [trainingGoal, setTrainingGoal] = useState<'speed' | 'accuracy'>(dataset?.training_goal || 'accuracy')
    const [isValidating, setIsValidating] = useState(false)

    useEffect(() => {
        if (dataset?.status === 'empty' && history.action === 'POP') {
            const baseUrl = `/datasets/${datasetId}`
            history.push(`${baseUrl}/schema`)
        }
    }, [])

    const LoadSD = useCallback(async () => {
        if (dataset?.status === 'empty' || dataset?.status === 'error') {
            setStopLoading(true)
            return;
        } else {
            const limit = 2000
            const dataToLoad = selectedSD

            const opts = schemaState.isMultitable ? { schema: schemaState.listSchemas.decomposed[dataToLoad.id].join('/'), limit } : { limit }
            const data = await apiHandler.getSyntheticData(dataset!.id, opts)

            // * Define Head Cols
            const headGeneration: HeadCell[] = []

            const synthData = await data.map((x: any, index: number) => ({ ...x, sarusId: index }))

            dataToLoad.schema.forEach((col: any, index: number) => {
                headGeneration.push({
                    disablePadding: index === 0,
                    label: col.name,
                    numeric: col.type.hasOwnProperty('integer')
                })
            })

            setHeadCells(headGeneration)
            setSyntheticDatas(synthData)
            setIsLoaded(true)
        }
    }, [dataset, schemaState, selectedSD])

    useEffect(() => {
        if (!schemaState.listSchemas) return;
        const details = schemaState.listSchemas.details
        if (!details) return;
        const totalNumber = details.reduce((acc: number, current: any) => acc + current.length, 0)
        const eachTables = details.map((table: any) => table.length)
        setNbCols({total: totalNumber, detail: eachTables})
    }, [schemaState])

    useEffect(() => {
        if(!isInit.current || !initiated) return
        LoadSD()
    }, [schemaState, initiated, LoadSD])

    useEffect(() => {
        if (!initiated) return

        if (isLoadingThis.current.id === selectedSD.id && !isLoadingThis.current.loaded) {
            isLoadingThis.current.loaded = true
            LoadSD()
            setDownloadClicked(false)
        }
    }, [selectedSD, initiated])

    useEffect(() => {
        if (typeof schemaToLoad === 'number' && schemaToLoad !== selectedSD.id) {
            setIsLoaded(false)
            setSelectedSD({ id: schemaToLoad, schema: schemaState.listSchemas.details[schemaToLoad] })
        }
    }, [schemaToLoad])

    const InitializeSD = useCallback(() => {
        if (initiated) return null;

        const isSpecStruct = features.hasOwnProperty('struct')
        const pureSchema = isSpecStruct ? features.struct : features.union
        let schemaListDecomposed: [string, string][] = []
        let schemaListRecomposed: string[] = []
        let selected = isSpecStruct ? pureSchema.fields : []
        let allSchemas: any = []

        if (!isSpecStruct) {
            pureSchema.fields.forEach((elem: any) => {
                elem.type.union.fields.forEach((e: any) => {
                    schemaListDecomposed.push([elem.name, e.name])
                    schemaListRecomposed.push([elem.name, e.name].join('.'))
                    allSchemas.push(e.type.struct)
                })
            })

            const phase1 = pureSchema.fields.filter((d: any) => {
                if (d.name === schemaListDecomposed[selectedSD.id][0]) {
                    return d
                } else return null
            })[0]

            selected = phase1.type.union.fields.filter((d: any) => {
                if (d.name === schemaListDecomposed[selectedSD.id][1]) {
                    return d
                } else return null
            })[0].type.struct.fields
        }

        allSchemas = allSchemas.map((table: any) => {
            return table.fields
        })

        const schemaList = {
            decomposed: schemaListDecomposed,
            recomposed: schemaListRecomposed,
            details: allSchemas
        }

        const fullData = {
            isMultitable: schemaList.recomposed.length > 1,
            isStruct: isSpecStruct,
            listSchemas: schemaList,
        }

        setSchemaState(fullData)
        setSelectedSD({id: 0, schema: selected})
        setInitiated(true)

    }, [initiated, schemaState, setSchemaState, setInitiated])

    useEffect(() => {
        if (initiated) isInit.current = true
    }, [initiated])

    useEffect(() => {
        if (initiated || !features) return;
        InitializeSD()
    }, [InitializeSD, features])

    const downloadSD = () => {
        if (!features) return;
        // convoluted logic TODO improve
        // for csv file dataset, use the endpoint without / and schema and table
        // for others, get schema & table from the type_metadata

        const tableName = schemaState.isMultitable ? schemaState.listSchemas.recomposed[selectedSD.id] : ''
        const tableSlash = schemaState.isMultitable ? ('/' + tableName.replace('.', '/')) : ''
        const tableUnder = tableName.replace('.', '_')
        console.log(tableUnder)
        setDownloadClicked(true)
        const url = `/v1/datasets/${dataset!.id}/synthetic_data${tableSlash}?format=zip`;
        const filename = schemaState.isMultitable ? `${dataset!.name}_${tableUnder}_stc.zip` : `${dataset!.name}_stc.zip`;
        const fakeLink = document.createElement('a');
        fakeLink.style.display = 'none';
        document.body.appendChild(fakeLink);
        fakeLink.setAttribute('href', url);
        fakeLink.setAttribute('download', filename);
        fakeLink.click();
    }

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    const DownloadButton = () => {
        const permit = hasPermissions('dataset.use');
        const FormattedButton = (
            <>
                <PrimaryButton
                    action={downloadSD}
                    disabled={!permit || downloadClicked}
                >
                    {schemaState.isMultitable ? 'Download selected Synthetic table' : 'Download synthetic data'}
                </PrimaryButton>
                <Typography variant="body2" color="textSecondary" sx={{mt: 1}}>
                    {
                        downloadClicked && `Download will start in a few seconds...`
                    }
                </Typography>
            </>
        )

        return permit ? FormattedButton : (
            <Tooltip title="You don't have sufficient permission to download synthetic data" arrow>
                <span>
                    {FormattedButton}
                </span>
            </Tooltip>
        )
    }

    const showRow = (row: any) => {
        if (!features || isNaN(selectedSD.id)) return null;
        const result: any = []
        delete row.sarusId;
        const schema = selectedSD.schema

        for (const key in row) {
            /*
            const feature = schema.filter((d: any) => d.name === key)[0]

            if (feature.type.name === "Datetime") {
                if (feature.type.optional?.type.enum) {
                    result.push(row[key])
                } else {
                    const value = feature.type.optional ? feature.type.optional.type.datetime.format : feature.type.datetime.format;
                    result.push(strftime(value, new Date(row[key])))
                }
            } else {
                result.push(row[key])
            }
            */
            result.push(row[key])
        }

        return result.map((cell: any, i: number) => (
            <TableCell
                component="th"
                scope="row"
                key={i}
                style={{ whiteSpace: 'nowrap' }}
            >
                {cell === null ? <Typography sx={{fontStyle: 'italic'}} color="textSecondary">null</Typography> : cell}
            </TableCell>
        ));
    }

    const TableSelection = () => {
        if(!schemaState.isMultitable) {
            return null
        }

        const options: string[] = schemaState.listSchemas.recomposed

        return (
            <NavByAutocomplete
                id="table-selection"
                options={options}
                chipMapping={options.map(o => ({name: o, status: <></>}))}
                onChange={(e, newValue) => {
                    const newId = options.indexOf(newValue!)
                    isLoadingThis.current = {id: newId, loaded: false}
                    if(newValue) setSchemaToLoad(newId)
                }}
                onInputChange={(e, newInput) => {
                    // if(options.indexOf(newInput) > -1) setSchemaToLoad(options.indexOf(newInput))
                }}
                value={options[selectedSD.id]}
                label="Select a table to preview synthetic data"
            />
        )
    }

    const DisplayTable = () => {
        if (dataset?.status === 'empty') {
            return <></>;
        } else if (dataset?.status === 'pending') {
            return <Grid item xs={12}><Loading label="Generating synthetic data..." /></Grid>;
        } else if (dataset?.status === 'error') {
            return (
                <Grid item xs={12}><Loading label="An error occurred so the synthetic data could not be generated" noProgress type="error" /></Grid>
            )
        } else if (!isLoaded) {
            return <Grid item xs={12}><Loading label="Loading synthetic data..." /></Grid>
        } else {
            return (
                <Grid item container spacing={1}>
                    <Grid item xs={12}>
                        <Title variant="h3" comp="h2">Synthetic Data preview</Title>
                    </Grid>
                    <Grid item xs={12} sx={{mb:2}}>
                        <Grid container spacing={0} justifyContent="space-between" alignItems="center">
                            <Grid item xs={6}>
                                <TableSelection />
                            </Grid>
                            <Grid item xs={6} textAlign="right">
                                <DownloadButton />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer style={{ overflowX: 'scroll', width: isMenuCollapsed ? `calc(100vw - 160px)` : `calc(100vw - 346px)` }}>
                            <Table
                                size={'small'}
                            >
                                <EnhancedTableHead
                                    classes={classes}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    rowCount={syntheticDatas.length}
                                    headCells={headCells}
                                />
                                <TableBody>
                                    {
                                        stableSort(syntheticDatas, getComparator(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row, index) => {
                                                return (
                                                    <TableRow key={index}>
                                                        {showRow(row)}
                                                    </TableRow>
                                                )
                                            })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[25, 50, 100]}
                            component="div"
                            count={syntheticDatas.length}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Grid>
                </Grid>
            )
        }
    }
    useEffect(() => {
        if (nbCols.total >= MAX_COLS) {
            setTrainingGoal('speed')
        }
    }, [nbCols])

    const formatProtections = useCallback(() => {
        if(protections) {
            const formattedData = Object.keys(protections).reduce((acc, key: keyof ProtectionsDataType) => {

                const selectedPKName = protections[key].selectedPK?.name
                const selectedPKRef = protections[key].selectedPK?.ref

                if(selectedPKRef) {
                    const parsedRef = selectedPKRef.split(":")
                    const selectedPKWithRef = parsedRef[0].split(".").pop()

                    acc[key] = [[selectedPKWithRef || ""], selectedPKRef]
                } else {
                    acc[key] = [[selectedPKName || ""], ""]
                }
                return acc
            }, {} as Record<keyof ProtectionsDataType, [string[], string]>)
            return formattedData
        } else {
            return dataset?.protection_spec
        }
    }, [protections, dataset])

    const ValidateDataset = useCallback(() => {
        if (!datasetId) return;
        setIsValidating(true)

        const relationshipSpecs = relationships;
        //const protectionSpecs =
        // TODO check with GdG
        // it has to work when the protection_spec was changed and when it wasn't
        // what is bad here is that the redux state is NOT reliable, because it's
        // not properly initialized to what is displayed if the user doesn't change the
        // protection.

        const protectionSpecs = formatProtections()

        const saveRelationships = async () => {
        // send the relationship_spec and protection_spec to the API for saving
            try {
                const ranges = { ranges_spec: schemaRanges ? schemaRanges : {} }
                const data = {
                    relationship_spec: relationshipSpecs,
                    protection_spec: JSON.stringify(protectionSpecs),
                    training_goal: trainingGoal,
                    ...ranges
                }

                await apiHandler.editDatasetRelationship(parseInt(datasetId), data)
                return { error: false }
            } catch (e) {
                if (axios.isAxiosError(e)) {
                    setErrorAPI((e as AxiosError).response?.data || '')
                    return { error: true, message: (e as AxiosError).response?.data}
                } else {
                    return { error: true, message: 'unknown error' }
                }
            }
        }

        const prepare = async () => {
            try {
                await apiHandler.prepareDataset(parseInt(datasetId));
                dispatch(ReloadDataset(parseInt(datasetId)))
                dispatch(AddClick())
                history.push(`/datasets/${datasetId}/access-rules`)
            } catch (e) {
                console.log("Error while prepare Dataset")
            }
        }

        const saveAndPrepare = async () => {
        // note for GdG: do we really need saveAndPrepare(), prepare() and saveRelationships() ???
        // It looks quite complicated
            try {
                const terminal = await saveRelationships()
                if (!terminal.error) {
                    prepare()
                } else {
                    setErrorInConsole({ id: errorInConsole.id + 1, message: terminal.message })
                }
            } catch (e) {
                console.log("Error Saving Schema");
            }
        }

        saveAndPrepare();
        dispatch(AddMonitorToManager(parseInt(datasetId), dataset!.status))

    }, [dataset, dispatch, errorInConsole, history, datasetId, schemaRanges, relationships, protections, trainingGoal, formatProtections])

    const DisplayMaxSize = () => {
        let maxSize = dataset?.dataspec.big_data_threshold
        if(maxSize) {
            return <TextField disabled name="data_max_size" value={maxSize} fullWidth />
        } else {
            return <TextField disabled name="data_max_size" value="Same as source" fullWidth sx={{fontStyle: "italic"}} />
        }
    }

    return (
        <Grid container spacing={4}>
            {
                dataset?.status === 'empty' && (
                    <Grid item xs={12}>
                        <Grid container spacing={4}>
                            <Grid item xs={8}>
                                <FormHelperText>
                                    Adjust the settings to customize the synthesis AI model training.<br />
                                    Click VALIDATE to launch the dataset preparation.
                                </FormHelperText>
                            </Grid>
                            <Grid item xs={4} sx={{textAlign: 'right'}}>
                                <PrimaryButton action={ValidateDataset} isLoading={isValidating}>
                                    Validate
                                </PrimaryButton>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            }

            <Grid item xs={12}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Title variant="h3" comp="h2">Synthesis settings</Title>
                    </Grid>
                    <Grid item container xs={12} justifyContent={'flex-start'}>
                        <div style={{display: 'flex', gap: 64}}>
                            <Grid container justifyContent={'space-between'} direction={'column'} spacing={2} sx={{width: 280}}>
                                <Grid item>
                                    <WithInfo content="Training goal" info="When choosing ACCURACY, a Deep Learning model capturing
                                        multivariate distributions will be trained to generate synthetic data,
                                        which can take time. If you want the synthetic data generation to be
                                        quicker, choose SPEED. A simpler model will be used preserving only
                                        univariate distributions." disabled={nbCols.total >= MAX_COLS}
                                    />
                                </Grid>
                                <Grid item>
                                    <ToggleButtonGroup
                                        color='primary'
                                        value={trainingGoal}
                                        exclusive
                                        disabled={dataset?.status !== 'empty' || nbCols.total >= MAX_COLS}
                                        onChange={(e: any, newGoal: 'accuracy' | 'speed') => {
                                            setTrainingGoal(newGoal)
                                        }}
                                        size='medium'
                                        fullWidth
                                    >
                                        <ToggleButton value="accuracy">Accuracy</ToggleButton>
                                        <ToggleButton value="speed">Speed</ToggleButton>
                                    </ToggleButtonGroup>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent={'space-between'} direction={'column'} spacing={2} sx={{width: 280}}>
                                <Grid item>
                                    <WithInfo content="Synthetic data max size" info="[Coming soon] The synthetic data max size is the maximum number of
                                        rows that will be generated for the dataset. If the source data has fewer
                                        rows than this parameter, the synthetic dataset will have the same size
                                        as the source data." disabled
                                    />
                                </Grid>
                                <Grid item>
                                    <DisplayMaxSize />
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <DisplayTable />
        </Grid>
    )
}

export default SyntheticData
