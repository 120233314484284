import { FC, useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { UserContext } from 'context/authContext';
import { IProtectedRoute } from 'types/routing';

const ProtectedRoute: FC<IProtectedRoute> = ({ ...rest }) => {
    const { isLoading, isLoggedIn } = useContext(UserContext);

    if (isLoading) {
        return null;
    } else if (isLoggedIn) {
        return <Route {...rest} />;
    } else {
        return <Redirect to="/login" />;
    }
}

export default ProtectedRoute
