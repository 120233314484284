import { FC, Fragment } from 'react'
import { ButtonProps } from './ButtonsType'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import { IconButton, Theme } from '@mui/material';

const useStyle = makeStyles((theme: Theme) => ({
    root: {
        minWidth: 32,
        marginRight: 8,
    }
}))

const GoBackButton: FC<ButtonProps> = (props) => {
    const { action, disabled, tooltip } = props;
    const classes = useStyle()

    const button = (
        <IconButton
            onClick={action}
            disabled={disabled}
            className={classes.root}
            size="large">
            <KeyboardBackspaceIcon />
        </IconButton>
    )

    if (tooltip) {
        return (
            <Tooltip title={tooltip} arrow>
                <span>
                    {button}
                </span>
            </Tooltip>
        )
    } else {
        return (
            <Fragment>
                {button}
            </Fragment>
        )
    }
}

export default GoBackButton
