import React, { FC, Fragment } from 'react'
import Button from '@mui/material/Button'
import { ButtonProps } from './ButtonsType'

const CancelButton: FC<ButtonProps> = (props) => {
    const { action, children, disabled, fullWidth } = props;
    
    return (
        <Fragment>
            <Button
                onClick={action}
                disabled={disabled}
                fullWidth={fullWidth}
            >{children}</Button>
        </Fragment>
    )
}

export default CancelButton
