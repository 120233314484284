import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import GroupForm from 'components/forms/GroupForm'
import apiHandler from 'api/apiHandler';
import { Group } from 'types/groups';
import { Body } from 'styles/layout';
import Loading from 'components/utils/Loading';
import { useDispatch } from 'react-redux';
import { AppSetResource, AppUnsetResource } from 'store/actions/AppActions';

const GroupEdit = () => {
    const { id } = useParams<{id: string}>();
    const [group, setGroup] = useState<Group>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isDataNotFound, setIsDataNotFound] = useState<boolean>(false);
    const history = useHistory();
    const dispatch = useDispatch()

    useEffect(() => {
        let isSubscribed = true;
        apiHandler.getGroup(Number(id)).then((result: any) => {
            if (result.singleton) history.push('/groups')        // Guard : redirect to groups if singleton
            if (isSubscribed) {
                setGroup(result)
                dispatch(AppSetResource(result.name))
                setIsLoading(false)
            }
        }).catch((error: any) => {
            setIsDataNotFound(true);
            dispatch(AppUnsetResource())
            setIsLoading(false);
        });

        return () => {
            isSubscribed = false;
        }
    }, [id, history, dispatch])

    if (isLoading) {
        return <Loading label="Loading group data..." />;
    } else if (isDataNotFound) {
        return <Loading label="Group not found." secondLabel="It might have been removed or you don't have access." noProgress />;
    } else {
        return (
            <Body>
                <GroupForm data={group} />
            </Body>
        )
    }
}

export default GroupEdit
