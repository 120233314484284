import React, { Fragment, FC, useState, useEffect, useContext } from 'react';
import DialogCreatePrivacyPolicy from 'components/forms/DialogCreatePrivacyPolicy';
import { FormBar } from 'components/stylized/actionBar';
import { UserContext } from 'context/authContext';
import { Autocomplete, Box, SelectChangeEvent } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import { cleanError } from 'helpers/forms';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Tooltip from '@mui/material/Tooltip';

// Icons
import PersonIcon from '@mui/icons-material/Person';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AddIcon from '@mui/icons-material/Add';

// Types
import { AllAccessRule } from 'types/datasets';
import { Group } from 'types/groups';
import { IPrivacyPolicy } from 'types/privacy-policy';
import { ErrorInForm } from 'types/forms';

import apiHandler from 'api/apiHandler';
import { useDispatch, useSelector, connect } from 'react-redux';
import { AppState } from 'store/configureStore';
import { DisplayUser } from 'utils/misc';
import { CreateAccessRule, UpdatePPAndSelectLast } from 'store/actions/DatasetActions';
import CustomPrompt from 'components/dialogs/CustomPrompt';
import { checkTypePPDialog } from 'components/forms/PrivacyPolicyForm';
import { CustomWidthTooltip } from 'components/FormElements/SarusAutoComplete';
import SarusACElement from 'components/FormElements/SarusACElement';

const useStyles = makeStyles((theme: Theme) => createStyles({
    icon: {
        display: 'inline-block',
        textAlign: 'left',
        marginRight: '1em',
    },
    field: {
        width: 300,
    },
    marginRight: {
        marginRight: 8,
    }
}))

interface FormProps {
    // createRule: (rule: { group: number, privacyPolicy: number }) => void,
    listPrivacyPolicies?: IPrivacyPolicy[]
}

type NewRule = {
    group?: Group,
    privacyPolicy?: IPrivacyPolicy,
}

const AddRuleForm: FC<FormProps> = React.memo((props) => {
    const { listPrivacyPolicies } = props;
    const classes = useStyles();

    const { dataset, canEdit } = useSelector((state: AppState) => ({
        dataset: state.dataset,
        canEdit: state.dataset.canEdit
    }));

    const dispatch = useDispatch();
    const { hasPermissions } = useContext(UserContext);

    const [openModal, setOpenModal] = useState(false);
    const [groupListId, setGroupListId] = useState<number[]>([]);
    const [group, setGroup] = useState<number | null>(null);
    const [PPListId, setPPListId] = useState<Array<number>>([]);
    const [newRule, setNewRule] = useState<NewRule>({});
    const [errorsInForm, setErrorsInForm] = useState<ErrorInForm[]>([]);
    
    const [privacyPolicy, setPrivacyPolicy] = useState<number | null>(null);
    const [ppToPick, setPPToPick] = useState<number>(-1);

    const [prompt, setPrompt] = useState<boolean>(false);

    useEffect(() => {
        setGroupListId(dataset.groups.map((group: Group) => group.id!));
    }, [dataset.groups]);

    useEffect(() => {
        if (!listPrivacyPolicies) return
        let PPID: number[] = []
        listPrivacyPolicies.forEach((pp) => pp.id && PPID.push(pp.id))
        setPPListId(PPID);
    }, [listPrivacyPolicies])

    useEffect(() => {
        if (ppToPick === -1) return;
        setPrivacyPolicy(ppToPick);
        setPPToPick(-1);
    }, [ppToPick])

    useEffect(() => {
        if (dataset.soonToBeCreatedRule.pp !== -1) setPPToPick(dataset.soonToBeCreatedRule.pp)
        if (dataset.soonToBeCreatedRule.group !== -1) setGroup(dataset.soonToBeCreatedRule.group);
    }, [dataset.soonToBeCreatedRule])

    useEffect(() => {
        if (group) {
            setNewRule((rule) => ({ ...rule, group: dataset.groups.find((gr: Group) => gr.id === group) }));
            cleanError("group", errorsInForm, setErrorsInForm);
        }
    }, [group, dataset.groups]);

    useEffect(() => {
        if (!privacyPolicy) {
            setNewRule((rule) => ({ ...rule, privacyPolicy: dataset.privacyPolicies[0] }))
            cleanError("privacyPolicy", errorsInForm, setErrorsInForm);
        } else {
            setNewRule((rule) => ({ ...rule, privacyPolicy: dataset.privacyPolicies.find((pp: IPrivacyPolicy) => pp.id === privacyPolicy) }));
            cleanError("privacyPolicy", errorsInForm, setErrorsInForm);
        }
    }, [privacyPolicy, dataset.privacyPolicies]);

    const isSingleton = (id: number): boolean => dataset.groups.find((group: Group) => group.id === id)!.singleton;
    const getGroupName = (id: number): string => {
        const group = dataset.groups.find((group: Group) => group.id === id);
        if (!group) return '[error]';
        if (group.singleton) {
            const user = dataset.users.find((user: any) => user.email === group.name || user.username === group.name)!;
            return DisplayUser(user);
        } else {
            return group?.name;
        }
    };

    const getPPName = (id: number): string => {
        if(!listPrivacyPolicies) return '[error]'
        const pp = listPrivacyPolicies.find(pp => pp.id === id)
        return pp!.name
    }
 
    // const handleChangePrivacyPolicy = (event: SelectChangeEvent<string | number>) => {
    //     if (event.target.value === "create") {
    //         setOpenModal(true);
    //         return;
    //     } else {
    //         setPrivacyPolicy(event.target.value as number);
    //     }
    // }

    const handleSubmit = () => {
        const errors: ErrorInForm[] = [];
        if (!newRule.group) errors.push({ name: "group", message: "Please pick a User or Group" });
        if (!newRule.privacyPolicy) errors.push({ name: "privacyPolicy", message: "Please select a Privacy Policy" });
        if (dataset.accessRules.find((rule: AllAccessRule) => rule.raw.group_id === newRule.group?.id && !rule.raw.revoked)) {
            errors.push({ name: 'group', message: 'This user/group already has access' })
        }
        setErrorsInForm(errors);
        if (errors.length === 0) {
            createRule({group: newRule.group!.id!, privacyPolicy: newRule.privacyPolicy!.id!});
            setNewRule({});
            setGroup(null);
            setPrivacyPolicy(null);
            setPPToPick(-1);
        }
    }

    const createRule = (newRule: { group: number, privacyPolicy: number }) => {
        let listAccesses = dataset.dataset ? [...dataset.dataset.accesses] : [];
        const newAccessRule = { group_id: newRule.group, pp_id: newRule.privacyPolicy, id: -1, current_epsilon: 0, revoked: false }
        listAccesses.push(newAccessRule);
        dispatch(CreateAccessRule(dataset.dataset!.id, listAccesses));
    }

    const CreateButton: FC = () => {
        
        const formatedButton = (
            <Button
                color="primary"
                variant="contained"
                onClick={handleSubmit}
                disabled={!canEdit || dataset.loadingAccess}
            >
                Add Rule
            </Button>
        )

        return canEdit ? formatedButton : (
            <Tooltip title="You don't have sufficient permission to edit this dataset" arrow>
                <span>
                    {formatedButton}
                </span>
            </Tooltip>
        )
    }

    const handlePrivacyPolicyPrompt = (answer: boolean) => {
        if (answer) {
            setPrompt(false)
            setOpenModal(false)
        } else {
            setPrompt(false)
        }
    }

    const handleCloseModal = (r: checkTypePPDialog) => {
        const { modified, data } = r;
        if (modified) {
            setPrompt(true)
        } else {
            if (data && (data as IPrivacyPolicy).name !== undefined) {
                apiHandler.createPrivacyPolicy(data).then((created: any) => {
                    dispatch(UpdatePPAndSelectLast(group || -1));
                })
            }
            setOpenModal(false);
        }
    }

    return (
        <Fragment>
            <DialogCreatePrivacyPolicy open={openModal} onClose={handleCloseModal} />
            {prompt && <CustomPrompt cleanUp={handlePrivacyPolicyPrompt}/>}
            <FormBar>
                <FormControl className={classes.field} variant="standard">
                    <Autocomplete
                        id="select-group-user"
                        value={group}
                        onChange={(event: any, newValue: number | null) => setGroup(newValue)}
                        options={
                            groupListId.filter((id: number) => {
                                if (!hasPermissions(['user.listAll', 'user.listOwn']) && isSingleton(id)) return false;
                                if (!hasPermissions(['group.listAll', 'group.listOwn']))
                                    if (!isSingleton(id))
                                        return false;
                                return true;
                            })
                        }
                        getOptionLabel={option => getGroupName(option)}
                        renderOption={(props, option) => (
                            <SarusACElement key={option} icon={isSingleton(option) ? <PersonIcon /> : <PeopleAltIcon />} passedProps={props} str={getGroupName(option)}>{getGroupName(option)}</SarusACElement>
                        )}
                        className={classes.field}
                        disabled={!canEdit}
                        renderInput={params => (
                            <TextField
                                {...params}
                                name="group"
                                label="User / Group"
                                InputLabelProps={{ shrink: true }}
                                error={!!errorsInForm.find((error: ErrorInForm) => error.name === "group")}
                                helperText={errorsInForm.find((error: ErrorInForm) => error.name === "group")?.message || 'Select a user or a group'}
                            />
                        )}
                    />
                </FormControl>
                <FormControl className={classes.field} variant="standard" style={{width: 280}}>
                    <Autocomplete 
                        id="field-privacy-policy"
                        onChange={(event: any, newValue: any) => {
                            if (newValue === 'create') {
                                setOpenModal(true)
                                setPrivacyPolicy(null)
                            } else {
                                setPrivacyPolicy(newValue)
                            }
                        }}
                        value={privacyPolicy}
                        disabled={!canEdit}
                        className={classes.field}
                        options={[...PPListId, 'create']}
                        getOptionLabel={opt => {
                            if (opt === null) {
                                return ''
                            } else if (opt === 'create') {
                                return "Create new privacy policy"
                            } else {
                                return getPPName(opt as number)
                            }
                        }}
                        renderOption={(props, option) => {
                            if (option === 'create') {
                                return (
                                    <SarusACElement key={option} passedProps={props} icon={<AddIcon />} str="Create new privacy policy">Create new privacy policy</SarusACElement>
                                )
                            } else {
                                return (
                                    <SarusACElement key={option} passedProps={props} str={getPPName(option as number)}>{getPPName(option as number)}</SarusACElement>
                                )
                            }
                        }}
                        renderInput={
                            params => (
                                <TextField
                                    {...params}
                                    name="privacy-policy"
                                    label="Privacy Policy"
                                    InputLabelProps={{ shrink: true }}
                                    error={!!errorsInForm.find((error: ErrorInForm) => error.name === "privacyPolicy")}
                                    helperText={errorsInForm.find((error: ErrorInForm) => error.name === "privacyPolicy")?.message || 'Select a user or a group'}
                                />
                            )
                        }
                    />
                </FormControl>
                <FormControl className="button" variant="standard">
                    <CreateButton />
                </FormControl>
            </FormBar>
        </Fragment>
    );
})

const mapState = (state: AppState) => ({ listPrivacyPolicies: state.dataset.privacyPolicies })

export default connect(mapState)(AddRuleForm);