import { Status } from "types/datasets"
import { MAX_NUM_CATEGORIES, MAX_POSSIBLE_VALUES } from "./ShowSchema"
import { Link } from "@mui/material"
import { SchemaRanges } from "types/schema"


type RangeValue = 'enum' | 'non-enum'
type SynthesisMethod = 'None (Public)' | 'Random in range' | 'Marginals-based' | 'Trigram model'

export type Option = {
    value: RangeValue,
    label: string,
    synthesis?: SynthesisMethod,
    display?: any
    hidden?: boolean
}

const convertToString = (str: any) => {
    if (typeof str !== 'string') {
        const stringified = str.join(', ')
        return stringified
    }

    return str.slice(1, -1)
}

const convertToCharset = (arr: string) => {
    if(!arr) return
    return JSON.parse(arr).map((char: number) => String.fromCharCode(char)).join(', ')
}


export const getOptionsFrom = (feature: any, featureData: any, tableProtection: string, datasetStatus: Status, displayPopup?: any,  colValue?: string) => {

    const SynthesisPublicOr = (str: SynthesisMethod) => { return tableProtection === 'public' ? 'None (Public)' : str }
    const getPossibleValueLength = (): number | undefined => {
        // if (datasetStatus === 'empty' || (datasetStatus === 'pending' && schemaRanges)) {

        // new algo: we look for the type.properties.possible_values_length which means we're still not fully onboarded
        // if it doesn't exist we look at the type.possible_values.length which means we are onboarded
        if (featureData.optional) {
            if (feature.type.optional.type.properties.possible_values_length)
            {return Number(feature.type.optional.type.properties.possible_values_length)}
            else {
                return feature.type.optional.type[featureData.type].possible_values.length;
            }
        }
        else {
            if (feature.type.properties.possible_values_length) {
                return Number(feature.type.properties.possible_values_length);
            }
            else {
                return feature.type[featureData.type].possible_values.length
            }
        }
    }

    const properties = featureData.optional ? feature.type.optional.type.properties : feature.type.properties
    const possibleValues = featureData.optional ?
        (datasetStatus === 'empty' ? feature.type.optional.type.properties.possible_values : feature.type.optional.type[featureData.type].possible_values)
        :  (datasetStatus === 'empty' ? feature.type.properties.possible_values : feature.type[featureData.type].possible_values)
    const possibleValuesLength = featureData.type !== "id" && featureData.type !== "unit" && featureData.type !== "boolean" ? getPossibleValueLength() : 0
    const getLabelForPossibleValue = () => {
        return possibleValuesLength ? possibleValuesLength > MAX_POSSIBLE_VALUES ? `>10000 possible values` : `${possibleValuesLength} possible ${possibleValuesLength > 1 ? `values` : `value`}` : 'Error on length'
    }

    const isNumber = (value: any): boolean => {
        return typeof parseFloat(value) === 'number' && !Number.isNaN(value)
    }

    const getMinFeature = () => {
        let result: any = 0
        if (featureData.optional) {
            result = isNumber(feature.type.optional.type[featureData.type].min) ? feature.type.optional.type[featureData.type].min : 'error'
        } else {
            result = isNumber(feature.type[featureData.type].min) ? feature.type[featureData.type].min : 'error'
        }

        if (result === 'NaN') {
            let values = []
            if (featureData.optional) {
                values = feature.type.optional.type[featureData.type].possible_values
            } else {
                values = feature.type[featureData.type].possible_values
            }
            values.sort().pop()
            result = values.shift()
        }
        return result
    }

    const getMaxFeature = () => {
        let result: any = 0
        if (featureData.optional) {
            result = isNumber(feature.type.optional.type[featureData.type].max) ? feature.type.optional.type[featureData.type].max : 'error'
        } else {
            result = isNumber(feature.type[featureData.type].max) ? feature.type[featureData.type].max : 'error'
        }

        if (result === 'NaN') {
            let values = []
            if (featureData.optional) {
                values = feature.type.optional.type[featureData.type].possible_values
            } else {
                values = feature.type[featureData.type].possible_values
            }
            values.sort().pop()
            result = values.pop()
        }
        return result
    }

    let options: Option[] = []
    switch (featureData.type) {
        case 'id':
            options = [{ label: 'Any integer', value: 'non-enum', synthesis: 'Random in range' }]
            break;
        case 'integer':
        case 'float':
        case 'date':
        case 'datetime':
        case 'time':
        case 'duration':
            const nbValueNumber = getLabelForPossibleValue()
            const titleNumberPopup = <>Possible {possibleValuesLength! > 1 ? 'values' : 'value'} for <em>{feature.name}</em></>
            const displayValues = possibleValuesLength && possibleValuesLength <= MAX_POSSIBLE_VALUES ? {
                display: <>(
                    <Link
                        onClick={(e) => displayPopup({ title: titleNumberPopup, data: convertToString(possibleValues) })}
                        sx={{ cursor: 'pointer' }}
                        onMouseDown={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                        }}
                    >show values</Link>)</>
            } : {}

            if (datasetStatus === 'empty') {
                 if (possibleValues) {
                     const optEnum: Option = {
                         value: 'enum',
                         label: nbValueNumber,
                         synthesis: SynthesisPublicOr('Marginals-based'),
                         ...displayValues
                     }
                     options.push(optEnum)
                 }
                 const optNonEnum: Option = {
                     value: 'non-enum',
                     label: `Any value between ${getMinFeature()} and ${getMaxFeature()}`,
                     synthesis: SynthesisPublicOr('Marginals-based')
                 }
                 options.push(optNonEnum)
             } else {
                  if (colValue === 'enum') {
                     options = [{
                         value: 'enum',
                         label: nbValueNumber,
                         synthesis: SynthesisPublicOr('Marginals-based'),
                         ...displayValues
                     }]
                 } else {
                     options = [{
                         value: 'non-enum',
                         label: `Any value between ${getMinFeature()} and ${getMaxFeature()}`,
                         synthesis: SynthesisPublicOr('Marginals-based')
                     }]
                 }
             }
            break;
        case 'text':
            const nbPossible = (possibleValuesLength && possibleValuesLength > 0) ? possibleValuesLength : 0
            const nbValueText = possibleValuesLength ? possibleValuesLength > MAX_POSSIBLE_VALUES ? `>10000 possible values` : `${possibleValuesLength} possible ${possibleValuesLength > 1 ? 'values' : 'value'}` : 'Error on length'
            const isOnlyOneEmpty = (): boolean => {
                if (nbPossible === 1) {
                    const values = typeof possibleValues === 'string' ? JSON.parse(possibleValues) : possibleValues
                    if(values[0] === '') return true
                }
                return false
            }
            const displayOnePossibleValue = () => {
                const checkOneEmpty = isOnlyOneEmpty()
                return checkOneEmpty ? `1 possible value (" ")` : nbValueText
            }

            const titleTextPVPopup = <>Possible {nbPossible > 1 ? 'values' : 'value'} of <em>{feature.name}</em></>
            const titleCharsetPopup = <>Charset of <em>{feature.name}</em></>

            const displayTextValues = nbPossible <= MAX_POSSIBLE_VALUES  && !isOnlyOneEmpty() ? {
                display: <>(
                    <Link
                        onClick={(e) => displayPopup({ title: titleTextPVPopup, data: convertToString(possibleValues) })}
                        sx={{ cursor: 'pointer' }}
                        onMouseDown={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                        }}
                    >show values</Link>)</>
            } : {}

            const displayTextCharset = properties.text_char_set ? {
                display: <>(
                    <Link
                        onClick={(e) => displayPopup({ title: titleCharsetPopup, data: convertToCharset(properties.text_char_set) })}
                        sx={{ cursor: 'pointer' }}
                        onMouseDown={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                        }}
                    >show charset</Link>)</>
            } : {}

            if (datasetStatus === 'empty') {
                 // we need to display both options
                 options = [
                     {
                         label: 'Any text using source charset',
                         value: 'non-enum',
                         synthesis: SynthesisPublicOr('Trigram model'),
                         ...displayTextCharset
                     },
                     {
                         label: displayOnePossibleValue(),
                         value: 'enum',
                         synthesis: SynthesisPublicOr('Marginals-based'),
                         ...displayTextValues
                     }
                 ]
            } else {
                 // we need to display only the chosen option
                 if (colValue === 'enum') {
                     options = [
                         {
                             value: 'enum',
                             label: nbValueText,
                             synthesis: SynthesisPublicOr('Marginals-based'),
                             ...displayTextValues
                         }
                     ]
                 } else {
                     options = [
                         {
                             value: 'non-enum',
                             label: `Any text using source character set`,
                             synthesis: SynthesisPublicOr('Trigram model'),
                             ...displayTextCharset
                         }
                     ]
                 }
             }
            break;
        case 'enum':
            const nbValueEnum = getLabelForPossibleValue()
            options = [{ label: nbValueEnum, value: 'enum', synthesis: SynthesisPublicOr('Marginals-based') }]
            break;
        case 'unit':
            options = [{ label: '1 possible value ("Null")', value: 'non-enum', synthesis: SynthesisPublicOr('Marginals-based') }]
            break;
        case 'boolean':
            options = [{ label: 'True, False', value: 'non-enum', synthesis: SynthesisPublicOr('Marginals-based') }]
            break;
        default:
            console.error(`Type unknown: ${feature.type}`)
    }

    return options
}

const getTypeFromDataSpec = (feature: any) => {
    const isOptional = feature.type.hasOwnProperty('optional')
    let featureType = ''
    const getType = (type: any) => {
        if (type.hasOwnProperty('integer')) return 'integer'
        else if (type.hasOwnProperty('id')) return 'id'
        else if (type.hasOwnProperty('float')) return 'float'
        else if (type.hasOwnProperty('boolean')) return 'boolean'
        else if (type.hasOwnProperty('integer')) return 'integer'
        else if (type.hasOwnProperty('time')) return 'time'
        else if (type.hasOwnProperty('duration')) return 'duration'
        else if (type.hasOwnProperty('date')) return 'date'
        else if (type.hasOwnProperty('datetime')) return 'datetime'
        else if (type.hasOwnProperty('text')) return 'text'
        else if (type.hasOwnProperty('enum')) return 'enum'
        else if (type.hasOwnProperty('unit')) return 'unit'
        else {
            console.log("------ TYPE ERROR ------")
            console.log(type)
            console.log("------------------------")
            return 'error'
        }
    }

    featureType = getType(isOptional ? feature.type.optional.type : feature.type)

    return {type: featureType, optional: isOptional}
}

const ParseColumn = (feature: any, tableProtection: string, datasetStatus: Status, schemaRanges?: SchemaRanges) => {
    const featureData = getTypeFromDataSpec(feature)
    const properties = featureData.optional ? feature.type.optional.type.properties : feature.type.properties
    let value: RangeValue = 'non-enum'

//    const getPossibleValueLength = (): number | undefined => {
//        if (datasetStatus === 'empty' || (datasetStatus === 'pending' && schemaRanges)) {
//            if(featureData.optional)
//                return feature.type.optional.type.properties.possible_values_length ? Number(feature.type.optional.type.properties.possible_values_length) : undefined
//            else
//                return feature.type.properties.possible_values_length ? Number(feature.type.properties.possible_values_length) : undefined
//        } else {
//            if (featureData.optional)
//                return feature.type.optional.type[featureData.type].possible_values ? feature.type.optional.type[featureData.type].possible_values.length : undefined
//            else
//                return feature.type[featureData.type].possible_values ? feature.type[featureData.type].possible_values.length : undefined
//        }
//    }
    const getPossibleValueLength = (): number | undefined => {
        // if (datasetStatus === 'empty' || (datasetStatus === 'pending' && schemaRanges)) {

        // new algo: we look for the type.properties.possible_values_length which means we're still not fully onboarded
        // if it doesn't exist we look at the type.possible_values.length which means we are onboarded
        if (featureData.optional) {
            if (feature.type.optional.type.properties.possible_values_length)
            {return Number(feature.type.optional.type.properties.possible_values_length)}
            else {
                return feature.type.optional.type[featureData.type].possible_values.length;
            }
        }
        else {
            if (feature.type.properties.possible_values_length) {
                return Number(feature.type.properties.possible_values_length);
            }
            else {
                return feature.type[featureData.type].possible_values.length
            }
        }
    }

    const possibleValuesLength = featureData.type !== "id" && featureData.type !== "unit" && featureData.type !== "boolean" ? getPossibleValueLength() : 0

    switch (featureData.type) {
        case 'id':
            value = 'non-enum'
            break;
        case 'integer':
        case 'float':
        case 'date':
        case 'datetime':
        case 'time':
        case 'duration':
        case 'text':
            if (datasetStatus === 'empty' ) {
                // there may be 2 options, we need to find the default one
                if (possibleValuesLength && possibleValuesLength <= MAX_NUM_CATEGORIES) {
                    value = 'enum'
                } else {
                    value = 'non-enum'
                }
            } else {
                // there is only one option
                if (possibleValuesLength) {
                    value = 'enum'
                } else {
                    value = 'non-enum'
                }
            }
            break;
        case 'enum':
            value = 'enum'
            break;
        case 'unit':
        case 'boolean':
            value = 'non-enum'
            break;
        default:
            console.error(`Unknown type: ${featureData.type}`)
    }

    if (datasetStatus === 'empty' && tableProtection === 'public') {
        if (properties.possible_values
            && properties.possible_values_length > 0
            && properties.possible_values_length <= MAX_POSSIBLE_VALUES) {
            value = 'enum'
        } else {
            value = 'non-enum'
        }
    }

    return value
}

type SchemaTable = {
    name: Array<string>,
    type: any,
    status: 'private' | 'public'
}

export const ParseRanges = (schemaTables: SchemaTable[], prefix: string[], datasetStatus: Status, schemaRanges?: SchemaRanges) => {
    let ranges: any = {};

    schemaTables.forEach((baseTable, index) => {
        const completeName = JSON.stringify([prefix[index], ...baseTable.name])
        baseTable.type.forEach((feature: any) => {
            if (schemaRanges && schemaRanges[completeName] && schemaRanges[completeName][feature.name] && baseTable.status !== 'public') {
                ranges[completeName] = {...ranges[completeName], [feature.name]: schemaRanges[completeName][feature.name]}
            } else {
                ranges[completeName] = {...ranges[completeName], [feature.name]: ParseColumn(feature, baseTable.status, datasetStatus, schemaRanges)}
            }
        })
    })

    return ranges
}
