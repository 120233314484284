import { FC, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { AllAccessRule } from 'types/datasets';
import { Box, Theme, Link } from '@mui/material';
import { Switch, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { DataGrid, GridCellParams, GridColDef, GridSortDirection } from '@mui/x-data-grid';
import { IPrivacyPolicy } from 'types/privacy-policy';
import { datagridStyles } from 'styles/datagrid';
import { PrivacyLog } from 'types/logs';
import { DisplayUser, formatDateString } from 'utils/misc';
import { useSelector } from 'react-redux';
import { AppState } from 'store/configureStore';
import { makeStyles } from '@mui/styles';
import GoBackButton from 'components/Buttons/GoBackButton';
import NumberFormat from 'react-number-format';
import WithInfo from 'components/WithInfo';
import ConsoleBox from 'components/ConsoleBox';
import OverflowTooltip from 'hooks/OverflowTooltip';

type DetailsProps = {
    rule: AllAccessRule,
    privacyPolicy: IPrivacyPolicy,
    privacyLogs: PrivacyLog[],
    closeDetails: () => void,
}

const useStyles = makeStyles((theme: Theme) => ({
    defaultParam: {
        fontStyle: 'italic',
        color: theme.palette.grey[500]
    }
}))

const ShowRuleDetails: FC<DetailsProps> = (props) => {
    const { rule, privacyPolicy, privacyLogs, closeDetails } = props;
    const style = useStyles()
    const classes = datagridStyles({});
    const dataset = useSelector((state: AppState) => state.dataset);
    const [hasDPA, setHasDPA] = useState<boolean>(true)

    useEffect(() => {
        if (privacyPolicy.per_request_epsilon === 0 && privacyPolicy.per_user_epsilon === 0 && privacyPolicy.global_epsilon === 0)
            setHasDPA(false)
    }, [privacyPolicy])

    const rows = privacyLogs.filter((privacyLog: PrivacyLog) => privacyLog.access_id === rule.raw.id);
    const columns: GridColDef[] = [
        { field: 'time', headerName: 'Time', renderCell: (params: GridCellParams) => OverflowTooltip({ tooltip: formatDateString(params.value as string) }), flex: 1 },
        { field: 'user', headerName: 'User', renderCell: (params: GridCellParams) => OverflowTooltip({tooltip: params.row.user.username}), flex: 1 },
        { field: 'action', headerName: 'Action', flex: 1, renderCell: (params) => OverflowTooltip({tooltip: params.value}) },
        { field: 'query_epsilon', headerName: 'Query privacy consumption', renderCell: (params: GridCellParams) => OverflowTooltip({ tooltip: (params.value as number).toFixed(3) }), flex: 1 },
    ]

    const showName = () => {
        const group = dataset.groups.find(group => group.name === rule.populated.group);
        if (group?.singleton) {
            const user = dataset.users.find(user => user.username === group.name || user.email === group.name)!;
            return DisplayUser(user);
        } else {
            return <>{group?.name}</>
        }
    }

    const showData = (data: any) => {
        const NumberFormatAny = NumberFormat as any;
        if (!data && data !== 0) {
            return <span className={style.defaultParam}>Unlimited</span>
        } else {
            return (
                <NumberFormatAny
                    displayType="text"
                    thousandSeparator={true}
                    value={data}
                />
            )
        }
    }

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <Typography variant="h3">
                    <GoBackButton action={closeDetails} tooltip="Back to access rule list" />
                    Access Rule detail {rule?.raw.revoked && `(Revoked)`}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                    An access rule enforces a privacy policy for a dataset and a user or group of users.
                    Privacy Policies can be created and edited from Privacy Policies section in the left menu,
                    and define how users should be able to access a dataset (access to synthetic data, to aggregated results,
                    Differential Privacy access and parameters etc.). <Link href="/docs/sarus_concepts.html#access-rule">Learn more</Link>.
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography variant="h4" gutterBottom>User / Group</Typography>
                {showName()}
            </Grid>
            <Grid item xs={8}>
                <Typography variant="h4" gutterBottom>Privacy Policy</Typography>
                {rule.populated.pp}
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4" gutterBottom>Privacy Policy detail</Typography>
                <Table>
                    <colgroup>
                        <col style={{ width: '33%' }} />
                        <col />
                    </colgroup>
                    <TableBody>
                        <TableRow>
                            <TableCell size="small">
                                <WithInfo
                                    content={<>Synthetic data access</>}
                                    info={
                                        <>
                                            Sarus by default provides synthetic data to let the data practitioners (data scientists, analysts)
                                            see and manipulate privacy-safe individual rows. Synthetic data are instrumental to get a feel of
                                            the source data, prepare analyses, build preprocessing pipelines, and design or debug ML models. <a href="/docs/sarus_concepts.html#synthetic-data">Learn more</a>.
                                        </>
                                    }
                                />
                            </TableCell>
                            <TableCell size="small">
                                <Switch disabled checked={privacyPolicy.synthetic_data_access} />
                                {privacyPolicy.synthetic_data_access ? 'Yes' : 'No'}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell size="small">
                                <WithInfo
                                    content={<>Differential Privacy access</>}
                                    info={
                                        <>
                                            When activated, all the data output accessed by the data practitioners under this privacy policy
                                            are protected by Differential Privacy. Some exceptions can be defined, see Whitelisting section
                                            below. <a href="/docs/sarus_concepts.html#differential-privacy">Learn more</a>.
                                        </>
                                    }
                                />
                            </TableCell>
                            <TableCell size="small">
                                <Switch disabled checked={hasDPA} />
                                {hasDPA ? 'Yes' : 'No'}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{ pb: 0, pt: 0 }} colSpan={2}>
                                <Box sx={{mr: 2, ml: 2}}>
                                    <Table>
                                        <colgroup>
                                            <col style={{ width: '33%' }} />
                                            <col />
                                        </colgroup>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell colSpan={2} size="small">
                                                    Differential Privacy parameters
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell size="small">DP per-query limit</TableCell>
                                                <TableCell size="small">{showData(privacyPolicy.per_request_epsilon)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell size="small">DP per-user limit</TableCell>
                                                <TableCell size="small">{showData(privacyPolicy.per_user_epsilon)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell size="small" sx={{borderBottom: '0'}}>DP per-group limit</TableCell>
                                                <TableCell size="small" sx={{borderBottom: '0'}}>{showData(privacyPolicy.global_epsilon)}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell size="small">
                                <WithInfo
                                    content={<>Whitelisting</>}
                                    info={
                                        <>
                                            On an exception basis and because you consider the risk is acceptable,
                                            you can decide to whitelist some operations or graphs for the data practitioners meaning the data practitioners
                                            will be allowed to directly execute those operations and graphs on the real remote data,
                                            without Differential Privacy. <a href="/docs/sarus_concepts.html#privacy-policy">Learn more</a>.
                                        </>
                                    }
                                />
                            </TableCell>
                            <TableCell size="small">
                                <Switch disabled checked={true} />
                                {true ? 'Yes' : 'No'}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{ pb: 0, pt: 0 }} colSpan={2}>
                                <Box sx={{ml: 2, mr: 2, py: 2}}>
                                    <ConsoleBox
                                        name="whitelistOps"
                                        update={() => {}}
                                        disable={true}
                                        error={''}
                                        baseValue={privacyPolicy.whitelisted_transforms}
                                        dlFilename={'whitelist_ops'}
                                    />
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4" gutterBottom>Access Logs</Typography>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    density="compact"
                    disableSelectionOnClick={true}
                    hideFooterSelectedRowCount={true}
                    disableColumnMenu={true}
                    sortModel={[{ field: 'time', sort: 'desc' as GridSortDirection }]}
                    autoHeight
                    className={classes.datagrid}
                />
            </Grid>
        </Grid>
    );
}

export default ShowRuleDetails;
