import React, { FC, ReactNode } from 'react'
import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        paddingBottom: theme.spacing(2),
        display: 'flex',
        justifyContent: (props: any) => props?.align === 'right' ? 'flex-end' : 'flex-start',
    },
    formBar: {
        display: 'flex',
        alignItems: 'flex-start',
        gap: theme.spacing(4),
        '& .button': {
            paddingTop: theme.spacing(2),
        }
    }
}));

type ActionProps = { children: ReactNode, align?: string };
export const ActionBar: FC<ActionProps> = ({ children, align }) => {
    const classes = useStyles({ align });
    return <div className={classes.root}>{children}</div>;
}

export const FormBar: FC<ActionProps> = ({ children }) => {
    const classes = useStyles({});
    return <div className={classes.formBar}>{children}</div>
}