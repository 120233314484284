import { AllAccessRule, CompleteDataset, Feature, MonitorProps } from "types/datasets";
import { Group } from "types/groups";
import { IPrivacyPolicy } from "types/privacy-policy";
import { User } from "types/user";
import { PrivacyLog } from "types/logs";
import { DataConnection } from "pages/DataConnectionShow";
import { SchemaRanges } from "types/schema";
import { ProtectionsDataType } from "store/reducers/DatasetReducer";

export type MonitorState = 'waiting' | 'calculating' | 'finished';

export const DATASET_LOADING = "DATASET_LOADING";
export const DATASET_UNLOAD = "DATASET_UNLOAD";
export const DATASET_FAIL = "DATASET_FAIL";
export const DATASET_SUCCESS = "DATASET_SUCCESS";
export const DATASET_EDIT = "DATASET_EDIT";
export const DATASET_RELOAD = "DATASET_RELOAD";
export const DATASET_ADD_CLICK = "DATASET_ADD_CLICK";
export const DATASET_RESET_CLICK = "DATASET_RESET_CLICK";

export const DATASET_SCHEMA_LOADING = "DATASET_SCHEMA_LOADING";
export const DATASET_SCHEMA_FAIL = "DATASET_SCHEMA_FAIL";
export const DATASET_SCHEMA_SUCCESS = "DATASET_SCHEMA_SUCCESS";
export const DATASET_SCHEMA_EDITED = "DATASET_SCHEMA_EDITED";

export const DATASET_SCHEMA_INIT_RANGES = "DATASET_SCHEMA_INIT_RANGES";
export const DATASET_SCHEMA_REMOVE_RANGE = "DATASET_SCHEMA_REMOVE_RANGE";
export const DATASET_SCHEMA_EDIT_RANGE = "DATASET_SCHEMA_EDIT_RANGE";
export const DATASET_SCHEMA_RANGES_EDITABLE = "DATASET_SCHEMA_RANGES_EDITABLE";

export const DATASET_UPDATE_MONITOR_STATE = "DATASET_UPDATE_MONITOR_STATE";
export const DATASET_UPDATE_MONITOR = "DATASET_UPDATE_MONITOR";

export const DATASET_UPDATE_PP_SELECT_LAST = "DATASET_UPDATE_PP_SELECT_LAST";
export const DATASET_RESET_LAST_PP = "DATASET_RESET_LAST_PP";

export const DATASET_EDIT_ACCESSRULES_INIT = "DATASET_EDIT_ACCESSRULES_INIT";
export const DATASET_EDIT_ACCESSRULES = "DATASET_EDIT_ACCESSRULES";
export const DATASET_RESET_ACCESSRULES_DATA = "DATASET_RESET_ACCESSRULES_DATA";

export const DATASET_EDIT_RELATIONSHIPS = "DATASET_EDIT_RELATIONSHIPS";
export const DATASET_EDIT_PROTECTIONS = "DATASET_EDIT_PROTECTIONS";
export const DATASET_INIT_PROTECTIONS = "DATASET_INIT_PROTECTIONS";

export interface DatasetLoading {
    type: typeof DATASET_LOADING
}

export interface DatasetUnload {
    type: typeof DATASET_UNLOAD
}

export interface DatasetFail {
    type: typeof DATASET_FAIL
}

export interface DatasetSuccess {
    type: typeof DATASET_SUCCESS,
    payload: {
        dataset: CompleteDataset,
        users: User[],
        canEdit: boolean,
        groups: Group[],
        privacyPolicies: IPrivacyPolicy[],
        privacyLogs: PrivacyLog[],
        accessRules: AllAccessRule[],
        owner: User,
        dataConnection?: DataConnection,
    }
}

export interface DatasetEdit {
    type: typeof DATASET_EDIT,
    payload: Partial<CompleteDataset>
}

export interface DatasetSchemaLoading {
    type: typeof DATASET_SCHEMA_LOADING
}

export interface DatasetSchemaFail {
    type: typeof DATASET_SCHEMA_FAIL,
    payload?: {
        message: string
    }
}

export interface DatasetSchemaSuccess {
    type: typeof DATASET_SCHEMA_SUCCESS,
    payload: {
        features: Feature[],
    }
}

export interface DatasetSchemaInitRanges {
    type: typeof DATASET_SCHEMA_INIT_RANGES,
    payload: SchemaRanges
}

export interface DatasetSchemaRemoveRange {
    type: typeof DATASET_SCHEMA_REMOVE_RANGE,
    payload: SchemaRanges
}

export interface DatasetSchemaEditRange {
    type: typeof DATASET_SCHEMA_EDIT_RANGE,
    payload: SchemaRanges
}

export interface DatasetSchemaEdited {
    type: typeof DATASET_SCHEMA_EDITED
}

export interface DatasetUpdateMonitorState {
    type: typeof DATASET_UPDATE_MONITOR_STATE,
    payload: MonitorState
}

export interface DatasetUpdateMonitor {
    type: typeof DATASET_UPDATE_MONITOR,
    payload: {
        isWorking: boolean,
        monitor: MonitorProps
    }
}

export interface DatasetUpdatePPSelectLast {
    type: typeof DATASET_UPDATE_PP_SELECT_LAST,
    payload: {
        privacyPolicies: IPrivacyPolicy[],
        pp: number,
        group: number
    }
}

export interface DatasetResetLastPP {
    type: typeof DATASET_RESET_LAST_PP
}

export interface DatasetEditAccessRulesInit {
    type: typeof DATASET_EDIT_ACCESSRULES_INIT
}

export interface DatasetEditAccessRules {
    type: typeof DATASET_EDIT_ACCESSRULES,
    payload: AllAccessRule[]
}

export interface DatasetReload {
    type: typeof DATASET_RELOAD,
    payload: CompleteDataset
}

export interface DatasetResetAccessRulesData {
    type: typeof DATASET_RESET_ACCESSRULES_DATA,
}

export interface DatasetAddClick {
    type: typeof DATASET_ADD_CLICK
}

export interface DatasetResetClick {
    type: typeof DATASET_RESET_CLICK
}

export interface DatasetSchemaRangesEditable {
    type: typeof DATASET_SCHEMA_RANGES_EDITABLE,
    payload: boolean
}

export interface DatasetEditRelationships {
    type: typeof DATASET_EDIT_RELATIONSHIPS,
    payload: string
}

export interface DatasetInitProtections {
    type: typeof DATASET_INIT_PROTECTIONS,
    payload: ProtectionsDataType
}

export interface DatasetEditProtections {
    type: typeof DATASET_EDIT_PROTECTIONS,
    payload: ProtectionsDataType
}

export type DatasetDispatchTypes =
    DatasetLoading
    | DatasetUnload
    | DatasetFail
    | DatasetSuccess
    | DatasetEdit
    | DatasetEditAccessRulesInit
    | DatasetEditAccessRules
    | DatasetSchemaLoading
    | DatasetSchemaFail
    | DatasetSchemaSuccess
    | DatasetSchemaInitRanges
    | DatasetSchemaRemoveRange
    | DatasetSchemaEditRange
    | DatasetSchemaEdited
    | DatasetSchemaRangesEditable
    | DatasetUpdateMonitorState
    | DatasetUpdateMonitor
    | DatasetUpdatePPSelectLast
    | DatasetResetLastPP
    | DatasetReload
    | DatasetResetAccessRulesData
    | DatasetAddClick
    | DatasetResetClick
    | DatasetEditRelationships
    | DatasetInitProtections
    | DatasetEditProtections