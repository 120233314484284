import {
    DatasetDispatchTypes,
    DATASET_ADD_CLICK,
    DATASET_EDIT,
    DATASET_EDIT_ACCESSRULES,
    DATASET_EDIT_ACCESSRULES_INIT,
    DATASET_FAIL,
    DATASET_LOADING,
    DATASET_RELOAD,
    DATASET_RESET_ACCESSRULES_DATA,
    DATASET_RESET_CLICK,
    DATASET_RESET_LAST_PP,
    DATASET_SCHEMA_FAIL,
    DATASET_SCHEMA_LOADING,
    DATASET_SCHEMA_SUCCESS,
    DATASET_SUCCESS,
    DATASET_UNLOAD,
    DATASET_UPDATE_PP_SELECT_LAST,
    DATASET_SCHEMA_EDIT_RANGE,
    DATASET_SCHEMA_REMOVE_RANGE,
    DATASET_SCHEMA_EDITED,
    DATASET_SCHEMA_INIT_RANGES,
    DATASET_SCHEMA_RANGES_EDITABLE,
    DATASET_EDIT_RELATIONSHIPS,
    DATASET_EDIT_PROTECTIONS,
    DATASET_INIT_PROTECTIONS
} from "store/actions/DatasetActionsType";
import { AllAccessRule, CompleteDataset } from "types/datasets";
import { Group } from "types/groups";
import { IPrivacyPolicy } from "types/privacy-policy";
import { User } from "types/user";
import { PrivacyLog } from "types/logs";
import { DataConnection } from "pages/DataConnectionShow";
import { SchemaRanges } from "types/schema";

export type LinkedKey = {foreign: string, primary: string}
export interface ProtectedKeyData {
    // the protection for one table
    selectedPK: {name: string, ref: string | null} | null,
    // simplified names and types
    status: "public" | "linked" | "private",
    options?: any,
}
export interface ProtectionsDataType {
    // the protections for all tables. Key is table path
    [key: string]: ProtectedKeyData
}

export interface DefaultDatasetState {
    loading: boolean,
    loadingSchema: boolean,
    loadingAccess?: boolean
    dataset?: CompleteDataset,
    users: User[],
    canEdit: boolean,
    owner?: User,
    groups: Group[],
    privacyPolicies: IPrivacyPolicy[],
    soonToBeCreatedRule: {group: number, pp: number},
    privacyLogs: PrivacyLog[],
    accessRules: AllAccessRule[],
    features?: any,
    dataConnection?: DataConnection,
    addingDataset?: boolean,
    schemaRanges?: SchemaRanges,
    schemaEdited: boolean,
    schemaEditable: boolean,
    relationships?: string,
    protections?: ProtectionsDataType,
};

const placeholderRelationships = `# You can uncomment and use the below template

#primary-keys:
#- namespace.table1.col1

#foreign-keys:
# namespace.table2.col3: namespace.table1.col1`

const defaultState: DefaultDatasetState = {
    loading: true,
    loadingSchema: false,
    users: [],
    canEdit: false,
    groups: [],
    privacyPolicies: [],
    soonToBeCreatedRule: {group: -1, pp: -1},
    privacyLogs: [],
    accessRules: [],
    schemaEdited: false,
    schemaEditable: true,
    protections: {},
    relationships: placeholderRelationships,
}

const datasetReducer = (state: DefaultDatasetState = defaultState, action: DatasetDispatchTypes): DefaultDatasetState => {
    switch (action.type) {
        case DATASET_LOADING:
            return {
                ...defaultState,
                loading: true
            }
        case DATASET_UNLOAD:
            return {
                loading: true,
                loadingSchema: false,
                users: [],
                canEdit: false,
                groups: [],
                privacyPolicies: [],
                soonToBeCreatedRule: {group: -1, pp: -1},
                privacyLogs: [],
                accessRules: [],
                addingDataset: false,
                schemaEdited: false,
                schemaEditable: true,
            }
        case DATASET_FAIL:
            return {
                ...state,
                loading: false,
            }
        case DATASET_SUCCESS:
            const newState = {
                ...state,
                loading: false,
                dataset: {...action.payload.dataset},
                users: action.payload.users,
                canEdit: action.payload.canEdit,
                groups: action.payload.groups,
                privacyPolicies: action.payload.privacyPolicies,
                privacyLogs: action.payload.privacyLogs,
                accessRules: action.payload.accessRules,
                owner: action.payload.owner,
                dataConnection: action.payload.dataConnection,
                addingDataset: false,
                schemaEdited: false,
                schemaEditable: true,
            }
            return newState
        case DATASET_EDIT:
            if (state.dataset) {
                return {
                    ...state,
                    dataset: {...state.dataset, ...action.payload }
                }
            } else {
                return state
            }
        case DATASET_SCHEMA_FAIL:
            return {
                ...state,
                loadingSchema: false,
            }
        case DATASET_SCHEMA_LOADING:
            return {
                ...state,
                loadingSchema: true
            }
        case DATASET_SCHEMA_SUCCESS:
            return {
                ...state,
                loadingSchema: false,
                features: action.payload.features
            }
        case DATASET_UPDATE_PP_SELECT_LAST:
            return {
                ...state,
                privacyPolicies: action.payload.privacyPolicies,
                soonToBeCreatedRule: {
                    group: action.payload.group,
                    pp: action.payload.pp
                }
            }
        case DATASET_RESET_LAST_PP:
            return {
                ...state,
                soonToBeCreatedRule: {group: -1, pp: -1}
            }
        case DATASET_EDIT_ACCESSRULES_INIT:
            return {
                ...state,
                loadingAccess: true
            }
        case DATASET_EDIT_ACCESSRULES:
            return {
                ...state,
                accessRules: action.payload,
                loadingAccess: false
            }
        case DATASET_RELOAD:
            return {
                ...state,
                dataset: action.payload
            }
        case DATASET_RESET_ACCESSRULES_DATA:
            return {
                ...state,
                loadingAccess: false,
                soonToBeCreatedRule: { group: -1, pp: -1 }
            }
        case DATASET_SCHEMA_INIT_RANGES:
            return {
                ...state,
                schemaRanges: action.payload
            }
        case DATASET_SCHEMA_EDIT_RANGE:
            let resultEditRange: {[key: string]: {[key: string]: any}} = {}
            const previousRangesInEdit = { ...state.schemaRanges }
            const schemaEditTable = Object.keys(action.payload)[0]
            if (Object.keys(previousRangesInEdit).find(x => x === schemaEditTable)) {
                const values = Object.values(action.payload)
                const keyToEdit = Object.keys(values[0])[0]
                if (previousRangesInEdit[schemaEditTable][keyToEdit] !== values[0][keyToEdit]) {
                    resultEditRange = { ...previousRangesInEdit }
                    resultEditRange[schemaEditTable][keyToEdit] = values[0][keyToEdit]
                }
            } else {
                resultEditRange = {...previousRangesInEdit, ...action.payload}
            }
            return {
                ...state,
                schemaRanges: resultEditRange
            }
        case DATASET_SCHEMA_REMOVE_RANGE:
            let resultRemoveRange: { [key: string]: { [key: string]: any } } = {}
            const previousRangesInRemove = { ...state.schemaRanges }
            const schemaRemoveTable = Object.keys(action.payload)[0]
            if (Object.keys(previousRangesInRemove).find(x => x === schemaRemoveTable)) {
                const valuesInRm = Object.values(action.payload)
                const keyToEditInRm = Object.keys(valuesInRm[0])[0]
                if (previousRangesInRemove.hasOwnProperty(schemaRemoveTable) && typeof previousRangesInRemove[schemaRemoveTable] === 'object') {
                    delete previousRangesInRemove[schemaRemoveTable][keyToEditInRm]
                    if (Object.keys(previousRangesInRemove[schemaRemoveTable]).length === 0) {
                        delete previousRangesInRemove[schemaRemoveTable]
                    }
                }
                resultRemoveRange = {...previousRangesInRemove}
            }
            return {
                ...state,
                schemaRanges: resultRemoveRange
            }
        case DATASET_SCHEMA_EDITED:
            return {
                ...state,
                schemaEdited: true,
            }
        case DATASET_ADD_CLICK:
            return {
                ...state,
                addingDataset: true
            }
        case DATASET_RESET_CLICK:
            return {
                ...state,
                addingDataset: false
            }
        case DATASET_SCHEMA_RANGES_EDITABLE:
            return {
                ...state,
                schemaEditable: action.payload
            }
        case DATASET_EDIT_RELATIONSHIPS:
            return {
                ...state,
                relationships: action.payload
            }
        case DATASET_INIT_PROTECTIONS:
            return {
                ...state,
                protections: action.payload
            }
        case DATASET_EDIT_PROTECTIONS:
            return {
                ...state,
                protections: action.payload
            }
        default:
            return state
    }
}

export default datasetReducer;
