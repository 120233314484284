import apiHandler from 'api/apiHandler';
import { DataConnection } from 'pages/DataConnectionShow';
import { Dispatch } from 'redux';
import { AppState } from 'store/configureStore';
import { LinkedKey, ProtectedKeyData } from 'store/reducers/DatasetReducer';
import { AccessRule, AccessRulePopulated, AllAccessRule, CompleteDataset, Feature } from 'types/datasets';
import { Group } from 'types/groups';
import { IPrivacyPolicy } from 'types/privacy-policy';
import { User } from 'types/user';
import { parse as parseFromPackage } from "yaml"

import {
    DatasetDispatchTypes,
    DATASET_LOADING,
    DATASET_SUCCESS,
    DATASET_FAIL,
    DATASET_SCHEMA_FAIL,
    DATASET_SCHEMA_LOADING,
    DATASET_SCHEMA_SUCCESS,
    DATASET_UPDATE_PP_SELECT_LAST,
    DATASET_RESET_LAST_PP,
    DATASET_EDIT,
    DATASET_EDIT_ACCESSRULES,
    DATASET_RELOAD,
    DATASET_EDIT_ACCESSRULES_INIT,
    DATASET_RESET_ACCESSRULES_DATA,
    DATASET_UNLOAD,
    DATASET_ADD_CLICK,
    DATASET_RESET_CLICK,
    DATASET_SCHEMA_EDIT_RANGE,
    DATASET_SCHEMA_REMOVE_RANGE,
    DatasetSchemaEdited,
    DATASET_SCHEMA_EDITED,
    DATASET_SCHEMA_INIT_RANGES,
    DATASET_SCHEMA_RANGES_EDITABLE,
    DATASET_EDIT_RELATIONSHIPS,
    DATASET_EDIT_PROTECTIONS,
    DATASET_INIT_PROTECTIONS,
} from './DatasetActionsType';
import { SchemaRanges } from 'types/schema';
import { directPathsToPe, shortestPath} from 'utils/key-propagation';

const getAllAccessRules = (accesses: AccessRule[], groups: Group[], privacyPolicies: IPrivacyPolicy[]) => {
    return accesses.map((access: AccessRule) => {
            const populated: AccessRulePopulated = {
                id: access.id,
                group: groups.find((group: Group) => group.id === access.group_id)!.name,
                pp: privacyPolicies.find((pp: IPrivacyPolicy) => pp.id === access.pp_id)!.name,
                current_epsilon: access.current_epsilon,
                delta: access.delta,
                max_expsilon: access.max_epsilon,
                revoked: access.revoked
            }
            return {
                raw: access,
                populated
            }
        })
}

export const LoadDataset = (id: number, perm: number, userId: number = 0) => async (dispatch: Dispatch<DatasetDispatchTypes>) => {
    try {
        dispatch({ type: DATASET_LOADING })
        const datasetCall = apiHandler.getDataset(id);

        const usersCall = apiHandler.getAllUsers();
        const groupsCall = apiHandler.getAllGroups();
        const privacyPoliciesCall = apiHandler.getAllPrivacyPolicies();
        const allCalls = await Promise.all([datasetCall, usersCall, groupsCall, privacyPoliciesCall]);
        const dataset = allCalls[0];
        const users = allCalls[1];
        const groups = allCalls[2];
        const privacyPolicies = allCalls[3];
        let connector: DataConnection | undefined = undefined;

        if (dataset.dataconnection_id)
            connector = await apiHandler.getDataConnection(dataset.dataconnection_id)

        const owner = users.find((user: User) => user.id === dataset.owner_id);
        const accessRules = getAllAccessRules(dataset.accesses, groups, privacyPolicies);

        let hasPerm = false;
        if (perm === 1 || (perm === 2 && dataset.owner_id === userId)) {
            hasPerm = true
        }

        dispatch({
            type: DATASET_SUCCESS,
            payload: {
                dataset: dataset,
                users: users,
                canEdit: hasPerm,
                groups: groups,
                privacyPolicies: privacyPolicies,
                privacyLogs: dataset.privacy_logs,
                accessRules: accessRules,
                owner: owner,
                dataConnection: connector,
            }
        })

    } catch (e) {
        console.log(e);
        dispatch({ type: DATASET_FAIL })
    }
};

export const UnloadDataset = () => async (dispatch: Dispatch<DatasetDispatchTypes>) => {
    dispatch({ type: DATASET_UNLOAD });
}

export const SetSchemaState = (state: 'loading' | 'success' | 'fail', error?: string, payload?: Feature[]) => async (dispatch: Dispatch<DatasetDispatchTypes>) => {
    if (state === 'loading') {
        dispatch({ type: DATASET_SCHEMA_LOADING });
    } else if (state === 'success') {
        dispatch({ type: DATASET_SCHEMA_SUCCESS, payload: {features: payload!} });
    } else {
        if (error) {
            dispatch({ type: DATASET_SCHEMA_FAIL, payload: {message: error} });
        } else {
            dispatch({ type: DATASET_SCHEMA_FAIL })
        }
    }
}

export const SetFeatures = (features: Feature[]) => async (dispatch: Dispatch<DatasetDispatchTypes>) => {
    try {
        dispatch({ type: DATASET_SCHEMA_SUCCESS, payload: { features: features } });
    } catch (e) {
        dispatch({ type: DATASET_SCHEMA_FAIL, payload: { message: "Erreur while parsing metadata" } });
    }
}

export const ReloadDataset = (id: number) => async (dispatch: Dispatch<DatasetDispatchTypes>) => {
    try {
        const dataset = await apiHandler.getDataset(id);
        dispatch({ type: DATASET_RELOAD, payload: dataset });
    } catch (e) {
        dispatch({ type: DATASET_FAIL });
    }
}

export const ReloadWithThisDataset = (dataset: any) => async (dispatch: Dispatch<DatasetDispatchTypes>) => {
    dispatch({ type: DATASET_RELOAD, payload: dataset })
}

export const EditDataset = (id: number, data: Partial<CompleteDataset>) => async (dispatch: Dispatch<DatasetDispatchTypes>) => {
    try {
        await apiHandler.editDataset(id, data);
        dispatch({ type: DATASET_EDIT, payload: data });
    } catch (e) {
        dispatch({ type: DATASET_FAIL });
    }
}

export const UpdatePPAndSelectLast = (group: number) => async (dispatch: Dispatch<DatasetDispatchTypes>) => {
    const privacyPolicies: IPrivacyPolicy[] = await apiHandler.getAllPrivacyPolicies();
    dispatch({
        type: DATASET_UPDATE_PP_SELECT_LAST,
        payload: {
            privacyPolicies: privacyPolicies,
            group,
            pp: privacyPolicies[privacyPolicies.length - 1].id!
        }
    })
}

export const ResetLastPP = () => async (dispatch: Dispatch<DatasetDispatchTypes>) => {
    dispatch({ type: DATASET_RESET_LAST_PP });
}

export const RevokeAccessRule = (id: number, accesses: AllAccessRule[]) => async (dispatch: Dispatch<DatasetDispatchTypes>) => {
    const updatedAccesses = accesses.map((access) => {
        if (access.raw.id === id) {
            access.raw.revoked = true;
            access.populated.revoked = true;
        }
        return access;
    })
    dispatch({ type: DATASET_EDIT_ACCESSRULES, payload: updatedAccesses });
}

export const ReloadAccesses = (id: number) => async (dispatch: Dispatch<DatasetDispatchTypes>, getState: () => AppState) => {
    try {
        dispatch({ type: DATASET_EDIT_ACCESSRULES_INIT });
        const dataset: CompleteDataset = await apiHandler.getDataset(id);
        const accesses = getAllAccessRules(dataset.accesses, getState().dataset.groups,  getState().dataset.privacyPolicies);
        dispatch({ type: DATASET_EDIT_ACCESSRULES, payload: accesses });
    } catch (e) {

    }
}

export const CreateAccessRule = (id: number, accesses: AccessRule[]) => async (dispatch: Dispatch<DatasetDispatchTypes>, getState: () => AppState) => {
    try {
        dispatch({ type: DATASET_EDIT_ACCESSRULES_INIT });
        await apiHandler.editDataset(id, { accesses });
        const reloadedDataset: CompleteDataset = await apiHandler.getDataset(id);
        const reloadedAccesses = getAllAccessRules(reloadedDataset.accesses, getState().dataset.groups, getState().dataset.privacyPolicies);
        dispatch({ type: DATASET_EDIT_ACCESSRULES, payload: reloadedAccesses });
        dispatch({ type: DATASET_RESET_ACCESSRULES_DATA });
    } catch (error) {
        console.log("Error in Create Access Rule")
    }
}

export const SchemaToggleEditableRanges = (editable: boolean) => async (dispatch: Dispatch<DatasetDispatchTypes>) => {
    dispatch({type: DATASET_SCHEMA_RANGES_EDITABLE, payload: editable})
}

export const AddClick = () => async (dispatch: Dispatch<DatasetDispatchTypes>) => {
    dispatch({ type: DATASET_ADD_CLICK })
}

export const EditionInSchema = () => async (dispatch: Dispatch<DatasetSchemaEdited>) => {
    dispatch({ type: DATASET_SCHEMA_EDITED})
}

export const ResetClick = () => async (dispatch: Dispatch<DatasetDispatchTypes>) => {
    dispatch({type: DATASET_RESET_CLICK})
}

export const SchemaEditRange = (column: SchemaRanges) => async (dispatch: Dispatch<DatasetDispatchTypes>) => {
    dispatch({ type: DATASET_SCHEMA_EDIT_RANGE, payload: column})
}

export const SchemaRemoveRange = (column: SchemaRanges) => async (dispatch: Dispatch<DatasetDispatchTypes>) => {
    dispatch({ type: DATASET_SCHEMA_REMOVE_RANGE, payload: column})
}

export const SchemaInitRanges = (ranges: SchemaRanges) => async (dispatch: Dispatch<DatasetDispatchTypes>) => {
    dispatch({type: DATASET_SCHEMA_INIT_RANGES, payload: ranges})
}

export const EditRelationships = (relationships: string) => async (dispatch: Dispatch<DatasetDispatchTypes>) => {
    console.log("dispatch editRelation ")
    console.log(relationships)
    dispatch({ type: DATASET_EDIT_RELATIONSHIPS, payload: relationships })
}

export const InitProtections = (protectionsList: {[key: string]: ProtectedKeyData}) => async (dispatch: Dispatch<DatasetDispatchTypes>) => {
    dispatch({type: DATASET_INIT_PROTECTIONS, payload: protectionsList})
}

const getPrimaryFromRef = (ref: string): string => {
    const rawPrimary = ref.split(":")[1].split(".")
    rawPrimary.pop()
    return rawPrimary.join(".")
}
const getParsedProtectionKey = (primary: string): string => {
    const rawProtectionKey = JSON.parse(primary)
    rawProtectionKey.shift()
    return rawProtectionKey.join(".")
}
//const checkHasPrimaryInOptionsRef = (option: any, primary: string): boolean => {
//    const parsedPrimary = getPrimaryFromRef(option.ref)
//    const parsedProtectionKey = getParsedProtectionKey(primary)
//    return parsedPrimary === parsedProtectionKey
//}

const isInherited = (val?: string) => val ? val.startsWith("Inherited from") : false

export const EditProtections = (protections: {[key:string]: string | null}) => async (dispatch: Dispatch<DatasetDispatchTypes>, getState: () => AppState) => {
    // TODO: the format of the key (json.stringify of an array) is really bad, we should get rid of it

    const removeColsFromTables = (array: string[]): string[] => {
        const mapped = array.map(item => {
            const segmented = item.split('.')
            segmented.pop()
            return segmented.join(".")
        })
        return Array.from(new Set(mapped))
    }
    const formatTableName = (key:string):string => {
        const fullNameCol = JSON.parse(key)
        const prefix = [...fullNameCol].shift()
        const woPrefix = fullNameCol.slice(1)
        let fullName = woPrefix.join(".")
        return fullName
    }

    const isDeletingKey = Object.keys(protections).length === 1 && Object.values(protections)[0] === null
    const currentProtections = {...getState().dataset.protections}
    const parsedRelationships = parseFromPackage(getState().dataset.relationships || "")

    for(const [key, value] of Object.entries(protections)) {
        // set correctly the redux state for the protections
        let optionalRef: null | string = null
        if(value && isInherited(value)) {
            const splittedValue = value.split(":")
            const primaryRef = splittedValue[0].replace("Inherited from", "").trim()
            const foreignRef = `${getParsedProtectionKey(Object.keys(protections)[0])}.${splittedValue[1].trim()}`
            optionalRef = `${foreignRef}:${primaryRef}`
        }
        let newKey: ProtectedKeyData = {
            selectedPK: value ? {name: value, ref: optionalRef} : null,
            status: value ? "private" : "public",
            options: currentProtections[key].options
        }
        // add the new protection in the redux state
        currentProtections[key] = newKey
    }

    if(parsedRelationships && parsedRelationships['foreign-keys']) {
        // handle linked tables if there are FK declared
        console.log("let's handle linked tables...")
        // new code from Luca
 
        let tablesWithPeId: string[]=[];
        for (const [table, protectionData] of Object.entries(currentProtections)) {
            if (protectionData.status==="private") {
                tablesWithPeId.push(formatTableName(table))
            }
          }
        const pathsToPeid = directPathsToPe(tablesWithPeId, parsedRelationships['foreign-keys'])
        console.log(pathsToPeid)
        let shortPaths: {[table:string]:string []} = {};
        for(const [tableA, path_lists] of Object.entries(pathsToPeid)) {
            shortPaths[tableA] = shortestPath(path_lists)  // TODO check not public per Luca's work
        }

        const prefix = "sarus_data"; // TODO
        const findOptionFromTables = (table: string, path: string [], fks: string []):{name:string, ref:string} => {
            for (const [foreign,primary] of Object.entries(fks)) {
                const [foreignNoCol,primaryNoCol] = removeColsFromTables([foreign,primary]);
                if (foreignNoCol===table && primaryNoCol === path[1]) {
                    const foreignCol = foreign.split(".").pop(); //only the column
                    return {
                        name: `Inherited from `+primary+`: `+foreignCol,
                        ref: foreign+`:`+primary
                    }
                }
            }
            return {
                name: `Inherited from unknown`,
                ref: `None`
            }
        }

        // here shortPaths is a dict with key is table name and value is a list of paths that allows to go to a peid
        for (const [linkedTable, path] of Object.entries(shortPaths)) {
            console.log("handling linked table : "+linkedTable)
            // we always add the option, and select it unless the table was already protected
            const stringifiedLinkedTableName = JSON.stringify([prefix, ...linkedTable.split(".")])
            const currentOptions = currentProtections[stringifiedLinkedTableName].options;
            let newOptions: any = []
            newOptions.push(
                findOptionFromTables(linkedTable, path, parsedRelationships['foreign-keys'])
            )
            if (!currentOptions.map((o: any) => o.name).includes(newOptions[0].name)) {
                currentProtections[stringifiedLinkedTableName].options = [...newOptions, ...currentOptions]
            }

            // then, we want to update table that were not private (so public or linked), but not the one public if we deleted
            if(currentProtections[stringifiedLinkedTableName].status === "linked" || (!isDeletingKey && currentProtections[stringifiedLinkedTableName].status === "public")) {
                currentProtections[stringifiedLinkedTableName].status = "linked"
                currentProtections[stringifiedLinkedTableName].selectedPK = newOptions[0]
            }
        }
    }

    if(isDeletingKey) {
        // in case we removed a protection key
        // console.log("Deleting a protection key:")
        // console.log(protections)
        // console.log("current protections:")
        // console.log(currentProtections);
        const parsedDeletedProtectionKey = getParsedProtectionKey(Object.keys(protections)[0])

        // let's look at all the tables
        for(const [key, value] of Object.entries(currentProtections)) {
            console.log(currentProtections[key].options);
            // remove the linked PK from the drop down options
            // if there is an option with a ref, filter the options list to remove the ones
            // @GDG TO CHECK: I got rid of the condition here and apply the filter always
        //    if(value.options.some((option: any) => {
    //            if(!option.ref) return false
    //            return checkHasPrimaryInOptionsRef(option, Object.keys(protections)[0])
    //        }))
    //        {
                currentProtections[key].options = currentProtections[key].options.filter((option: any) => {
                    if(!option.ref) return true
                    const parsedPrimary = getPrimaryFromRef(option.ref)
                    return parsedPrimary !== parsedDeletedProtectionKey
                })
    //        }
            if(value.selectedPK) {
                // now check if we need to remove the selected key because it was inherited
                // console.log("The table we are checking...");
                // console.log(key);
                // console.log("the selected value we are checking for deletion")
                // console.log(value.selectedPK.name)
                // TODO: avoid parsing the selectedPK.name but use ref : the issue is that ref may be empty
                const matches = value.selectedPK.name.match(/Inherited from (.*): .*/);
                // console.log("We extract the table name it comes from")
                if (matches) {
                    const table_array = matches[1].split("."); // this looks like a bug, but it's actually the first group
                    table_array.pop();
                    if(table_array.join(".")===parsedDeletedProtectionKey) {
                        // we unselect and make the table public
                        value.status = "public";
                        value.selectedPK = null
                    }
                }
            }
        }
    }

    dispatch({ type: DATASET_EDIT_PROTECTIONS, payload: currentProtections })
}
