import { useEffect, useState } from 'react'
import apiHandler from 'api/apiHandler'
import { GridColDef, GridCellParams } from '@mui/x-data-grid';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { IPrivacyPolicy } from 'types/privacy-policy'
import { Body } from 'styles/layout';
import { Title } from 'components/stylized/titles';
import { Typography } from '@mui/material';
import { Search } from 'types/datagrid';
import { DescList } from 'components/stylized/listItems';
import CloseIcon from '@mui/icons-material/Close';
import { formatNumber } from 'utils/misc';
import ListResource from 'assets/datagrids/ListResource';
import OverflowTooltip from 'hooks/OverflowTooltip';

const DEFAULT_WHITELIST = "WHITELISTED_TRANSFORMS: []\n"

const useStyles = makeStyles((theme: Theme) => ({
    defaultParam: {
            fontStyle: 'italic',
            color: theme.palette.grey[500]
        }
}))

const PrivacyPoliciesList = () => {
    const [privacypolicies, setPrivacyPolicies] = useState<IPrivacyPolicy[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [errorOnLoading, setErrorOnLoading] = useState<boolean>(false);
    const classes = useStyles();

    useEffect(() => {
        let isSubscribed = true;
        apiHandler.getAllPrivacyPolicies().then(result => {
            if (isSubscribed) {
                const PP = result.map((x: any) => ({...x, whitelist: x.whitelisted_transforms !== DEFAULT_WHITELIST}))
                setPrivacyPolicies(PP);
                setIsLoading(false);
            }
        }).catch(error => setErrorOnLoading(true));
        return () => {
            isSubscribed = false;
        }
    }, [])

    const deletePrivacyPolicy = async (ids: number[]) => {
        for (const id of ids) {
            try {
                await apiHandler.deletePrivacyPolicy(id)
            } catch (error) {
                console.error(`Failed to delete privacy policy with ID ${id}: ${error}`)
            }
        }
        const updatedPP = privacypolicies.filter(policy => typeof policy.id !== 'undefined' && !ids.includes(policy.id))
        setPrivacyPolicies(updatedPP)
    }

    const renderDPParams = (params: GridCellParams) => {
        if (params.row.global_epsilon === 0 && params.row.per_request_epsilon === 0 && params.row.per_user_epsilon === 0) {
            return <><CloseIcon style={{fontSize: 20, color: 'rgba(0, 0, 0, 0.38)'}}/></>
        } else if (!params.value && params.value !== 0) {
            return <span className={classes.defaultParam}>Unlimited</span>
        }
        return <>{formatNumber(params.value)}</>
    }

    const columns: GridColDef[] = [
        { field: 'id', hide: true },
        { field: 'name', headerName: 'Name', type: 'string', flex: 0.8, renderCell: (params) => OverflowTooltip({tooltip: params.value}) },
        {
            field: 'synthetic_data_access',
            headerName: 'Synth. data access', type: 'boolean', flex: 0.5, headerAlign: 'center'
        },
        {
            field: 'per_request_epsilon',
            headerName: 'DP per-query limit', align: 'center', flex: 0.5,
            renderCell: renderDPParams, headerAlign: 'center'
        },
        {
            field: 'per_user_epsilon',
            headerName: 'DP per-user limit', align: 'center', flex: 0.5,
            renderCell: renderDPParams, headerAlign: 'center'
        },
        {
            field: 'global_epsilon',
            headerName: 'DP per-group limit', align: 'center', flex: 0.5,
            renderCell: renderDPParams, headerAlign: 'center'
        }, {
            field: 'whitelist',
            headerName: 'Whitelisting', type: 'boolean', flex: 0.5, headerAlign: 'center'
        }
    ];

    const searchData: Search = {
        label: 'Search a privacy policy',
        type: 'privacypolicy'
    }

    return (
        <Body>
            <Title variant="h2" comp="h1">Privacy Policies</Title>
            <DescList>
                <Typography variant="body2" color="textSecondary">
                    Privacy Policies define how users should be able to access the datasets (access to synthetic data, to aggregated results, Differential Privacy access etc.)<br />
                    They are enforced by the access rules at the dataset level. 
                </Typography>
            </DescList>
            <ListResource
                rows={privacypolicies}
                columns={columns}
                loading={isLoading}
                doDelete={deletePrivacyPolicy}
                canSearch={searchData}
                errorOnLoading={errorOnLoading}
                resource="privacy-policies"
            
            />
        </Body>
    )
}

export default PrivacyPoliciesList
