import Chip from "@mui/material/Chip"


export const ProtectedChip = () => (
    <Chip label="Protected" sx={{ backgroundColor: "#78BEC2", color: "primary.dark" }} size="small" />
)

export const PublicChip = () => (
    <Chip label="Public" sx={{ backgroundColor: "warning.light", color: "warning.main" }} size="small" />
)

export const LinkedChip = () => (
    <Chip label="Linked" sx={{bgcolor: "rgba(120, 190, 194, 0.5)", color: "primary.dark"}} size="small"/>
)