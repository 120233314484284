import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { FC, ReactNode } from 'react'

export interface ButtonModal {
    label?: string,
    type?: string
}

interface DialogProps {
    title?: string,
    open: boolean,
    confirm: () => void,
    close: () => void,
    content: ReactNode,
    buttons?: {
        confirm?: Partial<ButtonModal>,
        close?: Partial<ButtonModal>
    }
}

const DialogBox: FC<DialogProps> = props => {
    const {
        title,
        open,
        confirm,
        close,
        content,
        buttons
    } = props

    const DBTitle = () => {
        if (!title) return null
        return (
            <DialogTitle>
                {title}
            </DialogTitle>
        )
    }

    const DBConfirmAction = () => {
        return (
            <Button onClick={confirm}>
                {buttons?.confirm?.label ?? 'Confirm'}
            </Button>
        )
    }

    const DBCancelAction = () => {
        return (
            <Button onClick={close}>
                {buttons?.close?.label ?? 'Cancel'}
            </Button>
        )
    }

    return (
        <Dialog open={open} onClose={close}>
            <DBTitle />
            <DialogContent>
                <DialogContentText>
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <DBCancelAction />
                <DBConfirmAction />
            </DialogActions>
        </Dialog>
    )
}

export default DialogBox