import React, { useState, useEffect, useContext, useCallback, useRef } from 'react'
import { Body } from 'styles/layout';
import apiHandler from 'api/apiHandler';
import { useHistory, useLocation, useParams } from 'react-router';
import Loading from 'components/utils/Loading';
import { UserContext } from 'context/authContext';
import Stepper from 'components/Stepper';
import TitleDataset from 'components/TitleDataset';
import InfoAndSource from 'components/datasets/InfoAndSource';
import Schema from 'components/datasets/Schema/index';
import AccessRules from 'components/datasets/AccessRules';
import SyntheticData from 'components/datasets/SyntheticData/index';
import { Grid } from '@mui/material';
import TopCloseButton from 'components/TopCloseButton';
import { isEqual } from 'lodash'

import { createSelector } from '@reduxjs/toolkit';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/configureStore';
import { LoadDataset, ReloadDataset, ReloadWithThisDataset, UnloadDataset } from 'store/actions/DatasetActions';
import { AddMonitorToManager, StartMonitoring } from 'store/actions/MonitorActions';
import { InitSchema, SchemaChange, SchemaCheck, SchemaUnload } from 'store/actions/SchemaActions';
import { AppSetResource, AppUnsetResource } from 'store/actions/AppActions';

type APICall = { data: any, status: number };
type renderProps = { activeTab: number };

const monitorList = (state: AppState) => state.monitor
const selectorMonitor = (id: number) => createSelector(
    monitorList,
    (list) => list[id]?.status
)

const datasetData = (state: AppState) => state.dataset
const selectorDataset = () => createSelector(
    datasetData,
    (ds) => ({dataset: ds.dataset, isLoading: ds.loading, isSchemaLoading: ds.loadingSchema})
)

const selectorBase = (state: AppState) => state.schema.checkingState

const DatasetEdit = () => {
    const history = useHistory();
    const location = useLocation();
    const { datasetId } = useParams<{ datasetId: string }>();
    const { user, hasPermissions } = useContext(UserContext);
    const [activeTab, setActiveTab] = useState<number>(0);
    const [initialized, setInitialized] = useState<boolean>(false);

    const dispatch = useDispatch();
    const schemaChecked = useSelector(selectorBase, isEqual);
    const { dataset, isLoading, isSchemaLoading } = useSelector(selectorDataset(), isEqual)
    const monitor = useSelector(selectorMonitor(parseInt(datasetId)), isEqual)
    const lastMonitorState = useRef<undefined | string>(undefined)

    useEffect(() => {
        if (lastMonitorState.current !== monitor) {
            if (monitor === 'finished') {
                dispatch(ReloadDataset(parseInt(datasetId)))
            }
            lastMonitorState.current = monitor
        }
    }, [monitor])

    useEffect(() => {
        if (!hasPermissions(['dataset.listAll', 'dataset.listOwn'])) history.push('/datasets');
        let perm = 0;
        if (hasPermissions('dataset.editAny')) perm = 1;
        else if (hasPermissions('dataset.editOwn')) perm = 2;
        dispatch(LoadDataset(parseInt(datasetId), perm, user?.id))
        dispatch(InitSchema());

        return () => {
            dispatch(UnloadDataset());
            dispatch(AppUnsetResource());
            dispatch(SchemaUnload());
        }
    }, [history, datasetId, user, hasPermissions, dispatch])

    const checkSchema = useCallback((id: number): any => {
        apiHandler.checkSchema(id).then(result => {
            if (result.status === 'PENDING') {
                dispatch(SchemaCheck('PENDING'))
                return new Promise(resolve => {
                    setTimeout(resolve, 500)
                }).then(() => {
                    checkSchema(id)
                });
            } else {
                setTimeout(() => dispatch(SchemaCheck(result.status, result.info)), 1000)
                return result;
            }
        })
    }, [dispatch]);

    const doInferSchema = useCallback((id: number) => {
        if (monitor !== undefined) {
            // Don't redo an infer schema if it's already in state
            return;
        }
        dispatch(SchemaChange('WORKING'))
        apiHandler.inferSchema(id).then(result => {
            if ((result as APICall).status !== 200) dispatch(SchemaChange('ERROR', 'API Call not 200'))
            else {
                checkSchema(id)
            }
        }).catch(() => dispatch(SchemaChange('ERROR', 'Error catched inferSchema')));
    }, [checkSchema, dispatch, monitor])

    useEffect(() => {
        // if ((dataset && dataset.id === parseInt(datasetId)) && !initialized) {
        if (dataset && !initialized) {
            setInitialized(true);
            dispatch(AddMonitorToManager(dataset.id, dataset.status))
            dispatch(AppSetResource(dataset.name))

            if (dataset?.schema_data_type && Object.keys(dataset.schema_data_type).length === 0) {
                // INFER SCHEMA
                doInferSchema(parseInt(datasetId));
            } else {
                // SET FEATURES
                dispatch(SchemaChange('FINISHED', dataset.schema_data_type))
            }
        }
    }, [dataset, dispatch, datasetId, doInferSchema, initialized])

    useEffect(() => {
        if (!isSchemaLoading && (dataset && (dataset.status !== 'ready' && dataset.status !== 'empty')) && monitor === 'none') {
            dispatch(StartMonitoring(dataset.id))
        }
    }, [isSchemaLoading, dataset, monitor, dispatch])


    useEffect(() => {
        if (schemaChecked === 'SUCCESS') {
            setTimeout(() => apiHandler.getDataset(parseInt(datasetId)).then(reloadedDataset => {
                if (Object.keys(reloadedDataset.schema_data_type).length === 0) {
                    dispatch(SchemaChange('ERROR', 'empty'));
                } else {
                    dispatch(SchemaChange('FINISHED', reloadedDataset.schema_data_type));
                    dispatch(ReloadWithThisDataset(reloadedDataset))
                }
            }).catch((e) => console.log(e)), 1000)
        }
    }, [schemaChecked, datasetId, dispatch])

    // Set active tab by decomposing last element of the url
    useEffect(() => {
        const pathname = location.pathname;
        const decomposedPath = pathname.slice(1).split('/');
        const lastElement = decomposedPath[decomposedPath.length - 1];
        if (lastElement === 'schema') {
            setActiveTab(1);
        } else if (lastElement === 'synthetic-data') {
            setActiveTab(2);
        } else if (lastElement === 'access-rules') {
            setActiveTab(3);
        }
    }, [location]);

    const handleChange = (event: React.ChangeEvent<{}>, value: number) => {
        setActiveTab(value);
        const baseUrl = `/datasets/${datasetId}`;
        switch (value) {
            case 0:
                history.push(`${baseUrl}`);
                break;
            case 1:
                history.push(`${baseUrl}/schema`);
                break;
            case 2:
                history.push(`${baseUrl}/synthetic-data`);
                break;
            case 3:
                history.push(`${baseUrl}/access-rules`);
                break;
        }
    };

    const RenderStep = React.memo(({ activeTab }: renderProps) => {
        switch (activeTab) {
            case 0:
                return <InfoAndSource />;
            case 1:
                return <Schema />
            case 2:
                return <SyntheticData />
            default:
                return <AccessRules />
        }
    })

    if (isLoading) {
        return <Loading label="Loading dataset..." />
    } else if (!isLoading && dataset == null) {
        return (
            <Loading label="Dataset not found." secondLabel="It might have been removed or you don't have access." noProgress />
        )
    } else {
        return (
            <Body>
                <Grid container>
                    <Grid item xs={11}>
                        <TitleDataset />
                    </Grid>
                    <Grid item xs={1} style={{ textAlign: 'right', display: 'flex', alignItems: 'center', marginTop: 8, marginBottom: 21, justifyContent: 'flex-end' }}>
                        <div style={{marginRight: -5}}>
                            <TopCloseButton action={() => history.push('/datasets')} />
                        </div>
                    </Grid>
                </Grid>
                <Stepper steps={[
                    { label: "Info & source" },
                    { label: "Schema" },
                    { label: "Synthetic data" },
                    { label: "Access rules", disabled: dataset?.status === 'empty' },
                ]} onChange={handleChange} actualStep={activeTab} />
                <RenderStep activeTab={activeTab} />
            </Body>
        )
    }

}

export default DatasetEdit
