import { createSelector } from '@reduxjs/toolkit';
import apiHandler from 'api/apiHandler';
import ErrorDisplay from 'assets/screens/ErrorDisplay';
import axios, { AxiosError } from 'axios';
import ShowSchema from 'components/tables/ShowSchema';
import Loading from 'components/utils/Loading';
import { UserContext } from 'context/authContext';
import { isEqual } from 'lodash';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { AddClick, LoadDataset, ReloadDataset, ReloadWithThisDataset, SchemaToggleEditableRanges } from 'store/actions/DatasetActions';
import { AddMonitorToManager } from 'store/actions/MonitorActions';
import { SchemaChange } from 'store/actions/SchemaActions';
import { AppState } from 'store/configureStore';

export type ErrorConsole = {id: number, message: string}

interface SchemaProps {
}

const datasetStatus = (state: AppState) => state.dataset.dataset?.status ?? ''

const Schema: FC<SchemaProps> = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {user, hasPermissions} = useContext(UserContext)
    const { schemaState } = useSelector((state: AppState) => ({
        schemaState: state.schema,
    }), shallowEqual);
    const status = useSelector(datasetStatus, isEqual)
    const schemaRanges = useSelector((state: AppState) => state.dataset.schemaRanges)
    const { datasetId } = useParams<{datasetId: string}>()
    const [initiated, setInitiated] = useState<boolean>(false);
    const [errorInConsole, setErrorInConsole] = useState<ErrorConsole>({ id: 0, message: '' })
    const [errorAPI, setErrorAPI] = useState<string | false>(false)

    useEffect(() => {
        if (!initiated && schemaState.features) {
            setInitiated(true);
        }
    }, [schemaState, initiated])

    const handleValidateSchema = useCallback((data: {txt?: string, protection: string}) => {
        if (!datasetId || !status) return;
        const { txt, protection } = data;
        dispatch(SchemaToggleEditableRanges(false))
        const saveRelationships = async () => {
            try {
                const ranges = { ranges_spec: schemaRanges ? schemaRanges : {} }
                const saving = await apiHandler.editDatasetRelationship(parseInt(datasetId), { relationship_spec: txt, protection_spec: protection, ...ranges })
                // let perm = 0;
                // if (hasPermissions('dataset.editAny')) perm = 1;
                // else if (hasPermissions('dataset.editOwn')) perm = 2;
                // dispatch(LoadDataset(parseInt(datasetId), perm, user?.id))
                return {error: false}
            } catch (e) {
                if (axios.isAxiosError(e)) {
                    setErrorAPI((e as AxiosError).response?.data || '')
                    return {error: true, message: (e as AxiosError).response?.data}
                } else {
                    return {error: true, message: 'unknown error'}
                }
            }
        }

        const prepare = async () => {
            try {
                await apiHandler.prepareDataset(parseInt(datasetId));
                dispatch(ReloadDataset(parseInt(datasetId)))
                dispatch(AddClick())
                history.push(`/datasets/${datasetId}/access-rules`)
            } catch (e) {
                console.log("Error while prepare Dataset")
            }
        }

        const saveAndPrepare = async () => {
            try {
                const terminal = await saveRelationships()
                if (!terminal.error) {
                    prepare()
                } else {
                    setErrorInConsole({ id: errorInConsole.id + 1, message: terminal.message })
                }
            } catch (e) {
                console.log("Error Saving Schema");
            }
        }

        saveAndPrepare();
        dispatch(AddMonitorToManager(parseInt(datasetId), status))
    }, [datasetId, status, dispatch, schemaRanges, errorInConsole]);

    if (schemaState.features) {
        return <ShowSchema handleNext={handleValidateSchema} errorAPI={{get: errorAPI, set: setErrorAPI}} errorInConsole={errorInConsole} />
    } else if (schemaState.checkingState === 'FAILURE') {
        return <ErrorDisplay message={schemaState.errorInSchema} />
    } else {
        return <Loading label="Auto-detection of data schema and ranges..." secondLabel="This step might take a few minutes." />
    }
}

export default Schema
