import { FC, useEffect, useState } from 'react'
import { RouteComponentProps, useParams } from 'react-router-dom'
import { Body } from 'styles/layout'
import UserForm from 'components/forms/UserForm'

const UserElement: FC<RouteComponentProps<any>> = props => {
    const { id } = useParams<{id: string}>();
    const [user, setUser] = useState<number | 'profile'>();

    useEffect(() => {
        if (id) {
            setUser(Number(id))
        } else {
            setUser('profile')
        }

    }, [id])

    return (
        <Body>
            <UserForm userId={user} />
        </Body>
    )
}

export default UserElement
