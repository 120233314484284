import styled from 'styled-components';
import theme from 'styles/muiTheme';

const transition = '200ms ease-in';

export const World = styled.div<{ logged: boolean }>`
    margin: 0;
    background: #ffffff;
    ${({ logged }) => logged ?
        `
        display: grid;
        grid-template-areas: 'sidenav main' 'sidenav main';
        grid-template-rows: 70px 1fr;
        grid-template-columns: 70px 1fr;` :
        `
        display: flex;
        justify-content: center;
        align-items: center;
        background: #eaf3f3;
        `
    }
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 14;
    /* transition: all ${transition}; */
    min-height: 100vh;
    width: 100%;
`

interface ISideNavProps {
    isCollapsed: boolean,
}

export const SideNav = styled.nav<ISideNavProps>`
    background: ${theme.palette.primary.dark};
    grid-area: sidenav;
    width: ${({ isCollapsed }) => isCollapsed ? '70px' : '256px'};
    /* transition: all ${transition}; */
    position: fixed;
    height: 100%;
    overflow: hidden;
    /* white-space: nowrap; */

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        position: absolute;
        top: 230px;
        width: ${({ isCollapsed }) => isCollapsed ? '70px' : '256px'};
        /* transition: all ${transition}; */

        li {
            a {
                color: ${theme.palette.primary.contrastText};
                text-decoration: none;
                min-height: 48px;
                display: grid;
                grid-template-columns: ${({ isCollapsed }) => isCollapsed ? '1fr' : '50px 1fr'};
                align-items: center;
                position: relative;
                padding-left: ${({ isCollapsed }) => isCollapsed ? '0px' : '20px'};
                justify-content: ${({ isCollapsed }) => isCollapsed ? 'center' : 'left'};
                text-align: ${({ isCollapsed }) => isCollapsed ? 'center' : 'left'};
                /* transition: all ${transition}; */

                &:hover {
                    background: ${theme.palette.primary.main};
                }

                svg {
                    margin: 0 ${({ isCollapsed }) => isCollapsed ? 'auto' : ''};
                }
            }

            & a.active {
                background: ${theme.palette.primary.main};

                &:after {
                    font-family: "Material Icons";
                    content:  ${({ isCollapsed }) => isCollapsed ? '' : '"keyboard_arrow_right"'};
                    font-size: 1.2rem;
                    right: 10px;
                    position: absolute;
                }
            }
        }
    }

    .collapseIcon {
        position: absolute;
        bottom: 20px;
        transform: ${({ isCollapsed }) => isCollapsed ? 'rotate(0)' : 'rotate(180deg)'};
        cursor: pointer;
        left: ${({ isCollapsed }) => isCollapsed ? '20px' : '210px'};
        /* transition: all ${transition}; */
        color: ${theme.palette.primary.main};

        &:hover {
            color: white;
        }
    }

    div.brandLogo {
        text-align: center;
        padding: 50px 0;
        padding: ${({ isCollapsed }) => isCollapsed ? '16px' : '50px'} 0;
        color: ${theme.palette.primary.contrastText};
        font-size: 1rem;
        transition: all ${transition};
        
        .logo {
            width: ${({ isCollapsed }) => isCollapsed ? '40px' : '100px'};
            /* transition: all ${transition}; */
        }
    }
    
`

export const TopNav = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    padding: 0 30px;
    z-index: 10 !important;

    > div {
        display: flex;
        align-items: center;
        gap: 10px;
    }
`

type MainProps = {
    isCollapsed: boolean,
    logged: boolean,
}

const calculateMainMargin = (isCollapsed: boolean, isLogged: boolean) => {
    if (!isLogged || isCollapsed) return '0';
    else return 'calc(256px - 70px)'
}

export const Main = styled.div<MainProps>`
    grid-area: main;
    margin-left: ${({ isCollapsed, logged }) => calculateMainMargin(isCollapsed, logged)};
    /* transition: all ${transition}; */
`;

export const Body = styled.div`
    padding: 0 32px 32px;
    z-index: 1;
`

export const MenuActions = styled.div`

    .action {
        display: flex;
        align-items: center;
        margin-left: 16px;

        > svg,
        > .badge > svg {
            cursor: pointer;
        }

        > .menu {
            position: absolute;
            top: 45px;
            overflow: hidden;
            white-space: nowrap;
            height: 0;
            transition: all ${transition};
            width: 200px;
            z-index: 10;
        }

        &:hover {
            > svg,
            .badge > svg {
                color: ${theme.palette.primary.main};
            }

            > .menu {
                height: inherit;
            }
        }

        &.first .menu { right: 125px; }
        &.second .menu { right: 75px; }
        &.third .menu { right: 25px; }
    }
`

export const WaitingTab = styled.div`
    display: flex;
    color: ${theme.palette.grey[400]};
    flex-direction: column;
    gap: 32px;
    margin-top: 150px;
    justify-content: center;
    align-items: center;
`