import UserForm from 'components/forms/UserForm'
import { Body } from 'styles/layout'

const UserCreate = () => {
    return (
        <Body>
            <UserForm userId="create" />
        </Body>
    )
}

export default UserCreate
