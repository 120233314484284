import { Typography } from '@mui/material'
import { FC, ReactNode } from 'react'
import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1)
        },
        h1: {
            marginBottom: theme.spacing(2)
        },
        h2: {
            marginBottom: theme.spacing(2)
        },
        h3: {
            marginBottom: theme.spacing(0),
        },
        noGutter: {
            marginBottom: 0,
        }
    })
);

type TitleType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

interface ITitleProps {
    children: ReactNode,
    variant: TitleType,
    comp?: any,
    className?: string,
    noGutter?: boolean,
}

export const Title: FC<ITitleProps> = props => {
    const classes = useStyles();
    const { children, variant, comp, className, noGutter } = props

    const getClasses = () => {
        const el = comp ? comp : variant;
        let style = `${classes.title}`
        switch (el) {
            case 'h1':
                // return `${classes.title} ${classes.h1} ${className}`;
                style += ` ${classes.h1}`
                break;
            case 'h2':
                // return `${classes.title} ${classes.h2} ${className}`;
                style += ` ${classes.h2}`
                break;
            case 'h3':
                // return `${classes.title} ${classes.h3} ${className}`;
                style += ` ${classes.h3}`
                break;
            default:
                // return `${classes.title} ${className}`;
        }
        style += ` ${noGutter ? `${classes.noGutter}` : ``} ${className}`
        return style
    }

    return (
        <Typography
            variant={variant}
            className={getClasses()}
            component={comp === 'div' ? 'div' : comp}
        >
            {children}
        </Typography>)
}