import React from 'react'
import { Switch, Route } from 'react-router';
import { useLocation } from 'react-router-dom'
import LoginPage from 'pages/LoginPage';
import ProtectedRoute from 'components/auth/ProtectedRoute';
import DatasetList from 'pages/DatasetList';
import DatasetEdit from 'pages/DatasetEdit';
import DatasetCreate from 'pages/DatasetCreate';
import GroupList from 'pages/GroupList';
import GroupCreate from 'pages/GroupCreate';
import GroupEdit from 'pages/GroupEdit';
import UserList from 'pages/UserList';
import PrivacyPoliciesList from 'pages/PrivacyPoliciesList';
import PrivacyPoliciesCreate from 'pages/PrivacyPoliciesCreate';
import PrivacyPoliciesEdit from 'pages/PrivacyPoliciesEdit';
import SettingList from 'pages/SettingList';
import LogList from 'pages/LogList';
import UserCreate from 'pages/UserCreate';
import Tests from 'pages/Tests';
import UserElement from 'pages/UserElement';
import SignupPage from 'pages/SignupPage';
import UserProfile from 'pages/UserProfile';
import DataConnectionList from 'pages/DataConnectionList';
import DataConnectionCreate from 'pages/DataConnectionCreate';
import DataConnectionShow from 'pages/DataConnectionShow';
import LostPassword from 'pages/LostPassword';
import HealthList from 'pages/HealthList';

const Routes = () => {
    const location = useLocation()

    return (
        <Switch>
            <Route path="/login" component={LoginPage} />
            <Route path="/signup" component={SignupPage} />
            <Route path="/forgotten-password" component={LostPassword} />
            <ProtectedRoute path="/" component={DatasetList} exact />
            <ProtectedRoute path="/datasets" component={DatasetList} exact key={Date.now()} />
            <ProtectedRoute path="/datasets/create" component={DatasetCreate} exact />
            <ProtectedRoute path="/datasets/:datasetId" component={DatasetEdit} />
            <ProtectedRoute path="/data-connections" component={DataConnectionList} exact key={Date.now()} />
            <ProtectedRoute path="/data-connections/create" component={DataConnectionCreate} exact />
            <ProtectedRoute path="/data-connections/:id" component={DataConnectionShow} />
            <ProtectedRoute path="/users" component={UserList} exact key={Date.now()} />
            <ProtectedRoute path="/users/create" component={UserCreate} exact />
            <ProtectedRoute path="/users/:id" component={UserElement} />
            <ProtectedRoute path="/profile" component={UserProfile} key={Date.now()} />
            <ProtectedRoute path="/groups" component={GroupList} exact key={Date.now()} />
            <ProtectedRoute path="/groups/create" component={GroupCreate} exact />
            <ProtectedRoute path="/groups/:id" component={GroupEdit} />
            <ProtectedRoute path="/privacy-policies" component={PrivacyPoliciesList} exact key={Date.now()} />
            <ProtectedRoute path="/privacy-policies/create" component={PrivacyPoliciesCreate} exact />
            <ProtectedRoute path="/privacy-policies/:id" component={PrivacyPoliciesEdit} />
            <ProtectedRoute path="/settings" component={SettingList} key={Date.now()} />
            <ProtectedRoute path="/audit" component={LogList} key={Date.now()} />
            <ProtectedRoute path="/health" component={HealthList} key={location.key} />
            <Route exact path="/testing" component={Tests} />
        </Switch>
    )
}

export default Routes
