import {
    Button,
    IconButton,
    Link,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'
import { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store/configureStore'
import { Edit } from '@mui/icons-material';
import { formatDateString, formatNumber } from 'utils/misc';
import { DisplayStatus } from 'components/stylized/chips';
import { EditDataset } from 'store/actions/DatasetActions';
import DetailViewer from './DetailViewer';
import { useEffect } from 'react';
import { getListConnectors } from 'assets/data-connections/connectorsData';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const useStyles = makeStyles(() => ({
    editMode: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 16
    },
    w700: {
        width: 700
    },
    w400: {
        width: 400
    }
}))

type ViewState = {
    source: boolean
}

const InfoAndSource = () => {
    const { dataset, owner, canEdit, dataConnection, monitorManager } = useSelector((state: AppState) => ({
        dataset: state.dataset.dataset,
        owner: state.dataset.owner,
        canEdit: state.dataset.canEdit,
        dataConnection: state.dataset.dataConnection,
        monitorManager: state.monitor
    }), shallowEqual);
    const classes = useStyles();
    const dispatch = useDispatch();

    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [inEditMode, setInEditMode] = useState<boolean>(false);
    const [viewState, setViewState] = useState<ViewState>({ source: false })
    const [record, setRecord] = useState<string>(dataset?.human_description || '');
    const [ownerName, setOwnerName] = useState<string>('...');
    const [connectorType, setConnectorType] = useState<{name: string | JSX.Element, logo: string}>({name: '', logo: ''});

    useEffect(() => {
        if (owner) {
            setOwnerName(`${owner.email} ${owner.username && ` - ${owner.username}`}`)
        }
    }, [owner]);

    useEffect(() => {
        const base = getListConnectors().find((w) => w.name === dataConnection?.connector_type)
        if (base?.name) {
            setConnectorType({ name: base.labelLong || base.label, logo: base.logo });
        }
    }, [dataConnection])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRecord(event.target.value);
        setIsDirty(true)
    }

    const handleSave = async (event: React.SyntheticEvent) => {
        event.preventDefault();
        dispatch(EditDataset(dataset!.id, { human_description: record }));
        setIsDirty(false);
        setInEditMode(false);
    }

    const showSources = () => {
        if (!dataset) return ''
        const limitChar = 62
        if (!dataset.dataconnection_id) {
            return JSON.parse(dataset.source)[0].split('/').pop()
        } else {
            let source = JSON.parse(dataset.source)
            if (source[0] && source[0].startsWith('file:///')) {
                source = source[0].substr(source[0].lastIndexOf('/') + 1)
            } else {
                source = source.join(', ')
            }
            const truncatedSource = source.substring(0, limitChar)
            const showButton = source.length > limitChar
            if (viewState.source) {
                return (
                    <div className={classes.editMode}>
                        <Typography variant="body2">{source}</Typography>
                        {showButton && <Link href="#"
                            sx={{width: 60, textAlign: 'center'}}
                            onClick={(e) => {
                            e.preventDefault()
                            setViewState(s => ({...s, source: false}))
                        }}>Hide</Link>}
                    </div>
                )
            } else {
                return (
                    <div className={classes.editMode}>
                        <Typography variant="body2">{truncatedSource}{source.length > limitChar && '...'}</Typography>
                        {showButton && <Link href="#"
                            sx={{width: 60, textAlign: 'center'}}
                            onClick={(e) => {
                            e.preventDefault()
                            setViewState(s => ({...s, source: true}))
                        }}>See all</Link>}
                    </div>
                )
            }
        }
    }

    const displayDataConnection = () => {
        if(dataset) {
            if(dataset.dataconnection_id && dataset.dataconnection_id !== 1) {
                return dataConnection?.name
            } else {
                return <em>None</em>
            }
        } else {
            return null
        }
    }

    return (
        <>
            <Table size="small" className={classes.w700}>
                <colgroup>
                    <col style={{ width: '40%' }} />
                    <col />
                </colgroup>
                <TableBody>
                    <TableRow>
                        <TableCell variant="head" className={classes.w400}>Description</TableCell>
                        <TableCell>
                            {
                                inEditMode ? (
                                        <div className={classes.editMode}>
                                            <TextField onChange={handleChange} value={record} fullWidth />
                                            <Button onClick={handleSave} size="small" color="primary" disabled={!isDirty}>
                                                Save
                                            </Button>
                                        </div>
                                    ) : (
                                        <div className={classes.editMode}>
                                            <Typography variant="body2">{record !== '' ? record : dataset?.human_description}</Typography>
                                            {canEdit && <IconButton size="small" onClick={() => setInEditMode(true)}><Edit /></IconButton>}
                                        </div>
                                    )
                            }
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell variant="head">Slug</TableCell>
                        <TableCell>
                            <Typography variant="body2">{dataset?.name}</Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell variant="head">Data Connection</TableCell>
                        <TableCell>{displayDataConnection()}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell variant="head">Source type</TableCell>
                        <TableCell>
                            {
                                dataset?.dataconnection_id && dataset.dataconnection_id !== 1 ? (
                                    <Typography variant="body2" style={{display: 'flex', alignItems: 'center', gap: 8}}>
                                        <img src={connectorType.logo} style={{height: 20}} alt={connectorType.name.toString()}/> {connectorType.name}
                                    </Typography>
                                    ) : (
                                    <Typography variant="body2" style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                                        <CloudUploadIcon fontSize="small" /> File upload
                                    </Typography>
                                )
                            }
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell variant="head">Source</TableCell>
                        <TableCell>
                            {showSources()}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell variant="head">Source total rows</TableCell>
                        <TableCell>
                            <Typography variant="body2">{dataset?.source_size ? formatNumber(dataset.source_size) : 'Unknown'}</Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell variant="head">Owner</TableCell>
                        <TableCell>
                            <Typography variant="body2">{ownerName}</Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell variant="head">Created</TableCell>
                        <TableCell>
                            <Typography variant="body2">{dataset && formatDateString(dataset.created)}</Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell variant="head">Last modified</TableCell>
                        <TableCell>
                            <Typography variant="body2">{dataset && formatDateString(dataset.last_modified as string)}</Typography>
                        </TableCell>
                        </TableRow>
                    <TableRow>
                        <TableCell variant="head">Status</TableCell>
                        <TableCell><DisplayStatus status={dataset?.status} /></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell variant="head" style={{ verticalAlign: 'top' }}>Status detail</TableCell>
                        <TableCell>
                            {(dataset && monitorManager[dataset.id]) && <DetailViewer dataset={dataset}/>}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </>
    )
}

export default InfoAndSource
