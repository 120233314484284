import React, { createContext, useCallback, useEffect, useState } from 'react'
import ReactDOM from 'react-dom';
import { BrowserRouter, useLocation } from 'react-router-dom';
import CustomPrompt from 'components/dialogs/CustomPrompt';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import theme from 'styles/muiTheme';
import App from 'App';
const basename = (window as any).env.BASENAME;

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


export const RouterContext = createContext({to: '', from: ''})
export const RouterProvider = ({ children }: {children: any}) => {
    const location = useLocation();
    const [route, setRoute] = useState<{to: string, from: string}>({
        to: location.pathname,
        from: location.pathname,
    })
    
    useEffect(() => {
        setRoute((prev) => ({to: location.pathname, from: prev.to}))
    }, [location])

    return (
        <RouterContext.Provider value={route}>
            {children}
        </RouterContext.Provider>
    )
}

const Router = () => {

    const useConfirmation = useCallback((message: any, callback: any) => {
        const node: any = document.getElementById("cprompt");
        const data = JSON.parse(message);

        const cleanUp = (answer: boolean) => {
            ReactDOM.unmountComponentAtNode(node);
            callback(answer)
        }

        ReactDOM.render((
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <CustomPrompt message={data.message} cancel={data.cancel} confirm={data.confirm} cleanUp={cleanUp} />
                </ThemeProvider>
            </StyledEngineProvider>), node);
    }, [])

    return (
        <BrowserRouter getUserConfirmation={useConfirmation} basename={basename}>
            <RouterProvider>
                <CssBaseline />
                <App />
            </RouterProvider>
        </BrowserRouter>
    )
}

export default Router
