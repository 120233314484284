import ImgGCS from 'images/gcs.png'
import ImgS3 from 'images/aws-s3-logo.png'
import ImgBigQuery from 'images/bigquery.png'
import ImgGCSQL from 'images/googlecloudsql.png'
import ImgAzureBlobStorage from 'images/azureblobstorage.png'
import ImgAzureSQL from 'images/azuresql.png'
import ImgDatabricks from 'images/databricks.png'
import ImgSnowflake from 'images/snowflake.png'
import ImgMySQL from 'images/mysql.png'
import ImgMongoDB from 'images/mongoDB.png'
import ImgCosmosDB from 'images/cosmosdb.png'
import ImgHDFS from 'images/hdfs.png'
import ImgAthena from 'images/aws-athena.png'
import ImgPostgreSQL from 'images/postgresql.png'
import ImgSynapse from 'images/azuresynapse.png'
import ImgSQLServer from 'images/sqlserver.png'
import ImgHive from 'images/hive.png'
import ImgElasticsearch from 'images/elasticsearch.png'
import ImgOracle from 'images/oracleDB.png'
import ImgRedshift from 'images/aws-redshift-logo.svg'
import ImgHTTP from 'images/http.svg'

export interface Connector {
    name: string,
    nameFormat: string,
    label: string | JSX.Element,
    labelLong?: string | JSX.Element,
    isAvailable: boolean,
    sourceType: string,
}

export interface ConnectorWithLogo extends Connector {
    logo: string
}

export const getConnectorIcon = (connector: string): string => {
    switch (connector) {
        case 'gcs':
            return ImgGCS
        case 's3':
            return ImgS3
        case 'azure':
            return ImgAzureBlobStorage
        case 'snowflake':
            return ImgSnowflake
        case 'bigquery':
            return ImgBigQuery
        case 'redshift':
            return ImgRedshift
        case 'athena':
            return ImgAthena
        case 'synapse':
            return ImgSynapse
        case 'googlecloudsql':
            return ImgGCSQL
        case 'azuresql':
            return ImgAzureSQL
        case 'mysql':
            return ImgMySQL
        case 'postgres':
            return ImgPostgreSQL
        case 'sqlserver':
            return ImgSQLServer
        case 'hive':
            return ImgHive
        case 'databricks':
            return ImgDatabricks
        case 'hdfs':
            return ImgHDFS
        case 'oracle':
            return ImgOracle
        case 'elasticsearch':
            return ImgElasticsearch
        case 'mongodb':
            return ImgMongoDB
        case 'cosmosdb':
            return ImgCosmosDB
        case 'http':
            return ImgHTTP
        default:
            return ''
    }
}


// Connector List :
// name: quick access to a connector, like an ID
// nameFormat: name to the endpoint dataconnection_format
// label: quick label (ex: GCS)
// label: complete label name (ex: Google Cloud Storage)
// logo: URL to logo image
// isAvailable: boolean, define if disable or not
// source_type: define the data source_type to send at the dataset creation

export const getListConnectors = (): ConnectorWithLogo[] => ([
        { name: 'postgres', nameFormat: 'postgres', label: 'PostgreSQL', logo: ImgPostgreSQL, isAvailable: true, sourceType: 'postgres_table' },
        { name: 'mysql', nameFormat: 'mysql', label: 'MySQL', logo: ImgMySQL, isAvailable: true, sourceType: 'mysql_table' },
        { name: 'sqlserver', nameFormat: 'sqlserver', label: 'SQL Server', logo: ImgSQLServer, isAvailable: true, sourceType: 'sqlserver_table' },
        { name: 'bigquery', nameFormat: 'bigquery', label: 'BigQuery', logo: ImgBigQuery, isAvailable: true, sourceType: 'bigquery_table' },
        { name: 'redshift', nameFormat: 'redshift', label: 'Redshift', logo: ImgRedshift, isAvailable: true, sourceType: 'redshift_table' },
        { name: 'synapse', nameFormat: 'synapse', label: 'Synapse', logo: ImgSynapse, isAvailable: true, sourceType: 'synapse_table' },
        { name: 'azuresql', nameFormat: 'azuresql', label: 'Azure SQL', logo: ImgAzureSQL, isAvailable: true, sourceType: 'azuresql_table' },
        { name: 'gcs', nameFormat: 'gcs', label: 'GCS', labelLong: 'Google Cloud Storage', logo: ImgGCS, isAvailable: true, sourceType: 'csv' },
        { name: 's3', nameFormat: 's3', label: 'S3', labelLong: 'Amazon S3', logo: ImgS3, isAvailable: true, sourceType: 'csv' },
        { name: 'azureblob', nameFormat: 'azureblob', label: <>Azure<br /> Blob Storage</>, logo: ImgAzureBlobStorage, isAvailable: true, sourceType: 'csv' },
        { name: 'databricks', nameFormat: 'databricks', label: 'Databricks', logo: ImgDatabricks, isAvailable: true, sourceType: 'databricks_table' },
        // { name: 'snowflake', nameFormat: 'snowflake', label: 'Snowflake', logo: ImgSnowflake, isAvailable: false, sourceType: '' },
        // { name: 'athena', nameFormat: 'athena', label: 'Athena', logo: ImgAthena, isAvailable: false, sourceType: '' },
        { name: 'mongodb', nameFormat: 'mongodb', label: 'MongoDB', logo: ImgMongoDB, isAvailable: true, sourceType: 'mongodb_table' },
        // { name: 'hive', nameFormat: 'hive', label: 'Hive', logo: ImgHive, isAvailable: true, sourceType: 'hive_table' },
        // { name: 'hdfs', nameFormat: 'hdfs', label: 'HDFS', logo: ImgHDFS, isAvailable: false, sourceType: '' },
        // { name: 'oracle', nameFormat: 'oracle', label: 'Oracle', logo: ImgOracle, isAvailable: false, sourceType: '' },
        // { name: 'googlecloudsql', nameFormat: 'googlecloudsql', label: <>Google<br /> Cloud SQL</>, logo: ImgGCSQL, isAvailable: false, sourceType: '' },
        // { name: 'elasticsearch', label: 'Elasticsearch', logo: ImgElasticsearch, isAvailable: false, sourceType: '' },
        // { name: 'cosmosdb', label: 'Cosmos DB', logo: ImgCosmosDB, isAvailable: false, sourceType: '' },
        // { name: 'http', label: 'HTTP', logo: ImgHTTP, isAvailable: false, sourceType: '' },
    ])
