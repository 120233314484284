// Types
import { ErrorInForm } from "types/forms";

// Clean Error on edited field
export const cleanError = (field: string | string[], list: ErrorInForm[], setter: (list: any) => void) => {
    const filtered = Array.isArray(field) && field.length === 1 ? field[0] : field;
    if (Array.isArray(filtered)) {
        console.log(filtered)
            filtered.forEach((f) => console.log(f))
            console.log("handle fields");
            return;
        }

        if (list.find((error: ErrorInForm) => error.name === filtered)) {
            const filteredErrors = list.filter((error: ErrorInForm) => error.name !== filtered);
            setter(filteredErrors);
        }
    }
