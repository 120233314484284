import { AppActionDispatchTypes, APP_SET_RESOURCE, APP_UNSET_RESOURCE } from "store/actions/AppActionsType"

export interface AppBaseData {
    loadedResource: string | undefined
}

const initialData: AppBaseData = {
    loadedResource: undefined
}


const appReducer = (state: AppBaseData = initialData, action: AppActionDispatchTypes): AppBaseData => {
    switch (action.type) {
        case APP_SET_RESOURCE:
            return {
                ...state,
                loadedResource: action.payload
            }
        case APP_UNSET_RESOURCE:
            return {
                ...state,
                loadedResource: undefined
            }
        default:
            return state
    }
}

export default appReducer