const isSarusHost = (): boolean => {
    const sarusHost = 'sarus.tech'
    const actualHost = window.location.hostname
    return actualHost.includes(sarusHost)
}

const globalConfig = {
    landingPage: '/datasets',                               // HomePage of the app
    apiURL: '/v1/',                                         // URL of API
    development: window.location.hostname === 'localhost',  // Environment, is in development
    activateGoogleLogin: false,                             // Display (or not) the google login
    isSarusHost: isSarusHost(),                             // is Sarus the host of the instance

    // Misc
    gridSameConf: false,

    // Notifications
    notificationDuration: 3000,

    dataset: {
        maxColumn: 200,
        minRow: 70
    }
}

const uniqueGridDataSettings = {
    defaultPage: 5,
    nbElemPerPage: [2, 5, 10],
}

export const gridConfig = {
    // Resources shown per page (List)
    defaultPageSize: {
        datasets: globalConfig.gridSameConf ? uniqueGridDataSettings.defaultPage : 10,
        users: globalConfig.gridSameConf ? uniqueGridDataSettings.defaultPage : 10,
        groups: globalConfig.gridSameConf ? uniqueGridDataSettings.defaultPage : 10,
        privacyPolicies: globalConfig.gridSameConf ? uniqueGridDataSettings.defaultPage : 10,
        settings: globalConfig.gridSameConf ? uniqueGridDataSettings.defaultPage : 10,
    },

    nbElemPerPage: {
        datasets: globalConfig.gridSameConf ? uniqueGridDataSettings.nbElemPerPage : [5, 10, 25],
        users: globalConfig.gridSameConf ? uniqueGridDataSettings.nbElemPerPage : [5, 10, 25],
        groups: globalConfig.gridSameConf ? uniqueGridDataSettings.nbElemPerPage : [5, 10, 25],
        privacyPolicies: globalConfig.gridSameConf ? uniqueGridDataSettings.nbElemPerPage : [5, 10, 25],
        settings: globalConfig.gridSameConf ? uniqueGridDataSettings.nbElemPerPage : [5, 10, 25],
    },
}

export default globalConfig;